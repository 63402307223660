import { HttpParams } from '@angular/common/http';
import { Injectable, Optional, SkipSelf } from '@angular/core';

import { ListDataRequest } from '@hrz/core/models/list-data-request';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class ListRequestService {

  constructor(
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: ListRequestService
    ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('ListRequestService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  getSearchParams(request: ListDataRequest): HttpParams {
    let params: HttpParams = new HttpParams()
      .set('page', request.page && request.page.toString())
      .set('noOfRecords', request.rowsPerPage && request.rowsPerPage.toString())
      .set('sortBy', request.sortBy)
      .set('sortOrder', request.sortOrder)
      .set('intensiveSearch', `${request.intensiveSearch}`);

    if (request.filters) {
      Object.keys(request.filters)
        .filter(key => request.filters[key])
        .map(key => params = params.append(key, request.filters[key]));
    }

    if (request.searchBar) {
      params.append('SearchBar', request.searchBar);
    }

    if (request.status) {
      params.append('Status', request.status);
    }

    if (request.filter) {
      request.filter.forEach((value: string, key: string) => {
        params.append(key, value);
      });
    }

    return params;
  }

  getCaseSearchParams(request: ListDataRequest): Map<string, any> {
    const params: Map<string, any> = new Map<string, any>();

    params.set('page', request.page && request.page.toString());
    params.set('noOfRecords', request.rowsPerPage && request.rowsPerPage.toString());

    params.set('sortBy', request.sortBy);
    params.set('sortOrder', request.sortOrder);

    if (request.filters) {
      Object.keys(request.filters)
        .filter(key => request.filters[key])
        .map(key => params.set(key, request.filters[key]));
    }

    if (request.searchBar) {
      params.set('SearchBar', request.searchBar);
    }

    if (request.status) {
      params.set('Status', request.status);
    }

    if (request.filter) {
      request.filter.forEach((value: string, key: string) => {
        params.set(key, value);
      });
    }

    if (request.multiSelectFilter) {
      request.multiSelectFilter.forEach((value: string[], key: string) => {
        if (value.length > 0) {
          params.set(key, value);
        }
      });
    }

    return params;
  }
}
