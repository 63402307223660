import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { JobInvoice } from '@hrz/core/models/job-invoice';
import { Enum } from '@hrz/core/models/enum';
import { JobInvoiceService } from '../../services/job-invoice.service';
import { EnumService } from '@hrz/core/services/enum.service';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { SpinnerService } from '@hrz/core/services/spinner.service';

@Component({
  selector: 'authorize-step1',
  template: `
    <div
      bsModal
      #myModal="bs-modal"
      class="modal hide fade overlayable"
      role="dialog"
      aria-labelledby="myModalLabel"
      tabindex="1"
      aria-hidden="true"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false">
      <div class="modal-dialog modal-customsize" style="width: 80%; " role="document">
        <div class="modal-content" style="height:100%;">
          <div class="spinner-wrapper">
            <div class="modal-header">
              <h4 class="modal-title">{{ 'DOSSIER.JOBINVOICE.REQUESTCREDITNOTE' | translate }}</h4>
            </div>
            <div class="modal-body">
              <div class="form-group row" validation-block="RequestCreditReason">
                <label required class="col-md-4 col-lg-3  form-control-label" for="model">{{
                  'DOSSIER.JOBINVOICE.CREDIT_NOTE_REASON' | translate
                }}</label>
                <div class="col-md-6 col-lg-5 col-xl-4">
                  <select
                    id="model"
                    name="Model"
                    [(ngModel)]="Context.RequestCreditReason"
                    class="form-control"
                    size="1">
                    <option [ngValue]="null" selected="selected">-- {{ 'DOSSIER.JOBINVOICE.NO_REASON_SELECTED' | translate }} --</option>
                    <option [ngValue]="undefined" hidden selected="selected">
                      -- {{ 'DOSSIER.JOBINVOICE.SELECT_REASON' | translate }} --
                    </option>
                    <option *ngFor="let at of reasons" [ngValue]="at.Key">{{ at.Value }}</option>
                  </select>
                  <div #validationOutput></div>
                </div>
              </div>

              <div class="card-footer">
                <div class="row">
                  <div class="col-md-8 offset-md-4 offset-lg-3">
                    <button type="button" class="btn btn-default" (click)="clickCancel()">{{ 'COMMON.CANCEL' | translate }}</button>
                    <button type="button" class="btn btn-primary" (click)="clickOk()">{{ 'COMMON.OK' | translate }}</button>
                  </div>
                </div>
              </div>
            </div>
            <spinner name="creditNoteReasonSpinner" [show]="true" [message]="'COMMON.PLEASE_WAIT' | translate"></spinner>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class CreditNoteReasonModal implements OnInit, AfterViewInit, OnDestroy {
  public onOk: Function;
  public onClose: Function;
  public onDestroy: Function;
  public onRequested: Function;
  private _context: JobInvoice;

  set Context(value: JobInvoice) {
    console.log('CreditNoteReasonModal.dossier has been set!');
    this._context = value;
  }
  get Context(): JobInvoice {
    return this._context;
  }

  public DossierId: number;

  // This will be called when the modal is being closed / destroyed.
  reasons: Enum[];
  private closeModal: Function;

  @ViewChild('myModal') private childModal: ModalDirective;
  @ViewChild('step2Form') step2Form: FormGroup;

  constructor(
    private toasterService: ToasterService,
    private jobInvoiceService: JobInvoiceService,
    private enumService: EnumService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    console.log('CreditNoteReasonModal.ngOnInit');
    this.enumService.getCreditNoteReaons().then(results => {
      this.reasons = results;
    });
  }

  ngAfterViewInit(): void {
    console.log('CreditNoteReasonModal.ngAfterViewInit');
    this.childModal.show();
    setTimeout(() => {
      this.spinnerService.hide('creditNoteReasonSpinner');
    }, 500);
  }
  ngOnDestroy(): void {
    console.log('CreditNoteReasonModal.ngOnDestroy');
    if (this.onDestroy) { this.onDestroy(); }
  }

  clickClose(): void {
    console.log('CreditNoteReasonModal.clickClose()');
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickCancel(): void {
    console.log('AuthorizeStep1Model.clickCancel()');
    this.clickClose();
  }

  clickOk(): void {
    console.log('AuthorizeStep1Model.clickOk()');
    if (!this.Context.RequestCreditReason || this.Context.RequestCreditReason.length <= 0) {
      this.toasterService.showError(this.translateService.instant('DOSSIER.JOBINVOICE.ERRORS.CREDIT_NOTE_REASON'));
      return;
    }

    this.spinnerService.show('creditNoteReasonSpinner');
    this.jobInvoiceService
      .createCreditNote(this.DossierId, this.Context)
      .then(result => this.handleRequestAuthorizationCommand(result, this))
      .then(_handled => {
        this.spinnerService.hide('creditNoteReasonSpinner');
        if (this.onOk) { this.onOk(); }
        this.onRequested(this.Context);
        this.closeModal();
      })
      .catch(_error => {
        this.toasterService.showError(this.translateService.instant('DOSSIER.JOBINVOICE.ERRORS.FAILED_TO_REQUEST'));
        this.spinnerService.hide('creditNoteReasonSpinner');
      });
  }
  private handleRequestAuthorizationCommand(result: any, scope: any): Promise<boolean> {
    if (result.status === 200) {
      scope.toasterService.showSuccess(this.translateService.instant('COMMON.COMMAND.SUCCESS'));
      scope.Context = result.entity.Entity;
      return Promise.resolve(true);
    } else {
      if (!result.commandValidations || result.commandValidations.length <= 0) {
        scope.toasterService.showError(result);
      } else {
        scope.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED_GENERAL'));
      }
      return Promise.resolve(false);
    }
  }
}
