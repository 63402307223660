export class UrlUtils {
  public static getQueryStringForIds(ids: number[]) {
    let idsQueryString = '';
    ids.forEach(id => {
      idsQueryString += `ids=${id}&`;
    });
    // Remove the trailing ampersand
    idsQueryString = idsQueryString.slice(0, -1);
    return idsQueryString;
  }
}
