import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { SpinnerService } from '@hrz/core/services/spinner.service';

@Component({
  selector: 'spinner',
  styleUrls: ['./spinner.component.scss'],
  template: `
    <div *ngIf="show">
      <div class="overlay">
          <div class="spinner">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
              <span>{{message}}</span>
          </div>
      </div>
    </div>
  `,
})
export class SpinnerComponent implements OnInit, OnDestroy {
  constructor(private spinnerService: SpinnerService) {}

  @Input() name: string;
  @Input() message: string;
  @Input() group: string;

  private isShowing = false;

  @Input()
  get show(): boolean {
    return this.isShowing;
  }

  @Output() showChange = new EventEmitter();

  set show(val: boolean) {
    this.isShowing = val;
    this.showChange.emit(this.isShowing);
  }

  ngOnInit(): void {
    if (!this.name) { throw new Error('Spinner must have a name attribute.'); }

    this.spinnerService._register(this);
  }

  ngOnDestroy(): void {
    this.spinnerService._unregister(this);
  }
}
