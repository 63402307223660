import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { FormHelpers } from '@hrz/core/utils/helpers';
import { FileUpload } from '@hrz/core/models/document/file-upload';
import { Storage } from '@hrz/core/models/document/storage';
import { StructureInfo } from '@hrz/core/models/document/structure-info';

@Component({
  selector: 'tm-file-upload',
  templateUrl: './file-upload.component.html',
})
export class FileUploadComponent implements OnInit, OnChanges {
  @Input() parent: StructureInfo;
  @Input() storages: Storage[];
  fileUploadModel: FileUpload;
  fileUploadForm: FormGroup;

  constructor() {
    this.fileUploadForm = new FormGroup({
      parentPath: new FormControl({ value: '', disabled: true }, Validators.required),
      name: new FormControl(''),
      file: new FormControl(null, Validators.required),
      storage: new FormControl(null, Validators.required),
    });
  }

  ngOnInit() {
    this.setFormValues();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['storages'] && this.storages) {
      this.setFormValues();
    }
  }

  isValid(): boolean {
    return this.fileUploadForm.valid;
  }

  isDirty(): boolean {
    return this.fileUploadForm.dirty;
  }

  getModel(): FileUpload {
    return {
      data: {
        storageConfigurationId: this.fileUploadForm.get('storage').value,
        parentId: this.parent.id,
        Filename: '',
      },
      file: this.fileUploadForm.get('file').value,
    };
  }

  setFormValues() {
    this.fileUploadForm.patchValue({
      parentPath: this.parent.path,
      storage: this.storages && this.storages.length > 0 ? this.storages[0].id : null,
    });
    FormHelpers.markAsPristine(this.fileUploadForm);
  }
}
