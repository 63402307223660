import { Component, Output, Input, EventEmitter } from '@angular/core';
import { File as FileResult } from '@hrz/core/models/document/file';

@Component({
  selector: 'app-photo-list',
  templateUrl: 'photo-list.component.html',
  styleUrls: ['./photo-list.component.scss'],
})
export class PhotoListComponent {
  @Input() photos: FileResult[];
  @Output() onDownload: EventEmitter<FileResult> = new EventEmitter<FileResult>();

  download(photo: FileResult) {
    this.onDownload.emit(photo);
  }
}
