import { Component, EventEmitter, Input, OnInit, Output, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import * as moment from 'moment';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';

import { DateRange } from '@hrz/core/models/scheduler/date-range';
import { AvailableHours } from '@hrz/core/models/available-hours';
import { Dossier } from '@hrz/core/models/dossier';
import { BlockedMinuteOption } from '@hrz/core/models/scheduler/blocked-minute-option';
import { configureBootstrapDatepicker } from '@hrz/core/utils/bootstrap-datepicker-utils';


@Component({
  selector: 'app-edit-dossier-appointment',
  templateUrl: './edit-dossier-appointment.component.html',
})
export class EditDossierAppointmentComponent implements OnInit, AfterViewInit {
  @Input() availableHours: AvailableHours[];
  @Input() currentDate: Date;
  @Input() startTime: Date;
  @Input() endTime: Date;
  @Input() blockedMinutesBefore: number;
  @Input() blockedMinutesAfter: number;
  @Input() dossier: Dossier;
  @Output() onDateChange: EventEmitter<Date> = new EventEmitter<Date>();
  editDossierAppointmentForm: FormGroup;
  isToSloteDisable = true;
  availableStartHours: any[];
  availableEndHours: any[];
  isSlotMatch = false;
  setStartTime: string;
  setEndTime: string;
  setBlockedMinutesBefore: number;
  setBlockedMinutesAfter: number;
  setInavailableHoursEndTime : string; 

  bsDatepickerConfig: Partial<BsDatepickerConfig>;
  minDate: Date;
  setStartHours: any[];
  setEndHours: any[];

  public availableBlockedMinutes: BlockedMinuteOption[] = [
    { text: '00:00', value: 0 },
    { text: '00:30', value: 30 },
    { text: '01:00', value: 60 },
    { text: '01:30', value: 90 },
    { text: '02:00', value: 120 },
    { text: '02:30', value: 150 },
    { text: '03:00', value: 180 },
    { text: '03:30', value: 210 },
    { text: '04:00', value: 240 },
  ];

  constructor(
    private bsLocaleService: BsLocaleService
  ) {}

  ngOnInit() {
    // Boostrap Datepicker config
    this.bsDatepickerConfig = configureBootstrapDatepicker(this.bsLocaleService);

    this.editDossierAppointmentForm = new FormGroup({
      date: new FormControl(null, Validators.required),
      hourRangeStart: new FormControl(null, Validators.required),
      hourRangeEnd: new FormControl(null, Validators.required),
      blockedMinutesBefore: new FormControl(null, Validators.required),
      blockedMinutesAfter: new FormControl(null, Validators.required),
    });
    this.minDate = new Date();
    this.editDossierAppointmentForm.get('date').valueChanges.subscribe(v => this.dateChange(v));
    if (this.currentDate) {
      this.editDossierAppointmentForm.get('date').setValue(this.currentDate);
    }
    this.isToSloteDisable = true;
    this.setStartTime = moment.utc(this.startTime, 'HH:mm').local().format('HH:mm');
    this.setEndTime = ' ' + moment.utc(this.endTime, 'HH:mm').local().format('HH:mm');
    this.setBlockedMinutesBefore = this.blockedMinutesBefore;
    this.setBlockedMinutesAfter = this.blockedMinutesAfter;
    this.setInavailableHoursEndTime = this.setEndTime.toString();


    this.subscribeToFormChanges();
    this.editDossierAppointmentForm.get('blockedMinutesBefore').setValue(this.setBlockedMinutesBefore);
    this.editDossierAppointmentForm.get('blockedMinutesAfter').setValue(this.blockedMinutesAfter);
  }
  ngAfterViewInit(): void {
    this.setAvailabelHours();    
    if (this.isEmptyObject(this.availableStartHours)){
        this.editDossierAppointmentForm.get('hourRangeStart').setValue(this.setStartTime);
        this.editDossierAppointmentForm.get('hourRangeEnd').setValue(this.setInavailableHoursEndTime);
      } else {
        this.editDossierAppointmentForm.get('hourRangeEnd').setValue(this.setEndTime);
        this.editDossierAppointmentForm.get('hourRangeStart').setValue(this.setStartHours);
    }
  }



  subscribeToFormChanges() {
      this.editDossierAppointmentForm.get('hourRangeStart').valueChanges.subscribe(newValue => {
          this.onFromChange(newValue, this.availableHours);
          this.setStartTime = newValue;
      });

      this.editDossierAppointmentForm.get('hourRangeEnd').valueChanges.subscribe(newValue => {
      this.setEndTime = newValue;
    });
     
  }

  setAvailabelHours(): void {
    this.availableStartHours = this.availableHours;
    this.availableStartHours.forEach((element, _index) => {
      if (this.setStartTime === element.displayStartHours) {
        this.setStartHours = element;
      }
    });

    this.availableEndHours = this.availableHours;
    this.availableEndHours.forEach((element, _index) => {
      if (this.setEndTime === element.displayEndHours) {
        this.setEndHours = element;
      }
      if (this.setStartTime == element.displayStartHours) {
        this.isSlotMatch = true;
      }

      if (this.isSlotMatch) {
        element.isdisabled = false;
      } else {
        element.isdisabled = true;
      }
    });
  }

  isEmptyObject(obj) {
    return obj && Object.keys(obj).length === 0;
  }

  isValid(): boolean {
    return this.editDossierAppointmentForm.valid;
  }

  isDirty(): boolean {
    return this.editDossierAppointmentForm.dirty;
  }

  dateChange(value: Date) {
    this.onDateChange.emit(value);
  }

  onFromChange(selectedSlot, availableHoursList) {
    this.isSlotMatch = false;
    this.availableEndHours = availableHoursList;
    this.availableEndHours.forEach((element, _index) => {
      if (selectedSlot) {
        if (selectedSlot.displayStartHours == element.displayStartHours) {
          this.isSlotMatch = true;
        }
      }

      if (this.isSlotMatch) {
        element.isdisabled = false;
      } else {
        element.isdisabled = true;
      }

      if (element.isdisabled == false && this.setEndTime == element.displayEndHours) {
        this.setEndHours = element;
      } else if (element.isdisabled == false && selectedSlot.displayEndHours == element.displayEndHours) {
        this.setEndHours = element;
      }
    });

     this.editDossierAppointmentForm.get('hourRangeEnd').setValue(this.setEndHours);
  }
  concatenateDate(date: moment.Moment, time: moment.Moment): moment.Moment {
    const dateFormat = 'MM/DD/YYYY';
    const timeFormat = 'HH:mm:ss';
    const format = `${dateFormat} ${timeFormat}`;
    const timeMoment =  moment(time, timeFormat);
    var concatValue =  moment(date.format(dateFormat) + ' ' + timeMoment.hours()+':'+timeMoment.minutes(), format);
    return concatValue;
  }

  getModel(): DateRange {
    const date = moment(this.editDossierAppointmentForm.get('date').value as Date);
    const start = this.editDossierAppointmentForm.get('hourRangeStart').value;
    const end = this.editDossierAppointmentForm.get('hourRangeEnd').value;
    let result;
    if (this.isEmptyObject(this.availableStartHours)){
    result = {
      startDate: this.concatenateDate(date, start).utc().format(),
      endDate: this.concatenateDate(date, end).utc().format(),
      blockedMinutesBefore: this.editDossierAppointmentForm.value.blockedMinutesBefore,
      blockedMinutesAfter: this.editDossierAppointmentForm.value.blockedMinutesAfter,
    };
   }
   else {
    result = {
      startDate: this.concatenateDate(date, start.displayStartHours).utc().format(),
      endDate: this.concatenateDate(date, end.displayEndHours).utc().format(),
      blockedMinutesBefore: this.editDossierAppointmentForm.value.blockedMinutesBefore,
      blockedMinutesAfter: this.editDossierAppointmentForm.value.blockedMinutesAfter,
    };
   }
    console.log(result);
    return result;
  }
}
