import * as moment from 'moment';

const getHoursTo = (hours: number) => {
  return hours === 0 ? 24 : hours;
};

export const timeRangeToMoment = (timeFrom: string, timeTo: string, currentDate: Date): { from: moment.Moment; to: moment.Moment } => {
  const from = moment(timeFrom, 'HH:mm').utc();
  const to = moment(timeTo, 'HH:mm').utc();
  const date = moment(currentDate).utc().set({ hour: 0, minute: 0, second: 0 });
  return {
    from: moment(date).add(from.hour(), 'hour').add(from.minute(), 'minute').local(),
    to: moment(date).add(getHoursTo(to.hour()), 'hour').add(to.minute(), 'minute').local(),
  };
};
