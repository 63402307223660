import { Component, Input, Output, EventEmitter } from '@angular/core';
import { File } from '@hrz/core/models/document/file';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
})
export class FileListComponent {
  @Input() files: File[] = [];
  @Input() noFilesMsg = 'DOSSIER.INSTRUCTION.FILE_LIST.NO_FILES';
  @Input() removable = true;

  @Output() onDownload: EventEmitter<File> = new EventEmitter<File>();
  @Output() onRemove: EventEmitter<File> = new EventEmitter<File>();

  download(file: File) {
    this.onDownload.emit(file);
  }

  remove(file: File) {
    this.onRemove.emit(file);
  }
}
