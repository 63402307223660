import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { BaseActionModal } from '../base/base-action.modal';
import { FileShareType } from '@hrz/core/models/document/file-share-type';

@Component({
  templateUrl: './file-share.modal.html',
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class FileShareModal extends BaseActionModal implements OnInit {
  fileShareTypeEnum = FileShareType;
  fileShareType: FileShareType;
  @ViewChild('fileShareModal') childModal: ModalDirective;

  constructor() {
    super();
    this.fileShareType = FileShareType.Organization;
  }

  ngOnInit() {}

  onTabSelect(shareType: FileShareType) {
    this.fileShareType = shareType;
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    switch (this.fileShareType) {
      case FileShareType.Email:
        this.shareByEmail();
        break;
      case FileShareType.Organization:
        this.shareInOrganization();
        break;
    }
  }

  shareByEmail() {
    console.log('Share by email');
  }

  shareInOrganization() {
    console.log('Share in organization');
  }
}
