// tslint:disable:max-line-length
export default {
  ResourcePlugin_Title: 'Resource',
  ResourcePlugin_InsertForm_Title: 'Insert resource',
  ResourcePlugin_InsertForm_Height_Label: 'Height:',
  ResourcePlugin_InsertForm_Width_Label: 'Width:',
  ResourcePlugin_InsertForm_Name_Label: 'Name:',
  ResourcePlugin_InsertForm_Height_Placeholder: 'Height in pixels',
  ResourcePlugin_InsertForm_Width_Placeholder: 'Width in pixels',
  ResourcePlugin_InsertForm_Name_Placeholder: 'Name',
  ResourcePlugin_InsertForm_ValidationError: 'Values are incorrect',

  PropertyFormattingPlugin_Title: 'Property formatting',
  PropertyFormattingPlugin_Menu_Numeric: 'Numeric',
  PropertyFormattingPlugin_Menu_Datetime: 'Date and time',

  PropertyFormats_Numeric_FixedPoint_Name: 'Fixed-point',
  PropertyFormats_Numeric_FixedPoint_Example: '12.123 -> 12.1 | supports precision specifier as number of decimal digits',

  PropertyFormats_Numeric_Currency_Name: 'Currency',
  PropertyFormats_Numeric_Currency_Example: '123.456 -> 123.46€ | supports precision specifier as number of decimal digits',

  PropertyFormats_Numeric_Percent_Name: 'Percent',
  PropertyFormats_Numeric_Percent_Example: '1 -> 100.00 % | supports precision specifier as number of decimal places',

  PropertyFormats_Numeric_Number_Name: 'Number',
  PropertyFormats_Numeric_Number_Example: '1234.567 -> 1,234.57 | supports precision specifier as number of decimal places',

  PropertyFormats_Datetime_ShortDateLongTime_Name: 'Short date + long time',
  PropertyFormats_Datetime_ShortDateLongTime_Example: '3/9/2008 10:05:07',
  PropertyFormats_Datetime_LongTime_Name: 'Long time',
  PropertyFormats_Datetime_LongTime_Example: '10:05:07',

  'DomainPropertySelector.Title': 'Domain data properties',
  'DomainPropertySelector.Menu.Text': '-- No properties available --',
  'DomainPropertySelector.Menu.Tooltip': 'Select domain object type at "General info" tab',
};
