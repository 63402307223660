import { of as observableOf, Observable } from 'rxjs';
import { switchMap, tap, finalize } from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { FormGroup, FormControl } from '@angular/forms';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { CheckInModalData } from '@hrz/core/models/dossier/check-in-modal-data';
import { ModalStep } from '@hrz/core/models/dossier/modal-step';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { IndicateDamageComponent } from '../indicate-damage/indicate-damage.component';
import { DamageIndicateData } from '@hrz/core/models/dossier/damage-indicate-data';
import { CheckInModalStepType } from '@hrz/core/models/dossier/check-in-modal-step-type';
import { SignatureComponent } from '@hrz/shared/components/canvas-helper/signature/signature.component';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { HiddenFieldService } from '../../services/hidden-field.service';
import { AuthService } from '@hrz/core/services/auth.service';

@Component({
  templateUrl: './faster-dossier-instruction-check-in.modal.html',
  styleUrls: ['./faster-dossier-instruction-check-in.modal.scss']
})
@DynamicModal()
export class FasterDossierInstructionCheckInModal implements AfterViewInit, OnInit {
  @ViewChild('dossierInstructionCheckIn') childModal: ModalDirective;
  @ViewChild(IndicateDamageComponent) indicateDamageComponent: IndicateDamageComponent;
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('fitterSignature') fitterSignature: SignatureComponent;

  model: CheckInModalData;
  onCancel: () => void;
  onModelUpdated: (checkInModalData: CheckInModalData) => CheckInModalData;
  onFinish: (checkInModalData: CheckInModalData) => CheckInModalData;
  ModalStepType = CheckInModalStepType;
  private closeModal: Function;

  question1FormGroup: FormGroup;
  question2FormGroup: FormGroup;
  question3FormGroup: FormGroup;

  private canContinueWithLegalNote = false;
  private useLegalNoteVersion: string;

  hiddenFields: string[];

  constructor(
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private sanitizer: DomSanitizer,
    private hiddenFieldService: HiddenFieldService,
    private authService: AuthService
  ) {
    console.log('FasterDossierInstructionCheckInModal.constructor()');
  }

  ngOnInit() {
    console.log('FasterDossierInstructionCheckInModal.ngOnInit()');
    const stepType = this.getStepTypeByStep(this.model.step);
    this.model.modalStep = this.getDefaultModalStep(stepType);
    this.model.navigationInProgress = true;
    this.authService
      .getTenantId()
      .toPromise()
      .then(tenantId => {
        this.model.LegalEntityCountryIsoCode = tenantId.toString();
        this.useLegalNoteVersion = this.translateService.instant('LEGAL_NOTE.' + this.model.LegalEntityCountryIsoCode + '.USE_VERSION');
        this.model.LegalNoteInitialContent = this.sanitizer.bypassSecurityTrustHtml(
          this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.INITIAL')
        );
        this.model.LegalNoteQuestion1Content = this.sanitizer.bypassSecurityTrustHtml(
          this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_1')
        );
        this.model.LegalNoteQuestion2Content = this.sanitizer.bypassSecurityTrustHtml(
          this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_2')
        );
        this.model.LegalNoteQuestion3Content = this.sanitizer.bypassSecurityTrustHtml(
          this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_3')
        );
        this.model.LegalNoteVersion = this.useLegalNoteVersion;

        if (!this.useLegalNoteVersion || this.useLegalNoteVersion.indexOf('LEGAL_NOTE.') !== -1) {
          this.toasterService.showError(
            'Please configure Translation: "LEGAL_NOTE.' +
            this.model.LegalEntityCountryIsoCode +
            '.USE_VERSION" correctly. Or contact the system administrator.'
          );
        }
      });
    this.hiddenFieldService.getHiddenFieldsForCurrentLegalEntity().then(hiddenFields => {
      this.hiddenFields = hiddenFields;
      if (this.hiddenFields.includes('dossier.checkinout.LegalNoteQuestion1Content')) {
        this.model.LegalNoteQuestion1Disabled = true;
      }
    });

    this.question1FormGroup = this.initializeQuestion1FormGroup(this.model);
    this.question2FormGroup = this.initializeQuestion2FormGroup(this.model);
    this.question3FormGroup = this.initializeQuestion3FormGroup(this.model);
    this.canContinueWithLegalNote = true; // this.model.LegalNoteQuestion3Allowed;
    this.model.navigationInProgress = false;
  }

  ngOnDestroy() {
    console.log('FasterDossierInstructionCheckInModal.ngOnDestroy()');
  }

  ngAfterViewInit(): void {
    console.log('FasterDossierInstructionCheckInModal.ngAfterViewInit()');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
  }

  showField(fieldName: string): boolean {
    return this.hiddenFields && !this.hiddenFields.includes(fieldName);
  }

  close(): void {
    console.log('FasterDossierInstructionCheckInModal.close()');
    if (this.model.step === CheckInModalStepType.fitterSignature) {
      this.model.step = CheckInModalStepType.clientSignature;
    }
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onModelUpdated(model);
      });
  }

  cancel(): void {
    console.log('FasterDossierInstructionCheckInModal.cancel()');
    this.closeModal();
  }

  finish(): void {
    console.log('FasterDossierInstructionCheckInModal.finish()');
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onFinish(model);
      });
  }

  childComponentDataChange() {
    console.log('FasterDossierInstructionCheckInModal.childComponentDataChange()');
    this.model.modalStep.anyChanges = true;
  }

  navigateToStep = (stepType: CheckInModalStepType) => () => {
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;
    this.beforeNavigationAction()
      .pipe(
        finalize(() => {
          this.model.navigationInProgress = false;
        })
      )
      .subscribe(() => {
        this.model.step = +stepType;
        this.model.modalStep = this.getDefaultModalStep(stepType);
      });
  }

  beforeNavigationAction(): Observable<{}> {
    console.log('FasterDossierInstructionCheckInModal.beforeNavigationAction()');
    if (!this.model.modalStep.anyChanges) {
      return observableOf({});
    }

    switch (this.model.modalStep.step) {
      // Capture start end.
      case CheckInModalStepType.indicateDamage:
        this.clientSignature.clear();
        this.fitterSignature.clear();
      case CheckInModalStepType.legalNote:
        this.model = this.UpdateModelWithLegalNoteForms(this.model);
        return observableOf({});
      default:
        return observableOf({});
    }
  }

  isCheckInValid(): boolean {
    return this.indicateDamageComponent.isValid();
    // there should be added other components
  }

  updateModel(): Observable<CheckInModalData> {
    console.log('FasterDossierInstructionCheckInModal.updateModel()');
    return this.indicateDamageComponent.getModel().pipe(
      tap((damageIndicateData: DamageIndicateData) => {
        this.model.damageIndicateData = damageIndicateData;
        const scoped = this;
        const reader = new FileReader();
        reader.readAsDataURL(this.model.damageIndicateData.highlightedDamageImage.result);
        reader.onloadend = function () {
          const base64data = reader.result;
          scoped.model.damageIndicateData.highlightedDamageImage.resultBase64 = base64data as string;
        };
        this.model.clientSignature = this.clientSignature.getImageAsString();
        this.model.fitterSignature = this.fitterSignature.getImageAsString();
      }),
      tap(() => {
        this.model = this.UpdateModelWithLegalNoteForms(this.model);
      }),
      switchMap(() => observableOf(this.model))
    );
  }

  clientSignatureChange(clientSignature: string) {
    this.model.clientSignature = clientSignature;
  }

  fitterSignatureChange(fitterSignature: string) {
    this.model.fitterSignature = fitterSignature;
  }

  private getDefaultModalStep = (step: number): ModalStep => {
    return [
      {
        step: CheckInModalStepType.indicateDamage,
        nextStepText: 'DOSSIER.MODALS.STEP_1_3_IN',
        previousStepText: 'COMMON.CANCEL',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.legalNote),
        previousStepSelect: this.cancel.bind(this),
        canGoNextStep: () => this.indicateDamageComponent.checkForValid(),
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.legalNote,
        nextStepText: 'DOSSIER.MODALS.STEP_2_3_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.clientSignature),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.indicateDamage),
        canGoNextStep: () => this.canContinueWithLegalNote,
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.clientSignature,
        nextStepText: 'DOSSIER.MODALS.STEP_3_3_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.fitterSignature),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.legalNote),
        canGoNextStep: () => this.model.clientSignature != null && this.model.clientSignature.length > 0,
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.fitterSignature,
        nextStepText: 'DOSSIER.MODALS.FINISH_CHECK_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.finish.bind(this),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.clientSignature),
        canGoNextStep: () => this.model.fitterSignature != null && this.model.fitterSignature.length > 0,
        anyChanges: false,
      },
    ].find((modalStep: ModalStep) => modalStep.step === this.getStepTypeByStep(step));
  }

  private getStepTypeByStep(step: number): CheckInModalStepType {
    this.spinnerService.hide('chekInSpinner');
    return CheckInModalStepType[CheckInModalStepType[step]];
  }

  private initializeQuestion1FormGroup(model: { LegalNoteQuestion1Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question1: new FormControl(model.LegalNoteQuestion1Allowed != null ? model.LegalNoteQuestion1Allowed : false),
    });
  }

  private initializeQuestion2FormGroup(model: { LegalNoteQuestion2Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question2: new FormControl(model.LegalNoteQuestion2Allowed != null ? model.LegalNoteQuestion2Allowed : false),
    });
  }

  private initializeQuestion3FormGroup(model: { LegalNoteQuestion3Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question3: new FormControl(model.LegalNoteQuestion3Allowed != null ? model.LegalNoteQuestion3Allowed : false),
    });
  }

  private UpdateModelWithLegalNoteForms(model: CheckInModalData): CheckInModalData {
    const question1formValue = (fieldName: string) =>
      model.LegalNoteQuestion1Disabled ? true : this.question1FormGroup.get(fieldName).value;
    const question2formValue = (fieldName: string) => this.question2FormGroup.get(fieldName).value;
    const question3formValue = (fieldName: string) => this.question3FormGroup.get(fieldName).value;

    const allowQ1 = question1formValue('question1');
    const allowQ2 = question2formValue('question2');
    const allowQ3 = question3formValue('question3');

    return {
      ...model,
      LegalNoteQuestion1Allowed: allowQ1,
      LegalNoteQuestion2Allowed: allowQ2,
      LegalNoteQuestion3Allowed: allowQ3,
      LegalNoteInitialAllowed: true,
    };
  }
}
