import { throwError as observableThrowError, timer as observableTimer, zip as observableZip, Observable, Subscription } from 'rxjs';

import { mergeMap, retryWhen } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown/bs-dropdown.config';
import { FormGroup, FormControl } from '@angular/forms';

import * as moment from 'moment-timezone';
import { AuthService } from '@hrz/core/services/auth.service';
import { TranslateInitializeService } from '@hrz/core/services/translate-initialize.service';
import { DynamicModalService } from '@hrz/shared/dynamic-modal.module';
import { ConfirmationsModule } from '@hrz/shared/modals/confirmations.module';
import { LogoutModal } from '@hrz/shared/modals/logout/logout.modal';
import { LanguageCodeService } from '@hrz/core/services/language-code.service';
import { LanguageCode } from '@hrz/core/models/multilangual/language-code';
import { FormHelpers } from '@hrz/core/utils/helpers';
import { WindowService } from '@hrz/core/services/window.service';

@Component({
  selector: 'hrz-user-profile-dropdown',
  templateUrl: 'user-profile-dropdown.component.html',
  styleUrls: ['./user-profile-dropdown.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { autoClose: false } }],
})
export class UserProfileDropdownComponent implements OnInit, OnDestroy {
  languages: LanguageCode[] = [];
  userProfileForm: FormGroup;
  userLanguageCode: string;
  subscriptions: Subscription[] = [];
  private zoneName: any;
  public timeZone: any;
  public username: string;

  constructor(
    private windowService: WindowService,
    private authService: AuthService,
    private translateInitializeService: TranslateInitializeService,
    private modalService: DynamicModalService,
    private languageCodeService: LanguageCodeService
  ) {
    this.initializeForm();
  }

  ngOnInit() {
    this.zoneName = moment.tz.guess();
    this.timeZone = moment.tz(this.zoneName).zoneAbbr();
    this.username = this.authService.getUserName();

    observableZip(this.languageCodeService.list(), this.translateInitializeService.getLanguageObservable())
      .pipe(
        retryWhen((errors: Observable<Response>) =>
          errors.pipe(
            mergeMap(error => {
              if (error && error.status === 401) {
                return observableTimer(1000);
              } else {
                return observableThrowError(error);
              }
            })
          )
        )
      )
      .subscribe(([languages, languageCode]: [LanguageCode[], string]) => {
        this.languages = languages || [];
        this.userLanguageCode = languageCode;
        this.updateForm();
      });

    this.subscriptions.push(
      this.userProfileForm.get('language').valueChanges.subscribe(v => {
        this.setLanguage(v);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  logout() {
    this.modalService.create(ConfirmationsModule, LogoutModal, {
      onOk: () => {
        this.authService.logout();
      },
      onClose: () => {},
    });
  }

  languageCompare(a: LanguageCode, b: LanguageCode): boolean {
    return a && b ? a.code === b.code : a === b;
  }

  setLanguage(language: LanguageCode) {
    if (language == null) {
      return;
    }
    this.translateInitializeService.setLanguage(language.code).subscribe(() => {
      this.windowService.nativeWindow.location.reload();
    });
  }

  private updateForm(): void {
    this.userProfileForm.controls['language'].patchValue(
      this.languages.find(x => x.code === this.userLanguageCode),
      { emitEvent: false }
    );
    FormHelpers.markAsPristine(this.userProfileForm);
  }

  private initializeForm() {
    this.userProfileForm = new FormGroup({
      language: new FormControl(null),
    });
  }
}
