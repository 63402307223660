import { Injectable, Optional, SkipSelf } from '@angular/core';

import { AuthService } from '@hrz/core/services/auth.service';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { HiddenEnum } from '@hrz/core/models/hidden-enum';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HiddenEnumService {
  private baseUrl = ConfigurationManager.AppSettings.organizationApi;
  private legalEntityHiddenFieldUrl = this.baseUrl + '/legalentityhiddenenum';
  private hiddenEnums: HiddenEnum[] = undefined;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: HiddenEnumService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('HiddenEnumService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getHiddenEnumsForCurrentLegalEntity(): Promise<HiddenEnum[]> {
    if (this.hiddenEnums) { return Promise.resolve(this.hiddenEnums); }

    return this.authService
      .getTenantId()
      .toPromise()
      .then(tenantId => {
        return this.http
          .get<HiddenEnum[]>(`${this.legalEntityHiddenFieldUrl}/${tenantId}`)
          .toPromise()
          .then(response => {
            this.hiddenEnums = response
            return this.hiddenEnums;
          })
          .catch(this.serviceActionHandler.handleDefaultError);
      });
  }
}
