import { Directive, ElementRef, OnChanges, Input, Renderer2, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[floatInputLabel]',
})
export class floatInputLabelDirective implements AfterViewInit, OnChanges {
  @Input('floatInputLabel') inputValue;

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'float-input');
    this.el.nativeElement.addEventListener('focus', () => {
      if (!this.el.nativeElement.readOnly) {
        this.renderer.addClass(this.el.nativeElement, 'active');
      }
    });

    this.el.nativeElement.addEventListener('blur', () => {
      if (this.isNotEmpty(this.inputValue) || this.isNotEmpty(this.el.nativeElement.value)) {
        this.renderer.addClass(this.el.nativeElement, 'active');
      } else {
        this.renderer.removeClass(this.el.nativeElement, 'active');
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.isNotEmpty(this.inputValue) || this.isNotEmpty(this.el.nativeElement.value)) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }
  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(): void {
    console.log('onChange fired');
    if (this.isNotEmpty(this.inputValue) || this.isNotEmpty(this.el.nativeElement.value)) {
      this.renderer.addClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    }
  }

  isNotEmpty(value: string) {
    return value && value !== null && value != undefined && value != 'undefined' && value != '' && value != ' ';
  }
}

@Directive({
  selector: '[floatSelectLabel]',
})
export class floatSelectLabelDirective implements AfterViewInit, OnChanges {
  @Input('floatSelectLabel') inputValue;

  ngAfterViewInit(): void {
    // console.log( 'Floatselect'+ this.inputValue + 'aa ' +this.el.nativeElement.classList);

    if (this.inputValue === 'NotSet' || this.inputValue === 'OneTime' || this.inputValue === 'null' || this.inputValue === '') {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'active');
    }
  }

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(): void {
    console.log('onChange fired');
    if (this.inputValue === 'NotSet' || this.inputValue === 'OneTime' || this.inputValue === 'null') {
      this.renderer.removeClass(this.el.nativeElement, 'active');
    } else {
      this.renderer.addClass(this.el.nativeElement, 'active');
    }
  }

  ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'float-select');
    this.el.nativeElement.addEventListener('focus', () => {
      this.renderer.addClass(this.el.nativeElement, 'active');
    });

    this.el.nativeElement.addEventListener('blur', () => {
      if (this.inputValue === 'NotSet' || this.inputValue === 'OneTime' || this.inputValue === 'null' || this.inputValue === '') {
        this.renderer.removeClass(this.el.nativeElement, 'active');
      } else {
        this.renderer.addClass(this.el.nativeElement, 'active');
      }
    });
  }

  isNotEmpty(value: string) {
    return value && value !== null && value !== undefined && value !== 'undefined' && value !== '' && value !== ' ';
  }
}
