import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { EditDossierAppointmentComponent } from '../edit-dossier-appointment/edit-dossier-appointment.component';
import { Address } from '@hrz/core/models/address';
import { ApiActionResult } from '@hrz/core/models/api-action-result';

@Component({
  templateUrl: 'change-fitting-station.modal.html',
  styleUrls: ['change-fitting-station.modal.scss']
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class ChangeFittingStationModal implements OnInit, AfterViewInit, OnDestroy {
  public onSave: (fittingAddress: Address) => Observable<ApiActionResult>;
  public onAfterSave: () => void;
  public onFailure: () => void;

  public message: string;
  public requestFittingAddress: Boolean;
  public fittingAddress: Address = new Address();

  public _loading = false;
  public editMode: boolean;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value;
  }

  private closeModal: Function;

  @ViewChild('editDossierAppointment') private childModal: ModalDirective;
  @ViewChild(EditDossierAppointmentComponent) editDossierAppointmentForm: EditDossierAppointmentComponent;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.childModal.show();
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
  }

  clickSave(): void {
    this.loading = true;
    this.onSave(this.fittingAddress)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterSave) {
            this.onAfterSave();
          }
        },
        () => {
          this.onFailure();
        }
      );
  }

  clickEdit(): void {
    this.editMode = true;
  }

  shouldSetClickOutsideDisabled = (disabled: boolean) => {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: disabled ? 'static' : true,
      keyboard: !disabled,
    };
  }
}
