import { mergeMap, catchError, map } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthService } from '@hrz/core/services/auth.service';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { TopicToTemplateMapping } from '@hrz/core/models/messagecenter/topic-to-template-mapping';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateMappingService {

  private apiUrl = ConfigurationManager.AppSettings.messagingApi + '/template-mapping';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: TemplateMappingService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('TemplateMappingService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public list(): Observable<TopicToTemplateMapping[]> {
    return this.http.get<TopicToTemplateMapping[]>(this.apiUrl, { headers: this.httpHeaders }).pipe(
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public get(id: number): Observable<TopicToTemplateMapping> {
    return this.http.get<TopicToTemplateMapping>(`${this.apiUrl}/${id}`, { headers: this.httpHeaders }).pipe(
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public update(item: TopicToTemplateMapping): Observable<ApiActionResult> {
    return this.http.put(`${this.apiUrl}`, item, { headers: this.httpHeaders }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public create(item: TopicToTemplateMapping): Observable<ApiActionResult> {
    return this.http.post(`${this.apiUrl}`, item, { headers: this.httpHeaders }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public delete(id: number): Observable<ApiActionResult> {
    return this.http.delete(`${this.apiUrl}/${id}`, { headers: this.httpHeaders }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public getMissingMaterialId(): Observable<number> {
    return this.authService.getTenantId().pipe(
      mergeMap(tenantId =>
        this.http.get<number>(`${this.apiUrl}/missingmaterial/OnOrderMaterial/${tenantId}`, { headers: this.httpHeaders })
      ),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public getTemaplteId(topicType: string): Observable<number> {
    // MissingMaterial is faulty stated in service... Until this is fixed (uri remapping)
    return this.authService.getTenantId().pipe(
      mergeMap(tenantId =>
        this.http.get<number>(`${this.apiUrl}/missingmaterial/${topicType}/${tenantId}`, { headers: this.httpHeaders })
      ),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public getMissingMaterialEmailList(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/missingmaterial/emails`, { headers: this.httpHeaders }).pipe(
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public getOrderMaterialEmailList(tenantId: number): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}/ordermaterial/emails/${tenantId}`, { headers: this.httpHeaders }).pipe(
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }
}
