export namespace ActionTypeKeys {
  export const accept = 'accept';
  export const approve_credit = 'approve_credit';
  export const approve_pre_authorization = 'approve_pre_authorization';
  export const authorize_dossier = 'authorize_dossier';
  export const check_out_job = 'check_out_job';
  export const countersale_approve_credit = 'countersale_approve_credit';
  export const countersale_reject_credit = 'countersale_reject_credit';
  export const countersale_request_credit = 'countersale_request_credit';
  export const countersale_request_invoice = 'countersale_request_invoice';
  export const countersale_request_proforma = 'countersale_request_proforma';
  export const create_account_invoice = 'create_account_invoice';
  export const create_credit_note = 'create_credit_note';
  export const dossier_fsinvoicing_create_invoice = 'dossier_fsinvoicing_create_invoice';
  export const dossier_fsinvoicing_create_proforma = 'dossier_fsinvoicing_create_proforma';
  export const newdossier = 'newdossier';
  export const re_open_job = 're_open_job';
  export const reject_authorization = 'reject_authorization';
  export const reject_credit = 'reject_credit';
  export const reject_dossier_authorization = 'reject_dossier_authorization';
  export const reject_financial_validation = 'reject_financial_validation';
  export const reject_pre_authorization = 'reject_pre_authorization';
  export const reopen_financial_validation = 'reopen_financial_validation';
  export const request_authorization = 'request_authorization';
  export const request_credit = 'request_credit';
  export const request_financial_validation = 'request_financial_validation';
  export const request_pre_authorization = 'request_pre_authorization';
  export const set_account_invoice_created = 'set_account_invoice_created';
  export const set_account_invoice_ready_to_sent = 'set_account_invoice_ready_to_sent';
  export const set_account_invoice_rejected = 'set_account_invoice_rejected';
  export const set_credit_note_created = 'set_credit_note_created';
  export const start_authorization = 'start_authorization';
  export const validate_financial_validation_authority = 'validate_financial_validation_authority';
  export const validate_financial_validation = 'validate_financial_validation';
}
