import { Point } from '@hrz/core/models/point';

export class HighlightImage {
  id: number;
  baseImage: string;
  points: Point[];
  result: Blob;
  resultBase64: string;
  resultWidth: number;
  resultHeight: number;
}
