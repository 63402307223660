const JobType = {
  NotSet: 'NotSet',
  CounterSales: 'CounterSales',
  Repair: 'Repair',
  Replace: 'Replace',
  Service: 'Service',
};

type JobType = (typeof JobType)[keyof typeof JobType];
export { JobType };
