import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageMagnifierComponent } from './components/image-magnifier/imageMagnifier.component'
import { floatInputLabelDirective, floatSelectLabelDirective } from './directives/floatInputLabel.directive';
import { PipesModule } from '@hrz/shared/pipes/pipes.module';
import { SendToEmailComponent } from './components/send-to-email/send-to-email.component';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from '../shared/spinner.module';

@NgModule({
  imports: [CommonModule, PipesModule, ReactiveFormsModule, SpinnerModule, TranslateModule.forChild()],
  declarations: [
    ImageMagnifierComponent,
    SendToEmailComponent,
    floatInputLabelDirective,
    floatSelectLabelDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ImageMagnifierComponent,
    SendToEmailComponent,
    floatInputLabelDirective,
    floatSelectLabelDirective
  ]
})
export class SharedComponentModule {}
