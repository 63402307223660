﻿import { Document } from '@hrz/core/models/document';

export class NewJobPromotion {
  Name: string;
  Code: string;
  Comment: string;
  JobId: number;
  Document: Document;
  PromotionId: number;
}
