import { Injectable, Optional, SecurityContext, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { Note } from '@hrz/core/models/note';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  private baseUrl = ConfigurationManager.AppSettings.dossierApi;
  private dossierUrl = this.baseUrl + '/dossier';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: NoteService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('NoteService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getAllByDossierId(dossierId: number): Promise<Note[]> {
    return this.http
      .get<Note[]>(`${this.dossierUrl}/${dossierId}/note`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public create(note: Note): Promise<ApiActionResult> {
    console.log('POST - JSON Command: ', JSON.stringify(note));

    const createCommand = {
      CreatedAt: note.CreatedAt,
      CreatedBy: note.CreatedBy,
      Comment: note.Comment,
      DossierId: note.DossierId,
      Attachments: note.Attachments,
    };
    console.log('CreateCommand:', JSON.stringify(createCommand));
    const sanitizedComment = this.domSanitizer.sanitize(SecurityContext.HTML, createCommand.Comment);
    createCommand.Comment = sanitizedComment;
    return this.http
      .post(`${this.dossierUrl}/${note.DossierId}/note`, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
