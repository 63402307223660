import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { ByteHelpers } from '@hrz/core/utils/helpers';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { AuthService } from '@hrz/core/services/auth.service';
import { DossierService } from '@hrz/core/services/dossier.service';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { DossierCollaborationMessageService } from '../../services/dossier-collaboration-message.service';
import { CollaborationMessage } from '@hrz/core/models/dossier/collaboration-message';
import { Dossier } from '@hrz/core/models/dossier';
import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { FileService } from '@hrz/core/services/file.service';
import { Document } from '@hrz/core/models/document';
import { EnumService } from '@hrz/core/services/enum.service';
import { Enum } from '@hrz/core/models/enum';
import { HiddenEnum } from '@hrz/core/models/hidden-enum';
import { HiddenEnumService } from '../../services/hidden-enum.service';
import { InsuranceInfo } from '@hrz/core/models/insurance-info';
import { Protocol } from '@hrz/core/models/protocol';
import { TemplateService } from '@hrz/core/services/template.service';
import { Template } from '@hrz/core/models/template/template';
import { Permission as Perm } from '@hrz/core/models/enums/permission.enum';
import { CountryEnum } from '@hrz/core/enums/country.enum';

@Component({
  providers: [
    TranslateService,
    FileService,
  ],
  templateUrl: './send-new-message.modal.html',
  styleUrls: ['send-new-message.modal.scss'],
})
@DynamicModal()
export class SendNewMessageModal implements OnInit, AfterViewInit, OnDestroy {
  public onClose: Function;
  public onOk: Function;
  public onMessageSend: Function;
  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('sendMessageModal') childModal: ModalDirective;
  public getTemplateListSubscription: Subscription = Subscription.EMPTY;

  public context: CollaborationMessage;
  allowedFileTypes: string[];
  Recipients: Enum[];
  fileUploading: boolean;
  public protocol: Protocol;
  public hiddenEnums: HiddenEnum[];
  public allowSendingOfDocument: boolean;
  public showCommunicationTabTemplates = false;
  private tenantId: number;

  insuranceInfo: InsuranceInfo;
  templateList: Template[];
  selectedTemplate: Template;
  perm = Perm;
  isGreece = false;

  constructor(
    private authService: AuthService,
    private dossierService: DossierService,
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private collaborationMessageService: DossierCollaborationMessageService,
    private fileService: FileService,
    private enumService: EnumService,
    private hiddenEnumService: HiddenEnumService,
    private templateService: TemplateService
  ) {}

  ngOnInit(): void {
    console.log('SendNewMessageModal.ngOnInit');
    this.authService.getTenantId().subscribe(tenantId => (this.isGreece = tenantId === CountryEnum.Greece));

    this.hiddenEnumService.getHiddenEnumsForCurrentLegalEntity().then(hiddenEnums => {
      this.hiddenEnums = hiddenEnums;
      this.authService
        .getTenantId()
        .subscribe(result => {
          this.tenantId = result;
          let tenantCollaborationName = 'CollaborationRecipients' + this.tenantId.toString();

          if (this.tenantId === CountryEnum.Greece && this.authService.isCentralDepartment()) {
            tenantCollaborationName += 'CD';
          }

          this.enumService.getCollaborationRecipients(tenantCollaborationName).then(recipients => {
            const list = recipients;
            this.dossierService
              .getProtocolByFlowAndDossier('SendIcMessage', this.context.DossierId)
              .then(result => {
                if (result) {
                  this.protocol = result;
                  this.Recipients = list.filter(c => this.showEnum(tenantCollaborationName, c.Key));
                } else {
                  this.Recipients = list.filter(c => c.Key !== 'InsuranceCompany' && this.showEnum(tenantCollaborationName, c.Key)); // Exclude the Insurance Company.
                }
              })
              .catch(_failure => {
                this.Recipients = list.filter(c => c.Key !== 'InsuranceCompany' && this.showEnum(tenantCollaborationName, c.Key)); // Exclude the Insurance Company.
              });
          });
        })
        .unsubscribe();
    });

    if (this.authService.userHasScope(Perm.r_ds_ct_tmp)) {
      this.showCommunicationTabTemplates = true;
    }
  }

  ngAfterViewInit(): void {
    console.log('SendNewMessageModal.ngAfterViewInit');
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('SendNewMessageModal.ngOnDestroy');
  }

  showEnum(enumName: string, entryName: string): boolean {
    return !this.hiddenEnums || !this.hiddenEnums.some(e => e.Enum == enumName && e.Entry == entryName);
  }

  recipientChanged(event: any): void {
    console.log('SendMessageModal.recipientChanged()');
    if (event === 'InsuranceCompany' && this.protocol) {
      const recipient = this.protocol.Settings.find(c => c.SettingName === 'collaborationRecipientName');
      const subject = this.protocol.Settings.find(c => c.SettingName === 'collaborationSubject');
      const allowSendingOfDocument = this.protocol.Settings.find(
        protocol => protocol.SettingName === 'collaborationAllowSendingOfDocument'
      );

      if (recipient) {
        this.context.To = recipient.SetttingValue;
      } else {
        this.context.To = this.insuranceInfo.Account.Name;
      }
      if (subject) {
        this.context.Subject = subject.SetttingValue;
      }
      this.context.Content = '';
      this.allowSendingOfDocument = !!allowSendingOfDocument;
    } else if (
      event == 'CallCenter' ||
      event == 'CentralDepartment' ||
      event == 'Customer' ||
      event == 'FittingStation' ||
      event == 'FsControlDepartment' ||
      event == 'PurchasingDepartment' ||
      event == 'PricingDepartment' ||
      event == 'InvoicingDepartment'
    ) {
      this.handleDestinationMail();
    } else if (event === 'Other' || event === 'null') {
      this.context.To = '';
    } else {
      this.context.To = '';
      this.toasterService.showError(`${event} ${this.translateService.instant('COLLABORATION.TO_NOT_DEFINED')}`);
    }

    if (this.showCommunicationTabTemplates && event && event !== 'null') {
      this.getCommunicationTabTemplates(event);
    }
  }

  getCommunicationTabTemplates(recipient: string) {
    this.spinnerService.show('sendMessageModalSpinner');
    this.templateList = [];

    const groupCode = 'CommunicationTabTemplate' + this.tenantId;
    const userRole = this.authService.isCentralDepartment() ? 'CentralDepartment' : 'Fittingstation';
    const typeCode = `${userRole}|${recipient}`;

    this.dossierService
      .getCodeTranslationListByGroupAndType(groupCode, typeCode)
      .then(codeTranslations => {
        const templateIds = codeTranslations.map(x => x.CodeValue);
        this.getTemplateListSubscription.unsubscribe();
        this.getTemplateListSubscription = this.templateService.getList(templateIds).subscribe(templates => {
          this.templateList = templates;
          this.spinnerService.hide('sendMessageModalSpinner');
        });
      })
      .catch(() => this.spinnerService.hide('sendMessageModalSpinner'));
  }

  templateChanged(template: Template): void {
    console.log('SendMessageModal.templateChanged()', template);
    if (template) {
      this.context.Subject = template.subject;
      this.context.Content = template.templateContent;
    }
  }

  handleDestinationMail(): void {
    this.spinnerService.show('sendMessageModalSpinner');
    this.collaborationMessageService
      .getDestinationMails(this.context.DossierId, this.context.Recipient, 'Email')
      .then(result => {
        if (result && result.length > 0 && result[0] != null) {
          this.context.To = result.join('; ');
        } else {
          this.toasterService.showError(`${this.translateService.instant('COLLABORATION.NO_EMAIL_CONFIGURED')}`);
          this.context.To = '';
        }
        this.spinnerService.hide('sendMessageModalSpinner');
      })
      .catch(() => this.spinnerService.hide('sendMessageModalSpinner'));
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }
  clickSend(): void {
    this.spinnerService.show('sendMessageModalSpinner');
    this.executeCommand().then(succeeded => {
      this.spinnerService.hide('sendMessageModalSpinner');
      if (succeeded) {
        if (this.onOk) {
          this.onOk();
        }
        this.closeModal();
      }
    });
  }

  // getDestinationMails(): Promise<boolean> {
  //     console.log('SendNewMessageModal.getDestinationMails()');
  //     return this.collaborationMessageService
  //         .getDestinationMails(this.context.DossierId, this.context.Recipient, 'Email')
  //         .then((result) => this.handleCommandAction(result));
  // }

  executeCommand(): Promise<boolean> {
    console.log('SendNewMessageModal.executeCommand()');
    return this.collaborationMessageService.create(this.context).then(result => this.handleCommandAction(result));
  }

  handleCommandAction(result: any): boolean {
    if (result.status === 200 || result.status === 201 || result.status === 202) {
      if (!this.context.Id) { this.context.Id = result.entity.Id; }
      return true;
    } else {
      this.spinnerService.hide('sendMessageModalSpinner');
      if (!result.commandValidations || result.commandValidations.length <= 0) {
        this.toasterService.showError(result);
      } else {
        const allErrors = [];
        result.commandValidations.map(validation => {
          allErrors.push(validation.Value);
        });
        this.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED'), allErrors);
      }
      return false;
    }
  }

  onChangeFile($event: any, _documentType: string): void {
    console.log('SendNewMessageModal.onChangeFile()');
    const scope = this;
    const file = $event.target.files[0];

    if (this.allowedFileTypes && this.allowedFileTypes.length > 0) {
      const fileType = file['type'];
      const isValidFile = this.isFileValidFile(fileType);
      if (isValidFile == false) {
        console.log(`SendNewMessageModal.onChangeFile() - file valid: ${fileType}`);
        this.toasterService.showError(
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.WRONGTYPE')} ${this.formatFileType(file.name)}. ` +
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.ALLOWED')} ${this.userFriendlyFileTypes().join(', ')}`
        );
        return;
      }
    }

    this.readFileAsBuffer(scope, file, file);
  }

  isFileValidFile(fileType: string): boolean {
    console.log(`SendNewMessageModal.isFileValidFile() - file type: ${fileType}`);
    const validImageType = this.allowedFileTypes.indexOf(fileType) >= 0;

    return validImageType;
  }

  formatFileType(fileType: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileType)[1];
  }

  userFriendlyFileTypes(): string[] {
    const returnValue = [];
    this.allowedFileTypes.forEach(fileType => {
      returnValue.push(fileType.substring(fileType.indexOf('/') + 1));
    });
    return returnValue;
  }

  readFileAsBuffer(scope: any, file: File, originalFile: File): void {
    scope.fileUploading = true;
    const reader = new FileReader();
    reader.onprogress = function (_progEvent: ProgressEvent) {
      scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    };
    reader.onload = function () {
      try {
        const document = new Document();
        document.Dossier = new Dossier();
        document.Dossier.Id = scope.context.DossierId;
        document.FileMetaData = new FileMetaData();

        const arrayBuffer = this.result;
        document.FileContent = ByteHelpers.arrayBufferToBase64(arrayBuffer);
        document.FileMetaData.FileName = file.name;
        document.FileMetaData.FileSize = file.size;
        document.FileMetaData.FileType = originalFile.type;
        document.File = file;
        document.File.newfilename = file.name;
        scope.onUploadFinished(document);
      } catch (ex) {
        console.log(ex);
        scope.fileUploading = false;
      }
    };
    reader.readAsArrayBuffer(file);
  }

  onUploadFinished(uploadedDocument: Document): void {
    console.log('SendNewMessageModal.onUploadFinished() - Step 1, save to DocumentManagement API');
    if (!uploadedDocument) { return; }
    this.spinnerService.show('uploadAttachmentSpinner');
    this.fileService
      .uploadDocument(uploadedDocument)
      .pipe(finalize(() => {}))
      .subscribe(
        (file: any) => {
          console.log('SendNewMessageModal.onUploadFinished() - Step 2, add file id to attachments');

          if (!this.context.Attachments) {
            this.context.Attachments = [];
          }

          this.context.Attachments.push({
            FileName: uploadedDocument.FileMetaData.FileName,
            Reference: file.id,
          });

          this.spinnerService.hide('uploadAttachmentSpinner');
          this.fileUploading = false;
        },
        _error => {
          this.spinnerService.hide('uploadAttachmentSpinner');
          this.fileUploading = false;
          this.toasterService.showApiError(_error);
        }
      );
  }

  removeFile(fileId: number): void {
    this.fileUploading = true;
    this.fileService.delete({ ids: [fileId] }).subscribe(
      () => {
        console.log('SendNewMessageModal.onRemoveFileFinished()');
        this.context.Attachments = this.context.Attachments.filter(item => item.Reference !== fileId);
        this.fileUploading = false;
      },
      error => {
        // this.spinnerService.hide('uploadAttachmentSpinner');
        console.log(error);
        this.fileUploading = false;
      }
    );
  }
}
