import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../../dynamic-modal.module';

const FormControlNames = {
  ReasonForDiscount: 'ReasonForDiscount',
};

type onOkFunc = (reasonForDiscount: string) => void;
type onCloseFunc = () => void;

@Component({
  templateUrl: 'discount-approval-request.modal.html',
})
@DynamicModal()
export class DiscountApprovalRequestModal implements AfterViewInit {
  public FormControlNames = FormControlNames;
  public formGroup: FormGroup;
  public onOk: onOkFunc;
  public onClose: onCloseFunc;

  @ViewChild('discountApprovalRequestModal') private childModal: ModalDirective;

  private closeModal: Function;

  constructor(private readonly formBuilder: FormBuilder) {
    this.formGroup = this.buildForm();
  }

  ngAfterViewInit(): void {
    this.childModal.show();
  }

  public cancel(): void {
    if (this.onClose) {
      this.onClose();
    }
    this.closeModal();
  }

  public ok(): void {
    if (this.onOk) {
      const reasonForDiscount = this.formGroup.controls[FormControlNames.ReasonForDiscount].value;
      this.onOk(reasonForDiscount);
    }
    this.closeModal();
  }

  private buildForm(): FormGroup {
    const formGroup = this.formBuilder.group({
      [FormControlNames.ReasonForDiscount]: [null, Validators.required],
    });
    formGroup.setValidators(this.formValidator());
    return formGroup;
  }

  private formValidator(): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const reasonControl = formGroup.controls[FormControlNames.ReasonForDiscount];
      const reason = reasonControl.value;
      if (!reason || reason.length === 0) {
        reasonControl.setErrors({ key: 'ValueRequired' });
      } else {
        reasonControl.setErrors(null);
      }

      return;
    };
  }
}
