import { finalize } from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { BaseActionModal } from '../base/base-action.modal';
import { Role } from '@hrz/core/models/role';
import { StructureInfo } from '@hrz/core/models/document/structure-info';
import { StructureManagePermissionsComponent } from '../structure-manage-permissions/structure-manage-permissions.component';
import { PermissionService } from '@hrz/core/services/permission.service';
import { PermissionInfo } from '@hrz/core/models/document/permission-info';

@Component({
  templateUrl: './structure-manage-permissions.modal.html',
})
@DynamicModal()
export class StructureManagePermissionsModal extends BaseActionModal {
  onChangePermissions: Function;
  onFailure: Function;
  structure: StructureInfo;
  @ViewChild('structureManagePermissionsModal') childModal: ModalDirective;
  @ViewChild(StructureManagePermissionsComponent) structureManagePermissionsForm: StructureManagePermissionsComponent;
  roles: Role[];

  constructor(
    private permissionService: PermissionService
  ) {
    super();
    this.roles = [
      { id: '00000000-0000-0000-0000-000000000000', name: 'Admin' },
      { id: '11111111-1111-1111-1111-111111111111', name: 'Operator' },
    ];
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    this.changePermissions();
  }

  changePermissions() {
    const permissionsEditModel = this.structureManagePermissionsForm.getModel();
    this.loading = true;
    this.permissionService
      .update(permissionsEditModel)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        (_permissionInfo: PermissionInfo) => {
          this.onChangePermissions();
        },
        error => {
          this.onFailure(error);
        }
      );
  }
}
