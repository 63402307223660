import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { ProductType } from '@hrz/core/models/enums/product-type.enum';
import { Job } from '@hrz/core/models/job';
import { JobLine } from '@hrz/core/models/job-line';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';

@Component({
  selector: 'app-modify-job-line',
  templateUrl: './modify-job-line.modal.html',
})
@DynamicModal()
export class ModifyJobLineModal implements OnInit, AfterViewInit {
  @ViewChild('modifyJobLineModal') private childModal: ModalDirective;

  canDeleteJobLine = false;
  canReplaceJobLine = false;
  private closeModal: Function;
  private isJobLocked = false;
  private job: Job;
  private jobLine: JobLine;
  private onDelete: Function;
  private onReplace: Function;

  constructor() {}

  ngOnInit(): void {
    this.registerDataSource();
  }

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: 'static',
      keyboard: false,
      show: true,
    };
  }

  clickCancel(): void {
    this.closeModal();
  }

  clickDelete(): void {
    this.onDelete(this.jobLine);
    this.closeModal();
  }

  clickReplace(): void {
    this.onReplace(this.jobLine);
    this.closeModal();
  }

  private registerDataSource(): void {
    const materialJobLines = this.job.JobLines.filter(x => x.ProductType === ProductType.Material);
    const materialJobLinesCount = !!materialJobLines ? materialJobLines.length : 0;

    this.canDeleteJobLine = materialJobLinesCount >= 2 && !this.isJobLocked;
    this.canReplaceJobLine = materialJobLinesCount >= 1 && !this.isJobLocked;
  }
}
