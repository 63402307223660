import { Component, ElementRef, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-image-magnifier',
  template: `
    <div class="ImageZoomContainer" #ImageWrapper>
      <img class="img-responsive" [src]="imageSrc" #defaultImage style="cursor:crosshair;" />
      <div class="ImageZoomFullContainer" #zommlens>
        <img [src]="imageSrc" #ZoomImage />
      </div>
    </div>
  `
})
export class ImageMagnifierComponent implements OnInit {
  @ViewChild('ImageWrapper') ImageWrapperView: ElementRef;
  @ViewChild('defaultImage') defaultImageView: ElementRef;
  @ViewChild('zommlens') zommlensView: ElementRef;
  @ViewChild('ZoomImage') ZoomImageView: ElementRef;
  public imageHeight: number;
  public imageWidth: number;
  public zoomImageHeight: number;
  public zoomImageWidth: number;
  public mouseX: number;
  public mouseY: number;
  public zoomaImageRatio: any;
  public lens: any;
  public zImage: any;
  public dImage: any;
  public zoomLevel = 3;
  public lensWidth = 150;
  public lensHeight = 100;

  @Input() imageSrc: SafeResourceUrl | string;
  @Input() lensCustomWidth: number;
  @Input() lensCustomHeight: number;
  @Input() lensZommLevel: number;

  constructor(private renderer: Renderer2) {}
  ngOnInit(): void {
    console.log('ImageMagnifierComponent.ngOnInit');
    this.renderer.listen(this.defaultImageView.nativeElement, 'mousemove', event => this.hoverMouseMove(event));
    this.renderer.listen(this.defaultImageView.nativeElement, 'mouseenter', _event => this.hoverMouseEnter());
    this.renderer.listen(this.defaultImageView.nativeElement, 'mouseleave', _event => this.hoverMouseLeave());
    this.dImage = this.defaultImageView.nativeElement;
    this.lens = this.zommlensView.nativeElement;
    this.zImage = this.ZoomImageView.nativeElement;

    if (this.lensCustomWidth !== undefined) {
      this.lensWidth = this.lensCustomWidth;
    }
    if (this.lensZommLevel !== undefined) {
      this.zoomLevel = this.lensZommLevel;
    }
    if (this.lensCustomHeight !== undefined) {
      this.lensHeight = this.lensCustomHeight;
    }
  }
  private hoverMouseEnter() {
    this.lens.style.display = 'block';
    this.lens.style.width = this.lensWidth + 'px';
    this.lens.style.height = this.lensHeight + 'px';
    this.findRatio();
  }

  private hoverMouseLeave() {
    this.lens.style.display = 'none';
  }

  private hoverMouseMove(event: MouseEvent) {
    this.calculateZoomPosition(event);
  }

  private findRatio() {
    if (this.defaultImageView.nativeElement.offsetWidth < 1000) {
      this.ZoomImageView.nativeElement.style.width = this.dImage.offsetWidth * this.zoomLevel + 'px';
      this.ZoomImageView.nativeElement.style.height = this.dImage.offsetHeight * this.zoomLevel + 'px';
      this.zoomaImageRatio = this.ZoomImageView.nativeElement.offsetWidth / this.defaultImageView.nativeElement.offsetWidth;
    } else {
      this.zoomaImageRatio = this.ZoomImageView.nativeElement.offsetWidth / this.defaultImageView.nativeElement.offsetWidth;
    }
  }

  private calculateZoomPosition(event: MouseEvent) {
    this.mouseX = this.dImage.offsetWidth - event.offsetX;
    this.mouseY = this.dImage.offsetHeight - event.offsetY;
    if (this.mouseX < this.lensWidth * 1.3) {
      this.lens.style.top = event.offsetY - this.lensHeight / 3 + 'px';
      this.lens.style.left = event.offsetX - this.lensWidth * 1.3 + 'px';
    } else {
      this.lens.style.top = event.offsetY - this.lensHeight / 3 + 'px';
      this.lens.style.left = event.offsetX + this.lensWidth / 2.6 + 'px';
    }
    this.zImage.style.top = -(event.offsetY * this.zoomaImageRatio - this.lensHeight / 2) + 'px';
    this.zImage.style.left = -(event.offsetX * this.zoomaImageRatio - this.lensWidth / 2) + 'px';
  }
}
