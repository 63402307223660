import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { Quote } from '@hrz/core/models/quote';
import { GetQuoteValidityResponse } from '@hrz/core/models/quote/get-quote-validity-reponse';
import { CreateQuoteCommand } from '@hrz/core/models/quote/create-quote-command';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  private baseUrl = ConfigurationManager.AppSettings.dossierApi;
  private quotationUrl = this.baseUrl + '/quote';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private readonly http: HttpClient,
    private readonly serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: QuoteService
  ) {
     // Enforces this service to be loaded as a singleton
     if (parent) {
      appInsightsService.logException(new Error('QuoteService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public sendForApproval(quoteId: number, email: string): Promise<ApiActionResult> {
    const url = this.quotationUrl + `/${quoteId}/send-for-approval?customerEmail=${email}`;
    console.log('PUT - JSON Command: ', quoteId);

    return this.http
      .put(url, '', { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createQuote(createCommand: CreateQuoteCommand): Promise<ApiActionResult> {
    const url = this.quotationUrl;

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getValidityPeriod(): Promise<ApiActionResult<GetQuoteValidityResponse>> {
    const url = this.quotationUrl + '/validity-period';

    return this.http
      .get(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getQuotesForDossier(dossierId: number): Promise<ApiActionResult<Quote[]>> {
    const url = `${this.quotationUrl}/${dossierId}/get-all-by-dossier`;
    return this.http
      .get(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public reject(quoteId: number): Promise<ApiActionResult<{ ResultCode: number }>> {
    const url = `${this.quotationUrl}/${quoteId}/reject`;
    return this.http
      .put(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public accept(quoteId: number): Promise<ApiActionResult<{ ResultCode: number }>> {
    // 1 = fitter, this button is always done from horizons so its always the fitter.
    const url = `${this.quotationUrl}/${quoteId}/approve?signatureType=1`;
    return this.http
      .put(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionSuccess(error));
  }
}
