export const PropertyFormats = {
  numeric: [
    {
      name: 'PropertyFormats_Numeric_FixedPoint_Name',
      example: 'PropertyFormats_Numeric_FixedPoint_Example',
      format: 'F',
    },
    {
      name: 'PropertyFormats_Numeric_Currency_Name',
      example: 'PropertyFormats_Numeric_Currency_Example',
      format: 'C',
    },
    {
      name: 'PropertyFormats_Numeric_Percent_Name',
      example: 'PropertyFormats_Numeric_Percent_Example',
      format: 'P',
    },
    {
      name: 'PropertyFormats_Numeric_Number_Name',
      example: 'PropertyFormats_Numeric_Number_Example',
      format: 'N',
    },
  ],
  datetime: [
    {
      name: 'PropertyFormats_Datetime_ShortDateLongTime_Name',
      example: 'PropertyFormats_Datetime_ShortDateLongTime_Example',
      format: 'G',
    },
    {
      name: 'PropertyFormats_Datetime_LongTime_Name',
      example: 'PropertyFormats_Datetime_LongTime_Example',
      format: 'T',
    },
  ],
};
