import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription, Observable } from 'rxjs';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import * as moment from 'moment';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { AvailableTimeRange } from '@hrz/core/models/scheduler/available-time-range';
import { ScheduleAvailabilityService } from '@hrz/core/services/schedule-availability.service';
import { UnavailablePeriod } from '@hrz/core/models/unavailable-period';
import { Appointment } from '@hrz/core/models/appointment';
import { AppointmentUpdate } from '@hrz/core/models/appointment-update';
import { AppointmentSchedulerModel } from '@hrz/core/models/scheduler/appointment-scheduler-model';
import { AvailableHours } from '@hrz/core/models/available-hours';
import { AvailableSlot } from '@hrz/core/models/available-slot';
import { AppointmentData } from '@hrz/core/models/appointment-data';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { AuthService } from '@hrz/core/services/auth.service';
import { Permission } from '@hrz/core/models/enums/permission.enum';
import { EditDossierAppointmentComponent } from '../../../dossiers/modals/edit-dossier-appointment/edit-dossier-appointment.component';
import { EnumService } from '@hrz/core/services/enum.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: 'edit-create-wizard-appointment.modal.html',
  styleUrls: ['edit-create-wizard-appointment.modal.scss']
})
@DynamicModal()
export class EditCreateWizardAppointmentModal implements OnInit, AfterViewInit, OnDestroy {
  public onSave: (appointmentUpdate: AppointmentUpdate) => Observable<ApiActionResult<Appointment>>;
  public onAfterSave: (appointment: Appointment) => void;
  public onDelete: (appointmentId: number) => Observable<ApiActionResult<void>>;
  public onAfterDelete: (appointmentId: number) => void;
  public onFailure: () => void;
  public appointmentData: AppointmentData;
  public availableTimeRange: AvailableTimeRange[] = [];
  public item: AppointmentSchedulerModel;
  public appointmentDurationInHours: number;
  public formattedAppointmentDate: string;
  public availableHours: AvailableHours[] = [];
  public availablePeriodSubscription: Subscription = Subscription.EMPTY;
  public timeDiffBetweenAvailableHours = 60; // in minutes
  public _loading = false;
  public editMode: boolean;
  public fittingStationHasMobileService: boolean;
  public fittingStationIsMobileOnly: boolean;
  public isMobileJob: boolean;
  translatedJobType: any;
  damageLocationEnum: any;
  translatedDamageLocation: any;

  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this.shouldSetClickOutsideDisabled(value);
    this._loading = value;
  }

  private closeModal: Function;
  @ViewChild('editCreateWizardAppointment') private childModal: ModalDirective;
  @ViewChild(EditDossierAppointmentComponent) editDossierAppointmentFormComp: EditDossierAppointmentComponent;

  constructor(
    private scheduleAvailabilityService: ScheduleAvailabilityService,
    private authService: AuthService,
    private spinnerService: SpinnerService,
    private enumService: EnumService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.formattedAppointmentDate = moment.utc(this.item.start).local().format('YYYY-MM-DD');
    this.appointmentDurationInHours = moment.duration(moment(this.item.end).diff(moment(this.item.start))).asHours();
    this.setAvailableHours(this.item.start);
    this.appointmentData = { ...this.item.appointment.appointmentData };
    this.isMobileJob = this.item.appointment.isMobileJob || this.fittingStationIsMobileOnly;

    if (this.appointmentData.jobType === 'Repair') {
      this.translatedJobType = this.translateService.instant('DOSSIER.MODIFY.REPAIR');
    } else if (this.appointmentData.jobType === 'Replace') {
      this.translatedJobType = this.translateService.instant('DOSSIER.MODIFY.REPLACE');
    } else {
      this.translatedJobType = this.appointmentData.jobType;
    }

    this.enumService.getDamageLocation().then(results => {
      this.damageLocationEnum = results;
      this.translatedDamageLocation =
        this.damageLocationEnum.find(x => x.Key === this.appointmentData.damageLocation).Value || this.appointmentData.damageLocation;
    });
  }

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: false,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
  }

  clickDelete(): void {
    this.loading = true;
    this.onDelete(this.item.id)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterDelete) {
            this.onAfterDelete(this.item.id);
          }
        },
        () => {
          this.onFailure();
          this.spinnerService.hide('appointmentSpinner');
        }
      );
  }

  dateChange(date: Date) {
    this.getAvailableSlots(date);
  }

  clickSave(): void {
    this.spinnerService.show('appointmentSpinner');
    this.updateDossierAppointment();
  }

  clickEdit(): void {
    this.editMode = true;
  }

  setAvailableHours(date: Date) {
    this.getAvailableSlots(date);
  }

  isValid(): boolean {
    return (
      this.editDossierAppointmentFormComp != null &&
      this.editDossierAppointmentFormComp.isDirty() &&
      this.editDossierAppointmentFormComp.isValid()
    );
  }

  updateDossierAppointment() {
    this.loading = true;

    if (this.authService.userHasScope(Permission.u_a_cnf)) {
      this.item.appointment.isConfirmed = true;
    }

    this.onSave(this.getUpdatedAppointmentModel())
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide('appointmentSpinner');
          this.closeModal();
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterSave) {
            this.onAfterSave(response.entity);
          }
        },
        () => {
          this.onFailure();
          this.spinnerService.hide('appointmentSpinner');
        }
      );
  }

  mapPeriods(periods: UnavailablePeriod[]): UnavailablePeriod[] {
    return periods.map(p => {
      p.start = moment.utc(p.start).local().toDate();
      p.end = moment.utc(p.end).local().toDate();
      return p;
    });
  }

  getAvailableSlots(date: Date): void {
    this.availablePeriodSubscription.unsubscribe();
    this.availablePeriodSubscription = this.scheduleAvailabilityService
      .getAvailableSlots(
        this.item.fittingStationId,
        this.item.appointment.appointmentEventType.id,
        moment(date).utc().startOf('day').format('YYYY-MM-DD')
      )
      .subscribe((slots: AvailableSlot[]) => {
        this.availableHours = this.mapAvailableHours(slots);
      });
  }

  mapAvailableHours(slots: AvailableSlot[]): AvailableHours[] {
    if (!slots) {
      return [];
    }
    const timeFormat = 'HH:mm:ss';
    return slots.map(s => ({
      start: moment.utc(s.start, timeFormat).local(),
      end: moment.utc(s.end, timeFormat).local(),
      displayHours: this.formatHours({ start: moment.utc(s.start, timeFormat).local(), end: moment.utc(s.end, timeFormat).local() }),
      displayStartHours: this.formatStartHours({ start: moment.utc(s.start, timeFormat).local() }),
      displayEndHours: this.formatEndHours({ end: moment.utc(s.end, timeFormat).local() }),
    }));
  }

  formatHours(hours: { start: moment.Moment; end: moment.Moment }): string {
    return `${hours.start.format('HH:mm')} - ${hours.end.format('HH:mm')}`;
  }

  formatStartHours(hours: { start: moment.Moment }): string {
    return `${hours.start.format('HH:mm')}`;
  }

  formatEndHours(hours: { end: moment.Moment }): string {
    return ` ${hours.end.format('HH:mm')}`;
  }

  shouldSetClickOutsideDisabled = (disabled: boolean) => {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: disabled ? 'static' : true,
      keyboard: !disabled,
    };
  }

  getUpdatedAppointmentModel(): AppointmentUpdate {
    const model = this.editDossierAppointmentFormComp.getModel();
    const appointmentUpdate: AppointmentUpdate = {
      id: this.item.id,
      dossierId: this.item.dossierId,
      fittingStationId: this.item.fittingStationId,
      appointmentEventTypeId: this.item.appointment.appointmentEventType.id,
      start: model.startDate,
      end: model.endDate,
      appointmentData: this.appointmentData,
      isMobileJob: this.isMobileJob,
      blockedMinutesBefore: model.blockedMinutesBefore,
      blockedMinutesAfter: model.blockedMinutesAfter,
      isConfirmed: this.item.appointment.isConfirmed,
      assignedByCallCenter: this.item.appointment.assignedByCallCenter,
      shouldNotify: false,
    };
    return appointmentUpdate;
  }
}
