import { Component, Input } from '@angular/core';
import { Availability } from '@hrz/core/models/logistic/availability.enum';

@Component({
  selector: 'app-availability-field',
  templateUrl: './availability-field.component.html',
})
export class AvailabilityFieldComponent {
  @Input() availability: Availability;

  colors = {};
  messages = {};

  constructor() {
    this.colors[Availability.GREEN] = '#7FFF00';
    this.colors[Availability.YELLOW] = '#FFFF66';
    this.colors[Availability.RED] = '#F44E4E';
    this.colors[Availability.BLUE] = '#1E90FF';
    this.colors[Availability.BLACK] = '#000000';

    this.messages[Availability.GREEN] = '';
    this.messages[Availability.YELLOW] = 'STOCK.AVAILABILITY.YES_ANOTHER';
    this.messages[Availability.RED] = 'STOCK.AVAILABILITY.NOT_AVAILABLE';
    this.messages[Availability.BLUE] = 'STOCK.AVAILABILITY.YES_OWN';
    this.messages[Availability.BLACK] = 'STOCK.AVAILABILITY.NO_STATUS';
  }
}
