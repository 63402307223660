import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';


import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { DossierStatusActionActivity } from '@hrz/core/models/dossier/dossier-status-action-activity';

@Component({
  templateUrl: 'authorization-rejected-warning.modal.html',
  styleUrls: ['authorization-rejected-warning-modal.scss'],
  providers: [],
})
@DynamicModal()
export class AuthorizationRejectedWarningModal implements OnInit, AfterViewInit, OnDestroy {
  public onFailure: () => void;
  public rejection: DossierStatusActionActivity;

  public _loading = false;
  public editMode: boolean;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this._loading = value;
  }

  private closeModal: Function;

  @ViewChild('authorizationRejectedWarning') private childModal: ModalDirective;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };

    this.childModal.show();
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
  }
}
