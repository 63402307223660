import { finalize } from 'rxjs/operators';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { ModalDirective } from 'ngx-bootstrap';
import { Dossier } from '@hrz/core/models/dossier';
import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { Note } from '@hrz/core/models/note';
import { FileService } from '@hrz/core/services/file.service';
import { NoteService } from '../../services/note.service';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { Document } from '@hrz/core/models/document';
import { ByteHelpers } from '@hrz/core/utils/helpers';

@Component({
  providers: [TranslateService],
  templateUrl: './create-new-note.modal.html',
  styleUrls: ['./create-new-note.modal.scss'],
})
@DynamicModal()
export class CreateNewNoteModal implements OnInit, AfterViewInit, OnDestroy {
  public onClose: Function;
  public onOk: Function;
  public onMessageSend: Function;
  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('addNoteModal') childModal: ModalDirective;

  public context: Note;
  allowedFileTypes: string[];
  fileUploading: boolean;

  constructor(
    private toasterService: ToasterService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private noteService: NoteService,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    console.log('CreateNewNoteModal.ngOnInit');
  }

  ngAfterViewInit(): void {
    console.log('CreateNewNoteModal.ngAfterViewInit');
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('CreateNewNoteModal.ngOnDestroy');
  }
  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }
  clickSend(): void {
    this.spinnerService.show('addNoteModalSpinner');
    this.executeCommand().then(succeeded => {
      this.spinnerService.hide('addNoteModalSpinner');
      if (succeeded) {
        if (this.onOk) {
          this.onOk();
        }
        this.closeModal();
      }
    });
  }

  executeCommand(): Promise<boolean> {
    console.log('CreateNewNoteModal.executeCommand()');
    return this.noteService.create(this.context).then(result => this.handleCommandAction(result));
  }

  handleCommandAction(result: any): boolean {
    if (result.status === 200 || result.status === 201 || result.status === 202) {
      return true;
    } else {
      this.spinnerService.hide('addNoteModalSpinner');
      if (!result.commandValidations || result.commandValidations.length <= 0) {
        this.toasterService.showError(result);
      } else {
        const allErrors = [];
        result.commandValidations.map(validation => {
          allErrors.push(validation.Value);
        });
        this.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED'), allErrors);
      }
      return false;
    }
  }

  onChangeFile($event: any, _documentType: string): void {
    console.log('CreateNewNoteModal.onChangeFile()');
    const scope = this;
    const file = $event.target.files[0];

    if (this.allowedFileTypes && this.allowedFileTypes.length > 0) {
      const fileType = file['type'];
      const isValidFile = this.isFileValidFile(fileType);
      if (isValidFile == false) {
        console.log(`CreateNewNoteModal.onChangeFile() - file valid: ${fileType}`);
        this.toasterService.showError(
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.WRONGTYPE')} ${this.formatFileType(file.name)}. ` +
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.ALLOWED')} ${this.userFriendlyFileTypes().join(', ')}`
        );
        return;
      }
    }

    this.readFileAsBuffer(scope, file, file);
  }

  isFileValidFile(fileType: string): boolean {
    console.log(`CreateNewNoteModal.isFileValidFile() - file type: ${fileType}`);
    const validImageType = this.allowedFileTypes.indexOf(fileType) >= 0;

    return validImageType;
  }

  formatFileType(fileType: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileType)[1];
  }

  userFriendlyFileTypes(): string[] {
    const returnValue = [];
    this.allowedFileTypes.forEach(fileType => {
      returnValue.push(fileType.substring(fileType.indexOf('/') + 1));
    });
    return returnValue;
  }

  readFileAsBuffer(scope: any, file: File, originalFile: File): void {
    scope.fileUploading = true;
    const reader = new FileReader();
    reader.onprogress = function (_progEvent: ProgressEvent) {
      scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    };
    reader.onload = function () {
      try {
        const document = new Document();
        document.Dossier = new Dossier();
        document.Dossier.Id = scope.context.dossierId;
        document.FileMetaData = new FileMetaData();

        const arrayBuffer = this.result;
        document.FileContent = ByteHelpers.arrayBufferToBase64(arrayBuffer);
        document.FileMetaData.FileName = file.name;
        document.FileMetaData.FileSize = file.size;
        document.FileMetaData.FileType = originalFile.type;
        document.File = file;
        document.File.newfilename = file.name;
        scope.onUploadFinished(document);
      } catch (ex) {
        console.log(ex);
        scope.fileUploading = false;
      }
    };
    reader.readAsArrayBuffer(file);
  }

  onUploadFinished(uploadedDocument: Document): void {
    console.log('CreateNewNoteModal.onUploadFinished() - Step 1, save to DocumentManagement API');
    if (!uploadedDocument) { return; }
    this.spinnerService.show('uploadAttachmentSpinner');
    this.fileService
      .uploadDocument(uploadedDocument)
      .pipe(finalize(() => {}))
      .subscribe(
        (file: any) => {
          console.log('CreateNewNoteModal.onUploadFinished() - Step 2, add file id to attachments');

          if (!this.context.Attachments) {
            this.context.Attachments = [];
          }

          this.context.Attachments.push({
            Filename: uploadedDocument.FileMetaData.FileName,
            Reference: file.id,
            Filesize: this.humanFileSize(uploadedDocument.FileMetaData.FileSize),
          });

          this.spinnerService.hide('uploadAttachmentSpinner');
          this.fileUploading = false;
        },
        _error => {
          this.spinnerService.hide('uploadAttachmentSpinner');
          this.fileUploading = false;
          this.toasterService.showApiError(_error);
        }
      );
  }

  removeFile(fileId: number): void {
    this.fileUploading = true;
    this.fileService.delete({ ids: [fileId] }).subscribe(
      () => {
        console.log('CreateNewNoteModal.onRemoveFileFinished()');
        this.context.Attachments = this.context.Attachments.filter(item => item.Reference !== fileId);
        this.fileUploading = false;
      },
      error => {
        console.log(error);
        this.fileUploading = false;
      }
    );
  }

  humanFileSize(size: number): string {
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return Number((size / Math.pow(1024, i)).toFixed(2)) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }
}
