import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { from as observableFrom } from 'rxjs';
import { tap, mergeMap, finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap';
import { saveAs } from 'file-saver';

import { FileDownload } from '@hrz/core/models/document/file-download';
import { Dossier } from '@hrz/core/models/dossier';
import { Document } from '@hrz/core/models/document';
import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { FileService } from '@hrz/core/services/file.service';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { DynamicModal, DynamicModalService } from '../dynamic-modal.module';
import { ByteHelpers } from '@hrz/core/utils/helpers';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { DocumentService } from '@hrz/core/services/document.service';
import { CommandActionService } from '@hrz/core/services/command-action.service';

@Component({
  templateUrl: 'multi-document-viewer.modal.html',
})
@DynamicModal()
export class MultiDocumentViewerModal implements OnInit, AfterViewInit, OnDestroy {
  @Input() documents: Document[] = [];
  @Input() dossier: Dossier;
  @Input() modalService: DynamicModalService;
  @Input() documentType: string;
  @Input() allowedFileTypes: string[];
  @Input() allowedDocumentCount: number;
  @ViewChild('multiDocumentViewerModal') private childModal: ModalDirective;

  message: string = undefined;

  public onUploadFinished: Function;
  public onFileRemoved: Function;

  constructor(
    private translateService: TranslateService,
    private fileService: FileService,
    private toasterService: ToasterService,
    private readonly spinnerService: SpinnerService,
    private readonly documentService: DocumentService,
    private commandActionService: CommandActionService
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }

  ngOnDestroy(): void {}

  public cancel(): void {
    this.childModal.hide();
  }

  onAddFile($event: any): void {
    console.log('MultiDocumentViewerModal.onAddFile() - files: ', $event.target.files);
    const file = $event.target.files[0];

    const fileType = file['type'];
    const isValidFile = this.isFileValidFile(fileType);
    if (isValidFile == false) {
      console.log(`MultiDocumentViewerModal.onAddFile() - file valid: ${fileType}`);
      this.toasterService.showError(
        `${this.translateService.instant('SHARED.MULTI_DOCUMENT_UPLOADER.FILE_UPLOAD.WRONG_TYPE')} ${this.formatFileType(file.name)}.` +
        `${this.translateService.instant('SHARED.MULTI_DOCUMENT_UPLOADER.FILE_UPLOAD.ALLOWED')} ` + 
        `${this.userFriendlyFileTypes().join(', ')}`
      );
      return;
    }

    console.log('MultiDocumentViewerModal.onAddFile() - Now reading the file as Buffer...');
    this.readFileAsBuffer(this, file, file);
  }

  private isFileValidFile(fileType: string): boolean {
    console.log(` .isFileValidFile() - file type: ${fileType}`);
    const validImageType = this.allowedFileTypes.indexOf(fileType) >= 0;

    return validImageType;
  }

  private userFriendlyFileTypes(): string[] {
    const returnValue = [];
    this.allowedFileTypes.forEach(fileType => {
      returnValue.push(fileType.substring(fileType.indexOf('/') + 1));
    });
    return returnValue;
  }

  private formatFileType(fileType: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileType)[1];
  }

  private readFileAsBuffer(currentScope: any, file: File, originalFile: File): void {
    this.spinnerService.show('uploadDocumentSpinner');
    const scope = currentScope;
    const reader = new FileReader();
    reader.onprogress = function (_progEvent: ProgressEvent) {
      scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    };
    reader.onload = function () {
      try {
        const currentDocument = new Document();
        currentDocument.Dossier = new Dossier();
        currentDocument.Dossier.Id = scope.dossier.Id;
        currentDocument.FileMetaData = new FileMetaData();
        currentDocument.DocumentType = scope.documentType;

        const arrayBuffer = this.result;
        currentDocument.FileContent = ByteHelpers.arrayBufferToBase64(arrayBuffer);
        currentDocument.FileBlob = new Blob([new Uint8Array(arrayBuffer as any)]);
        currentDocument.FileMetaData.FileName = `${file.name.split('.').shift()}_${scope.dossier.Id}.${file.name.split('.').pop()}`;
        currentDocument.FileMetaData.FileSize = file.size;
        currentDocument.FileMetaData.FileType = originalFile.type;
        currentDocument.File = file;
        currentDocument.File.newfilename = currentDocument.FileMetaData.FileName;
        currentDocument.FileNameTranslated = currentDocument.FileMetaData.FileName;
        scope.uploadDocument(currentDocument);

        scope.documents.push(currentDocument);
      } catch (ex) {
        console.log(ex);
        scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOAD_FAILED');
      } finally {
        scope.message = undefined;
      }
    };
    reader.readAsArrayBuffer(file);
  }

  uploadDocument(uploadedDocument: Document): void {
    console.log('MultiDocumentViewerModal.uploadDocument() - Step 1, save to DocumentManagement API', uploadedDocument);
    if (!uploadedDocument) {
      return;
    }
    this.fileService
      .uploadDocument(uploadedDocument)
      .pipe(finalize(() => {}))
      .subscribe(
        (file: any) => {
          uploadedDocument.FileMetaData.Reference = file.id;
          console.log('MultiDocumentViewerModal.uploadDocument() - Step 2, save to Dossier Document API');
          this.documentService
            .create(uploadedDocument)
            .then(result => this.commandActionService.handlePromiseAction(result))
            .then(result => {
              if (result.entity) {
                uploadedDocument.Id = result.entity.Id;
              } else {
                uploadedDocument.Id = result.Id;
              }

              this.onUploadFinished(uploadedDocument);
              this.spinnerService.hide('uploadDocumentSpinner');
            })
            .catch(_err => {
              this.spinnerService.hide('uploadDocumentSpinner');
            });
        },
        _error => {
          this.spinnerService.hide('uploadDocumentSpinner');
          this.toasterService.showApiError(_error);
        }
      );
  }

  btnRemoveDocument(document: Document): void {
    this.spinnerService.show('uploadDocumentSpinner');
    console.log('MultiDocumentViewerModal.btnRemoveDocument()');

    if (document == null) {
      return;
    }
    const fileId = +document.FileMetaData.Reference;
    if (fileId == null) {
      return;
    }

    this.spinnerService.show('uploadDocumentSpinner');
    this.fileService
      .delete({ ids: [fileId] })
      .pipe(
        mergeMap(() => observableFrom(this.documentService.delete(document))),
        tap(result => this.commandActionService.handlePromiseAction(result)),
        finalize(() => {
          this.documents.forEach((item, index) => {
            if (item === document) { this.documents.splice(index, 1); }
          });
          this.onFileRemoved(document);
          this.spinnerService.hide('uploadDocumentSpinner');
        })
      )
      .subscribe(() => {});
  }

  btnDocumentDownload(document: Document): void {
    console.log('MultiDocumentViewerModal.btnDocumentDownload()');
    if (document.FileBlob && document.FileMetaData) {
      saveAs(document.FileBlob, document.FileMetaData.FileName);
      return;
    }

    this.fileService.downloadSingle(Number(document.FileMetaData.Reference)).then((fileDownload: FileDownload) => {
      saveAs(fileDownload.blob, fileDownload.name);
    });
  }
}
