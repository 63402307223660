import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Protocol } from '@hrz/core/models/protocol';
import { Dossier } from '@hrz/core/models/dossier';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { DossierAction } from '@hrz/core/models/dossier-action';

@Component({
  selector: 'ic-auth-approve-modal',
  templateUrl: './ic-auth-approve.modal.html',
})
@DynamicModal()
export class IcAuthApproveModal implements OnInit, AfterViewInit, OnDestroy {
  public protocols: Protocol[];
  public dossier: Dossier;
  public dossierAction: DossierAction;
  public data: any;

  public contextApproveAuthNumber: string;
  public selectedProtocolId: number;

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('icAuthApproveModal') private childModal: ModalDirective;

  ngOnInit(): void {
    this.protocols = this.data.protocols;
    this.dossier = this.data.dossier;
    this.dossierAction = this.data.action;
    this.contextApproveAuthNumber = this.dossier.InsuranceInfo.IcClaimNumber;
  }

  ngAfterViewInit(): void {
    this.childModal.show();
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) {
      this.onClose();
    }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) {
      this.onOk(this.dossier, this.data.action, this.contextApproveAuthNumber);
    }
  }
}
