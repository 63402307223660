import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appValidateOnBlur]',
})
export class ValidateOnBlurDirective {
  constructor(public formControl: NgControl) {}

  @HostListener('blur') onBlur() {
    this.formControl.control.updateValueAndValidity();
  }
}
