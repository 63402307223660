import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { File as FileResult } from '@hrz/core/models/document/file';
import { DynamicModalService } from '@hrz/shared/dynamic-modal.module';
import { DeleteConfirmationModal } from '@hrz/shared/modals/delete-confirmation.modal';
import { ConfirmationsModule } from '@hrz/shared/modals/confirmations.module';

@Component({
  selector: 'app-form-upload-button',
  templateUrl: 'form-upload-button.component.html',
  styleUrls: ['./form-upload-button.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormUploadButtonComponent), multi: true }],
})
export class FormUploadButtonComponent implements ControlValueAccessor {
  @Input() accept: string;
  @Input() text: string;
  @Input() control: FormControl;
  @Output() onFileChange: EventEmitter<File> = new EventEmitter<File>();
  @Output() onFileRemove: EventEmitter<void> = new EventEmitter<void>();
  @Output() onDownload: EventEmitter<File> = new EventEmitter<File>();
  isDisabled: boolean;
  _model: FileResult;

  private file: File;

  get model() {
    return this._model;
  }

  set model(value: FileResult) {
    if (this._model === value) {
      return;
    }
    this._model = value;
    this.onChange(value);
  }

  constructor(
    private modalService: DynamicModalService,
    private translateService: TranslateService
  ) {}

  onChange = (_: FileResult) => {};
  onTouched = () => {};

  writeValue(value) {
    this.model = value;
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  fileChange(file: File) {
    this.file = file;
    if (file == null) {
      this.model = null;
      return this.onChange(null);
    }

    this.onFileChange.emit(file);
  }

  download(file: File = this.file) {
    this.onDownload.emit(file);
  }

  remove() {
    this.modalService.create(ConfirmationsModule, DeleteConfirmationModal, {
      message: this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DELETE.MESSAGE'),
      onOk: () => {
        this.model = null;
        this.fileChange(null);
        this.onFileRemove.emit();
      },
    });
  }
}
