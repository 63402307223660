import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
})
export class SearchInputComponent {
  @Input() value;
  @Input() placeholder: string;
  @Output() onChange: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.value = '';
    this.placeholder = '';
  }

  change(event: KeyboardEvent) {
    const value = (<HTMLInputElement>event.target).value;
    this.onChange.emit(value);
  }
}
