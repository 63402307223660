import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { EventType } from '@hrz/core/models/event-type';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { Dossier } from '@hrz/core/models/dossier';
import { Appointment } from '@hrz/core/models/appointment';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { AppointmentCreate } from '@hrz/core/models/appointment-create';
import { AppointmentData } from '@hrz/core/models/appointment-data';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { Enum } from '@hrz/core/models/enum';
import { noOnlyWhitespace } from '@hrz/core/utils/validators';
import { EnumService } from '@hrz/core/services/enum.service';
import { AuthService } from '@hrz/core/services/auth.service';
import { Permission } from '@hrz/core/models/enums/permission.enum';
import { Observable } from 'rxjs';

@Component({
  templateUrl: 'add-dossier-appointment.modal.html',
  styleUrls: ['add-dossier-appointment.modal.scss']
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class AddDossierAppointmentModal implements OnInit, AfterViewInit, OnDestroy {
  public onSave: (
    appointmentCreate: AppointmentCreate,
    preferredNotificationType: string,
    email: string
  ) => Observable<ApiActionResult<Appointment>>;

  onAfterSave: (appointment: Appointment) => void;
  onModalClose: () => void;
  eventDescription: EventType;
  onFailure: () => void;
  eventDate: Date;
  dossier: Dossier;
  appointmentTimeslot: string;
  appointmentData: AppointmentData;
  fittingStationHasMobileService: boolean;
  fittingStationIsMobileOnly: boolean;
  isMobileJob: boolean;
  ownerRequestsFitterToCome: boolean;
  editMobileAddressForm: FormGroup;
  isSaveEnable = true;
  isMobileJobDirty = false;
  preferredNotificationTypeOptions: Enum[] = [];
  preferredNotificationMode: boolean;
  ownerHasEmail: boolean;
  skipPreferredNotificationType = false;
  skipAskShouldNotify = false;
  shouldNotifyMode = false;
  shouldNotify = false;

  private preferredNotificationForm: FormGroup;
  private allowedNotificationTypes: string[];


  public _loading = false;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this.shouldSetClickOutsideDisabled(value);
    this._loading = value;
  }

  private closeModal: Function;
  @ViewChild('addDossierAppointment') private childModal: ModalDirective;

  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private authService: AuthService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.enumService.getPreferredNotificationTypes().then(items => {
      // Build up a list with translated values based on what is allowed.
      if (this.dossier && this.dossier.LegalEntity && this.dossier.LegalEntity.LegalEntitySettingsDto) {
        this.allowedNotificationTypes = this.dossier.LegalEntity.LegalEntitySettingsDto.AllowedNoticationType;
      }
      const allowed: Enum[] = [];
      if (this.allowedNotificationTypes) {
        this.allowedNotificationTypes.forEach(element => {
          const item = items.find(x => x.Key == element);
          if (item) {
            allowed.push(item);
          }
        });
        this.preferredNotificationTypeOptions = allowed;
      } else {
        // nothing is configured, by default allow all.
        this.preferredNotificationTypeOptions = items;
      }
    });
    const momentDate = moment(this.eventDate);
    this.appointmentTimeslot = `${momentDate.format('ddd DD MMM ')}
      ${momentDate.format('HH:mm')} ${this.translateService.instant('DOSSIER.MODALS.TILL')} ${momentDate
        .add(this.eventDescription.lengthInHours, 'hours')
        .format('HH:mm')}`;
    if (this.ownerRequestsFitterToCome) {
      this.isMobileJob = this.ownerRequestsFitterToCome;
    } else {
      this.isMobileJob = this.fittingStationIsMobileOnly;
    }

    this.editMobileAddressForm = new FormGroup({
      fittingAddressStreet: new FormControl(null, Validators.required),
      fittingAddressZipCode: new FormControl(null, Validators.required),
      fittingAddressCity: new FormControl(null, Validators.required),
    });
    this.editMobileAddressForm.valueChanges.subscribe(() => {
      if (this.editMobileAddressForm.valid && this.editMobileAddressForm.dirty) {
        this.isSaveEnable = true;
      }
    });

    this.preferredNotificationForm = this.formBuilder.group({
      preferredNotificationType: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.email, noOnlyWhitespace]),
    });
  }

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
    this.onModalClose();
  }

  clickSave(): void {
    if (this.skipPreferredNotificationType || this.dossier.DossierVehicle.Owner.PreferredNotificationType !== null) {
      if (this.isConfirmed() && !this.skipAskShouldNotify) {
        this.shouldNotifyMode = true;
      } else {
        let editInfo = this.editMobileAddressForm.value;
        this.appointmentData.fittingAddressStreet = editInfo.fittingAddressStreet;
        this.appointmentData.fittingAddressCity = editInfo.fittingAddressCity;
        this.appointmentData.fittingAddressZipCode = editInfo.fittingAddressZipCode;
        this.addAppointment();
      }
    } else {
      this.preferredNotificationMode = true;
    }
  }

  clickFinalSave(): void {
    if (this.isConfirmed()) {
      this.shouldNotifyMode = true;
    } else {
      this.addAppointment();
    }
  }

  addAppointment() {
    this.spinnerService.show('appointmentSpinner');
    this.loading = true;
    const modelForSave = {
      dossierId: this.dossier.Id,
      fittingStationId: this.dossier.Fittingstation.Id,
      appointmentEventTypeId: this.eventDescription.id,
      start: moment(this.eventDate).utc().format(),
      end: moment(this.eventDate).add(this.eventDescription.lengthInHours, 'hour').utc().format(),
      isMobileJob: this.isMobileJob,
      appointmentData: this.appointmentData,
      blockedMinutesBefore: 0,
      blockedMinutesAfter: 0,
      shouldNotify: this.shouldNotify,
      isConfirmed: this.isConfirmed(),
      assignedByCallCenter: this.isAssignedByCallCenter(),
    } as AppointmentCreate;

    let preferredNotificationType: string = null;
    let email: string = null;
    if (this.preferredNotificationMode) {
      preferredNotificationType = this.preferredNotificationForm.get('preferredNotificationType').value;
      if (!this.ownerHasEmail) {
        email = this.preferredNotificationForm.get('email').value;
      }
    }

    this.onSave(modelForSave, preferredNotificationType, email)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide('appointmentSpinner');
          this.closeModal();
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterSave) {
            this.onAfterSave(response.entity);
            this.spinnerService.hide('appointmentSpinner');
          }
        },
        () => {
          this.spinnerService.hide('appointmentSpinner');
          this.onFailure();
        }
      );
  }
  isValid(): boolean {
    if (!this.isMobileJob) {
      return true;
    } else {
      return this.editMobileAddressForm.valid;
    }
  }

  isConfirmed(): boolean {
    return this.authService.userHasScope(Permission.u_a_cnf);
  }
  isAssignedByCallCenter(): boolean {
    return this.authService.userHasScope(Permission.r_is_cc);
  }

  isFinalSaveValid(): boolean {
    if (this.ownerHasEmail || this.preferredNotificationForm.get('preferredNotificationType').value !== 'Email') {
      return this.preferredNotificationForm.get('preferredNotificationType').valid;
    }
    return this.preferredNotificationForm.valid;
  }

  shouldSetClickOutsideDisabled = (disabled: boolean) => {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: disabled ? 'static' : true,
      keyboard: !disabled,
      ignoreBackdropClick: true,
    };
  }

  clickDontNotify(): void {
    this.shouldNotify = false;
    this.addAppointment();
  }

  clickNotify(): void {
    this.shouldNotify = true;
    this.addAppointment();
  }
}
