import { catchError } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { Storage } from '@hrz/core/models/document/storage';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private apiUrl = ConfigurationManager.AppSettings.documentManagementApi + '/storage';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: StorageService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('StorageService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public list(): Observable<Storage[]> {
    return this.http.get<Storage[]>(this.apiUrl, { headers: this.httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }
}
