import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { DynamicModal } from '../../dynamic-modal.module';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { Document } from '@hrz/core/models/document';
import { FileService } from '@hrz/core/services/file.service';

@Component({
  selector: 'documents-slider-modal',
  templateUrl: './document-slider.modal.html',
  styleUrls: ['./document-slider.modal.scss'],
})
@DynamicModal()
export class DocumentsSliderModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public documents: Document[];

  public onOk: Function;
  public onClose: Function;
  public viewingIndex = 0;
  rotation = 0;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    console.log('DocumentsSliderModal.ngOnInit');
  }

  ngAfterViewInit(): void {
    console.log('DocumentsSliderModal.ngAfterViewInit');
    window.addEventListener(
      'message',
      e => {
        if (e.data === 'close') {
          console.log('close message received');
          this.clickClose();
        }
      },
      false
    );
    this.childModal.show();
    this.LoadFileContents();
  }

  ngOnDestroy(): void {
    console.log('DocumentsSliderModal.ngOnDestroy');
  }

  clickClose(): void {
    console.log('DocumentsSliderModal.clickClose');
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickDownload(): void {
    console.log('DocumentsSliderModal.clickDownload');
    // Identify the current view....
    // saveAs(this.pdfFile.blob, this.pdfFile.name);
  }

  clickOk(): void {
    console.log('DocumentsSliderModal.clickOk');
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }

  previousDocument(): void {
    this.rotation = 0;
    console.log('DocumentsSliderModal.previousDocument');
    if (this.viewingIndex - 1 === -1) { return; }

    this.viewingIndex = this.viewingIndex - 1;
  }

  nextDocument(): void {
    this.rotation = 0;
    console.log('DocumentsSliderModal.nextDocument');
    if (this.viewingIndex === this.documents.length - 1) { return; }

    this.viewingIndex = this.viewingIndex + 1;
  }
  nextButtonVisibile(): Boolean {
    if (!this.documents) { return false; }
    return this.viewingIndex !== this.documents.length - 1;
  }

  previousButtonVisible(): Boolean {
    return this.viewingIndex !== 0;
  }

  IsItemAnImage(document: Document): Boolean {
    return document.FileMetaData.FileType.indexOf('image') !== -1;
  }

  rotateClockwise(): void {
    if (this.rotation < 360) {
      this.rotation += 90;
    } else {
      this.rotation = 0;
    }
  }

  rotateCounterClockwise(): void {
    if (this.rotation > -360) {
      this.rotation -= 90;
    } else {
      this.rotation = 0;
    }
  }
  rotateImageClasses() : string {
    if(this.rotation === 90 || this.rotation === -270) return 'roatate90';
    if(this.rotation === 180 || this.rotation === -180) return 'roatate180';
    if(this.rotation === -90 || this.rotation === 270) return 'roatate270';
    else return "";
  }
  private LoadFileContents() {
    console.log('DocumentsSliderModal.LoadFileContents() - Amount: ' + (this.documents.length - 1));
    const scope = this;
    for (let i = 0; i <= this.documents.length - 1; i++) {
      const loadFileId = +this.documents[i].FileMetaData.Reference;
      const scopedDocument = this.documents[i];

      console.log(
        'DocumentsSliderModal.LoadFileContents() => Loading DocumentId: ' +
          this.documents[i].Id +
          ' and FileMetaData.Reference: ' +
          loadFileId +
          ' - FileType: ' +
          this.documents[i].FileMetaData.FileType
      );
      this.fileService.downloadDossierDocument(scopedDocument).subscribe((downloadedFile: FileDownload) => {
        const scopedDocument = scope.documents.find(c => c.Id === downloadedFile.LinkedDocumentId);
        scopedDocument.FileNameTranslated = downloadedFile.name;

        if (!scopedDocument) { return; }

        if (scopedDocument.FileMetaData.FileType.indexOf('application') != -1) {
          console.log(
            'DocumentsSliderModal.LoadFileContents() => Loaded File for DocumentId:' + scopedDocument.Id + '. Now reading into ByteArray...'
          );

          const fileReader = new FileReader();
          fileReader.onload = function () {
            scopedDocument.FileByteArray = this.result;
            scopedDocument.FinishedLoading = true;
          };
          fileReader.readAsArrayBuffer(downloadedFile.blob);
        } else {
          console.log(
            'DocumentsSliderModal.LoadFileContents() => Loaded File for DocumentId:' + scopedDocument.Id + '. Now creating Image Url...'
          );
          scopedDocument.FileContent = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(downloadedFile.blob));
          scopedDocument.FinishedLoading = true;
        }
      });
    }
  }
}
