import { zip as observableZip, of as observableOf, Observable } from 'rxjs';

import { map } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Enum } from '@hrz/core/models/enum';
import { TranslateInitializeService } from '@hrz/core/services/translate-initialize.service';
import { DropdownDefinitionService } from '@hrz/core/services/dropdown-definition.service';
import { DropdownDefinition } from '@hrz/core/models/multilangual/dropdown-definition';
import { DropdownItemTranslation } from '@hrz/core/models/multilangual/dropdown-item-translation';
import { DropdownItem } from '@hrz/core/models/multilangual/dropdown-item';
import { AccountTypeEnum } from '@hrz/core/models/account-type.enum';
import { ProtocolFlowEnum } from '../enums/protocol-flow.enum';
import { AuthService } from '@hrz/core/services/auth.service';
import { CountryService } from '@hrz/core/services/country.service';
import { CountryEnum } from '../enums/country.enum';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class EnumService {
  public static readonly ServiceCategory = 'ServiceCategory';
  private _jsonURL = '../../assets/json/italianBirthPlaces.json';
  defaultLanguageCode = 'en-GB';

  constructor(
    private readonly authService: AuthService,
    private readonly countryService: CountryService,
    private readonly dropdownDefinitionService: DropdownDefinitionService,
    private readonly http: HttpClient,
    private readonly translateInitializeService: TranslateInitializeService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: EnumService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('EnumService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  getContactTitles(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Mr.', Value: 'Mr.' } as Enum);
    list.push({ Key: 'Ms.', Value: 'Ms.' } as Enum);
    return Promise.resolve(list);
  }

  getFittingGroupTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Independent', Value: 'Independent' } as Enum);
    list.push({ Key: 'Chain', Value: 'Chain' } as Enum);
    return Promise.resolve(list);
  }

  getDossierStatusEnum(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Active', Value: 'Active' } as Enum);
    list.push({ Key: 'ReadyForInvoicing', Value: 'Ready For Invoicing' } as Enum);
    list.push({ Key: 'Invoiced', Value: 'Invoiced' } as Enum);
    list.push({ Key: 'Archived', Value: 'Archived' } as Enum);
    return Promise.resolve(list);
  }
  getContentTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'KeyUser', Value: 'Key User' } as Enum);
    list.push({ Key: 'BackupKeyUser', Value: 'Backup Key User' } as Enum);
    return Promise.resolve(list);
  }

  getContactTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'NotSet', Value: 'Not Set' } as Enum);
    list.push({ Key: 'Authorization', Value: 'Authorization' } as Enum);
    list.push({ Key: 'CallCenter', Value: 'Call Center' } as Enum);
    list.push({ Key: 'CentralClaimManagement', Value: 'Central Claim Management' } as Enum);
    list.push({ Key: 'StationManagerInternal', Value: 'Station Manager Internal' } as Enum);
    list.push({ Key: 'StationManagerExternal', Value: 'Station Manager External' } as Enum);
    list.push({ Key: 'Fitter', Value: 'Fitter' } as Enum);
    list.push({ Key: 'Customer', Value: 'Customer' } as Enum);
    list.push({ Key: 'FsControlDepartment', Value: 'FS Control Department' } as Enum);
    list.push({ Key: 'PurchasingDepartment', Value: 'Purchasing Department' } as Enum);
    list.push({ Key: 'PricingDepartment', Value: 'Pricing Department' } as Enum);
    list.push({ Key: 'InvoicingDepartment', Value: 'Invoicing Department' } as Enum);
    list.push({ Key: 'CommercialManager', Value: 'Commercial Manager' } as Enum);
    return Promise.resolve(list);
  }

  // GDES72-4324
  getApplicableRegions(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Insular', Value: 'Insular' } as Enum);
    list.push({ Key: 'Nacional', Value: 'Nacional' } as Enum);
    list.push({ Key: 'Peninsular', Value: 'Peninsular' } as Enum);
    list.push({ Key: 'Ceuta y Melilla', Value: 'Ceuta y Melilla' } as Enum);
    return Promise.resolve(list);
  }

  getCollaborationRecipients(dropdownKey: string): Promise<Enum[]> {
    return this.getByName(dropdownKey);
  }

  getStatusEnums(): Enum[] {
    const list = [];
    list.push({ Key: 'Active', Value: 'Active' } as Enum);
    list.push({ Key: 'Deactivated', Value: 'Disabled / Deactivated' } as Enum);
    list.push({ Key: 'Readonly', Value: 'Readonly' } as Enum);
    return list;
  }

  getFittingStationTypeEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Owned', Value: 'Owned' } as Enum);
    list.push({ Key: 'Franchise', Value: 'Franchise' } as Enum);
    list.push({ Key: 'Partner', Value: 'Partner' } as Enum);
    list.push({ Key: 'External', Value: 'External' } as Enum);
    list.push({ Key: 'MobileServiceOwned', Value: 'Mobile Service Owned' } as Enum);
    list.push({ Key: 'MobileServiceExternal', Value: 'Mobile Service External' } as Enum);

    return Promise.resolve(list);
  }

  getServiceTypeEnums(): Promise<Enum[]> {
    return this.getByName('FittingStationServiceTypes').then(result => result.sort((a, b) => a.Value.localeCompare(b.Value)));
  }

  getRejectReason(): Promise<Enum[]> {
    return this.getByName('RejectReason');
  }

  getActionReason(): Promise<Enum[]> {
    return this.getByName('ActionReason');
  }

  getComplaintTypeEnums(): Promise<Enum[]> {
    return this.getByName('TypeOfComplaint');
  }

  getPhotoTypeEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Station', Value: 'Station' } as Enum);
    list.push({ Key: 'Profile', Value: 'Profile' } as Enum);
    list.push({ Key: 'Cover', Value: 'Cover' } as Enum);
    list.push({ Key: 'Interiour', Value: 'Interiour' } as Enum);
    list.push({ Key: 'Exteriour', Value: 'Exteriour' } as Enum);
    list.push({ Key: 'Branding', Value: 'Branding' } as Enum);
    list.push({ Key: 'Employees', Value: 'Employees' } as Enum);

    return Promise.resolve(list);
  }

  getDaysOfWeekEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Sunday', Value: 'Sunday' } as Enum);
    list.push({ Key: 'Monday', Value: 'Monday' } as Enum);
    list.push({ Key: 'Tuesday', Value: 'Tuesday' } as Enum);
    list.push({ Key: 'Wednesday', Value: 'Wednesday' } as Enum);
    list.push({ Key: 'Thursday', Value: 'Thursday' } as Enum);
    list.push({ Key: 'Friday', Value: 'Friday' } as Enum);
    list.push({ Key: 'Saturday', Value: 'Saturday' } as Enum);

    return Promise.resolve(list);
  }

  getAccountTypeEnums(): Enum[] {
    const list = [];
    list.push({ Key: AccountTypeEnum[AccountTypeEnum.InsuranceCompany], Value: 'Insurance Company' } as Enum);
    list.push({ Key: AccountTypeEnum[AccountTypeEnum.Rental], Value: 'Rental' } as Enum);
    list.push({ Key: AccountTypeEnum[AccountTypeEnum.Lease], Value: 'Lease' } as Enum);
    list.push({ Key: AccountTypeEnum[AccountTypeEnum.Fleet], Value: 'Fleet' } as Enum);
    list.push({ Key: AccountTypeEnum[AccountTypeEnum.OneTime], Value: 'OneTime' } as Enum);

    list.push({ Key: 'Returning', Value: 'Returning' } as Enum);
    list.push({ Key: 'Ngc', Value: 'Ngc' } as Enum);

    return list;
  }

  getAccountGroupTypeEnums(): Promise<Enum[]> {
    return this.getByName('AccountGroupTypeEs');
  }

  getAccountTypes(): Promise<Enum[]> {
    return this.getByName('AccountType');
  }

  getOwnershipTypes(): Promise<Enum[]> {
    return this.getByName('OwnershipType');
  }

  getVatDeductions(tenantId: number): Promise<Enum[]> {
    if (tenantId === CountryEnum.Greece) {
      return this.getByName('VatDeductionClosed');
    }

    if (tenantId === CountryEnum.Estonia) {
      return this.getByName('VatDeductionEe');
    }

    if (tenantId === CountryEnum.Poland) {
      return this.getByName('VatDeductionPl');
    }

    return this.getByName('VatDeduction');
  }
  getFiscalSystem(tenantId: number): Promise<Enum[]> {
    // this method needs a tenant to load the values (the values are different per tenant)
    // the languagecode is used to fetch the list per tenant (so for italy it will always load the italian list,
    // even if your language is set to en-GB)

    const list = [];
    list.push({ key: 5, value: 'it-IT' });
    list.push({ key: 10, value: 'pt-PT' }); // todo Add all entities that needs this

    let languageCode = list.find(x => x.key === tenantId);
    if (!languageCode) {
      languageCode = 'en-GB';
    } else {
      languageCode = languageCode.value;
    }

    return this.getByNameAndLanguageCode('FiscalSystem', languageCode);
    // return this.getByName('FiscalSystem');
  }

  getComponentEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'InsuranceInfo', Value: 'Insurance Information' }, {
      Key: 'DossierValidationOverview',
      Value: 'Dossier Validation',
    } as Enum);
    return Promise.resolve(list);
  }

  getFieldTypeEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'List', Value: 'List' } as Enum);
    // list.push({ Key: 'MultipleCheck', Value: 'Multiple checkboxes' } as Enum);
    list.push({ Key: 'Input', Value: 'Default input field' } as Enum);
    // list.push({ Key: 'Bool', Value: 'Boolean field' } as Enum);
    return Promise.resolve(list);
  }

  getColorEnums(): Promise<Enum[]> {
    return this.getByName('Color');
  }

  getTransmissionTypeEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Automatic', Value: 'Automatic' } as Enum);
    list.push({ Key: 'Manual', Value: 'Manual' } as Enum);
    return Promise.resolve(list);
  }

  getVehicleBodyTypeEnums(): Promise<Enum[]> {
    return this.getByName('VehicleBodyType');
  }

  getVehicleTypeEnums(): Promise<Enum[]> {
    return this.getByName('VehicleType');
  }

  getSpecialVehicleEnums(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'NotSpecial', Value: 'Not Special' } as Enum);
    list.push({ Key: 'Police', Value: 'Police' } as Enum);
    list.push({ Key: 'UnregisteredCar', Value: 'Unregistered Car' } as Enum);
    return Promise.resolve(list);
  }

  getPriorityVehicleEnums(): Promise<Enum[]> {
    return this.getByName('PriorityVehicles');
  }

  getTemplateTypes(): Observable<Enum[]> {
    const list = [];
    list.push({ Key: 'Text', Value: '1' } as Enum);
    list.push({ Key: 'Html', Value: '2' } as Enum);
    list.push({ Key: 'Pdf', Value: '3' } as Enum);
    list.push({ Key: 'WhatsApp', Value: '4' } as Enum);
    return observableOf(list);
  }

  getJobTypes(): Promise<Enum[]> {
    return this.getByName('JobType');
  }

  getJobTypesByLanguageCode(languageCode: string): Promise<Enum[]> {
    return this.getByNameAndLanguageCode('JobType', languageCode);
  }

  getExplanationTypes(): Promise<Enum[]> {
    return this.getByName('ExplanationType');
  }

  getDamageDescriptionType(): Promise<Enum[]> {
    return this.getByName('DamageDescriptionType');
  }

  getContactMadeType(): Promise<Enum[]> {
    return this.getByName('ContactMadeType');
  }

  getVehicleBuildYearsEnums(): Promise<Enum[]> {
    const list = [];
    for (let i = new Date().getFullYear(); i >= 1960; i--) {
      list.push({ Key: i + '', Value: i + '' } as Enum);
    }
    return Promise.resolve(list);
  }

  getProtocolTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'NotSet', Value: 'Not Set' } as Enum);
    list.push({ Key: 'Email', Value: 'Email' } as Enum);
    list.push({ Key: 'Mail', Value: 'Mail' } as Enum);
    list.push({ Key: 'Portal', Value: 'Portal' } as Enum);
    list.push({ Key: 'Phone', Value: 'Phone' } as Enum);
    list.push({ Key: 'Txt', Value: 'Txt' } as Enum);
    list.push({ Key: 'WebService', Value: 'WebService' } as Enum);
    list.push({ Key: 'Upload', Value: 'Upload to Dossier' } as Enum);
    list.push({ Key: 'WebAuthority', Value: 'Web Authority' } as Enum);
    list.push({ Key: 'CallCenter', Value: 'CallCenter' } as Enum);
    list.push({ Key: 'InvoiceGeneration', Value: 'Invoice Generation' } as Enum);
    list.push({ Key: 'K4S', Value: 'K4S' } as Enum);
    return Promise.resolve(list);
  }

  getProtocolFlowTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Sending], Value: 'Sending' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Authorization], Value: 'Authorization' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Validation], Value: 'Validation' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.RetrieveIcUpdates], Value: 'Check for IC Updates' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.GenerateAuthorizationFile], Value: 'Generate Authorization File' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Cancellation], Value: 'Dossier Cancellation' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.IcSpecificDocumentAtCheckin], Value: 'IC document at checkin' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.IcSpecificDocumentAtCheckout], Value: 'IC document at checkout' } as Enum);
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.SendIcMessage], Value: 'Send IC Message' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.ContentValidation], Value: 'Field content validation' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.CustomAction], Value: 'Custom Action' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.IcView], Value: 'Ic View' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Ngc], Value: 'Non Glass Coverage' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.ProForma], Value: 'Proforma' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.VatDivert], Value: 'VatDivert' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.Quote], Value: 'Quote' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.PolicyQuery], Value: 'Policy query' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.InvoiceGeneration], Value: 'Invoice generation' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.ReceiveIcCase], Value: 'Receive IC case' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.SendIcPreparation], Value: 'Send IC preparation' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.SendIcUpdate], Value: 'Send IC Update' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.ResponseAction], Value: 'Response Action' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.AdasReportValidation], Value: 'Adas Report Validation' });
    list.push({ Key: ProtocolFlowEnum[ProtocolFlowEnum.AppGeneratedDocument], Value: 'Mobile App Generated Document' });

    return Promise.resolve(list);
  }

  getProtocolDocumentTypes(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'AccidentReport', Value: 'Accident Report' } as Enum);
    list.push({ Key: 'AccountInvoice', Value: 'Account Invoice' } as Enum);
    list.push({ Key: 'AudaflowReport', Value: 'Audaflow Report' } as Enum);
    list.push({ Key: 'AuthorityReport', Value: 'Authority Report' } as Enum);
    list.push({ Key: 'Back', Value: 'Back' } as Enum);
    list.push({ Key: 'BackLeft', Value: 'BackLeft' } as Enum);
    list.push({ Key: 'BackRight', Value: 'Back Right' } as Enum);
    list.push({ Key: 'BrokenGlass', Value: 'Broken glass(es)' } as Enum);
    list.push({ Key: 'CarRegistration', Value: 'Car Registration' } as Enum);
    list.push({ Key: 'CheckIn', Value: 'Check In' } as Enum);
    list.push({ Key: 'CheckOut', Value: 'Check Out' } as Enum);
    list.push({ Key: 'CreditInvoice', Value: 'Credit Invoice' } as Enum);
    list.push({ Key: 'CreditTransferDocument', Value: 'Credit Transfer Document' } as Enum);
    list.push({ Key: 'CustomerInvoice', Value: 'Customer Invoice' } as Enum);
    list.push({ Key: 'DamageDeclaration', Value: 'Damage Declaration' } as Enum);
    list.push({ Key: 'DirectSalesInvoice', Value: 'Direct Sales Invoice' } as Enum);
    list.push({ Key: 'DocumentOther', Value: 'Document Other' } as Enum);
    list.push({ Key: 'FitterInvoice', Value: 'Fitter Invoice' } as Enum);
    list.push({ Key: 'FitterOemInvoice', Value: 'Fitter Oem Invoice' } as Enum);
    list.push({ Key: 'FitterOwnRiskInvoice', Value: 'Fitter Own Risk Invoice' } as Enum);
    list.push({ Key: 'Front', Value: 'Front' } as Enum);
    list.push({ Key: 'FrontLeft', Value: 'Front Left' } as Enum);
    list.push({ Key: 'FrontRight', Value: 'Front Right' } as Enum);
    list.push({ Key: 'GlassDriveAuthorization', Value: 'Glass Drive Authorization' } as Enum);
    list.push({ Key: 'GreenCard', Value: 'Green Card' } as Enum);
    list.push({ Key: 'ICAuthorization', Value: 'IC Authorization' } as Enum);
    list.push({ Key: 'ICReevaluation', Value: 'IC Reevaluation' } as Enum);
    list.push({ Key: 'InsideVehicle', Value: 'Inside Vehicle' } as Enum);
    list.push({ Key: 'InsuranceContract', Value: 'Insurance Contract' } as Enum);
    list.push({ Key: 'Kms', Value: 'Kms' } as Enum);
    list.push({ Key: 'LeftSide', Value: 'Left Side' } as Enum);
    list.push({ Key: 'NewGlassLogo', Value: 'New Glass Logo' } as Enum);
    list.push({ Key: 'NotSet', Value: 'Not Set' } as Enum);
    list.push({ Key: 'OeInvoice', Value: 'OE Invoice' } as Enum);
    list.push({ Key: 'OfflineCheckIn', Value: 'Offline Check In' } as Enum);
    list.push({ Key: 'OfflineCheckOut', Value: 'Offline Check Out' } as Enum);
    list.push({ Key: 'OldGlassLogoScratched', Value: 'Old Glass Logo Scratched' } as Enum);
    list.push({ Key: 'OrderNumber', Value: 'Order Number' } as Enum);
    list.push({ Key: 'PictureAfter', Value: 'Picture After' } as Enum);
    list.push({ Key: 'PictureBefore', Value: 'PictureBefore' } as Enum);
    list.push({ Key: 'PictureChassis', Value: 'Picture Chassis' } as Enum);
    list.push({ Key: 'PictureDriverLicense', Value: 'Picture Driver License' } as Enum);
    list.push({ Key: 'PictureLicensePlate', Value: 'Picture License Plate' } as Enum);
    list.push({ Key: 'PictureOtherdamage', Value: 'Picture Otherdamage' } as Enum);
    list.push({ Key: 'PicturePassport', Value: 'Picture Passport' } as Enum);
    list.push({ Key: 'PictureRegistrationCard', Value: 'Picture Registration Card' } as Enum);
    list.push({ Key: 'Policy', Value: 'Policy' } as Enum);
    list.push({ Key: 'PresenceDocument', Value: 'Presence Document' } as Enum);
    list.push({ Key: 'RightSide', Value: 'Right Side' } as Enum);
    list.push({ Key: 'SendToAccount', Value: 'Send To Account' } as Enum);
    list.push({ Key: 'Sunroof', Value: 'Sunroof' } as Enum);
    list.push({ Key: 'TranquilidadeAgreement', Value: 'Tranquilidade agreement' } as Enum);
    list.push({ Key: 'UnsignedCheckIn', Value: 'Unsigned Check In' } as Enum);
    list.push({ Key: 'UnsignedCheckOut', Value: 'Unsigned Check Out' } as Enum);
    list.push({ Key: 'Vin', Value: 'Vin' } as Enum);
    list.push({ Key: 'WaiverDeclaration', Value: 'Waiver Declaration' } as Enum);
    list.push({ Key: 'WarrantyCertificate', Value: 'Warranty Certificate' } as Enum);
    list.push({ Key: 'AdasCalibrationReportBefore', Value: 'Adas report before' } as Enum);
    list.push({ Key: 'AdasCalibrationReportAfter', Value: 'Adas report after' } as Enum);
    list.push({ Key: 'CounterSalesProforma', Value: 'Counter sale proforma' } as Enum);
    list.push({ Key: 'ExpertAssessorReport', Value: 'Expert Assessor Report' } as Enum);
    list.push({ Key: 'Quotation', Value: 'Quotation' } as Enum);
    list.push({ Key: 'VatReceipt', Value: 'Vat Receipt' } as Enum);
    list.push({ Key: 'CalibrationValidationReport', Value: 'Calibration validation report' } as Enum);
    list.push({ Key: 'ProForma', Value: 'Pro Forma' } as Enum);
    list.push({ Key: 'Quittance', Value: 'Quittance' } as Enum);
    list.push({ Key: 'GDPR', Value: 'GDPR' } as Enum);
    list.push({ Key: 'PurchaseOrder', Value: 'Purchase Order' } as Enum);
    list.push({ Key: 'Nps', Value: 'Nps' } as Enum);
    return Promise.resolve(list);
  }

  getCreditNoteReaons(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'WrongAccount', Value: 'Wrong Account' } as Enum);
    list.push({ Key: 'WrongPrice', Value: 'Wrong Price' } as Enum);
    list.push({ Key: 'WrongCustomerDetails', Value: 'Wrong Customer Details' } as Enum);
    list.push({ Key: 'WrongMaterial', Value: 'Wrong Material' } as Enum);
    list.push({ Key: 'WrongOwnRisk', Value: 'Wrong Own Risk' } as Enum);
    list.push({ Key: 'WrongLimitedCoverage', Value: 'Wrong Limited Coverage' } as Enum);
    list.push({ Key: 'WrongVat', Value: 'Wrong Vat' } as Enum);
    list.push({ Key: 'WrongDamageDetails', Value: 'Wrong Damage Details' } as Enum);
    return Promise.resolve(list);
  }

  getAllianzPtDamageProvinces(): Promise<Enum[]> {
    const list = [];
    list.push({ Key: 'Aveiro', Value: 'Aveiro' } as Enum);
    list.push({ Key: 'Beja', Value: 'Beja' } as Enum);
    list.push({ Key: 'Braga', Value: 'Braga' } as Enum);
    list.push({ Key: 'Bragança', Value: 'Bragança' } as Enum);
    list.push({ Key: 'Castelo Branco', Value: 'Castelo Branco' } as Enum);
    list.push({ Key: 'Coimbra', Value: 'Coimbra' } as Enum);
    list.push({ Key: 'Évora', Value: 'Évora' } as Enum);
    list.push({ Key: 'Faro', Value: 'Faro' } as Enum);
    list.push({ Key: 'Guarda', Value: 'Guarda' } as Enum);
    list.push({ Key: 'Leiria', Value: 'Leiria' } as Enum);
    list.push({ Key: 'Lisboa', Value: 'Lisboa' } as Enum);
    list.push({ Key: 'Portalegre', Value: 'Portalegre' } as Enum);
    list.push({ Key: 'Porto', Value: 'Porto' } as Enum);
    list.push({ Key: 'Santarém', Value: 'Santarém' } as Enum);
    list.push({ Key: 'Setúbal', Value: 'Setúbal' } as Enum);
    list.push({ Key: 'Viana do Castelo', Value: 'Viana do Castelo' } as Enum);
    list.push({ Key: 'Vila Real', Value: 'Vila Real' } as Enum);
    list.push({ Key: 'Viseu', Value: 'Viseu' } as Enum);
    list.push({ Key: 'Açores', Value: 'Açores' } as Enum);
    list.push({ Key: 'Madeira', Value: 'Madeira' } as Enum);
    return Promise.resolve(list);
  }

  getDriverAndOwnerRelation(): Promise<Enum[]> {
    return this.getByName('DriverAndOwnerRelation');
  }

  getDossierStates(): Promise<Enum[]> {
    return this.getByName('DossierState');
  }

  getDossierSubstates(): Promise<Enum[]> {
    return this.getByName('DossierSubstate');
  }

  getAppointmentStates(): Promise<Enum[]> {
    return this.getByName('AppointmentState');
  }

  getGlassPositions(): Promise<Enum[]> {
    return this.getByName('GlassPosition');
  }

  getDamageLocation(): Promise<Enum[]> {
    return this.getByName('DamageLocation');
  }

  getCodificationStates(): Promise<Enum[]> {
    return this.getByName('CodificationState');
  }
  getNoCalibrationReasons() {
    return this.getByName('ReasonsNoCalibration');
  }

  getNotificationTypes(): Promise<Enum[]> {
    return this.getByName('NotificationType');
  }
  public getDossierCancelReasons(): Promise<Enum[]> {
    return this.getByName('DossierCancelReasons').then(results => {
      return this.sortByfirstTwoDigits(results);
    });
  }

  getGlassOnlyProductTypeIds(): Promise<string[]> {
    return Promise.resolve([
      '1',
      '2',
      '3',
      '4',
      '5',
      '6',
      '7',
      '8',
      '9',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '227',
      '253',
      '261',
      '262',
      '263',
      '264',
      '265',
      '266',
      '267',
      '268',
      '327',
      '328',
      '329',
      '330',
      '331',
      '332',
      '333',
      '334',
      '335',
      '336',
      '337',
      '338',
      '339',
      '341',
      '340',
      '342',
      '343',
      '344',
      '378',
    ]);
  }

  getFittingStationRegions(tenantId: number): Promise<Enum[]> {
    const list = [];
    if (tenantId === 5) {
      list.push({ Key: 'AG', Value: 'Agrigento' } as Enum);
      list.push({ Key: 'AL', Value: 'Alessandria' } as Enum);
      list.push({ Key: 'AN', Value: 'Ancona' } as Enum);
      list.push({ Key: 'AO', Value: 'Aosta' } as Enum);
      list.push({ Key: 'AP', Value: 'Ascoli Piceno' } as Enum);
      list.push({ Key: 'AQ', Value: 'L\'Aquila' } as Enum);
      list.push({ Key: 'AR', Value: 'Arezzo' } as Enum);
      list.push({ Key: 'AT', Value: 'Asti' } as Enum);
      list.push({ Key: 'AV', Value: 'Avellino' } as Enum);
      list.push({ Key: 'BA', Value: 'Bari' } as Enum);
      list.push({ Key: 'BG', Value: 'Bergamo' } as Enum);
      list.push({ Key: 'BI', Value: 'Biella' } as Enum);
      list.push({ Key: 'BL', Value: 'Belluno' } as Enum);
      list.push({ Key: 'BN', Value: 'Benevento' } as Enum);
      list.push({ Key: 'BO', Value: 'Bologna' } as Enum);
      list.push({ Key: 'BR', Value: 'Brindisi' } as Enum);
      list.push({ Key: 'BS', Value: 'Brescia' } as Enum);
      list.push({ Key: 'BT', Value: 'Barletta - Andria - Trani' } as Enum);
      list.push({ Key: 'BZ', Value: 'Bolzano' } as Enum);
      list.push({ Key: 'CA', Value: 'Cagliari' } as Enum);
      list.push({ Key: 'CB', Value: 'Campobasso' } as Enum);
      list.push({ Key: 'CE', Value: 'Caserta' } as Enum);
      list.push({ Key: 'CH', Value: 'Chieti' } as Enum);
      list.push({ Key: 'CI', Value: 'Carbonia Iglesias' } as Enum);
      list.push({ Key: 'CL', Value: 'Caltanissetta' } as Enum);
      list.push({ Key: 'CN', Value: 'Cuneo' } as Enum);
      list.push({ Key: 'CO', Value: 'Como' } as Enum);
      list.push({ Key: 'CR', Value: 'Cremona' } as Enum);
      list.push({ Key: 'CS', Value: 'Cosenza' } as Enum);
      list.push({ Key: 'CT', Value: 'Catania' } as Enum);
      list.push({ Key: 'CZ', Value: 'Catanzaro' } as Enum);
      list.push({ Key: 'EE', Value: 'Estero' } as Enum); // This field is not known in SAP !
      list.push({ Key: 'EN', Value: 'Enna' } as Enum);
      list.push({ Key: 'FC', Value: 'Forlì - Cesena' } as Enum);
      list.push({ Key: 'FE', Value: 'Ferrara' } as Enum);
      list.push({ Key: 'FG', Value: 'Foggia' } as Enum);
      list.push({ Key: 'FI', Value: 'Firenze' } as Enum);
      list.push({ Key: 'FM', Value: 'Fermo' } as Enum);
      list.push({ Key: 'FO', Value: 'Forli' } as Enum);
      list.push({ Key: 'FR', Value: 'Frosinone' } as Enum);
      list.push({ Key: 'GE', Value: 'Genova' } as Enum);
      list.push({ Key: 'GO', Value: 'Gorizia' } as Enum);
      list.push({ Key: 'GR', Value: 'Grosseto' } as Enum);
      list.push({ Key: 'IM', Value: 'Imperia' } as Enum);
      list.push({ Key: 'IS', Value: 'Isernia' } as Enum);
      list.push({ Key: 'KR', Value: 'Crotone' } as Enum);
      list.push({ Key: 'LC', Value: 'Lecco' } as Enum);
      list.push({ Key: 'LE', Value: 'Lecce' } as Enum);
      list.push({ Key: 'LI', Value: 'Livorno' } as Enum);
      list.push({ Key: 'LO', Value: 'Lodi' } as Enum);
      list.push({ Key: 'LT', Value: 'Latina' } as Enum);
      list.push({ Key: 'LU', Value: 'Lucca' } as Enum);
      list.push({ Key: 'MB', Value: 'Monza e Brianza' } as Enum);
      list.push({ Key: 'MC', Value: 'Macerata' } as Enum);
      list.push({ Key: 'ME', Value: 'Messina' } as Enum);
      list.push({ Key: 'MI', Value: 'Milano' } as Enum);
      list.push({ Key: 'MN', Value: 'Mantova' } as Enum);
      list.push({ Key: 'MO', Value: 'Modena' } as Enum);
      list.push({ Key: 'MS', Value: 'Massa Carrara' } as Enum);
      list.push({ Key: 'MT', Value: 'Matera' } as Enum);
      list.push({ Key: 'NA', Value: 'Napoli' } as Enum);
      list.push({ Key: 'NO', Value: 'Novara' } as Enum);
      list.push({ Key: 'NU', Value: 'Nuoro' } as Enum);
      list.push({ Key: 'OG', Value: 'Ogliastra' } as Enum);
      list.push({ Key: 'OR', Value: 'Oristano' } as Enum);
      list.push({ Key: 'OT', Value: 'Olbia Tempio' } as Enum);
      list.push({ Key: 'PA', Value: 'Palermo' } as Enum);
      list.push({ Key: 'PC', Value: 'Piacenza' } as Enum);
      list.push({ Key: 'PD', Value: 'Padova' } as Enum);
      list.push({ Key: 'PE', Value: 'Pescara' } as Enum);
      list.push({ Key: 'PG', Value: 'Perugia' } as Enum);
      list.push({ Key: 'PI', Value: 'Pisa' } as Enum);
      list.push({ Key: 'PN', Value: 'Pordenone' } as Enum);
      list.push({ Key: 'PO', Value: 'Prato' } as Enum);
      list.push({ Key: 'PR', Value: 'Parma' } as Enum);
      list.push({ Key: 'PS', Value: 'Pesaro' } as Enum);
      list.push({ Key: 'PT', Value: 'Pistoia' } as Enum);
      list.push({ Key: 'PU', Value: 'Pesaro - Urbino' } as Enum);
      list.push({ Key: 'PV', Value: 'Pavia' } as Enum);
      list.push({ Key: 'PZ', Value: 'Potenza' } as Enum);
      list.push({ Key: 'RA', Value: 'Ravenna' } as Enum);
      list.push({ Key: 'RC', Value: 'Reggio Calabria' } as Enum);
      list.push({ Key: 'RE', Value: 'Reggio Emilia' } as Enum);
      list.push({ Key: 'RG', Value: 'Ragusa' } as Enum);
      list.push({ Key: 'RI', Value: 'Rieti' } as Enum);
      list.push({ Key: 'RM', Value: 'Roma' } as Enum);
      list.push({ Key: 'RN', Value: 'Rimini' } as Enum);
      list.push({ Key: 'RO', Value: 'Rovigo' } as Enum);
      list.push({ Key: 'RV', Value: 'Rovigo' } as Enum);
      list.push({ Key: 'SA', Value: 'Salerno' } as Enum);
      list.push({ Key: 'SI', Value: 'Siena' } as Enum);
      list.push({ Key: 'SO', Value: 'Sondrio' } as Enum);
      list.push({ Key: 'SP', Value: 'La Spezia' } as Enum);
      list.push({ Key: 'SR', Value: 'Siracusa' } as Enum);
      list.push({ Key: 'SS', Value: 'Sassari' } as Enum);
      list.push({ Key: 'SV', Value: 'Savona' } as Enum);
      list.push({ Key: 'TA', Value: 'Taranto' } as Enum);
      list.push({ Key: 'TE', Value: 'Teramo' } as Enum);
      list.push({ Key: 'TN', Value: 'Trento' } as Enum);
      list.push({ Key: 'TO', Value: 'Torino' } as Enum);
      list.push({ Key: 'TP', Value: 'Trapani' } as Enum);
      list.push({ Key: 'TR', Value: 'Terni' } as Enum);
      list.push({ Key: 'TS', Value: 'Trieste' } as Enum);
      list.push({ Key: 'TV', Value: 'Treviso' } as Enum);
      list.push({ Key: 'UD', Value: 'Udine' } as Enum);
      list.push({ Key: 'VA', Value: 'Varese' } as Enum);
      list.push({ Key: 'VB', Value: 'Verbania' } as Enum);
      list.push({ Key: 'VC', Value: 'Vercelli' } as Enum);
      list.push({ Key: 'VE', Value: 'Venezia' } as Enum);
      list.push({ Key: 'VI', Value: 'Vicenza' } as Enum);
      list.push({ Key: 'VR', Value: 'Verona' } as Enum);
      list.push({ Key: 'VS', Value: 'Medio Campidano' } as Enum);
      list.push({ Key: 'VT', Value: 'Viterbo' } as Enum);
      list.push({ Key: 'VV', Value: 'Vibo Valentia' } as Enum);
    } else if (tenantId === 2) {
      list.push({ Key: 'ÁLAVA', Value: 'ÁLAVA' } as Enum);
      list.push({ Key: 'ALBACETE', Value: 'ALBACETE' } as Enum);
      list.push({ Key: 'ALICANTE', Value: 'ALICANTE' } as Enum);
      list.push({ Key: 'ALMERÍA', Value: 'ALMERÍA' } as Enum);
      list.push({ Key: 'ASTURIAS', Value: 'ASTURIAS' } as Enum);
      list.push({ Key: 'ÁVILA', Value: 'ÁVILA' } as Enum);
      list.push({ Key: 'BADAJOZ', Value: 'BADAJOZ' } as Enum);
      list.push({ Key: 'BALEARES', Value: 'BALEARES' } as Enum);
      list.push({ Key: 'BARCELONA', Value: 'BARCELONA' } as Enum);
      list.push({ Key: 'BURGOS', Value: 'BURGOS' } as Enum);
      list.push({ Key: 'CÁCERES', Value: 'CÁCERES' } as Enum);
      list.push({ Key: 'CÁDIZ', Value: 'CÁDIZ' } as Enum);
      list.push({ Key: 'CANTABRIA', Value: 'CANTABRIA' } as Enum);
      list.push({ Key: 'CASTELLÓN', Value: 'CASTELLÓN' } as Enum);
      list.push({ Key: 'CEUTA', Value: 'CEUTA' } as Enum);
      list.push({ Key: 'CIUDAD REAL', Value: 'CIUDAD REAL' } as Enum);
      list.push({ Key: 'CÓRDOBA', Value: 'CÓRDOBA' } as Enum);
      list.push({ Key: 'CUENCA', Value: 'CUENCA' } as Enum);
      list.push({ Key: 'GERONA', Value: 'GERONA' } as Enum);
      list.push({ Key: 'GRANADA', Value: 'GRANADA' } as Enum);
      list.push({ Key: 'GUADALAJARA', Value: 'GUADALAJARA' } as Enum);
      list.push({ Key: 'GUIPÚZCOA', Value: 'GUIPÚZCOA' } as Enum);
      list.push({ Key: 'HUELVA', Value: 'HUELVA' } as Enum);
      list.push({ Key: 'HUESCA', Value: 'HUESCA' } as Enum);
      list.push({ Key: 'JAÉN', Value: 'JAÉN' } as Enum);
      list.push({ Key: 'LA CORUÑA', Value: 'LA CORUÑA' } as Enum);
      list.push({ Key: 'LA RIOJA', Value: 'LA RIOJA' } as Enum);
      list.push({ Key: 'LAS PALMAS', Value: 'LAS PALMAS' } as Enum);
      list.push({ Key: 'LEÓN', Value: 'LEÓN' } as Enum);
      list.push({ Key: 'LÉRIDA', Value: 'LÉRIDA' } as Enum);
      list.push({ Key: 'LUGO', Value: 'LUGO' } as Enum);
      list.push({ Key: 'MADRID', Value: 'MADRID' } as Enum);
      list.push({ Key: 'MÁLAGA', Value: 'MÁLAGA' } as Enum);
      list.push({ Key: 'MELILLA', Value: 'MELILLA' } as Enum);
      list.push({ Key: 'MURCIA', Value: 'MURCIA' } as Enum);
      list.push({ Key: 'NAVARRA', Value: 'NAVARRA' } as Enum);
      list.push({ Key: 'ORENSE', Value: 'ORENSE' } as Enum);
      list.push({ Key: 'PALENCIA', Value: 'PALENCIA' } as Enum);
      list.push({ Key: 'PONTEVEDRA', Value: 'PONTEVEDRA' } as Enum);
      list.push({ Key: 'SALAMANCA', Value: 'SALAMANCA' } as Enum);
      list.push({ Key: 'SEGOVIA', Value: 'SEGOVIA' } as Enum);
      list.push({ Key: 'SEVILLA', Value: 'SEVILLA' } as Enum);
      list.push({ Key: 'SORIA', Value: 'SORIA' } as Enum);
      list.push({ Key: 'TARRAGONA', Value: 'TARRAGONA' } as Enum);
      list.push({ Key: 'TENERIFE', Value: 'TENERIFE' } as Enum);
      list.push({ Key: 'TERUEL', Value: 'TERUEL' } as Enum);
      list.push({ Key: 'TOLEDO', Value: 'TOLEDO' } as Enum);
      list.push({ Key: 'VALENCIA', Value: 'VALENCIA' } as Enum);
      list.push({ Key: 'VALLADOLID', Value: 'VALLADOLID' } as Enum);
      list.push({ Key: 'VIZCAYA', Value: 'VIZCAYA' } as Enum);
      list.push({ Key: 'ZAMORA', Value: 'ZAMORA' } as Enum);
      list.push({ Key: 'ZARAGOZA', Value: 'ZARAGOZA' } as Enum);
    } else if (tenantId === 10) {
      list.push({ Key: 'AVEIRO', Value: 'Aveiro' } as Enum);
      list.push({ Key: 'BEJA', Value: 'Beja' } as Enum);
      list.push({ Key: 'BRAGA', Value: 'Braga' } as Enum);
      list.push({ Key: 'BRAGANCA', Value: 'Bragança' } as Enum);
      list.push({ Key: 'CASTELOBRANCO', Value: 'Castelo Branco' } as Enum);
      list.push({ Key: 'COIMBRA', Value: 'Coimbra' } as Enum);
      list.push({ Key: 'EVORA', Value: 'Évora' } as Enum);
      list.push({ Key: 'FARO', Value: 'Faro' } as Enum);
      list.push({ Key: 'GUARDA', Value: 'Guarda' } as Enum);
      list.push({ Key: 'ILHAGRACIOSA', Value: 'Ilha da Graciosa' } as Enum);
      list.push({ Key: 'ILHAMADEIRA', Value: 'Ilha da Madeira' } as Enum);
      list.push({ Key: 'ILHAFLORES', Value: 'Ilha das Flores' } as Enum);
      list.push({ Key: 'ILHAPORTOSANTO', Value: 'Ilha de Porto Santo' } as Enum);
      list.push({ Key: 'ILHASANTAMARIA', Value: 'Ilha de Santa Maria' } as Enum);
      list.push({ Key: 'ILHASAOJORGE', Value: 'Ilha de São Jorge' } as Enum);
      list.push({ Key: 'ILHASAOMIGUEL', Value: 'Ilha de São Miguel' } as Enum);
      list.push({ Key: 'ILHADOCORVO', Value: 'Ilha do Corvo' } as Enum);
      list.push({ Key: 'ILHADOFAIAL', Value: 'Ilha do Faial' } as Enum);
      list.push({ Key: 'ILHADOPICO', Value: 'Ilha do Pico' } as Enum);
      list.push({ Key: 'ILHATERCEIRA', Value: 'Ilha Terceira' } as Enum);
      list.push({ Key: 'LEIRIA', Value: 'Leiria' } as Enum);
      list.push({ Key: 'LISBOA', Value: 'Lisboa' } as Enum);
      list.push({ Key: 'PORTALEGRE', Value: 'Portalegre' } as Enum);
      list.push({ Key: 'PORTO', Value: 'Porto' } as Enum);
      list.push({ Key: 'SANTAREM', Value: 'Santarém' } as Enum);
      list.push({ Key: 'SETUBAL', Value: 'Setúbal' } as Enum);
      list.push({ Key: 'VIANADOCASTELO', Value: 'Viana do Castelo' } as Enum);
      list.push({ Key: 'VILAREAL', Value: 'Vila Real' } as Enum);
      list.push({ Key: 'VISEU', Value: 'Viseu' } as Enum);
    }
    return Promise.resolve(list);
  }

  getExportLanguages(): Promise<Enum[]> {
    return this.getByName('ModuleLanguages');
  }

  public getHowDidYouFindUsOptions(): Promise<Enum[]> {
    return this.getByName('HowDidYouFindUsOptions');
  }

  getPaymentOptions(): Promise<Enum[]> {
    return this.getByName('PaymentOptions');
  }

  getCountersaleReason(dropdownKey: string): Promise<Enum[]> {
    return this.getByName(dropdownKey);
  }

  async getRequestCreditReasons(): Promise<Enum[]> {
    const tenantId = await this.authService.getTenantId().toPromise();
    const country = await this.countryService.getCountry(tenantId);
    const dropdownKey = 'NgcCreditReason' + country.IsoCode.toUpperCase();
    return this.getByName(dropdownKey);
  }

  async getRejectCreditRequestReasons(): Promise<Enum[]> {
    const tenantId = await this.authService.getTenantId().toPromise();
    const country = await this.countryService.getCountry(tenantId);
    const dropdownKey = 'CreditRejectReason' + country.IsoCode.toUpperCase();
    return this.getByName(dropdownKey);
  }

  getCountersaleSubStates(): Promise<Enum[]> {
    return this.getByName('CountersaleSubstate');
  }

  getIdDocumentTypes(): Promise<Enum[]> {
    console.log('EnumService.getIdDocumentTypes()');
    return this.getByName('IdDocumentTypes');
  }

  getGlassConverageEnum(): Promise<Enum[]> {
    return this.getByName('GlassCoverage');
  }

  getPriorityEnum(): Promise<Enum[]> {
    return this.getByName('Priority');
  }

  getAdditionalNotificationEnum(): Promise<Enum[]> {
    return this.getByName('AdditionalNotification');
  }

  getComplaintStatusEnum(): Promise<Enum[]> {
    return this.getByName('ComplaintStatus');
  }

  getSaveForLaterReasonEnum(): Promise<Enum[]> {
    return this.getByName('SaveForLaterReason');
  }

  getHelloAutoJobTypeEnum(): Promise<Enum[]> {
    return this.getByName('HelloAutoJobType');
  }

  /*GDES-54
    getReasonsOfOEMUsage(): Promise<Enum[]>{
        console.log('EnumService.getReasonsOfOEMUsage');
        return this.getByName('OEMUsageReasons');
    }*/

  getServiceCategories(): Promise<Enum[]> {
    return this.getByName(EnumService.ServiceCategory);
  }

  getReasonsOfOEMUsage(): Promise<Enum[]> {
    console.log('EnumService.getReasonsOfOEMUsage');
    return this.getByName('OEMUsageReasons');
  }

  getDealersOfOEMUsage(): Promise<Enum[]> {
    console.log('EnumService.getReasonsOfOEMUsage');
    return this.getByName('OEMDealers');
  }

  getPreferredNotificationTypes(): Promise<Enum[]> {
    return this.getByName('PreferredNotificationTypes');
  }
  getAverageCosts(tenantId: number): Promise<Enum[]> {
    if (tenantId === 2) { return this.getByName('AverageCostsEs'); }
    return Promise.resolve([]);
  }

  getOpeningHourExceptionReasons(): Promise<Enum[]> {
    return this.getByName('OpeningHourExceptionReasons');
  }

  getTaxOffices(tenantId: number): Promise<Enum[]> {
    switch (tenantId) {
      case CountryEnum.Greece:
        return this.getByName('TaxOfficesGr');
      default:
        return Promise.resolve([]);
    }
  }

  getCurrency(tenantId: number): Enum[] {
    switch (tenantId) {
      case CountryEnum.Poland:
        return [
          { Key: 'currency', Value: 'zł' },
          { Key: 'align', Value: 'R' },
        ] as Enum[];
      default:
        return [
          { Key: 'currency', Value: '€' },
          { Key: 'align', Value: 'L' },
        ] as Enum[];
    }
  }

  private getByName(name: string): Promise<Enum[]> {
    return observableZip(this.translateInitializeService.getLanguageObservable(), this.dropdownDefinitionService.getByName(name))
      .pipe(map(([languageCode, result]: [string, DropdownDefinition]) => this.mapDropdownDefinitionToEnums(result, languageCode)))
      .toPromise();
  }

  private getByNameAndLanguageCode(name: string, languageCode: string): Promise<Enum[]> {
    languageCode = languageCode == null ? this.defaultLanguageCode : languageCode;

    return this.dropdownDefinitionService
      .getByName(name)
      .pipe(map(result => this.mapDropdownDefinitionToEnums(result, languageCode)))
      .toPromise();
  }

  private mapDropdownDefinitionToEnums(definition: DropdownDefinition, languageCode: string): Enum[] {
    if (definition === null || definition.dropdownDefinitionItems === null) {
      return [];
    }
    // if there aren't translations for selected language code, then set language code to default
    if (
      !definition.dropdownDefinitionItems.some(items =>
        items.dropdownDefinitionItemTranslations.some(
          translations => translations.languageCode && translations.languageCode.code.toLowerCase() === languageCode.toLowerCase()
        )
      )
    ) {
      languageCode = this.defaultLanguageCode;
    }
    return definition.dropdownDefinitionItems.map(item => this.mapDropdownItemToEnum(item, languageCode));
  }

  private mapDropdownItemToEnum(item: DropdownItem, languageCode: string): Enum {
    return {
      Key: item.key,
      Value: this.getEnumValueByLanguage(item.dropdownDefinitionItemTranslations, languageCode),
    };
  }

  private getEnumValueByLanguage(translations: DropdownItemTranslation[], languageCode: string) {
    const itemTranslation = translations.find(
      translation => translation.languageCode && translation.languageCode.code.toLowerCase() === languageCode.toLowerCase()
    );
    return itemTranslation && itemTranslation.translationContent;
  }

  async getItalianBirthPlaces(): Promise<Enum[]> {
    if (!localStorage.getItem('itFiscalPlaces')) {
      localStorage.setItem('itFiscalPlaces', JSON.stringify(await this.getJsonAsync()));
    }
    const places = localStorage.getItem('itFiscalPlaces');

    const list = [];
    JSON.parse(places, (key, value) => list.push({ Key: key, Value: value } as Enum));
    return list;
  }

  async getJsonAsync() {
    const response = await this.http.get(this._jsonURL).toPromise();
    return response;
  }
  private sortByfirstTwoDigits(results: Enum[]): Enum[] {
    return results
      .sort((a, b) => a.Key.localeCompare(b.Key))
      .map(cancelReasons => {
        if (cancelReasons.Key.indexOf('_') > -1) {
          // remove the first 2 digits
          cancelReasons.Key = cancelReasons.Key.split('_')[1];
        }
        return cancelReasons;
      });
  }
}
