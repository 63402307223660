import { finalize } from 'rxjs/operators';
import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

import { FormHelpers } from '@hrz/core/utils/helpers';
import { Role } from '@hrz/core/models/role';
import { PermissionService } from '@hrz/core/services/permission.service';
import { PermissionInfo } from '@hrz/core/models/document/permission-info';
import { StructureInfo } from '@hrz/core/models/document/structure-info';
import { PermissionEdit } from '@hrz/core/models/document/permission-edit';

@Component({
  selector: 'tm-structure-manage-permissions',
  templateUrl: './structure-manage-permissions.component.html'
})
export class StructureManagePermissionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() roles: Role[];
  @Input() structure: StructureInfo;
  loadingPermissions: boolean;
  canModifyPermissions: boolean;
  structureManagePermissionsForm: FormGroup;
  permissionInfo: PermissionInfo;
  subscriptions: Subscription[];

  constructor(private permissionService: PermissionService) {
    this.structureManagePermissionsForm = this.getForm();
    this.loadingPermissions = false;
    this.canModifyPermissions = false;
    this.subscriptions = [
      this.structureManagePermissionsForm.get('role').valueChanges.subscribe(_ => this.onRoleChange()),
      this.structureManagePermissionsForm.get('inherit').valueChanges.subscribe(v => this.onInheritChange(v)),
    ];
  }

  ngOnInit() {
    this.setFormValues({ path: this.structure.path });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['roles'] && this.roles) {
      this.setFormValues({
        role: this.roles && this.roles.length > 0 ? this.roles[0].id : null,
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(x => x.unsubscribe());
  }

  onInheritChange(value: boolean) {
    this.canModifyPermissions = !value;
    if (!this.permissionInfo || value === this.permissionInfo.inherited) {
      return;
    }
    value
      ? this.getPermissions(this.permissionService.getInheritedForRole, true)
      : this.getPermissions(this.permissionService.getForRole, true);
  }

  onRoleChange() {
    this.getPermissions(this.permissionService.getForRole);
  }

  getPermissions(getFunc: (structureId: number, roleId: string) => Observable<PermissionInfo>, customInheritance: boolean = false) {
    this.loadingPermissions = true;
    const roleId = this.structureManagePermissionsForm.get('role').value;
    getFunc(this.structure.id, roleId)
      .pipe(
        finalize(() => {
          this.loadingPermissions = false;
        })
      )
      .subscribe((permissionInfo: PermissionInfo) => {
        this.setPermissionInfo(permissionInfo, customInheritance);
      });
  }

  setPermissionInfo(permissionInfo: PermissionInfo, customInheritance: boolean = false) {
    this.permissionInfo = customInheritance
      ? { ...permissionInfo, inherited: this.structureManagePermissionsForm.get('inherit').value }
      : permissionInfo;

    this.setFormValues(
      {
        inherit: this.permissionInfo.inherited,
        permissions: {
          write: this.permissionInfo.permissions.write,
          read: this.permissionInfo.permissions.read,
          create: this.permissionInfo.permissions.create,
          share: this.permissionInfo.permissions.share,
          manage: this.permissionInfo.permissions.manage,
        },
      },
      !customInheritance
    );
  }

  setFormValues(value: { [key: string]: any }, setPristine: boolean = false) {
    this.structureManagePermissionsForm.patchValue(value);
    if (setPristine) {
      FormHelpers.markAsPristine(this.structureManagePermissionsForm);
    }
  }

  getForm(): FormGroup {
    return new FormGroup({
      path: new FormControl({ value: '', disabled: true }, Validators.required),
      role: new FormControl(null, Validators.required),
      inherit: new FormControl({ value: false, disabled: false }),
      permissions: new FormGroup({
        write: new FormControl(false),
        read: new FormControl(false),
        create: new FormControl(false),
        share: new FormControl(false),
        manage: new FormControl(false),
      }),
    });
  }

  getModel(): PermissionEdit {
    return {
      resourceStructureId: this.structure.id,
      roleId: this.structureManagePermissionsForm.get('role').value,
      inherit: this.structureManagePermissionsForm.get('inherit').value,
      permissions: {
        write: this.structureManagePermissionsForm.get('permissions').value['write'],
        read: this.structureManagePermissionsForm.get('permissions').value['read'],
        create: this.structureManagePermissionsForm.get('permissions').value['create'],
        share: this.structureManagePermissionsForm.get('permissions').value['share'],
        manage: this.structureManagePermissionsForm.get('permissions').value['manage'],
      },
    };
  }

  isValid(): boolean {
    return this.structureManagePermissionsForm.valid && !this.loadingPermissions;
  }

  isDirty(): boolean {
    return this.structureManagePermissionsForm.dirty;
  }
}
