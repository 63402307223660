import { Component } from '@angular/core';
import { LegalEntity } from '@hrz/core/models/legal-entity';
import { Fittingstation } from '@hrz/core/models/fittingstation';
import { DossierVehicle } from '@hrz/core/models/dossier-vehicle';
import { Memo } from '@hrz/core/models/memo';
import { VehiclePresence } from '@hrz/core/models/vehicle-presence';
import { InsuranceInfo } from '@hrz/core/models/insurance-info';
import { Damage } from '@hrz/core/models/damage';
import { Job } from '@hrz/core/models/job';
import { Document } from '@hrz/core/models/document';
import { Authorization } from '@hrz/core/models/authorization';
import { DossierStatus } from '@hrz/core/models/dossier-status';
import { DossierAction } from '@hrz/core/models/dossier-action';
import { ValidationProtocol } from '@hrz/core/models/validation-protocol';
import { Instruction } from '@hrz/core/models/dossier/instruction';
import { DossierStatusActivity } from '@hrz/core/models/dossier/dossier-status-activity';
import { DossierTaxInvoice } from '@hrz/core/models/dossier/dossier-taxinvoice';
import { DossierJobInstruction } from '@hrz/core/models/dossier/jobinstruction/dossier-job-instruction';
import { DossierTypeEnum } from '@hrz/core/models/enums/dossier-type.enum';
import { Quote } from '@hrz/core/models/quote';
import { DossierFieldsLock } from '@hrz/core/models/dossierfieldslock';
import { Account } from '@hrz/core/models/account';

export class Dossier {
  Id: number;
  DossierStatus: DossierStatus;
  DossierSource: string;
  CreationTime: Date;
  CreatedBy: string;
  LegalEntity: LegalEntity;
  Fittingstation: Fittingstation;
  DossierVehicle: DossierVehicle;
  Documents: Document[];
  Memos: Memo[];
  VehiclePresences: VehiclePresence[];
  InsuranceInfo: InsuranceInfo;
  Damages: Damage[];
  Jobs: Job[];
  DossierAuthorization: Authorization;
  IsReadOnly: boolean;
  Instruction: Instruction;
  DossierStatusActivities: DossierStatusActivity[];
  AveragePriceRemovalStatisticsIncluded: boolean;
  ReasonForCheck: string;
  HasBeenRemovedFromAverage: string;
  ExternalReference: string;

  // Not defined in DTO
  FittingStationId: number;
  DossierActions: DossierAction[];
  ActionRemark: string;
  ActionRemarkType: string;
  ValidationProtocols: ValidationProtocol[];
  DossierFieldsLocks: DossierFieldsLock[];
  TaxInvoices: DossierTaxInvoice[];
  // Communication: DossierCommunication[];

  triggerRegisterDataSource = false;

  JobInstruction: DossierJobInstruction;
  JobPerformanceCheckinFinished: Date;
  DossierTypeEnum: DossierTypeEnum;
  Quotes: Quote[];

  // 3504
  ShowApp: Boolean;
  LeadAppointmentStart: string;
  LeadAppointmentEnd: string;

  // 4907
  JobPerformanceJobStarted: Date;
  JobPerformanceJobFinished: Date;
  JobPerformanceCheckoutFinished: Date;

  // GDES-26
  ChargeFee: boolean;
  FittingStationPayer: Fittingstation;
  FeeUpdateDate: Date;
  FittingStationToCredit: Fittingstation;
  IsFittingStationBeingCredited: boolean;
  ToBeCreditedDate: Date;

  // GDES-68
  IsManualDossier: boolean;
  NgcAccount: Account;
}

export class ChangedDossier {
  Dossier: Dossier;
  From: Component;
  Action: string;
  ForObject: any;

  get FromComponentName(): string {
    if (!this.From) { return ''; }
    return this.From.constructor.name;
  }
}
