import { AfterContentInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DossierFilter } from '@hrz/core/models/dossier/dossier-filter-model';
import { Enum } from '@hrz/core/models/enum';
import { Fittingstation } from '@hrz/core/models/fittingstation';
import { AuthService } from '@hrz/core/services/auth.service';
import { DossierService } from '@hrz/core/services/dossier.service';
import { EnumService } from '@hrz/core/services/enum.service';
import { Permission as Perm } from '@hrz/core/models/enums/permission.enum';
import { JobType } from '@hrz/core/models/enums/job-type.enum';

@Component({
  selector: 'app-dossier-search-component',
  templateUrl: './dossier-search-component.component.html',
  styleUrls: ['./dossier-search-component.component.scss'],
})
export class DossierSearchComponent implements OnInit, OnDestroy, AfterContentInit {
  accounts: string[];
  subStates: Enum[];
  appointmentStates: Enum[];
  fittingstationsEnum: Fittingstation[];
  costRegionGroups: Enum[];
  jobTypes: Enum[];
  accountNameConfig: any;
  subStateConfig: any;
  fsConfig: any;
  jobTypeConfig: any;

  dossierFilter: DossierFilter;
  selectedAccounts: any;
  selectedSubStates: any;
  selectedAppointmentStates: string = null;
  selectedJobTypes: Enum[];
  selectedCostRegions: string = null;
  selectedFittingstationsEnum: any;

  cacheFilter: DossierFilter = null;

  @Input() useMultiSelect = false;
  @Input() cacheKey = '';

  @Input() showDate = false;
  @Input() showAccounts = false;
  @Input() showSubStates = false;
  @Input() showFS = false;
  @Input() showAppointmenStates = false;
  @Input() showEA = false;
  @Input() showADAS = false;
  @Input() showOEM = false;
  @Input() showJobTypes = false;
  @Input() showCostRegions = false;
  @Input() showDossierTypes = false;

  @Output() public onFilterChange = new EventEmitter();
  useIntensiveSearch = true;
  private config: any;
  perm = Perm;

  constructor(
    private dossierService: DossierService,
    private translateService: TranslateService,
    private enumService: EnumService,
    private auth: AuthService
  ) {
    this.setAllConfigs();
    this.loadItems();
  }

  ngOnInit() {
    this.dossierFilter = new DossierFilter();
    
    let intensiveSearch = this.getDossierFilter();
    this.useIntensiveSearch = intensiveSearch
     ? intensiveSearch.intensiveSearch : true;
     this.dossierFilter.intensiveSearch = this.useIntensiveSearch;
  }

  ngAfterContentInit(): void {
    this.setCacheFilter();
    this.setAllConfigs();
  }

  ngOnDestroy(): void {}

  filterList() {
    this.onFilterChange.emit(this.dossierFilter);
  }

  resetFilter() {
    this.dossierFilter = new DossierFilter();
    this.selectedAccounts = null;
    this.selectedSubStates = null;
    this.selectedAppointmentStates = null;
    this.selectedFittingstationsEnum = null;
    this.selectedJobTypes = null;
    this.dossierFilter.intensiveSearch = this.useIntensiveSearch;
    this.onFilterChange.emit(this.dossierFilter);
  }
  changeAccountValue($event: any) {
    if (!this.dossierFilter.account) {
      this.dossierFilter.account = [];
    }
    if (this.useMultiSelect) {
      this.dossierFilter.account = $event.value.map(s => s);
    } else {
      if (this.dossierFilter.account.includes($event.value)) {
        this.dossierFilter.account = [];
        this.selectedAccounts = null;
      } else {
        this.dossierFilter.account = [];
        this.dossierFilter.account.push($event.value);
      }
    }
    this.fixFilterDisplayOrder();
    this.onFilterChange.emit(this.dossierFilter);
  }
  changeSubStateValue($event: any) {
    if (!this.dossierFilter.subState) {
      this.dossierFilter.subState = [];
    }
    if (this.useMultiSelect) {
      this.dossierFilter.subState = $event.value.map(s => s.Key);
    } else {
      if (this.dossierFilter.subState.includes($event.value.Key)) {
        this.dossierFilter.subState = [];
        this.selectedSubStates = null;
      } else {
        this.dossierFilter.subState = [];
        this.dossierFilter.subState.push($event.value.Key);
      }
    }
    this.fixFilterDisplayOrder();
    this.onFilterChange.emit(this.dossierFilter);
  }
  changeFSValue($event: any) {
    if (!this.dossierFilter.fittingstation) {
      this.dossierFilter.fittingstation = [];
    }
    if (this.useMultiSelect) {
      this.dossierFilter.fittingstation = $event.value.map(s => s.Id);
    } else {
      if (this.dossierFilter.fittingstation.includes($event.value.Id)) {
        this.dossierFilter.fittingstation = [];
        this.selectedFittingstationsEnum = null;
      } else {
        this.dossierFilter.fittingstation = [];
        this.dossierFilter.fittingstation.push($event.value.Id);
      }
    }
    this.fixFilterDisplayOrder();
    this.onFilterChange.emit(this.dossierFilter);
  }
  changeJobTypeValue($event: any) {
    if (!this.dossierFilter.jobType) {
      this.dossierFilter.jobType = [];
    }
    if (this.useMultiSelect) {
      this.dossierFilter.jobType = $event.value.map(s => s.Key);
    } else {
      if (this.dossierFilter.jobType.includes($event.value.Key)) {
        this.dossierFilter.jobType = [];
        this.selectedJobTypes = null;
      } else {
        this.dossierFilter.jobType = [];
        this.dossierFilter.jobType.push($event.value.Key);
      }
    }
    this.fixFilterDisplayOrder();
    this.onFilterChange.emit(this.dossierFilter);
  }

  changeAppointmentStatesValue() {
    this.dossierFilter.appointmentState = [];
    this.dossierFilter.appointmentState.push(this.selectedAppointmentStates);
    this.onFilterChange.emit(this.dossierFilter);
  }

  changeFilter() {
    this.onFilterChange.emit(this.dossierFilter);
  }
  changeIntensiveSearchUsage($event: any) {
    if ($event === 'undefined' || $event === 'null' || $event === undefined) {
      return;
    }
    this.dossierFilter.intensiveSearch = $event;
    this.resetFilter();
  }

  catchEnterKey(event: any): void {
    if (event.keyCode == 13) {
      this.changeFilter();
    }
  }

  setCacheFilter() {
    this.selectedAccounts = [];
    this.selectedSubStates = [];
    this.selectedAppointmentStates = null;
    this.selectedFittingstationsEnum = [];
    this.cacheFilter = this.getDossierFilters();
    console.log('cacheFilter', this.cacheFilter);
    if (this.cacheFilter) {
      this.dossierFilter = this.cacheFilter;
       this.selectedAccounts = Array.from(new Set(this.dossierFilter.account));
      if (this.dossierFilter.fittingstation && this.fittingstationsEnum)
      {
        this.dossierFilter.fittingstation.forEach(cachedFs => {
          let fittingstationLabel = this.fittingstationsEnum.find(fsEnum=> fsEnum.Id === cachedFs);
          this.selectedFittingstationsEnum.push(fittingstationLabel); 
        });
      }
    }
  }
  setAccountList(accountsList: string[]) {
    this.accounts = null;
    this.accounts = accountsList.map(x => x);
  }
  private getDossierFilters(): DossierFilter {
    return this.cacheRead('dossierFilter');
  }

  private cacheRead(key: string): any {
    console.log('cacheKey', this.cacheKey);
    const storageKey = this.cacheKey + key;
    const foundValue = localStorage.getItem(storageKey);
    console.log('DossierOverviewPage.cacheRead() -> key, value:', storageKey, foundValue);

    if (foundValue && foundValue.length > 0) {
      if (this.isJsonString(foundValue)) {
        return JSON.parse(foundValue);
      }
      return foundValue;
    }
    if (key === 'dateRange') {
      return 'LastThreeMonths';
    }
    return '';
  }
  private isJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  private setAllConfigs() {
    this.accountNameConfig = this.setConfig('DOSSIER.OVERVIEW.OPTIONS.ACCOUNT.SELECT', 'Value', 'Key');
    this.subStateConfig = this.setConfig('DOSSIER.OVERVIEW.OPTIONS.SUB_STATE.SELECT', 'Value', 'Value');
    this.fsConfig = this.setConfig('DOSSIER.OVERVIEW.OPTIONS.FITTINGSTATION.SELECT', 'Name', 'Name');
    this.jobTypeConfig = this.setConfig('AVERAGE_PRICE_STATISTICS.SELECT.JOB_TYPE', 'Value', 'Value');
  }

  fixFilterDisplayOrder() {
    function fixNgxDropdown(source: any): any {
      if (source && source.length > 0) {
        return [...source];
      }
      return source;
    }

    this.accounts = fixNgxDropdown(this.accounts);
    this.fittingstationsEnum = fixNgxDropdown(this.fittingstationsEnum);
    this.subStates = fixNgxDropdown(this.subStates);
    this.selectedJobTypes = fixNgxDropdown(this.selectedJobTypes);
  }

  private loadItems(): void {
    this.loadEnums();
    this.loadAccounts();
    this.loadFittingStations();
  }
  private loadEnums() {
    let enumRequests: Promise<[Enum[], Enum[], Enum[], Enum[]]>;
    this.auth.getTenantId().subscribe(tenantId => {
      enumRequests = Promise.all([
        this.enumService.getDossierSubstates(),
        this.enumService.getAppointmentStates(),
        this.enumService.getAverageCosts(tenantId),
        this.enumService.getJobTypes(),
      ]);
    });

    enumRequests.then(([substates, appointmentStates, costRegions, jobTypes]) => {
      this.subStates = substates.filter(x => !!x.Value).sort((a, b) => a.Value.toLocaleLowerCase().localeCompare(b.Value));
      this.appointmentStates = appointmentStates;
      this.jobTypes = jobTypes.filter(j => [JobType.Repair, JobType.Replace, JobType.Service].includes(j.Key));
      this.costRegionGroups = costRegions;
      if (this.cacheFilter) {
        this.subStates
          .filter(x => this.cacheFilter.subState.indexOf(x.Key) >= 0)
          .forEach(x => {
            if (!this.useMultiSelect) {
              this.selectedSubStates = x;
            } else {
              this.selectedSubStates.push(x);
            }
          });
        if (this.cacheFilter.appointmentState.length > 0) {
          this.selectedAppointmentStates = this.cacheFilter.appointmentState[0];
        }
      }
    });
  }

  private loadAccounts() {
    this.dossierService
      .loadAllAccounts()
      .then(results => {
        if (results && results.length > 0 && !this.accounts) {
          this.accounts = results.sort((a, b) => a.Name.localeCompare(b.Name)).map(account => account.Name);
          if (this.cacheFilter) {
            this.cacheFilter.account
              .filter(x => x !== '')
              .forEach(x => {
                if (!this.useMultiSelect) {
                  this.selectedAccounts = x;
                } else if(!this.selectedAccounts.includes(x)) {
                     this.selectedAccounts.push(x);
                }
              });
          }
        }
      })
      .catch(() => {});
  }

  private loadFittingStations() {
    this.dossierService
      .loadFittingStationsForUser()
      .then(fittingstations => {
        if (fittingstations && fittingstations.length > 0) {
          this.fittingstationsEnum = fittingstations.sort((a, b) => a.Name.localeCompare(b.Name)).map(fs => fs);
          if (this.cacheFilter) {
            this.fittingstationsEnum
              .filter(x => this.cacheFilter.fittingstation.indexOf(x.Id) >= 0)
              .forEach(x => {
                if (!this.useMultiSelect) {
                  this.selectedFittingstationsEnum = x;
                } else {
                  this.selectedFittingstationsEnum.push(x);
                }
              });
          }
        }
      })
      .catch(() => {});
  }

  private setConfig(placeholderkey: any, displaykey: string, searchOnKey: string) {
    this.config = {
      displayKey: displaykey,
      search: true,
      height: '250px',
      placeholder: this.translateService.instant(placeholderkey),
      customComparator: () => {},
      limitTo: Number.MAX_VALUE,
      clearOnSelection: true,
      moreText: this.translateService.instant('COMMON.MORE'),
      noResultsFound: this.translateService.instant('COMMON.NO_RESULT_FOUND'),
      searchPlaceholder: this.translateService.instant('COMMON.SEARCH'),
      searchOnKey: searchOnKey,
    };
    return this.config;
  }

  private getDossierFilter(): any {
    let foundValue = localStorage
        .getItem('localstorage.dossier.overview.dossierFilter');

    return JSON.parse(foundValue);
  }  
}
