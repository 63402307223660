import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'genericDataFilter',
})
export class GenericDataFilterPipe implements PipeTransform {
  transform(array: any[] = [], field: string = '', query: string = ''): any {
    if (!array) {
      return [];
    }
    return array.filter(x => (x[field] != null ? x[field].toString().match(new RegExp(query, 'i')) : false));
  }
}
