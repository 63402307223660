import { catchError } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { PermissionInfo } from '@hrz/core/models/document/permission-info';
import { PermissionEdit } from '@hrz/core/models/document/permission-edit';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private apiUrl = ConfigurationManager.AppSettings.documentManagementApi + '/permission';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: PermissionService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('PermissionService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getForRole = (structureId: number, roleId: string): Observable<PermissionInfo> => {
    return this.http.get<PermissionInfo>(`${this.apiUrl}/${structureId}/${roleId}`, { headers: this.httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public getInheritedForRole = (structureId: number, roleId: string): Observable<PermissionInfo> => {
    return this.http.get<PermissionInfo>(`${this.apiUrl}/${structureId}/${roleId}/inherited`, { headers: this.httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public update = (permissionEdit: PermissionEdit): Observable<PermissionInfo> => {
    return this.http
      .put<PermissionInfo>(`${this.apiUrl}/${permissionEdit.resourceStructureId}/${permissionEdit.roleId}`, permissionEdit, {
        headers: this.httpHeaders,
      })
      .pipe(
        catchError(this.serviceActionHandler.handleDefaultError)
      );
  }
}
