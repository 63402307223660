import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { JobInvoice } from '@hrz/core/models/job-invoice';
import { DynamicModal } from '../../../../../shared/dynamic-modal.module';
import { Job } from '@hrz/core/models/job';
import { Enum } from '@hrz/core/models/enum';
import { EnumService } from '@hrz/core/services/enum.service';
import { AuthService } from '@hrz/core/services/auth.service';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { SpinnerHelper } from '@hrz/core/utils/spinner-helper';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { ActionTypeKeys } from '@hrz/core/models/action-type-keys';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { JobInvoiceService } from '../../../services/job-invoice.service';
import { DossierService } from '@hrz/core/services/dossier.service';

@Component({
  selector: 'request-credit-modal',
  templateUrl: './request-credit-modal.component.html',
})
@DynamicModal()
export class RequestCreditModal implements OnInit, AfterViewInit, OnDestroy {
  public creditReasons: Enum[];
  public job: Job;
  public onSuccess: () => void;
  protected requestCreditForm: FormGroup;
  private closeModal: Function;
  private spinnerHelper: SpinnerHelper;
  public currentJobInvoice: JobInvoice;

  public bankAccountIsRequired = new BehaviorSubject<boolean>(false);
  private enableBankAccountCodes: string[];

  @ViewChild('myModal')
  private childModal: ModalDirective;

  constructor(
    private toasterService: ToasterService,
    private dossierService: DossierService,
    private enumService: EnumService,
    private formBuilder: FormBuilder,
    private jobInvoiceService: JobInvoiceService,
    spinnerService: SpinnerService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {
    this.requestCreditForm = this.formBuilder.group({
      creditReason: [null, Validators.required],
      additionalNotes: [null, null],
    });

    this.spinnerHelper = new SpinnerHelper(spinnerService, () => 'requestCreditNoteNgcSpinner');
  }

  async ngOnInit(): Promise<void> {
    console.log('requestCreditModal.ngOnInit');

    this.requestCreditForm.get('creditReason').valueChanges.subscribe(creditReason => {
      if (this.isBankaccountNumberNeeded(creditReason)) {
        this.requestCreditForm.addControl('bankAccountNumber', new FormControl(null, [Validators.required]));
      } else {
        this.requestCreditForm.removeControl('bankAccountNumber');
      }
    });

    this.currentJobInvoice = this.job.JobInvoices[0];
    if (this.job.Dossier.DossierVehicle !== null) {
      const returningAccountIban = this.job.Dossier.DossierVehicle.Owner.Iban;
      if (this.job.Dossier.DossierVehicle.Owner.AccountType === 'Returning' && returningAccountIban && returningAccountIban.trim()) {
        this.currentJobInvoice.RequestCreditIban = returningAccountIban;
      }
    }

    this.authService.getTenantId().subscribe(tenantId => {
      this.dossierService.codeTranslation(tenantId).then(x => {
        if (x) {
          this.enableBankAccountCodes = x.split(';');
        }
      });
    });

    this.requestCreditForm.get('creditReason').valueChanges.subscribe(
      newValue =>{
        this.currentJobInvoice = newValue;
        this.currentJobInvoice.RequestCreditReason = newValue;
      });

    this.requestCreditForm.get('bankAccountNumber').valueChanges.subscribe(
      newValue =>{
        this.currentJobInvoice.RequestCreditIban = newValue;
      });

    this.requestCreditForm.get('additionalNotes').valueChanges.subscribe(
      newValue =>{
        this.currentJobInvoice.RequestCreditAdditionalNotes = newValue;
      });
  }

  async ngAfterViewInit(): Promise<void> {
    console.log('requestCreditModal.ngAfterViewInit');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: false,
      ignoreBackdropClick: true,
    };
    setTimeout(() => this.loadCreditReasons()); // setTimeout prevents ExpressionChangedAfterItHasBeenCheckedError
  }

  private async loadCreditReasons(): Promise<void> {
    await this.spinnerHelper.withSpinnerAsync(async () => {
      try {
        this.creditReasons = await this.enumService.getRequestCreditReasons();
        console.log('RequestCreditModal.creditReasons', this.creditReasons);
      } catch (err) {
        const errorMessage = this.translateService.instant('DOSSIER.INVOICE_FS.FAILED_LOADING_CREDIT_REASONS');
        this.toasterService.showError(errorMessage);
      }
    });
  }

  ngOnDestroy(): void {
    console.log('requestCreditModal.ngOnDestroy');
  }

  resetAndClose() {
    this.closeModal();
  }

  public async requestCredit(): Promise<void> {
    console.log('RequestCreditModal.requestCredit', this.job);

    if (!this.job.JobInvoices || this.job.JobInvoices.length !== 1) {
      throw new Error('Requesting credit notes is currently only supported for jobs with 1 invoice.');
    }
    const jobInvoiceId = this.job.JobInvoices[0].Id;

    await this.spinnerHelper.withSpinnerAsync(async () => {
      let apiActionResult = await this.jobInvoiceService.patch(this.job.Dossier.Id, this.job.Id, jobInvoiceId, {
        RequestCreditAdditionalNotes: this.currentJobInvoice.RequestCreditAdditionalNotes,
        RequestCreditIban: this.currentJobInvoice.RequestCreditIban,
        RequestCreditReason: this.currentJobInvoice.RequestCreditReason,
      });
      if (apiActionResult.commandSucceeded) {
        apiActionResult = await this.dossierService.processAction(this.job.Dossier, ActionTypeKeys.request_credit);
      }
      this.handleApiActionResult(apiActionResult);
    });
  }

  private handleApiActionResult(apiActionResult: ApiActionResult<any>) {
    if (apiActionResult.commandSucceeded) {
      const successMessage = this.translateService.instant('DOSSIER.INVOICE_FS.CREDIT_APPROVAL_REQUESTED');
      this.toasterService.showSuccess(successMessage);
      if (!!this.onSuccess) {
        this.onSuccess();
      }
      this.resetAndClose();
    } else {
      console.error('RequestCreditModal.requestCredit failed', apiActionResult);
      const errorMessage = this.translateService.instant('DOSSIER.INVOICE_FS.CREDIT_APPROVAL_REQUEST_FAILED');
      this.toasterService.showError(errorMessage);
    }
  }

  isValid(): boolean {
    return this.requestCreditForm.valid && this.currentJobInvoice.RequestCreditReason !== 'null';
  }

  isBankaccountNumberNeeded(reason: string): boolean {
    if (this.enableBankAccountCodes) {
      this.bankAccountIsRequired.next(this.enableBankAccountCodes.includes(reason));
      return this.enableBankAccountCodes.includes(reason);
    }
    return false;
  }
}
