import { AuthService } from '@hrz/core/services/auth.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Permission } from '@hrz/core/models/enums/permission.enum';

import { NotificationEventService } from '@hrz/core/services/notification-event.service';
import { CountryEnum } from '@hrz/core/enums/country.enum';
import { ConfigService } from '@hrz/core/services/config.service';

const hideSidebarKey = 'HideSidebar';
@Component({
  selector: 'hrz-dashboard',
  templateUrl: './full-layout.component.html',
})
export class FullLayoutComponent implements OnInit, AfterViewInit {

  private _authorityChecked = false;
  private _userIsWebAuthority: Boolean = false;

  public disabled = false;
  public status: { isopen: boolean } = { isopen: false };
  public isLinkedToPortugal = false;
  perm = Permission;
  hideSidebar = false;
  public newEventsCount = 0;
  public eventsReadSubscription: any;
  public tenantUsesDiscountApprovalRequest: boolean = undefined;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationEventService,
    private readonly configService: ConfigService
  ) {
    console.log('FullLayoutComponent.constructor()');
  }
  ngAfterViewInit(): void {
    // Hide sidebar when cache is found
    if (this.cacheRead(hideSidebarKey) === 'true') {
      document.querySelector('body').classList.add('sidebar-compact');
      this.hideSidebar = true;
      localStorage.removeItem(hideSidebarKey);
    }
  }

  ngOnInit(): void {
    console.log('FullLayoutComponent.ngOnInit()');
    this.authService.getTenantId().subscribe(tenantId => {
      if (tenantId === CountryEnum.Portugal) {
        this.isLinkedToPortugal = true;
      }

      this.configService.get().then(config => {
        this.tenantUsesDiscountApprovalRequest =
          !!config.TenantsUsingDiscountApprovalRequest && config.TenantsUsingDiscountApprovalRequest.includes(tenantId);
      });
    });

    this.notificationService.getNewEvents().then(count => {
      this.newEventsCount = count;
    });

    this.eventsReadSubscription = this.notificationService.onEventsRead.subscribe((_amountOfEvents: number) => {
      this.notificationService.getNewEvents().then(count => {
        this.newEventsCount = count;
      });
    });
  }

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  private cacheRead(key: string): any {
    const storageKey = key;
    const foundValue = localStorage.getItem(storageKey);
    console.log('FullLayoutComponent.cacheRead() -> key, value:', storageKey, foundValue);
    if (foundValue && foundValue.length > 0) {
      return foundValue;
    }
    return '';
  }

  public compactSidebarBtnStatus(): void {
    if (document.querySelector('body').classList.contains('sidebar-compact')) {
      document.querySelector('body').classList.add('btn-compact-pressed');
      document.querySelector('body').classList.remove('btn-expand-pressed');
    } else {
      document.querySelector('body').classList.remove('btn-compact-pressed');
      document.querySelector('body').classList.add('btn-expand-pressed');
    }

    window.dispatchEvent(new Event('resize'));
  }

  public compactSidebarDashboard(): void {
    console.log('Dashboard menu Clicked:');
    // document.querySelector('body').classList.add('sidebar-compact');
    //  window.dispatchEvent(new Event('resize'));
  }

  public compactSidebarForTablet(): void {
    if (window.innerWidth <= 768) {
      console.log('Close Sidebar');
      document.querySelector('body').classList.remove('sidebar-mobile-show');
      window.dispatchEvent(new Event('resize'));
    }
  }

  public userIsWebAuthority(): Boolean {
    if (!this._authorityChecked) {
      this._authorityChecked = true;
      this._userIsWebAuthority = this.authService.isWebAuthorityUser(null);
    }
    return this._userIsWebAuthority;
  }
}
