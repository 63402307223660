import { Injectable, Optional, SkipSelf } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
// Import our authentication service
import { AuthService } from '@hrz/core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: AuthGuard) {
    console.log('AuthGuard.Constructor()');

    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('AuthGuard is a Singleton and should only be loaded in AppModule.'));
    }
  }

  canActivate() {
    console.log('AuthGuard.canActivate()');
    // If the user is not logged in we'll send them back to the home page
    if (!this.auth.isAuthenticated()) {
      return true; // Erkan Ceyhan, we need to find a better approach....
    }
    return true;
  }
}
