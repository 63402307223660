import { finalize } from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { StructureCreate } from '@hrz/core/models/document/structure-create';
import { StructureCreateComponent } from '../structure-create/structure-create.component';
import { DocumentStructureService } from '../../services/document-structure.service';
import { StructureDetail } from '@hrz/core/models/document/structure-detail';
import { BaseActionModal } from '../base/base-action.modal';

@Component({
  templateUrl: './structure-create.modal.html',
})
@DynamicModal()
export class StructureCreateModal extends BaseActionModal {
  public structureCreateModel: StructureCreate;
  public onStructureCreate: Function;
  public onFailure: Function;
  @ViewChild('structureCreateModal') childModal: ModalDirective;
  @ViewChild(StructureCreateComponent) structureCreateForm: StructureCreateComponent;

  constructor(private documentStructureService: DocumentStructureService) {
    super();
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    this.createStructure();
  }

  createStructure() {
    const structureCreateModel = this.structureCreateForm.getModel();
    this.loading = true;
    this.documentStructureService
      .create(structureCreateModel)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        (_structure: StructureDetail) => {
          this.onStructureCreate();
        },
        error => {
          this.onFailure(error);
        }
      );
  }
}
