import { Component, ViewChild, OnInit, AfterViewInit,  OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal} from '../../../../shared/dynamic-modal.module';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DossierTaxInvoice } from '@hrz/core/models/dossier/dossier-taxinvoice';

@Component({
  selector: 'reverse-tax-invoice-modal',
  providers: [TranslateService],
  templateUrl: './reverse-tax-invoice.modal.html',
})
@DynamicModal()
export class ReverseTaxInvoiceModal implements OnInit, AfterViewInit, OnDestroy {
  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;

  public onClose: Function;
  public onAddProduct: Function;

  protected formAddProdcut: FormGroup;
  public context: DossierTaxInvoice;
  public relinkDossierId: number;
  public relinkDossierDisabled = true;

  @ViewChild('myModal')
  private childModal: ModalDirective;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.formAddProdcut = this.formBuilder.group({ relinkDossierId: [null, Validators.required] });
  }

  ngOnInit(): void {
    console.log('requestCreditModal.ngOnInit');
  }

  ngAfterViewInit(): void {
    console.log('requestCreditModal.ngAfterViewInit');
    this.childModal.show();
    this.formAddProdcut.get('relinkDossierId').valueChanges.subscribe(value => 
      this.relinkDossierDisabled = !this.formAddProdcut.valid || !value || this.context.DossierId == value);
  }

  ngOnDestroy(): void {
    console.log('requestCreditModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.formAddProdcut.reset();
    this.closeModal();
    if (this.onClose) {
      this.onClose();
    }
  }

  clickReverse() {
    console.log('requestCreditModal.clickRequestCredit');
    this.onAddProduct(this.context, this.formAddProdcut.value.relinkDossierId);
    this.closeModal();
  }
}
