import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'hrz-breadcrumbs',
  template: `
  <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs" let-last="last">
    <li
      class="breadcrumb-item"
      *ngIf="(breadcrumb.label.title && breadcrumb.url.substring(breadcrumb.url.length - 1) == '/') || (breadcrumb.label.title && last)"
      [ngClass]="{ active: last }">
      <a *ngIf="!last" [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</a>
      <span *ngIf="last" [routerLink]="breadcrumb.url">{{ breadcrumb.title }}</span>
    </li>
  </ng-template>`,
})
export class BreadcrumbsComponent implements OnInit {
  breadcrumbs: Array<Object>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(_ => {
      this.breadcrumbs = [];
      let currentRoute = this.route.root,
        url = '';
      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        childrenRoutes.forEach(childRoute => {
          if (route.outlet === 'primary') {
            const routeSnapshot = childRoute.snapshot;
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
            if (childRoute.snapshot.data.breadCrumClearUrlValue) {
              url = url.replace(childRoute.snapshot.data.breadCrumClearUrlValue, '');
            }
            const newBreadCrumb = {
              label: childRoute.snapshot.data,
              url: url,
              title: this.translateService.instant(childRoute.snapshot.data.title),
            };
            const idParameter = childRoute.snapshot.data.idParam;

            // GDES-68
            const manualParam = childRoute.snapshot.data.manualParam;

            // GDES-68
            if (idParameter && childRoute.snapshot.params[idParameter] && childRoute.snapshot.params[manualParam]) {
              if (childRoute.snapshot.params[manualParam] === 'true') {
                newBreadCrumb.title += ' (' + childRoute.snapshot.params[idParameter] + ') --- Manual Dossier';
              }
            } else if (idParameter && childRoute.snapshot.params[idParameter]) {
              newBreadCrumb.title += ' (' + childRoute.snapshot.params[idParameter] + ')';
            }

            this.breadcrumbs.push(newBreadCrumb);
            currentRoute = childRoute;
          }
        });
      } while (currentRoute);
    });
  }

  ngOnInit(): void {
    if (this.breadcrumbs === undefined) {
      this.breadcrumbs = [];
      let currentRoute = this.route.root,
        url = '';
      do {
        const childrenRoutes = currentRoute.children;
        currentRoute = null;
        childrenRoutes.forEach(childRoute => {
          if (childRoute.outlet === 'primary') {
            const routeSnapshot = childRoute.snapshot;
            url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
            if (childRoute.snapshot.data.breadCrumClearUrlValue) {
              url = url.replace(childRoute.snapshot.data.breadCrumClearUrlValue, '');
            }
            const newBreadCrumb = {
              label: childRoute.snapshot.data,
              url: url,
              title: this.translateService.instant(childRoute.snapshot.data.title),
            };
            const idParameter = childRoute.snapshot.data.idParam;

            if (idParameter && childRoute.snapshot.params[idParameter]) {
              newBreadCrumb.title += ' (' + childRoute.snapshot.params[idParameter] + ')';
            }
            this.breadcrumbs.push(newBreadCrumb);
            currentRoute = childRoute;
          }
        });
      } while (currentRoute);
    }
  }
}
