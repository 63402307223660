import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'momentDate',
})
export class MomentDatePipe implements PipeTransform {
  transform(value: any, format: string = '', lowercase = false): string {
    const momentDate = moment(value);
    if (!momentDate.isValid()) {
      return value;
    }
    return lowercase ? momentDate.format(format).toLowerCase() : momentDate.format(format);
  }
}

@Pipe({
  name: 'momentDateLocal',
})
export class MomentDateLocalPipe implements PipeTransform {
  transform(value: any, format: string = '', lowercase = false): string {
    const momentDate = moment.utc(value).local();
    if (!momentDate.isValid()) {
      return value;
    }
    return lowercase ? momentDate.format(format).toLowerCase() : momentDate.format(format);
  }
}
