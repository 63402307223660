import { catchError, map } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { FileMove } from '@hrz/core/models/document/file-move';
import { File } from '@hrz/core/models/document/file';
import { Document } from '@hrz/core/models/document';
import { DocumentProcedure } from '@hrz/core/models/documentProcedure';
import { FileDelete } from '@hrz/core/models/document/file-delete';
import { FileUpload } from '@hrz/core/models/document/file-upload';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { FittingstationAdas } from '@hrz/core/models/fittingstation-adas';
import { TranslateService } from '@ngx-translate/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class FileService {

  private apiUrl = ConfigurationManager.AppSettings.documentManagementApi + '/file';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    private translateService: TranslateService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: FileService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('FileService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public download(ids: number[]): Observable<FileDownload> {
    console.log('FileService.download: ', ids);
    let params: HttpParams = new HttpParams();
    ids.forEach(id => {
      params = params.append('id', id.toString());
    });
    return this.http.get(`${this.apiUrl}/content`, { params: params, observe: 'response', responseType: 'blob' }).pipe(
      map((response: HttpResponse<Blob>): FileDownload => {
        return {
          blob: new Blob([response.body]),
          name: decodeURIComponent(response.headers.get('x-filename')),
          LinkedDocumentId: 0,
        };
      }),
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public downloadWithFileName(ids: number[], filename: string): Observable<FileDownload> {
    console.log('FileService.download: ', ids);
    let params: HttpParams = new HttpParams();
    ids.forEach(id => {
      params = params.append('id', id.toString());
    });
    return this.http.get(`${this.apiUrl}/content`, { params: params, observe: 'response', responseType: 'blob' }).pipe(
      map((response: HttpResponse<Blob>): FileDownload => {
        return { blob: new Blob([response.body]), name: filename, LinkedDocumentId: 0 };
      }),
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public downloadDossierDocument(document: Document, size: string = ''): Observable<FileDownload> {
    let params: HttpParams = new HttpParams()
        .set('id', document.FileMetaData.Reference);
    if (size !== '') {
       params = params.set('sizeType', size);
    }

    return this.http.get(`${this.apiUrl}/content`, { params: params, observe: 'response', responseType: 'blob' }).pipe(
      map((response) => {
        const originalFileName = decodeURIComponent(response.headers.get('x-filename'));
        const blobFile = new Blob([response.body]);
        return { blob: blobFile, name: this.translateFileName(originalFileName, document), LinkedDocumentId: document.Id };
      }),
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public translateFileName(originalFileName: string, document: Document): string {
    try {
      const translationKey = `DOCUMENT_MANAGEMENT.FILE_NAME.${document.DocumentType.toLocaleUpperCase()}`;
      const translatedName = this.translateService.instant(translationKey);

      if (translatedName && translatedName !== translationKey) {
        const extension = originalFileName.substring(originalFileName.lastIndexOf('.') + 1);

        let fileName: string = translatedName;
        if (document.Dossier) { fileName += `_${document.Dossier.Id}`; }
        if (document.IsSigned) { fileName += `_${this.translateService.instant(`DOCUMENT_MANAGEMENT.FILE_NAME.SIGNED`)}`; }
        if (document.Id && document.Id > 0) { fileName += `_(${document.Id})`; }

        return `${fileName}.${extension}`;
      }
    } catch (error) {
      console.log(error);
    }
    return originalFileName;
  }

  public downloadSingle(id: number, documentId: number = 0, size: string = ''): Promise<FileDownload> {
    console.log('FileService.downloadSingle: ', id, 'LinkedDocumentId: ' + documentId, 'Size: ' + size);
    return this.http
      .get(this.apiUrl + '/content?id=' + id + '&sizeType=' + size, { observe: 'response', responseType: 'blob' })
      .toPromise()
      .then((response: HttpResponse<Blob>) => {
        return {
          blob: new Blob([response.body]),
          name: decodeURIComponent(response.headers.get('x-filename')),
          LinkedDocumentId: documentId,
        };
      })
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public uploadDocument(document: Document, location?: string): Observable<File> {
    if (!location) {
      console.warn(`fileService.uploadDocument() => Setting the location to PT\\Dossier\\${document.Dossier.Id}`);
      location = 'PT\\Dossier\\' + document.Dossier.Id;
    }
    const command = {
      Content: document.FileContent,
      Filename: document.FileMetaData.FileName,
      Location: location,
      Metadata: {},
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http.post<File>(this.apiUrl + '/create-by-path', command).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  // GDES72-4320
  public uploadDocumentProcedure(document: DocumentProcedure, location?: string): Observable<File> {
    if (!location) {
      console.warn(`fileService.uploadDocument() => Setting the location to PT\\Dossier\\${document.ProcedureId}`);
      location = 'PT\\Dossier\\' + document.ProcedureId;
    }

    const command = {
      Content: document.FileContent,
      Filename: document.DocumentMetaData.FileMetaData.FileMetaDataFileName,
      Location: location,
      Metadata: {},
    };

    console.log('POST - JSON Command: ', JSON.stringify(command));

    return this.http.post<File>(this.apiUrl + '/create-by-path', command).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public uploadDocumentForCodification(base64content: string, filename: string): Observable<File> {
    const location = `PT\\Codification_Requests`;
    const command = {
      Content: base64content,
      Filename: filename,
      Location: location,
      Metadata: {},
    };
    return this.http.post<File>(this.apiUrl + '/create-by-path', command).pipe(
      catchError(error => this.serviceActionHandler.handleDefaultObservableError(error))
    );
  }

  public uploadDocumentForSendToAccount(document: Document, account: String): Observable<File> {
    const location = `PT\\Send_To_Account\\${account}_uploaded`;
    console.warn(`fileService.uploadDocumentForSendToAccount() => Setting the location to ${location}`);
    const command = {
      Content: document.FileContent,
      Filename: document.FileMetaData.FileName,
      Location: location,
      Metadata: {},
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http.post<File>(this.apiUrl + '/create-by-path', command).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public uploadDocumentForFittingstationAdas(document: Document, fittingstationAdas: FittingstationAdas) {
    const location = `PT\\Fittingstation\\ADAS\\${fittingstationAdas.Id}_${fittingstationAdas.Serialnumber}`;
    console.warn(`fileService.uploadDocumentForFittingstationAdas() => Setting the location to ${location}`);
    const command = {
      Content: document.FileContent,
      Filename: document.FileMetaData.FileName,
      Location: location,
      Metadata: {},
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http.post<File>(this.apiUrl + '/create-by-path', command).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public upload(fileUpload: FileUpload): Observable<File> {
    const formData = new FormData();
    formData.append('data', JSON.stringify(fileUpload.data));
    formData.append('file', fileUpload.file);
    return this.http.post<File>(this.apiUrl, formData).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public move(fileMove: FileMove): Observable<File> {
    return this.http.put<File>(`${this.apiUrl}/move`, fileMove, { headers: this.httpHeaders }).pipe(
      catchError(this.serviceActionHandler.handleDefaultError)
    );
  }

  public delete(fileDelete: FileDelete): Observable<void> {
    return this.http
      .request('delete', this.apiUrl, {headers: this.httpHeaders, body: fileDelete})
      .pipe(catchError(error => this.serviceActionHandler.handleDefaultObservableError(error)));
  }
}
