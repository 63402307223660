import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'delete-confirmation-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered glassdrive-form   glassdrive-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ 'SHARED.MODALS.DELETE_CONFIRMATION' | translate }}</h4>
            <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ message }}</p>
          </div>
          <div class="modal-footer clearfix">
            <button type="button" class="btn btn-secondary-light btn-md pull-left" (click)="clickCancel()">
              {{ 'COMMON.CANCEL' | translate }}
            </button>
            <button type="button" class="btn btn-primary btn-md pull-left" (click)="clickOk()">
              {{ 'COMMON.YES_I_AM_SURE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class DeleteConfirmationModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  ngOnInit(): void {
    console.log('DeleteConfirmationModal.ngOnInit');
  }
  ngAfterViewInit(): void {
    console.log('DeleteConfirmationModal.ngAfterViewInit');
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('DeleteConfirmationModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
