import { Component, Input, Output, OnInit, EventEmitter, IterableDiffers } from '@angular/core';
import { OpeningHour } from '@hrz/core/models/opening-hour';
import { DayOfWeekEnum } from '@hrz/core/models/day-of-week.enum';

@Component({
  selector: 'fittingstation-opening-hour-list',
  template: `
    <span>[{{ timezone }}]</span>
    <div class="generic-list">
      <div class="generic-group" *ngFor="let item of items">
        <div class="groupkey">
          {{ item.key }}
        </div>
        <div class="groupvalue">
          <div
            *ngFor="let subItem of item.value; let i = index"
            (click)="selectItem(subItem, i)"
            [class.active]="subItem === selectedItem"
            class="generic-item">
            {{ subItem.TimeFrom }} - {{ subItem.TimeTo }}
          </div>
        </div>
        <hr />
        <div class="clear"></div>
      </div>
      <div *ngIf="!items || items.length === 0">
        {{ 'FITTINGSTATIONS.OPENING_HOURS.LIST.NO_OPENING_HOURS_CONFIGURED_YET' | translate }}
      </div>
    </div>
  `,
})
export class FittingstationOpeningHourList implements OnInit {
  private _items: OpeningHour[] = [];
  private _itemsFromOrigin: OpeningHour[];
  get items(): OpeningHour[] {
    return this._items;
  }

  @Input()
  set items(value: OpeningHour[]) {
    this._itemsFromOrigin = value;
    this._items = this.groupedItems(value);
  }
  @Input() timezone: string;
  @Input() selectedItem: OpeningHour;
  @Output() onSelectItem: EventEmitter<OpeningHour> = new EventEmitter<OpeningHour>();

  selectedRowIndex;
  private iterableDiffers: any;

  constructor(private _iterableDiffers: IterableDiffers) {
    this.iterableDiffers = this._iterableDiffers.find([]).create(null);
  }
  ngOnInit(): void {
    console.log('FittingstationOpeningHourList.ngOnInit()');
  }
  ngDoCheck() {
    const changes = this.iterableDiffers.diff(this._itemsFromOrigin);
    if (changes) {
      this.items = this._itemsFromOrigin;
    }
  }

  public groupedItems(notGrouped): any[] {
    if (!notGrouped) { return; }

    const currentItems = notGrouped;
    const groupedObj = currentItems.reduce((prev, cur) => {
      if (!prev[cur['DayOfWeek']]) {
        prev[cur['DayOfWeek']] = [cur];
      } else {
        prev[cur['DayOfWeek']].push(cur);
      }
      return prev;
    }, {});
    return Object.keys(groupedObj)
      .map(key => ({ key, value: groupedObj[key] }))
      .sort((a, b) => {
        const dayOfWeekA = this.getDayOfWeekByKey(a.key);
        const dayOfWeekB = this.getDayOfWeekByKey(b.key);

        if (dayOfWeekA > dayOfWeekB) {
          return 1;
        }
        if (dayOfWeekA < dayOfWeekB) {
          return -1;
        }
        return 0;
      });
  }

  getDayOfWeekByKey(enumValue: string): number {
    const keys = Object.keys(DayOfWeekEnum).filter(x => DayOfWeekEnum[x] == enumValue);
    return keys.length > 0 ? parseInt(keys[0]) : 0;
  }

  selectItem(entity: any, index: number): void {
    console.log('FittingstationOpeningHourList.selectItem()', entity, index);
    this.selectedRowIndex = index;
    this.selectedItem = entity;
    this.onSelectItem.emit(entity);
  }
}
