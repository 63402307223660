import { Component, EventEmitter, Input, Output, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { TreeNode } from 'primeng/primeng';
import { Structure } from '@hrz/core/models/document/structure';
import { flatten } from '@hrz/core/utils/flatten';

@Component({
  selector: 'tm-structure-tree',
  templateUrl: './structure-tree.component.html',
  styleUrls: ['./structure-tree.component.scss'],
})
export class StructureTreeComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() structure: Structure[];
  @Output() onSubstructureSelect: EventEmitter<number>;
  structureTree: TreeNode[];
  selectedNode: TreeNode;

  constructor() {
    this.onSubstructureSelect = new EventEmitter();
    this.structureTree = [];
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['structure'] || changes['structure'].currentValue == null) {
      return;
    }
    this.buildStructureTree(changes['structure'].currentValue);
    this.restoreSelectedNode();
  }

  nodeSelect(event: any) {
    this.onSubstructureSelect.emit(this.getStructureWhereId(event.node.data.id));
  }

  buildStructureTree(structure: Structure[]) {
    this.structureTree = structure.map(s => this.toTreeNode(s));
  }

  toTreeNode = (structure: Structure): TreeNode => {
    const previousNode: TreeNode = this.getNodeWhereId(structure.id);
    return {
      data: {
        id: structure.id,
      },
      expanded: previousNode ? previousNode.expanded : false,
      label: structure.name,
      expandedIcon: 'fa-folder-open',
      collapsedIcon: 'fa-folder',
      children: structure.nestedStructures.map(this.toTreeNode),
    };
  }

  getNodeWhereId(id: number) {
    const nodes = this.traverseTreeNode([...this.structureTree], id);
    return nodes && nodes[0];
  }

  traverseTreeNode(nodes: TreeNode[], id: number) {
    return flatten(nodes.map(node => (node.data.id === id ? node : this.traverseTreeNode(node.children, id))));
  }

  getStructureWhereId(id: number) {
    const structures = this.traverseStructure(this.structure, id);
    return structures && structures[0];
  }

  traverseStructure(structures: Structure[], id: number) {
    return flatten(structures.map(s => (s.id === id ? s : this.traverseStructure(s.nestedStructures, id))));
  }

  restoreSelectedNode() {
    if (this.selectedNode == null) {
      return;
    }
    const foundNode = this.getNodeWhereId(this.selectedNode.data.id);
    if (foundNode == null) {
      return;
    }
    this.selectedNode = this.getNodeWhereId(this.selectedNode.data.id);
    this.nodeSelect({ node: this.selectedNode });
  }
}
