import { Account } from '@hrz/core/models/account';
import { Address } from '@hrz/core/models/address';
import { Dossier } from '@hrz/core/models/dossier';
import { Document } from '@hrz/core/models/document';

export class DossierAccount {
  Id: number;
  AccountType: string;
  DateOfBirth: Date;
  PlaceOfBirth: string;
  Gender: string;
  Name: string;
  CompanyName: string;
  MiddleName: string;
  Surname: string;
  BsnId: string;
  Vat: string;
  VatDeduction: string;
  Coc: string;
  Iban: string;
  // LegalEntityId: number;
  Ownership: string;
  AccountAddress: Address;
  FittingAddress: Address;
  Email: string;
  Mobile: string;
  Phone: string;
  Fax: string;
  SDICode: string;
  PECEmail: string;
  Dossier: Dossier;
  OwnerAlsoDriver: boolean;
  Documents: Document[];
  DriverLicense: string;
  DriverLicenseType: string;
  DriverLicenseExpiry: Date;
  RelationToOwner?: string;
  OtherRelationToOwner?: string;
  ContactMadeType: string;
  HowDidYouFindUsId?: number;
  Location: string;
  LastAppointmentDate: Date;
  LegalRepresentative: string;
  FiscalSystem: string;
  OwnershipType: string;
  VatDiverted: boolean;
  PreferredNotificationType: string;
  TaxOffice: string;
  Account: Account;
}
