import { finalize, tap } from 'rxjs/operators';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy, ComponentRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { DynamicModal, DynamicModalService } from '../../../../shared/dynamic-modal.module';
import { Promotion } from '@hrz/core/models/promotion';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { ByteHelpers } from '@hrz/core/utils/helpers';
import { DocumentManagementModalModule } from '../../../documentmanagement/documentmanagement-modal.module';
import { FileDownloadModal } from '../../../documentmanagement/modals/file-download/file-download.modal';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { Document } from '@hrz/core/models/document';
import { FileService } from '@hrz/core/services/file.service';
import { saveAs } from 'file-saver';
import { Dossier } from '@hrz/core/models/dossier';
import { NewJobPromotion } from '@hrz/core/models/newjobpromotion';
import { SpinnerHelper } from '@hrz/core/utils/spinner-helper';
import { FileDelete } from '@hrz/core/models/document/file-delete';
import { SpinnerService } from '@hrz/core/services/spinner.service';

const MAX_FILE_SIZE = 5242880; // 5 MB

@Component({
  selector: 'add-promotion-frame-modal',
  providers: [TranslateService],
  templateUrl: './add-promotion-frame.modal.html',
})
@DynamicModal()
export class AddPromotionFrameModal implements OnInit, AfterViewInit, OnDestroy {
  public onClose: Function;
  public onSave: Function;
  private jobPromotion: NewJobPromotion;
  public promotions: Promotion[];
  public dossier: Dossier;
  public jobId: number;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;
  private readonly pdfFileName = 'PDF';
  private readonly pdfFileType = 'application/pdf';
  private readonly spinnerHelper: SpinnerHelper;
  private readonly spinnerName = 'addPromotionSpinner';

  constructor(
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private modalService: DynamicModalService,
    private fileService: FileService,
    private spinnerService: SpinnerService

  ) {
    this.spinnerHelper = new SpinnerHelper(this.spinnerService, () => this.spinnerName);
  }
  ngOnInit(): void {
    console.log('AddPromotionFrameModal.ngOnInit');
    this.jobPromotion = new NewJobPromotion();
    this.jobPromotion.JobId = this.jobId;
  }

  ngAfterViewInit(): void {
    console.log('AddPromotionFrameModal.ngAfterViewInit');
    if (this.promotions != null && this.promotions.length > 0) {
      this.childModal.show();
    } else {
      this.toasterService.showWarning(`${this.translateService.instant('DOSSIER.JOB_FS.NO_PROMOTIONS')}`);
    }
  }

  ngOnDestroy(): void {
    console.log('AddPromotionFrameModal.ngOnDestroy');
  }

  clickSave(): void {
    if (this.onSave) {
      this.onSave(this.jobPromotion);
      this.clickCancel();
    }
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  onAddFile($event: any): void {
    console.log('AddPromotionFrameModal.onAddFile()');
    const file = $event.target.files[0];

    const fileType = file['type'];
    const isValidFile = this.isFileValidFile(fileType);
    if (isValidFile == false) {
      console.log(`AddPromotionFrameModal.onAddFile() - filetype should be ${this.pdfFileName} but was: ${fileType.toUpperCase()}`);
      this.toasterService.showError(
        `${this.translateService.instant('DOSSIER.JOB_FS.FILE_UPLOAD.WRONG_TYPE')} ${this.formatFileType(file.name).toUpperCase()}. ` +
        `${this.translateService.instant('DOSSIER.JOB_FS.FILE_UPLOAD.ALLOWED')} ${this.pdfFileName}.`
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      this.toasterService.showError(`${this.translateService.instant('DOSSIER.JOB_FS.FILE_UPLOAD.EXCEED_MAX_FILE_SIZE')}`);
      return;
    }

    console.log('AddPromotionFrameModal.onAddFile() - Now reading the file as Buffer...');
    this.readFileAsBuffer(this, file, file);
  }
  private isFileValidFile(fileType: string): boolean {
    console.log(` .isFileValidFile() - file type: ${fileType}`);
    return fileType.toLowerCase() === this.pdfFileType;
  }

  private formatFileType(fileType: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileType)[1];
  }

  isValid(): boolean {
    if (this.jobPromotion != null) {
      if (this.jobPromotion.PromotionId > 0) {
        return true;
      }
      return false;
    }

    return false;
  }

  private readFileAsBuffer(currentScope: any, file: File, originalFile: File): void {
    const scope = currentScope;
    const reader = new FileReader();
    reader.onprogress = function (_progEvent: ProgressEvent) {
      scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    };

    reader.onload = function () {
      try {
        if (!scope.currentDocument) {
          scope.currentDocument = new Document();
        }

        if (!scope.currentDocument.FileMetaData) {
          scope.currentDocument.FileMetaData = new FileMetaData();
        }

        scope.currentDocument.DocumentType = scope.promotionDocumentType;

        const arrayBuffer = this.result;
        scope.currentDocument.Dossier = scope.dossier;
        scope.currentDocument.FileContent = ByteHelpers.arrayBufferToBase64(arrayBuffer);
        scope.currentDocument.FileBlob = new Blob([new Uint8Array(arrayBuffer as ArrayBufferLike)]);
        scope.currentDocument.FileMetaData.FileName = `${scope.promotionDocumentType}_${file.name}`;
        scope.currentDocument.FileMetaData.FileSize = file.size;
        scope.currentDocument.FileMetaData.FileType = originalFile.type;
        scope.currentDocument.File = file;
        scope.currentDocument.File.newfilename = scope.currentDocument.FileMetaData.FileName; // This is required so we can change the filename that will be uploaded to DocumentManagement...
        scope.currentDocument.FileNameTranslated = scope.fileService.translateFileName(
          scope.currentDocument.FileMetaData.FileName,
          scope.currentDocument
        );
        if (scope.jobPromotion.Document == null) {
          scope.jobPromotion.Document = new Document();
        }
        scope.jobPromotion.Document = scope.currentDocument;
        scope.onUploadFinished(scope.currentDocument);
      } catch (ex) {
        console.log(ex);
        scope.message = scope.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOAD_FAILED');
      } finally {
        scope.message = undefined;
      }
    };
    reader.readAsArrayBuffer(file);
  }

  btnDocumentDownload(event: Document) {
    this.modalService.create(DocumentManagementModalModule, FileDownloadModal, {}).subscribe((componentRef: ComponentRef<{}>) => {
      const downloadFileObservable = this.downloadDocument(event).subscribe(
        (_file: FileDownload) => {
          this.toasterService.showSuccess(this.translateService.instant('DOSSIER.JOB_FS.FILE_UPLOAD.MESSAGE_SUCCESS'));
        },
        (error: any) => {
          componentRef.destroy();
          console.error('AddPromotionFrameModal.btnDocumentDownload() => Failed to download the file: ' + error);
        },
        () => {
          componentRef.destroy();
        }
      );
      componentRef.onDestroy(() => {
        downloadFileObservable.unsubscribe();
      });
    });
  }

  private downloadDocument(event: Document): Observable<FileDownload> {
    if (!event.FileMetaData.Reference) {
      saveAs(event.FileBlob, this.fileService.translateFileName(event.FileMetaData.FileName, event));
      return;
    }

    return this.fileService.downloadDossierDocument(event).pipe(
      tap((fileDownload: FileDownload) => {
        saveAs(fileDownload.blob, fileDownload.name);
      })
    );
  }

  btnRemoveDocument(): void {
    const document = this.jobPromotion.Document;
    console.log('Deleting the following file with name: ' + document.FileMetaData.FileName);
    this.onFileRemoved(document);
    this.jobPromotion.Document = null;
  }

  onUploadFinished(uploadedDocument: Document): void {
    console.log('AddPromotionFrameModal.onUploadFinished() - Step 1, save to DocumentManagement API');
    if (!uploadedDocument) {
      return;
    }
    this.spinnerHelper.show();
    this.fileService
      .uploadDocument(uploadedDocument)
      .pipe(finalize(() => {}))
      .subscribe(
        (file: any) => {
          uploadedDocument.FileMetaData.Reference = file.id;
          console.log('AddPromotionFrameModal.onUploadFinished() - Step 2, save to Dossier Document API');
          this.spinnerHelper.hide();
        },
        _error => {
          this.spinnerHelper.hide();
          this.toasterService.showApiError(_error);
        }
      );
  }

  public onFileRemoved(document: Document) {
    console.log('AddPromotionFrameModal.onFileRemoved()');

    if (!document) {
      return;
    }

    this.spinnerHelper.withSpinnerAsync(async () => {
      if (document.FileMetaData.Reference) {
        const fileId = +document.FileMetaData.Reference;
        const deleteFile = new FileDelete();
        deleteFile.ids = [];
        deleteFile.ids.push(fileId);

        await this.fileService.delete(deleteFile).subscribe(
          _result => {},
          error => {
            console.log(error);
          }
        );
      }
    });
  }
}
