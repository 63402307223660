import { CountryEnum } from '@hrz/core/enums/country.enum';
import { PaymentInfoMethodEnum } from '@hrz/core/models/enums/payment-info-method.enum';

export const appConfig = {
  dateFormat: 'dd-MM-yyyy',
  emailRegex: '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$',
  purchaseOrder: {
    createNew: {
      tenants: [CountryEnum.Spain],
    },
  },
  tenantsUsingService: [CountryEnum.NotDefined, CountryEnum.Italy, CountryEnum.Portugal, CountryEnum.Spain],
  paymentMethods: [
    {
      tenantId: 10,
      excluded: [PaymentInfoMethodEnum[PaymentInfoMethodEnum.Multiple]],
    },
  ],
  vehicleIdentificationNumberMaxLength: 17,
  bootstrapDatepickerConfig: {
    containerClass: 'theme-dark-blue', // available: theme-default, theme-green, theme-blue, theme-dark-blue, theme-red, theme-orange
    dateInputFormat: 'DD/MM/YYYY'
  }
};
