﻿export class Address {
  AdresType: string;
  Street: string;
  ZipCode: string;
  City: string;
  Extra: string;
  Region: string;
  Province: string;
  CountryId: number;
  CountryIsoCode: string;
  CountryName: string;
  CountryCurrency: string;
  CountryCulture: string;
}
