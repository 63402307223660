import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { appConfig } from '../../../app-config';
import { CreateQuoteCommand } from '@hrz/core/models/quote/create-quote-command';
import { QuoteService } from '@hrz/core/services/quote.service';
import { DynamicModal } from '../dynamic-modal.module';

type FormControlName = 'additionalNotes' | 'email' | 'reference' | 'showEmail' | 'validityDate';

@Component({
  selector: 'cancel-with-reason-confirmation-modal',
  templateUrl: './create-quote.modal.html',
})
@DynamicModal()
export class CreateQuoteModel implements OnInit, AfterViewInit {
  public message: string;
  public dossierId: number;
  public customerMailaddress: string;
  public onOk: (createQuoteCommand: CreateQuoteCommand) => Promise<void>;
  public onClose: Function;
  public showEmail$: Observable<boolean>;
  private _showEmail$ = new BehaviorSubject<boolean>(false);
  public okButtonText$: Observable<string>;

  public cntrlAdditionalNotes: FormControlName = 'additionalNotes';
  public cntrlEmail: FormControlName = 'email';
  public cntrlReference: FormControlName = 'reference';
  public cntrlShowEmail: FormControlName = 'showEmail';
  public cntrlValidityDate: FormControlName = 'validityDate';

  private formControl(controlName: FormControlName): AbstractControl {
    return this.formGroup.controls[controlName];
  }

  public get isValid(): boolean {
    const showEmail = this.formControl('showEmail').value;
    const emailControl = this.formControl('email');
    const email = emailControl.value;
    const validity = this.formControl('validityDate').value;
    return this.formGroup.valid && !!validity && (!showEmail || (!!email && emailControl.valid));
  }

  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;
  public formGroup: FormGroup;

  constructor(
    private readonly quoteService: QuoteService,
    private readonly translateService: TranslateService
  ) {
    this.showEmail$ = this._showEmail$.asObservable();
    this.createFormGroup();
    this.setupOkButtonText();
  }

  private createFormGroup(): void {
    this.formGroup = new FormGroup({
      [this.cntrlAdditionalNotes]: new FormControl(''),
      [this.cntrlEmail]: new FormControl('', [Validators.pattern(appConfig.emailRegex)]),
      [this.cntrlReference]: new FormControl(''),
      [this.cntrlShowEmail]: new FormControl(false, [Validators.required]),
      [this.cntrlValidityDate]: new FormControl({ value: '', disabled: true }, [Validators.required]),
    });

    this.formControl('showEmail').valueChanges.subscribe(showEmail => {
      this._showEmail$.next(showEmail as boolean);
    });

    this.showEmail$.subscribe(showEmail => {
      const emailControl = this.formGroup.controls[this.cntrlEmail];
      if (!showEmail && !!emailControl.value) {
        emailControl.setValue('');
      } else {
        emailControl.setValue(this.customerMailaddress);
      }
    });
  }

  private setupOkButtonText(): void {
    const prefix = 'QUOTE.CREATE.';
    const createQuotationKey = prefix + 'CREATE_QUOTATION';
    const createAndSendQuotationKey = prefix + 'CREATE_AND_SEND_QUOTATION';
    const translationKeys = [createQuotationKey, createAndSendQuotationKey];
    const translations$ = this.translateService.get(translationKeys);
    this.okButtonText$ = this.showEmail$.pipe(
      withLatestFrom(translations$),
      map(([showEmail, translations]) => {
        return translations[showEmail ? createAndSendQuotationKey : createQuotationKey];
      })
    );
  }

  async ngOnInit(): Promise<void> {
    await this.setValidityDate();
  }

  private async setValidityDate(): Promise<void> {
    const apiResponse = await this.quoteService.getValidityPeriod();
    const validityInMs = apiResponse.entity.ValidityInMilliseconds;
    const validityDate = moment.utc(new Date()).add(validityInMs, 'milliseconds').local().toDate();
    this.formControl('validityDate').setValue(moment(validityDate).format('DD-MM-YYYY'));
  }

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) {
      this.onClose();
    }
  }

  async clickOk(): Promise<void> {
    this.closeModal();

    if (this.onOk) {
      const createQuoteCommand: CreateQuoteCommand = {
        AdditionalNotes: this.formControl('additionalNotes').value,
        CustomerEmail: this.formControl('email').value,
        CustomerReference: this.formControl('reference').value,
        DossierId: this.dossierId,
        QuotationSignatureType: 'Notset',
        QuotationType: 'Closed',
      };

      await this.onOk(createQuoteCommand);
    }
  }
}
