import { Component, OnInit } from '@angular/core';
import { SignalrDossierService } from './core/services/signalr.dossier.service';
import { NavigationEnd, Router } from '@angular/router';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { AuthService } from '@hrz/core/services/auth.service';
import { WarningDeprecatedVersionModal } from '@hrz/shared/modals/warning-deprecated-version/warning-deprecated-version-modal';
import { DossierModalsModule } from './features/dossiers/dossier-modals.module';
import { DynamicModalService } from '@hrz/shared/dynamic-modal.module';
import { environment } from '@hrz/env';

declare const gtag: Function;

@Component({
  selector: 'hrz-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {

  constructor(
    private signalrDossierService: SignalrDossierService,
    protected modalService: DynamicModalService,
    public authService: AuthService,
    router: Router,
    appInsightsService: AppInsightsService
  ) {
    appInsightsService.logTrace('AppComponent constructor');

    this.signalrDossierService.error$.subscribe(
      (error: any) => {
        console.warn(error);
      },
      (error: any) => {
        console.error('errors$ error', error);
      }
    );
    this.signalrDossierService.starting$.subscribe(
      () => {
        console.log('AppComponent.constructor() SignalR: service has been started');
      },
      () => {
        console.warn('AppComponent.constructor() SignalR: service failed to start!');
      }
    );
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('event', 'page_view', {
          page_hostname: window.location.hostname,
          page_path: event.urlAfterRedirects,
          page_location: window.location.origin + event.urlAfterRedirects,
        });
      }
    });
  }

  ngOnInit() {
    this.signalrDossierService.start();

    // Display warning deprecated modal randomly based on a defined percentage
    if (this.getRandomInt(100) <= environment.newHorizonsModal.ExpositionPercentage) {
      this.modalService.create(DossierModalsModule, WarningDeprecatedVersionModal);
    }
  }

  // Generate a random number between 0 (included) and max (excluded)
  private getRandomInt(max): number {
    return Math.floor(Math.random() * max);
  }
}
