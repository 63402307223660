import { Injectable, Injector, Optional, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { timer as observableTimer, of as observableOf, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import * as auth0 from 'auth0-js';
import { environment } from '@hrz/env';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { LegalEntity } from '@hrz/core/models/legal-entity';
import { UserContext } from '@hrz/core/models/user-context';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _isAuthenticated = false;
  private refreshSubscription: any;
  private auth0: any;
  private auth0domain = ConfigurationManager.AppSettings.auth0Domain;
  private auth0cliendId = ConfigurationManager.AppSettings.auth0ClientId;
  private _scopes: string[] = null;

  constructor(private injector: Injector,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: AuthService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('AuthService is a Singleton and should only be loaded in AppModule.'));
    }

    console.log('AuthService.Constructor()');

    let redirectUrl = environment.applicationUrl;

    const requestedHash = window.location.hash;
    if (requestedHash.indexOf('#/') !== -1) { redirectUrl = window.location.hash; }

    // if (state.url.indexOf(environment.applicationUrl + "/"))
    this.auth0 = new auth0.WebAuth({
      clientID: environment.auth0ClientId,
      domain: environment.auth0Domain,
      responseType: 'token id_token',
      audience: environment.auth0Identifier,
      redirectUri: environment.applicationUrl,
      scope: 'openid',
      state: redirectUrl,
    });
  }

  private isTokenExpired(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() >= expiresAt;
  }

  public get router(): Router {
    // This creates router property on your service.
    // Needed to prevent cyclic dependencies
    return this.injector.get(Router);
  }

  public login(): void {
    console.log('AuthService.login()');
    this.clearLocalStorageDossierOverview();
    this.auth0.authorize();
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    this._isAuthenticated = new Date().getTime() < expiresAt;

    return this._isAuthenticated;
  }

  public handleAuthentication(): Promise<string> {

    return new Promise((resolve, _) => {
      this.auth0.parseHash((err, authResult) => {
        console.log('AuthService.handleAuthentication(): Parsing the Hash...');
        if (authResult && authResult.accessToken && authResult.idToken) {
          console.log('AuthService.handleAuthentication(): Access token received, setting to session...');
          this.setSession(authResult);
          const state = authResult.state;
          window.location.hash = '';

          if (this.isWebAuthorityUser(authResult.accessToken)) {
            this.router.navigate(['/webauthority/overview']);
          } else if (state.indexOf('#/') !== -1) {
            this.router.navigate([state.replace('#', '')]);
          } else {
            this.router.navigate(['/']);
          }
          resolve('ok');
        } else if (err) {
          console.log('AuthService.handleAuthentication(): Access token not found?? error received...');
          this.router.navigate(['/']);
          console.log(err);
          resolve('ok');
        } else {
          console.log('AuthService.handleAuthentication(): no error and also no access token found...');
          resolve('ok');
        }
      });
    });
  }

  public renewToken() {
    console.log('AuthService.renewToken()');
    this.auth0.renewAuth(
      {
        audience: environment.auth0Identifier,
        redirectUri: ConfigurationManager.AppSettings.applicationUrl + '/silent.html',
        usePostMessage: true,
      },
      (err, result) => {
        if (err) {
          console.log(err);
        } else {
          this.setSession(result);
        }
      }
    );
  }

  private setSession(authResult): void {

    this._isAuthenticated = true;

    const expiresAt = JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);

    this._scopes = null;
    this.getTenantId().subscribe((tenantId: number) => {
      if (!ConfigurationManager.UserContext) {
        ConfigurationManager.UserContext = new UserContext();
      }
      ConfigurationManager.UserContext.LegalEntity = new LegalEntity();
      ConfigurationManager.UserContext.LegalEntity.Id = tenantId;
    });

    this.getFittingStationId();
    this.scheduleRenewal();
  }

  public logout(): void {
    console.log('AuthService.logout()');
    this.clearLocalStorageAuthenAuthor();
    this.clearLocalStorageDossierOverview();
    window.location.href = `https://${this.auth0domain}/v2/logout?` + 
    `returnTo=${encodeURIComponent(environment.applicationUrl)}&client_id=${this.auth0cliendId}`;
    // Mark as no longer authenticated
    this._isAuthenticated = false;
  }

  public getTenantId(): Observable<number> {
    console.log('AuthService.getTentantId()');
    const token = localStorage.getItem('access_token');
    if (!token) {
      return observableOf(0);
    }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    return observableOf(+result['https://horizons.com/legalentity']);
  }

  public getFittingStationGroupId() {
    console.log('AuthService.getFittingStationGroupId()');
    const token = localStorage.getItem('access_token');
    if (!token) { return 0; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    return +result['https://horizons.com/fitting-station-group'];
  }

  public getFittingStationGroupIds(): number[] {
    console.log('AuthService.getFittingStationGroupIds()');
    const token = localStorage.getItem('access_token');
    if (!token) { return []; }
    const helper = new JwtHelperService();
    const resultToken = helper.decodeToken(token);
    const result = resultToken['https://horizons.com/fitting-station-group'];
    return result === undefined ? [] : result.split(',').map(id => Number(id));
  }

  public getFittingStationId() {
    console.log('AuthService.getFittingStationId()');
    const token = localStorage.getItem('access_token');
    if (!token) { return 0; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    return +result['https://horizons.com/fitting-station'];
  }

  public getFittingStationIds(): number[] {
    console.log('AuthService.getFittingStationId()');
    const token = localStorage.getItem('access_token');
    if (!token) { return []; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    if (result['https://horizons.com/fitting-station'] === undefined) {
      return [];
    }
    const ids = JSON.parse(JSON.stringify(result['https://horizons.com/fitting-station'])) as string;
    if (!ids) { return []; }
    const split = ids.split(',');
    const mapped = split.map(item => Number(item));
    return mapped;
  }
  public getUserName() {
    console.log('AuthService.getUserName()');
    const token = localStorage.getItem('id_token');
    if (!token) { return 0; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    return result['https://horizons.com/username'];
  }
  public getUserMail() {
    console.log('AuthService.getUserMail()');
    const token = localStorage.getItem('id_token');
    if (!token) { return 0; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    return result['https://horizons.com/usermail'];
  }
  public getUserNameWithFallbackMail() {
    console.log('AuthService.getUserNameWithFallbackMail()');
    let username = this.getUserName();
    if (username === undefined || username === '') {
      username = this.getUserMail();
    }
    return username;
  }

  public isCentralDepartment(): boolean {
    console.log('AuthService.isCentralDepartment()');
    const token = localStorage.getItem('access_token');
    if (!token) { return false; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    const value = result['https://horizons.com/isCentralDepartment'];
    if (!value) {
      return false;
    } else {
      return value.toLowerCase() === 'true';
    }
  }

  public isProcessingUser(): Boolean {
    console.log('AuthService.isProcessingUser()');
    const token = localStorage.getItem('access_token');
    if (!token) { return false; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    const isProcessingUser = result['https://horizons.com/isProcessingUser'];
    return isProcessingUser.toLowerCase() === 'true';
  }

  public isWebAuthorityUser(token: any): Boolean {
    console.log('AuthService.isWebAuthorityUser()');
    if (!token) { token = localStorage.getItem('access_token'); }

    if (!token) { return false; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    const isWebAuthorityUser = result['https://horizons.com/webAuthorityIds'];
    return isWebAuthorityUser;
  }

  public getWebAuthorityIds(): number[] {
    console.log('AuthService.getWebAuthorityIds()');
    const token = localStorage.getItem('access_token');
    if (!token) { return []; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    const ids = JSON.parse(JSON.stringify(result['https://horizons.com/webAuthorityIds'])) as string;
    if (!ids) { return []; }

    const split = ids.split(',');
    const mapped = split.map(item => Number(item));
    return mapped;
  }

  public scheduleRenewal() {

    if (this.isTokenExpired()) { return; }

    this.unscheduleRenewal();
    const source = observableOf(
      JSON.parse(window.localStorage.getItem('expires_at'))
    ).pipe(
      mergeMap(expiresAt => {
        const now = Date.now();
        // Use the delay in a timer to
        // run the refresh at the proper time
        return observableTimer(Math.max(1, expiresAt - now));
      })
    );

    // Once the delay time from above is
    // reached, get a new JWT and schedule
    // additional refreshes
    this.refreshSubscription = source.subscribe(() => {
      this.renewToken();
      this.scheduleRenewal();
    });
  }

  public unscheduleRenewal() {
    console.log('AuthService.unscheduleRenewal()');
    if (!this.refreshSubscription) { return; }
    this.refreshSubscription.unsubscribe();
  }

  get scopes(): string[] {
    if (!this._scopes) {
      console.log('auth.service: Scopes is empty, getting from LS');
      this._scopes = this.getScopes();
    }
    return this._scopes;
  }

  public getScopes(): Array<string> {
    const token = localStorage.getItem('access_token');
    if (!token) { return null; }
    const helper = new JwtHelperService();
    const result = helper.decodeToken(token);
    // return  result['scope'];
    return result['scope'].split(' ');
  }

  public userHasScope(scope: string): boolean {
    const grantedScopes = this.scopes;
    return grantedScopes ? grantedScopes.indexOf(scope) > -1 : false;
  }

  public userHasScopes(scopes: Array<string>): boolean {
    const grantedScopes = this.scopes;
    return grantedScopes && scopes ?
      scopes.every(scope => grantedScopes.indexOf(scope) > -1) :
      false;
  }

  private clearLocalStorageAuthenAuthor(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_context');
  }

  private clearLocalStorageDossierOverview(): void {
    localStorage.removeItem('localstorage.dossier.overview.dateRange');
    localStorage.removeItem('localstorage.dossier.overview.state');
    localStorage.removeItem('localstorage.dossier.overview.subState');
    localStorage.removeItem('localstorage.dossier.overview.fittingstation');
    localStorage.removeItem('localstorage.dossier.overview.account');
    localStorage.removeItem('localstorage.dossier.overview.searchQuery');
    localStorage.removeItem('localstorage.dossier.overview.activePage');
    localStorage.removeItem('localstorage.dossier.overview.dossierFilter');
  }
}
