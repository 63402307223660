import { map, catchError } from 'rxjs/operators';
import { EventEmitter, Injectable, Optional, Output, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { DocFilter, Document } from '@hrz/core/models/document';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { Observable } from 'rxjs';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private dossierUrl = ConfigurationManager.AppSettings.dossierApi + '/dossier';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  @Output() documentGeneratedEvents = new EventEmitter<Document>();

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: DocumentService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('DocumentService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public create(document: Document): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${document.Dossier.Id}/document/`;
    const createCommand = {
      DossierId: document.Dossier.Id,
      DossierVehicleId: document.DossierVehicleId,
      DamageId: document.DamageId,
      InsuranceInfoId: document.InsuranceInfoId,
      DocumentType: document.DocumentType,
      MetaDataFileName: document.FileMetaData.FileName,
      MetaDataFileType: document.FileMetaData.FileType,
      MetaDataFileSize: document.FileMetaData.FileSize,
      MetaDataReference: document.FileMetaData.Reference,
      Number: document.Number,
      UploadDate: new Date(),
      IsSigned: document.IsSignedOnUpload,
    };
    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public delete(document: Document): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${document.Dossier.Id}/document/${document.Id}`;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public approve(document: Document): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${document.Dossier.Id}/document/${document.Id}/approve`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public generateDocument(documentType: string, dossierId: number): Observable<ApiActionResult<any>> {
    const url = `${this.dossierUrl}/${dossierId}/document/${documentType}/generate`;
    const command = {
      DossierId: dossierId,
      DocumentType: documentType,
      Persist: true,
    };
    return this.http.post(url, JSON.stringify(command), { headers: this.headers }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public generateDocumentGenerated(document: Document) {
    this.documentGeneratedEvents.emit(document);
  }

  public get(dossierID: number, docFilter: DocFilter): Observable<ApiActionResult<Document[]>> {
    const params: HttpParams = new HttpParams()
      .set('filter.type', docFilter.type)
      .set('filter.latest', docFilter.latest.toString());
    return this.http.get(`${this.dossierUrl}/${dossierID}/document`, { params: params }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public patch(doc: Document, operation: string, propertyName: string, value: string): Observable<ApiActionResult> {
    const url = `${this.dossierUrl}/${doc.Dossier.Id}/document/${doc.Id}`;
    const patchCommand = {
      Operations: [
        {
          Operation: operation,
          PropertyName: propertyName,
          Value: value,
        },
      ],
    };
    return this.http.patch(url, JSON.stringify(patchCommand), { headers: this.headers }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleActionError(error))
    );
  }

  public linkDocumentsToJobline(joblineId: number, dossierId: number): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${dossierId}/document/${joblineId}/linkDocumentsToJobline`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
