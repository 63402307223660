import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Enum } from '@hrz/core/models/enum';
import { EnumCheckable } from '@hrz/core/models/enum-checkable';
import { EnumService } from '@hrz/core/services/enum.service';

import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'export-translations-modal',
  template: `
  <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <div class="modal-header">
              <h4 class="modal-title">{{ 'SHARED.MODALS.EXPORT_MODULE_TRANSLATIONS' | translate }}</h4>
              <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          <div class="modal-body">
              <div class="info-box">
                  <label class="form-control-label" for="exportLanguage">{{
                      'SHARED.MODALS.CHOOSE_LANGUAGE_TO_EXPORT_INTO' | translate
                      }}</label>
                      <select
                        id="exportLanguage"
                        name="ExportLanguage"
                        [(ngModel)]="ChosenExportLanguage"
                        class="form-control"
                        size="1">
                      <option [ngValue]="null" selected="selected">-- {{ 'SHARED.MODALS.NO_LANGUAGE_SELECTED' |
                          translate }} --</option>
                      <option [ngValue]="undefined" hidden selected="selected">-- {{ 'SHARED.MODALS.SELECT_LANGUAGE' |
                          translate }} --</option>
                      <option *ngFor="let el of exportLanguages" [ngValue]="el.Key">{{ el.Value }}</option>
                  </select>
              </div>
              <br />
              <div class="info-box" *ngIf="filter">
                  <h6 class="float-left">{{ 'SHARED.MODALS.SELECT_MODULES_TO_EXPORT' | translate }}</h6>
                  <span class="float-right"
                  >(<input
                    type="checkbox"
                    id="filAll"
                    class="form-check-input"
                    #filterAll
                    checked="checked"
                    (click)="checkUnCheckAll(filterAll.checked)" /><label for="filAll">All</label>)</span
                  >
                  <div class="clear">&nbsp;</div>
                  <div class="check-container">
                      <div *ngFor="let fil of filter">
                          <input type="checkbox" [id]="fil.Key" class="form-check" [(ngModel)]="fil.Checked" /><label
                              [for]="fil.Key">
                              {{ fil?.Value + '' }}</label><br />
                      </div>
                  </div>
              </div>
          </div>
          <div class="modal-footer">
              <input type="checkbox" id="jsonFileCheckbox" class="form-check" [(ngModel)]="jsonFile" /><label
                  for="jsonFileCheckbox">JSON</label>
              <button type="button" class="btn btn-primary" [disabled]="!ChosenExportLanguage" (click)="clickOk()">
                  {{ 'COMMON.EXPORT_FOR_LANGUAGE' | translate }}
              </button>
          </div>
      </div>
  </div>
</div>
  `,
})
@DynamicModal()
export class ExportTranslationsModal implements OnInit, AfterViewInit, OnDestroy {
  public exportLanguages: Enum[];
  public ChosenExportLanguage: Enum;

  public onOk: Function;
  public onClose: Function;

  filter: EnumCheckable[];
  jsonFile: Boolean;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  constructor(private enumService: EnumService) {}

  ngOnInit(): void {
    console.log('ExportTranslationsModal.ngOnInit');
    this.enumService.getExportLanguages().then(results => {
      this.exportLanguages = results;
    });
    if (!this.filter) { this.filter = []; }
  }
  ngAfterViewInit(): void {
    console.log('ExportTranslationsModal.ngAfterViewInit');
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('ExportTranslationsModal.ngOnDestroy');
  }

  checkUnCheckAll(check: boolean): void {
    for (let i = 0; i <= this.filter.length - 1; i++) {
      this.filter[i].Checked = check;
    }
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) {
      this.onOk(
        this.ChosenExportLanguage,
        this.filter.filter(c => c.Checked),
        this.jsonFile
      );
    }
  }
}
