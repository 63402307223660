import { SpinnerService } from '@hrz/core/services/spinner.service';

export class SpinnerHelper {
  private counter = 0;

  constructor(
    private readonly spinnerService: SpinnerService,
    private readonly spinnerName: () => string
  ) {
    this.spinnerService = spinnerService;
  }

  public show() {
    this.counter++;
    if (this.counter >= 1) {
      this.spinnerService.show(this.spinnerName());
    }
  }

  public hide() {
    this.counter--;
    if (this.counter < 0) {
      console.warn('SpinnerHelper counter reached ' + this.counter);
      this.counter = 0;
    }
    if (this.counter === 0) {
      this.spinnerService.hide(this.spinnerName());
    }
  }

  public withSpinner(action: () => void, finallyAction?: () => void) {
    try {
      this.show();
      action();
    } finally {
      if (!!finallyAction) {
        finallyAction();
      }
      this.hide();
    }
  }

  public async withSpinnerAsync(actionAsync: () => Promise<void>, finallyAction?: () => void) {
    try {
      this.show();
      await actionAsync();
    } finally {
      if (!!finallyAction) {
        finallyAction();
      }
      this.hide();
    }
  }
}
