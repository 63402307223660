import { Injectable, Optional, SkipSelf } from '@angular/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ValidationFormControlService {
  private errorMessages: { [key: string]: string };

  constructor(private translateService: TranslateService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: ValidationFormControlService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('ValidationFormControlService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  getErrorMessages(): { [key: string]: string } {
    if (this.errorMessages) {
      return this.errorMessages;
    }

    this.setErrorMessages();
    return this.errorMessages;
  }

  private setErrorMessages(): void {
    this.errorMessages = {
      required: this.translateService.instant('COMMON.VALIDATION.REQUIRED'),
      whitespace: this.translateService.instant('COMMON.VALIDATION.REQUIRED'),
      invalidDateFrom: this.translateService.instant('COMMON.VALIDATION.INVALID_DATE.FROM_DATE'),
      invalidDateTo: this.translateService.instant('COMMON.VALIDATION.INVALID_DATE.TO_DATE'),
    };
  }
}
