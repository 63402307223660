import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Fittingstation } from '@hrz/core/models/fittingstation';
import { FittingstationService as Service } from '@hrz/core/models/fittingstation-service';
import { FittingstationServiceArea as ServiceArea } from '@hrz/core/models/fittingstation-service-area';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { Photo } from '@hrz/core/models/photo';
import { OpeningHour } from '@hrz/core/models/opening-hour';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { FittingstationAdas as Adas } from '@hrz/core/models/fittingstation-adas';
import { Document } from '@hrz/core/models/document';
import { AdasBrand } from '@hrz/core/models/adasbrand';
import {
  AppointmentCapacity,
  AppointmentEventType,
  AppointmentCapacityException,
  AppointmentCapacityExceptionReason,
} from '@hrz/core/models/appointment-capacity';
import { BlackListedAccount } from '@hrz/core/models/blacklisted-account';
import { Account } from '@hrz/core/models/account';
import { AppointmentLogisticsTime } from '@hrz/core/models/appointment-logistics-time';
import { OpeningHourException } from '@hrz/core/models/opening-hour-exception';
import { UrlUtils } from 'src/app/core/utils/url.utils';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class FittingStationService {
  private baseUrl = ConfigurationManager.AppSettings.fittingApi;
  private fittingstationUrl = this.baseUrl + '/fittingstation';
  private openingHourExceptionUrl = this.baseUrl + '/openinghourexception';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: FittingStationService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('FittingStationService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  loadAll(): Promise<Fittingstation[]> {
    return this.http
      .get<Fittingstation[]>(this.fittingstationUrl)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public get(id: number): Promise<Fittingstation> {
    return this.http
      .get<Fittingstation>(this.fittingstationUrl + '/' + id)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getMultiple(ids: number[]): Promise<Fittingstation[]> {
    const url = `${this.fittingstationUrl}/GetMultiple?${UrlUtils.getQueryStringForIds(ids)}`;

    return this.http
      .get<Fittingstation[]>(url, { headers: this.headers })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public create(fittingstation: Fittingstation, fittingStationGroupId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}`;
    const createCommand = {
      Name: fittingstation.Name,
      LegalName: fittingstation.LegalName,
      GroupType: fittingstation.GroupType,
      Status: fittingstation.Status,
      FittingstationGroupId: fittingStationGroupId,
      BusinessContactId: fittingstation.BusinessContactId,
      EmergencyContactId: fittingstation.EmergencyContactId,
      CommercialContactId: fittingstation.CommercialContactId,
      PostalAddressStreet: fittingstation.PostalAddress.Street,
      PostalAddressZipCode: fittingstation.PostalAddress.ZipCode,
      PostalAddressCity: fittingstation.PostalAddress.City,
      PostalAddressRegion: fittingstation.PostalAddress.Region,
      PostalAddressExtra: fittingstation.PostalAddress.Extra,
      PostalAddressCountryId: fittingstation.PostalAddress.CountryId,
      BusinessAddressStreet: fittingstation.BusinessAddress.Street,
      BusinessAddressZipCode: fittingstation.BusinessAddress.ZipCode,
      BusinessAddressExtra: fittingstation.BusinessAddress.Extra,
      BusinessAddressCity: fittingstation.BusinessAddress.City,
      BusinessAddressRegion: fittingstation.BusinessAddress.Region,
      BusinessAddressCountryId: fittingstation.BusinessAddress.CountryId,
      BusinessLanguageId: fittingstation.BusinessLanguage.Id,
      FittingstationType: fittingstation.FittingstationType,
      IsPartner: fittingstation.IsPartner,
      ErpIdentifier: fittingstation.ErpIdentifier,
      TimeZoneInfoId: fittingstation.TimeZoneInfoId,
      LocationIdentifier: fittingstation.LocationIdentifier,
      NotSyncToGmb: fittingstation.NotSyncToGmb,
      HideInCallCentre: fittingstation.HideInCallCentre,
      LocationInfoAndInstructions: fittingstation.LocationInfoAndInstructions,
      IsRepairAndGo: fittingstation.IsRepairAndGo,
      HideInApptTool: fittingstation.HideInApptTool,
      TaxOffice: fittingstation.TaxOffice,
      IsConsignment: fittingstation.IsConsignment,
      LogisticsTimeOpenHoursOnly: fittingstation.LogisticsTimeOpenHoursOnly,
    };
    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
  public update(fittingstation: Fittingstation): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/${fittingstation.Id}`;
    const updateCommand = {
      FittingstationId: fittingstation.Id,
      Name: fittingstation.Name,
      LegalName: fittingstation.LegalName,
      GroupType: fittingstation.GroupType,
      Status: fittingstation.Status,
      PostalAddressStreet: fittingstation.PostalAddress.Street,
      PostalAddressZipCode: fittingstation.PostalAddress.ZipCode,
      PostalAddressCity: fittingstation.PostalAddress.City,
      PostalAddressRegion: fittingstation.PostalAddress.Region,
      PostalAddressExtra: fittingstation.PostalAddress.Extra,
      PostalAddressCountryId: fittingstation.PostalAddress.CountryId,
      BusinessAddressStreet: fittingstation.BusinessAddress.Street,
      BusinessAddressZipCode: fittingstation.BusinessAddress.ZipCode,
      BusinessAddressExtra: fittingstation.BusinessAddress.Extra,
      BusinessAddressCity: fittingstation.BusinessAddress.City,
      BusinessAddressRegion: fittingstation.BusinessAddress.Region,
      BusinessAddressCountryId: fittingstation.BusinessAddress.CountryId,
      BusinessLanguageId: fittingstation.BusinessLanguage.Id,
      FittingstationType: fittingstation.FittingstationType,
      IsPartner: fittingstation.IsPartner,
      ErpIdentifier: fittingstation.ErpIdentifier,
      TimeZoneInfoId: fittingstation.TimeZoneInfoId,
      LocationIdentifier: fittingstation.LocationIdentifier,
      NotSyncToGmb: fittingstation.NotSyncToGmb,
      HideInCallCentre: fittingstation.HideInCallCentre,
      LocationInfoAndInstructions: fittingstation.LocationInfoAndInstructions,
      IsRepairAndGo: fittingstation.IsRepairAndGo,
      HideInApptTool: fittingstation.HideInApptTool,
      TaxOffice: fittingstation.TaxOffice,
      IsConsignment: fittingstation.IsConsignment,
      LogisticsTimeOpenHoursOnly: fittingstation.LogisticsTimeOpenHoursOnly,
    };
    console.log('PUT - JSON Command: ', JSON.stringify(updateCommand));
    return this.http
      .put(url, JSON.stringify(updateCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public delete(fittingstationId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/${fittingstationId}`;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public attachDetachBusinessContact(fittingstation: Fittingstation, contactId: number): Promise<boolean> {
    let url = `${this.fittingstationUrl}`;
    const command = {
      ContactId: contactId,
      FittingstationId: fittingstation.Id,
    };
    if (contactId > 0) {
      url = url + '/attachbusinesscontact';
    } else {
      url = url + '/detachbusinesscontact';
    }

    console.log('POST - JSON Command Attach/Detach Business Contact: ', JSON.stringify(command));
    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false));
  }

  public attachDetachEmergencyContact(fittingstation: Fittingstation, contactId: number): Promise<boolean> {
    let url = `${this.fittingstationUrl}`;
    const command = {
      ContactId: contactId,
      FittingstationId: fittingstation.Id,
    };
    if (contactId > 0) {
      url = url + '/attachemergencycontact';
    } else {
      url = url + '/detachemergencycontact';
    }

    console.log('POST - JSON Command Attach/Detach Emergency Contact: ', JSON.stringify(command));
    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false));
  }

  public attachDetachCommercialContact(fittingstation: Fittingstation, contactId: number): Promise<boolean> {
    let url = `${this.fittingstationUrl}`;
    const command = {
      ContactId: contactId,
      DomainEntityId: fittingstation.Id,
      FittingstationId: fittingstation.Id,
    };
    if (contactId > 0) {
      url = url + '/attachecommercialcontact';
    } else {
      url = url + '/detachcommercialcontact';
    }

    console.log('POST - JSON Command Attach/Detach Commercial Contact: ', JSON.stringify(command));
    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(() => Promise.resolve(true))
      .catch(() => Promise.resolve(false));
  }

  public addFittingstationService(fittingstationService: Service): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PostFittingstationService?fittingstationId=` + fittingstationService.FittingstationId;
    const command = {
      FittingstationId: fittingstationService.FittingstationId,
      ServiceType: fittingstationService.ServiceType,
      Value: fittingstationService.Value,
    };

    console.log('POST - JSON Command add fittingstation service: ', JSON.stringify(command));

    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateFittingstationService(fittingstationService: Service): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PutFittingstationService?id=` + fittingstationService.Id;
    const command = {
      FittingstationServiceId: fittingstationService.Id,
      FittingstationId: fittingstationService.FittingstationId,
      ServiceType: fittingstationService.ServiceType,
      Value: fittingstationService.Value,
    };

    console.log('PUT - JSON Command update fittingstation service: ', JSON.stringify(command));
    return this.http
      .put(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteFittingstationService(fittingstationServiceId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/DeleteFittingstationService?id=` + fittingstationServiceId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  // ********* ServiceAreas ****************
  public addServiceArea(serviceArea: ServiceArea): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/ServiceArea`;
    const command = {
      FittingstationId: serviceArea.FittingstationId,
      Region: serviceArea.Region,
    };
    console.log('POST - JSON Command add fittingstation service area: ', JSON.stringify(command));

    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteServiceArea(serviceArea: ServiceArea): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/ServiceArea`;
    const command = {
      FittingstationId: serviceArea.FittingstationId,
      Region: serviceArea.Region,
    };
    console.log('DELETE - JSON Command delete fittingstation service area: ', JSON.stringify(command));
    return this.http
      .request('delete', url, { headers: this.headers, body: JSON.stringify(command) })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addPhoto(photo: Photo): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/addphototofittingstation`;
    const command = {
      FittingstationId: photo.FittingstationId,
      Name: photo.Name,
      PhotoType: 'Station',
      FileName: photo.FileName,
      FileSize: photo.FileSize,
      FileType: photo.FileType,
      B64EncodedFileContent: photo.FileContent,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add photo: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updatePhoto(photo: Photo): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PutPhoto?id=` + photo.Id;
    const command = {
      PhotoId: photo.Id,
      FittingstationId: photo.FittingstationId,
      Name: photo.Name,
      PhotoType: 'Station',
      FileName: photo.FileName,
      FileSize: photo.FileSize,
      FileType: photo.FileType,
      B64EncodedFileContent: photo.FileContent,
    };
    const stringified = JSON.stringify(command);

    console.log('PUT - JSON command update photo: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deletePhoto(photo: Photo): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/DeletePhoto?id=` + photo.Id;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateOpeningHour(openingHour: OpeningHour): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PutOpeningHours`;
    const command = {
      OpeningHoursId: openingHour.Id,
      FittingstationId: openingHour.FittingstationId,
      DayOfWeek: openingHour.DayOfWeek,
      TimeFrom: openingHour.TimeFrom,
      TimeTo: openingHour.TimeTo,
    };
    const stringified = JSON.stringify(command);
    console.log('PUT - JSON command add opening hour: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addOpeningHour(openingHour: OpeningHour): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/addopeninghourstofittingstation`;
    const command = {
      FittingstationId: openingHour.FittingstationId,
      DayOfWeek: openingHour.DayOfWeek,
      TimeFrom: openingHour.TimeFrom,
      TimeTo: openingHour.TimeTo,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add opening hour: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteOpeningHour(openingHourId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/DeleteOpeningHours?id=` + openingHourId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getOpeningHours(fittingstationId: number): Promise<OpeningHour[]> {
    const url = `${this.fittingstationUrl}/getallopeninghours?fittingstationId=${fittingstationId}`;
    return this.http
      .get<OpeningHour[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public updateOpeningHourException(item: OpeningHourException): Promise<ApiActionResult> {
    const url = `${this.openingHourExceptionUrl}`;
    const command = {
      Id: item.Id,
      FittingstationId: item.FittingstationId,
      ClosedWholePeriod: item.ClosedWholePeriod,
      Comment: item.Comment,
      Reason: item.Reason,
      TimeFrom: item.TimeFrom,
      TimeTo: item.TimeTo,
      StartDate: item.StartDate,
      EndDate: item.EndDate,
    };
    const stringified = JSON.stringify(command);
    console.log('PUT - JSON command add opening hour exception: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addOpeningHourException(item: OpeningHourException): Promise<ApiActionResult> {
    const url = `${this.openingHourExceptionUrl}`;
    const command = {
      FittingstationId: item.FittingstationId,
      ClosedWholePeriod: item.ClosedWholePeriod,
      Comment: item.Comment,
      Reason: item.Reason,
      TimeFrom: item.TimeFrom,
      TimeTo: item.TimeTo,
      StartDate: item.StartDate,
      EndDate: item.EndDate,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add opening hour exception: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteOpeningHourException(id: number): Promise<ApiActionResult> {
    const url = `${this.openingHourExceptionUrl}?id=` + id;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getOpeningHourExceptions(fittingstationId: number): Promise<OpeningHourException[]> {
    const url = `${this.openingHourExceptionUrl}/getallopeninghourexceptions?fittingstationId=${fittingstationId}`;
    return this.http
      .get<OpeningHourException[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getFittingstationsOfGroup(fittingstationId: number): Promise<Fittingstation[]> {
    const url = `${this.fittingstationUrl}/${fittingstationId}/group`;
    return this.http
      .get<Fittingstation[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public deleteFittingstationAdas(fittingstationAdasId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/DeleteFittingstationAdas?id=` + fittingstationAdasId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateFittingstationAdas(fittingstationAdas: Adas): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PutFittingstationAdas?id=` + fittingstationAdas.Id;
    const command = {
      FittingstationAdasId: fittingstationAdas.Id,
      FittingstationId: fittingstationAdas.FittingstationId,
      BrandId: fittingstationAdas.Brand.Id,
      ModelId: fittingstationAdas.Model.Id,
      Serialnumber: fittingstationAdas.Serialnumber,
    };

    console.log('PUT - JSON Command update fittingstation adas: ', JSON.stringify(command));
    return this.http
      .put(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addFittingstationAdas(fittingstationAdas: Adas): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PostFittingstationAdas?fittingstationId=` + fittingstationAdas.FittingstationId;
    const command = {
      BrandId: fittingstationAdas.Brand.Id,
      ModelId: fittingstationAdas.Model.Id,
      FittingstationId: fittingstationAdas.FittingstationId,
      Serialnumber: fittingstationAdas.Serialnumber,
    };

    console.log('POST - JSON Command add fittingstation adas: ', JSON.stringify(command));

    return this.http
      .post(url, JSON.stringify(command), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getAdas(id: number): Promise<Adas> {
    const url = `${this.fittingstationUrl}/GetFittingstationAdas?id=` + id;
    return this.http
      .get<Adas>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getAdasBrands(): Promise<AdasBrand[]> {
    const url = `${this.fittingstationUrl}/adasbrands`;
    return this.http
      .get<AdasBrand[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public createAdasDocument(document: Document, fittingstationAdasId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/PostFittingstationAdasDocument?id=` + fittingstationAdasId;
    const createCommand = {
      Number: document.Number,
      FittingstationAdasId: fittingstationAdasId,
      DocumentType: document.DocumentType,
      MetaDataFileName: document.FileMetaData.FileName,
      MetaDataFileType: document.FileMetaData.FileType,
      MetaDataFileSize: document.FileMetaData.FileSize,
      MetaDataReference: document.FileMetaData.Reference,
      UploadDate: new Date(),
    };
    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteAdasDocument(document: Document, fittingstationAdasId: number): Promise<ApiActionResult> {
    const url =
      `${this.fittingstationUrl}/DeleteFittingstationAdasDocument?id=` + document.Id + `&fittingStationAdasId=` + fittingstationAdasId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getBlacklistedAccounts(fittingStationId: number): Promise<BlackListedAccount[]> {
    console.log('FittingStationService.getBlackListedAccounts()');
    return this.http
      .get<BlackListedAccount[]>(this.fittingstationUrl + '/blockedAccounts?fittingStationId=' + fittingStationId)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getAvailableAccounts(fittingStationId: number): Promise<Account[]> {
    console.log('FittingstationService.getAvailableAccounts()');
    return this.http
      .get<Account[]>(this.fittingstationUrl + '/availableAccounts?fittingStationId=' + fittingStationId)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public blockAccount(blacklistedAccount: BlackListedAccount): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/blockAccount/?fittingStationId=${blacklistedAccount.FittingStationId}` +
    `&accountId=${blacklistedAccount.Id}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public unblockAccount(blacklistedAccount: BlackListedAccount): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/unblockAccount/?fittingStationId=${blacklistedAccount.FittingStationId}` +
    `&accountId=${blacklistedAccount.Id}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  // ********* AppointmentCapacity ****************

  public updateAppointmentCapacity(appointmentCapacity: AppointmentCapacity): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/putappointmentcapacity`;
    const command = {
      Id: appointmentCapacity.Id,
      FittingstationId: appointmentCapacity.FittingstationId,
      DayOfWeek: appointmentCapacity.DayOfWeek,
      AppointmentEventTypeId: appointmentCapacity.AppointmentEventType.Id,
      Morning: appointmentCapacity.Morning,
      Afternoon: appointmentCapacity.Afternoon,
    };
    const stringified = JSON.stringify(command);
    console.log('PUT - JSON command add opening hour: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addAppointmentCapacity(appointmentCapacity: AppointmentCapacity): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/addappointmentcapacity`;
    const command = {
      FittingstationId: appointmentCapacity.FittingstationId,
      DayOfWeek: appointmentCapacity.DayOfWeek,
      AppointmentEventTypeId: appointmentCapacity.AppointmentEventType.Id,
      Morning: appointmentCapacity.Morning,
      Afternoon: appointmentCapacity.Afternoon,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add opening hour: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteAppointmentCapacity(appointmentCapacityId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/deleteappointmentcapacity?id=` + appointmentCapacityId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getAppointmentCapacities(fittingstationId: number): Promise<AppointmentCapacity[]> {
    const url = `${this.fittingstationUrl}/getallappointmentcapacities?fittingstationId=${fittingstationId}`;
    return this.http
      .get<AppointmentCapacity[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  // ********* AppointmentCapacityException ****************

  public updateAppointmentCapacityException(appointmentCapacityException: AppointmentCapacityException): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/putappointmentcapacityexception`;
    const command = {
      Id: appointmentCapacityException.Id,
      FittingstationId: appointmentCapacityException.FittingstationId,
      Week: appointmentCapacityException.Week,
      Year: appointmentCapacityException.Year,
      DayOfWeek: appointmentCapacityException.DayOfWeek,
      AppointmentEventTypeId: appointmentCapacityException.AppointmentEventType.Id,
      Morning: appointmentCapacityException.Morning,
      Afternoon: appointmentCapacityException.Afternoon,
      ExceptionReasonId: appointmentCapacityException.Reason.Id,
      ReasonOther: appointmentCapacityException.ReasonOther,
    };
    const stringified = JSON.stringify(command);
    console.log('PUT - JSON command add opening hour: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addAppointmentCapacityException(appointmentCapacityException: AppointmentCapacityException): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/addappointmentcapacityexception`;
    const command = {
      FittingstationId: appointmentCapacityException.FittingstationId,
      DayOfWeek: appointmentCapacityException.DayOfWeek,
      Week: appointmentCapacityException.Week,
      Year: appointmentCapacityException.Year,
      AppointmentEventTypeId: appointmentCapacityException.AppointmentEventType.Id,
      Morning: appointmentCapacityException.Morning,
      Afternoon: appointmentCapacityException.Afternoon,
      ExceptionReasonId: appointmentCapacityException.Reason.Id,
      ReasonOther: appointmentCapacityException.ReasonOther,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add opening hour: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteAppointmentCapacityException(appointmentCapacityExceptionId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/deleteappointmentcapacityexception?id=` + appointmentCapacityExceptionId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getAppointmentCapacityExceptions(fittingstationId: number): Promise<AppointmentCapacityException[]> {
    const url = `${this.fittingstationUrl}/getallappointmentcapacityexceptions?fittingstationId=${fittingstationId}`;
    return this.http
      .get<AppointmentCapacityException[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getAppointmentEventTypes(): Promise<AppointmentEventType[]> {
    const url = `${this.fittingstationUrl}/getallappointmenteventtypes`;
    return this.http
      .get<AppointmentEventType[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getAppointmentCapacityExceptionReasons(): Promise<AppointmentCapacityExceptionReason[]> {
    const url = `${this.fittingstationUrl}/getallappointmentcapacityexceptionreasons`;
    return this.http
      .get<AppointmentCapacityExceptionReason[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  // Returns true if the fitting station can do Mobile Jobs
  public FittingStationHasMobileService(fittingStation: Fittingstation) {
    const hasMobileServices =
      fittingStation.FittingstationServices && fittingStation.FittingstationServices.findIndex(f => f.ServiceType === 'MobileUnit') > -1;
    const isMobileFittingStationType = fittingStation.FittingstationType === 'MobileServiceExternal';
    return hasMobileServices || isMobileFittingStationType;
  }

  // Returns true if the fitting station only performs mobile jobs
  public FittingStationIsMobileOnly(fittingStation: Fittingstation) {
    const isMobileFittingStationType = fittingStation.FittingstationType === 'MobileServiceExternal';
    return isMobileFittingStationType;
  }

  // ********* AppointmentLogisticsTime ****************

  public updateAppointmentLogisticsTime(appointmentLogisticsTime: AppointmentLogisticsTime): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/putappointmentlogisticstime`;
    const command = {
      Id: appointmentLogisticsTime.Id,
      FittingstationId: appointmentLogisticsTime.FittingstationId,
      AppointmentEventTypeId: appointmentLogisticsTime.AppointmentEventType.Id,
      LogisticsTime: appointmentLogisticsTime.LogisticsTime,
    };
    const stringified = JSON.stringify(command);
    console.log('PUT - JSON command add logistics time: ', stringified);
    return this.http
      .put(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public addAppointmentLogisticsTime(appointmentLogisticsTime: AppointmentLogisticsTime): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/addappointmentlogisticstime`;
    const command = {
      FittingstationId: appointmentLogisticsTime.FittingstationId,
      AppointmentEventTypeId: appointmentLogisticsTime.AppointmentEventType.Id,
      LogisticsTime: appointmentLogisticsTime.LogisticsTime,
    };
    const stringified = JSON.stringify(command);
    console.log('POST - JSON command add logistics time: ', stringified);
    return this.http
      .post(url, stringified, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public deleteAppointmentLogisticsTime(appointmentLogisticsTimeId: number): Promise<ApiActionResult> {
    const url = `${this.fittingstationUrl}/deleteappointmentlogisticstime?id=` + appointmentLogisticsTimeId;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getAppointmentLogisticTimes(fittingstationId: number): Promise<AppointmentLogisticsTime[]> {
    const url = `${this.fittingstationUrl}/getallappointmentlogisticstimes?fittingstationId=${fittingstationId}`;
    return this.http
      .get<AppointmentLogisticsTime[]>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }
}
