import { SizePipe } from '@hrz/shared/pipes/size.pipe';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTableModule } from 'angular2-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TreeModule, RatingModule, SharedModule as primengSharedModule } from 'primeng/primeng';

import '@progress/kendo-ui/js/kendo.scheduler.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.en-GB.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.pt-PT.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.es-ES.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.it-IT.js';

import { SpinnerComponent } from '@hrz/shared/components/spinner/spinner.component';
import { StructureTreeComponent } from '@hrz/shared/components/structure-tree/structure-tree.component';
import { DisableControlDirective } from '@hrz/shared/directives/disable-control.directive';
import { SchedulerComponent } from '@hrz/shared/components/scheduler/scheduler.component';
import { SearchInputComponent } from '@hrz/shared/components/search-input/search-input.component';
import { UploadButtonComponent } from '@hrz/shared/components/upload-button/upload-button.component';
import { PhotoListComponent } from '@hrz/shared/components/photo-list/photo-list.component';
import { PhotoItemComponent } from '@hrz/shared/components/photo-list/photo-item.component';
import { AvailabilityFieldComponent } from '@hrz/shared/components/availability-field/availability-field.component';
import { PipesModule } from '@hrz/shared/pipes/pipes.module';
import { MoneyInput } from '@hrz/shared/money-input.component';

import { ValidationFormControlComponent } from '@hrz/shared/components/validation-form-control/validation-form-control.component';
import { ValidateOnBlurDirective } from '@hrz/shared/directives/validate-on-blur.directive';
import { SpinnerModule } from '@hrz/shared/spinner.module';
import { RequiresVatDivertDirective } from '@hrz/shared/directives/requires-vatdivert.directive';
import { AlertModule, BsDatepickerModule, TooltipModule } from 'ngx-bootstrap';
import { DossierSearchComponent } from '@hrz/shared/components/dossier-search-component/dossier-search-component.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';

import { EditDossierAppointmentComponent } from '../features/dossiers/modals/edit-dossier-appointment/edit-dossier-appointment.component';
import { FittingstationOpeningHourList } from '../features/fittingstations/components/fittingstation-opening-hour.list';
import { FittingstationServiceList } from '../features/fittingstations/components/fittingstation-service.list';
import { FormFileUploaderComponent } from './components/form-file-uploader/form-file-uploader.component';
import { FormUploadButtonComponent } from './components/form-upload-button/form-upload-button.component';
import { ManageDocumentComponent } from './components/manage-document/manage-document.component';
import { SharedComponentModule } from './sharedComponent.module';
import { ScrollSpyDirective } from './directives/scroll-spy.directive';
import { FileListComponent } from '../features/dossiers/components/instruction/file-list/file-list.component';
import { TinymceTemplateEditorComponent } from './components/tinymce/tinymce-template-editor.component';

@NgModule({
  imports: [
    FormsModule,
    SpinnerModule,
    BsDropdownModule.forRoot(),
    CommonModule,
    DataTableModule,
    PipesModule,
    primengSharedModule,
    RatingModule,
    ReactiveFormsModule,
    TabsModule.forRoot(),
    TranslateModule.forChild(),
    BsDatepickerModule.forRoot(),
    TreeModule,
    SelectDropDownModule,
    TooltipModule,
    AlertModule.forRoot(),
    SharedComponentModule
  ],

  declarations: [
    AvailabilityFieldComponent,
    DisableControlDirective,
    FormFileUploaderComponent,
    FormUploadButtonComponent,
    ManageDocumentComponent,
    MoneyInput,
    PhotoItemComponent,
    PhotoListComponent,
    RequiresVatDivertDirective,
    SchedulerComponent,
    SearchInputComponent,
    SizePipe,
    SpinnerComponent,
    StructureTreeComponent,
    UploadButtonComponent,
    ValidateOnBlurDirective,
    ValidationFormControlComponent,
    EditDossierAppointmentComponent,
    DossierSearchComponent,
    FittingstationServiceList,
    FittingstationOpeningHourList,
    ScrollSpyDirective,
    FileListComponent,
    TinymceTemplateEditorComponent
  ],
  exports: [
    ModalModule,
    CommonModule,
    TabsModule,
    FormsModule,
    BsDropdownModule,
    ReactiveFormsModule,
    SpinnerModule,
    DataTableModule,
    DisableControlDirective,
    RequiresVatDivertDirective,
    ValidateOnBlurDirective,
    SizePipe,
    MoneyInput,
    AvailabilityFieldComponent,
    FormFileUploaderComponent,
    FormUploadButtonComponent,
    ManageDocumentComponent,
    PhotoItemComponent,
    PhotoListComponent,
    SchedulerComponent,
    SearchInputComponent,
    SpinnerComponent,
    StructureTreeComponent,
    UploadButtonComponent,
    ValidationFormControlComponent,
    EditDossierAppointmentComponent,
    DossierSearchComponent,
    FittingstationOpeningHourList,
    FittingstationServiceList,
    ScrollSpyDirective,
    SharedComponentModule,
    FileListComponent,
    TinymceTemplateEditorComponent
  ],
  providers: [
  ],
})
export class SharedModule {}
