import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { HttpStatus } from '@hrz/core/models/http-status';
import { Message } from '@hrz/core/models/messagecenter/message';
import { MessageService } from '@hrz/core/services/message.service';
import { TemplateMappingService } from '@hrz/core/services/template-mapping-service';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { emailValidator } from '@hrz/core/utils/email.validator';

@Component({
  selector: 'send-to-email',
  templateUrl: './send-to-email.component.html',
  styleUrls: ['./send-to-email.component.scss']
})
export class SendToEmailComponent implements OnInit {
  @Input() attachments: number[];
  @Input() set email(value: string) {
    this.emailFormControl.setValue(value);
  }
  get email(): string {
    return this.emailFormControl.value;
  }
  @Input() sender: string;
  @Input() subject: string;
  @Input() emailAddressIsReadOnly = false;
  @Input() overrideSendEmail: (email: string) => Promise<boolean>;

  emailFormControl = new FormControl('');
  get hasValidEmailAddress(): boolean {
    return !!this.emailFormControl.value && this.emailFormControl.valid;
  }
  isProcessing = false;
  templateId: number;

  constructor(
    private toasterService: ToasterService,
    private messageService: MessageService,
    private templateMappingService: TemplateMappingService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.emailFormControl.setValue(this.email);
    this.emailFormControl.setValidators([emailValidator]);

    this.handleErrors(async () => {
      this.templateId = await this.templateMappingService.getTemaplteId('EmailDocument').toPromise();
      console.log('SendToMail.ngOninit - templateId', this.templateId);
    });
  }

  private inputIsValid(): boolean {
    let inputIsValid = true;

    const validateValue = (name: keyof SendToEmailComponent, required: boolean = true) => {
      const value: any = this[name];
      if (!value) {
        (required ? console.error : console.warn)('SendToMail.' + name + ' ' + (required ? 'should be' : 'is not') + 'defined');
        if (required) { inputIsValid = false; }
      }
    };

    validateValue('email');
    validateValue('subject', false);
    validateValue('templateId');

    return inputIsValid;
  }

  async sendMail(): Promise<void> {
    let success: boolean;

    try {
      this.isProcessing = true;
      if (!!this.overrideSendEmail) {
        success = await this.overrideSendEmail(this.email);
      } else {
        await this.handleErrors(async () => {
          if (!this.inputIsValid()) {
            throw new Error('SendToMail has invalid input parameters. See console for more information.');
          }
          const message = this.getMessage();
          const result = await this.messageService.send(message).toPromise();
          success = result.status === HttpStatus.ok;
        });
      }
    } finally {
      this.isProcessing = false;
    }

    this.handleResult(success);
  }

  private async handleErrors(method: () => Promise<void>): Promise<void> {
    try {
      await method();
    } catch (error) {
      console.error('Failed sending email from SendToMailComponent', error);
      this.alertSendingFailed();
    }
  }

  private handleResult(success: boolean): void {
    if (success) {
      const msg = this.translateService.instant('COMMON.SENDING_MAIL_SUCCEEDED');
      this.toasterService.showSuccess(msg);
      this.emailFormControl.reset();
    } else {
      this.alertSendingFailed();
    }
  }

  private alertSendingFailed() {
    this.toasterService.showError(this.translateService.instant('COMMON.SENDING_MAIL_FAILED'));
  }

  private getMessage(): Message {
    return {
      Attachments: this.attachments,
      DomainData: '{}',
      Recipient: this.emailFormControl.value,
      Sender: this.sender,
      SourceIdentifier: 'SendToEmail',
      Subject: this.subject,
      TemplateMappingId: this.templateId,
    };
  }
}
