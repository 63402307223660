import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { FittingstationService } from '@hrz/core/models/fittingstation-service';
import { EnumService } from '@hrz/core/services/enum.service';

@Component({
  selector: 'fittingstation-service-list',
  template: `
    <table
      class="table table-hover"
      [mfData]="items | dataFilter: filterText"
      #mf="mfDataTable"
      [mfRowsOnPage]="rowsOnPage"
      [(mfSortBy)]="sortBy"
      [(mfSortOrder)]="sortOrder">
      <thead class="table-inverse">
        <tr>
          <th width="10%">
            <mfDefaultSorter by="Id">{{ 'FITTINGSTATIONS.SERVICES.LIST.COLUMN_ID' | translate }}</mfDefaultSorter>
          </th>
          <th>
            <mfDefaultSorter by="ServiceType">{{ 'FITTINGSTATIONS.SERVICES.LIST.COLUMN_SERVICE_TYPE' | translate }}</mfDefaultSorter>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of mf.data; let i = index" (click)="selectItem(item, i)" [class.active]="i === selectedRowIndex">
          <td width="10%">{{ item.Id }}</td>
          <td>
            {{ item.ServiceType }} <span *ngIf="item.Value">({{ item.Value }})</span>
          </td>
        </tr>
        <tr *ngIf="!items || items.length === 0" valign="center">
          <td colspan="2" align="center">{{ 'FITTINGSTATIONS.SERVICES.LIST.NO_SERVICES_DEFINED' | translate }}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="2">
            <mfBootstrapPaginator [rowsOnPageSet]="[5, 10, 15]"></mfBootstrapPaginator>
          </td>
        </tr>
      </tfoot>
    </table>
  `,
})
export class FittingstationServiceList implements OnInit {
  @Input() items: FittingstationService[] = [];
  @Input() selectedItem: FittingstationService;
  @Input() filterText: '';
  @Output() onSelectItem: EventEmitter<FittingstationService> = new EventEmitter<FittingstationService>();
  @Input() rows;

  rowsOnPage: number;
  sortBy = 'ServiceType';
  sortOrder = 'asc';
  selectedRowIndex;

  constructor(private enumService: EnumService) {}

  ngOnInit(): void {
    console.log('FittingstationList.ngOnInit()');
    this.rowsOnPage = this.rows || 10;
  }

  ngOnChanges() {
    if (this.items && this.items.length) {
      this.enumService.getServiceTypeEnums().then(serviceTypes => {
        this.items.forEach(itemToTranslate => {
          const instanceFound = serviceTypes.find(service => service.Key.toLowerCase() === itemToTranslate.ServiceType.toLowerCase());
          if (instanceFound) {
            itemToTranslate.ServiceType = instanceFound.Value;
          }
        });
      });
    }
  }

  selectItem(entity: any, index: number): void {
    console.log('FittingstationList.selectItem()', entity, index);
    this.selectedRowIndex = index;
    this.selectedItem = entity;
    this.onSelectItem.emit(entity);
  }
}
