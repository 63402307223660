import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss'],
})
export class UploadButtonComponent {
  @Input() accept: string;
  @Input() disabled = false;
  @Input() text: string;
  @Output() onChange: EventEmitter<File> = new EventEmitter<File>();

  change(file: File) {
    // TODO: should validate if type of file is in acceptance list
    this.onChange.emit(file);
  }
}
