import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { AuthService } from '@hrz/core/services/auth.service';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class HiddenFieldService {
  private baseUrl = ConfigurationManager.AppSettings.organizationApi;
  private legalEntityHiddenFieldUrl = this.baseUrl + '/legalentityhiddenfield';
  private hiddenFields: string[] = undefined;

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: HiddenFieldService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('HiddenFieldService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getHiddenFieldsForCurrentLegalEntity(): Promise<string[]> {
    if (this.hiddenFields) { return Promise.resolve(this.hiddenFields); }

    return this.authService
      .getTenantId()
      .toPromise()
      .then(tenantId => {
        return this.http
          .get<any>(this.legalEntityHiddenFieldUrl + '?legalentityid=' + tenantId)
          .toPromise()
          .then(response => {
            this.hiddenFields = response.map(item => item.FieldName);
            return this.hiddenFields;
          })
          .catch(this.serviceActionHandler.handleDefaultError);
      });
  }

  public showField(fieldName: string): boolean {
    return this.hiddenFields && !this.hiddenFields.includes(fieldName);
  }
}
