import { Component, ContentChild, AfterContentInit, OnDestroy, Input, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

import { stringFormat } from '@hrz/core/utils/string-format';
import { ValidationFormControlService } from '@hrz/core/services/validation-form-control.service';

@Component({
  selector: 'app-validation-form-control',
  templateUrl: 'validation-form-control.component.html',
})
export class ValidationFormControlComponent implements OnInit, AfterContentInit, OnDestroy {
  @Input() fieldName: string;
  @Input() customErrorMessages: { [key: string]: string } = {};
  @ContentChild(NgControl) control: NgControl;

  hasError = false;
  predefinedErrorMessages: { [key: string]: string };
  errorMessage = '';
  statusSubscription: Subscription = Subscription.EMPTY;

  constructor(private validationFormControlService: ValidationFormControlService) {}

  ngOnInit() {
    this.predefinedErrorMessages = this.validationFormControlService.getErrorMessages();
  }

  ngAfterContentInit() {
    if (this.control) {
      this.statusSubscription = this.control.statusChanges.subscribe(() => {
        this.statusChanged();
      });
    }
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
  }

  private statusChanged(): void {
    const errors: { [key: string]: string } = this.control.errors;
    const isFormDirty = this.control.dirty || this.control.touched;

    this.hasError = !!errors;
    this.errorMessage = '';

    if (this.hasError && isFormDirty) {
      this.setErrorMessage(errors);
    }
  }

  private setErrorMessage(errors: { [key: string]: string }): void {
    const customErrorKey = this.getCustomErrorKey(errors);

    this.errorMessage = customErrorKey != null ? this.customErrorMessages[customErrorKey] : this.getDefaultErrorMessage(errors);
  }

  private getCustomErrorKey(errors: { [key: string]: string }): string {
    return Object.keys(errors).find(errorKey => this.customErrorMessages[errorKey] != null);
  }

  private getDefaultErrorMessage(errors: { [key: string]: string | { [key: string]: Date } }): string {
    if (errors['required']) {
      return this.predefinedErrorMessages['required'];
    }
    if (errors['whitespace']) {
      return this.predefinedErrorMessages['whitespace'];
    }

    if (errors['invalidDate']) {
      const invalidDate = errors['invalidDate'];
      const dateFormat = 'MM/DD/YYYY';

      if (invalidDate['fromDate']) {
        return stringFormat(this.predefinedErrorMessages['invalidDateFrom'], moment(invalidDate['fromDate']).format(dateFormat));
      }
      if (invalidDate['toDate']) {
        return stringFormat(this.predefinedErrorMessages['invalidDateTo'], moment(invalidDate['toDate']).format(dateFormat));
      }
    }

    return '';
  }
}
