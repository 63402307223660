import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export const emailValidator = ((): ValidatorFn => {
  return (c: AbstractControl): ValidationErrors | null => {
    const emailRegExp = new RegExp(
      // tslint:disable-next-line
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!!c.value && !emailRegExp.test(c.value)) {
      return {
        address: 'is not valid',
      };
    }
    return null;
  };
})();
