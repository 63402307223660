import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { StructureCreate } from '@hrz/core/models/document/structure-create';
import { FormHelpers } from '@hrz/core/utils/helpers';

@Component({
  selector: 'tm-structure-create',
  templateUrl: './structure-create.component.html',
})
export class StructureCreateComponent implements OnInit {
  @Input() structureCreateModel: StructureCreate;
  structureCreateForm: FormGroup;

  constructor() {
    this.structureCreateForm = new FormGroup({
      path: new FormControl({ value: '', disabled: true }, Validators.required),
      name: new FormControl('', Validators.required),
    });
  }

  ngOnInit() {
    this.structureCreateForm.patchValue({
      path: this.structureCreateModel.path,
    });
    FormHelpers.markAsPristine(this.structureCreateForm);
  }

  isValid(): boolean {
    return this.structureCreateForm.valid;
  }

  isDirty(): boolean {
    return this.structureCreateForm.dirty;
  }

  getModel(): StructureCreate {
    return {
      parentId: this.structureCreateModel.parentId,
      path: this.structureCreateModel.path,
      name: this.structureCreateForm.get('name').value,
    };
  }
}
