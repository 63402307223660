import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'size',
})
export class SizePipe implements PipeTransform {
  transform(bytes: number, precision: number): string {
    if (bytes <= 0) {
      return `0${this.getPrecision(precision)} B`;
    }
    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const unitIdx = Math.floor(Math.log(bytes) / Math.log(1024));
    const value = bytes / Math.pow(1024, unitIdx);
    return `${value.toFixed(precision)} ${units[unitIdx]}`;
  }

  private getPrecision(precision): string {
    return precision <= 0 ? '' : `.${new Array(precision + 1).join('0')}`;
  }
}
