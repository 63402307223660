import { AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

export class BaseActionModal implements AfterViewInit {
  childModal: ModalDirective;
  protected closeModal: Function;

  _loading = false;
  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this.shouldSetClickOutsideDisabled(value);
    this._loading = value;
  }

  ngAfterViewInit(): void {
    this.childModal.show();
  }

  shouldSetClickOutsideDisabled = (disabled: boolean) => {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: disabled ? 'static' : true,
      keyboard: !disabled,
    };
  }
}
