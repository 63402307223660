import { throwError as observableThrowError, of as observableOf, Observable } from 'rxjs';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ValidationError } from '@hrz/core/models/validation-error';
import { ValidationHelpers } from '../utils/helpers';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { ErrorObservable } from 'rxjs/observable/ErrorObservable';
import { HttpStatus } from '@hrz/core/models/http-status';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class CommandActionService {
  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: CommandActionService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('CommandActionService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  handlePromiseAction<T>(result: ApiActionResult<T>, displaySuccess: boolean = true, displayFailure: boolean = true): Promise<T> {
    return this.handleAction(result, displaySuccess, displayFailure).toPromise();
  }

  handleAction<T>(result: ApiActionResult<T>, displaySuccess: boolean = true, displayFailure: boolean = true): Observable<T> {
    switch (result.status) {
      case HttpStatus.ok:
        return this.handleSuccess(result, displaySuccess);
      default:
        return this.handleFailure(result, displayFailure);
    }
  }

  private handleSuccess<T>(result: ApiActionResult<T>, display: boolean): Observable<T> {
    if (display) {
      if (result.commandHasValidationWarnings) {
        this.displayWarnings(result);
      } else {
        this.displaySuccess();
      }
    }
    return observableOf(result.entity);
  }

  private handleFailure<T>(result: ApiActionResult<T>, display: boolean): ErrorObservable<T> {
    if (this.hasCommandValidations(result.commandValidations)) {
      if (display) {
        this.displayCommandValidations(result.commandValidations);
      }
      return observableThrowError(result.commandValidations);
    }

    if (display) {
      this.displayApiActionResultError(result);
    }
    return observableThrowError([]);
  }

  private hasCommandValidations(commandValidations: ValidationError[]): boolean {
    return commandValidations != null && commandValidations.length > 0;
  }

  public displaySuccess(): void {
    this.toasterService.showSuccess(this.translateService.instant('COMMON.COMMAND.SUCCESS'));
  }

  public displayWarnings(result: ApiActionResult): void {
    if (result.commandHasValidationWarnings) {
      this.displayCommandValidationWarnings(result.commandWarnings);
    }
  }

  private displayApiActionResultError(result: ApiActionResult): void {
    this.toasterService.showError(result);
  }

  public displayCommandValidations(commandValidations: ValidationError[]): void {
    const itemsWithoutAssignedFields = ValidationHelpers.extractNotMapped(commandValidations);
    const itemsWithAssignedFields = ValidationHelpers.extractMapped(commandValidations);

    if (itemsWithoutAssignedFields.length > 0) {
      this.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED'), itemsWithoutAssignedFields);
    } else if (itemsWithAssignedFields.length > 0) {
      this.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED'), itemsWithAssignedFields);
    } else {
      this.toasterService.showError(this.translateService.instant('COMMON.COMMAND.FAILED_GENERAL'));
    }
  }

  private displayCommandValidationWarnings(commandValidations: ValidationError[]): void {
    const itemsWithoutAssignedFields = ValidationHelpers.extractNotMapped(commandValidations);
    const itemsWithAssignedFields = ValidationHelpers.extractMapped(commandValidations);

    if (itemsWithoutAssignedFields.length > 0) {
      this.toasterService.showWarning(this.translateService.instant('COMMON.COMMAND.WARNING'), itemsWithoutAssignedFields);
    } else if (itemsWithAssignedFields.length > 0) {
      this.toasterService.showWarning(this.translateService.instant('COMMON.COMMAND.WARNING'), itemsWithAssignedFields);
    } else {
      this.toasterService.showWarning(this.translateService.instant('COMMON.COMMAND.WARNING'));
    }
  }
}
