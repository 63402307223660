import { catchError, map } from 'rxjs/operators';
import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { Message } from '@hrz/core/models/messagecenter/message';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private apiUrl =  ConfigurationManager.AppSettings.messagingApi + '/messages';
  private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: MessageService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('MessageService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public send(message: Message): Observable<ApiActionResult> {
    console.log('MessageService.send()', message);
    return this.http.post(this.apiUrl, message, { headers: this.httpHeaders }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }
}
