export class HttpStatus {
  static readonly ok: number = 200;
  static readonly created: number = 201;

  static readonly badRequest: number = 400;
  static readonly unauthorized: number = 401;
  static readonly forbidden: number = 403;
  static readonly notFound: number = 404;

  static readonly internalServerError: number = 500;
}
