import { DomainProperty } from '@hrz/core/models/template/domain-property';
import { PropertyFormattingPlugin } from './plugins/property-formatting.plugin';
import { ResourcePlugin } from './plugins/resource.plugin';
import UserDefinedLanguages from '../../../../assets/i18n/tinymce/languages';
const noop = () => {}; // does nothing. Signals that no operation is required

import { Component, OnDestroy, AfterViewInit, EventEmitter, Input, Output, forwardRef, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tm-tiny-template-editor',
  template: `<textarea id="{{ elementId }}"></textarea>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TinymceTemplateEditorComponent),
      multi: true,
    },
  ],
})
export class TinymceTemplateEditorComponent implements AfterViewInit, OnDestroy, ControlValueAccessor, OnChanges {
  editor;
  editorDomainPropertyMenu;

  @Input() domainDataKey = '';
  @Input() domainDataProperties: DomainProperty[];
  @Input() elementId: String;
  @Input() height = 300;
  @Output() onEditorKeyup = new EventEmitter<any>();

  get value(): any {
    return this._value;
  }

  @Input()
  set value(value: any) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  // using '_value' because variable 'value' is already defined by get()/set()
  private _value = '';

  // Callback registered via registerOnChange (ControlValueAccessor)
  private onChangeCallback: (_: any) => void = noop;

  constructor(private translateService: TranslateService) {}

  ngOnChanges() {
    if (!this.domainDataProperties) {
      return;
    }
    this.refreshDomainPropertiesMenu();
  }

  refreshDomainPropertiesMenu() {
    const items = this.domainDataProperties.map(item => {
      return {
        text: item.displayName,
        onClick: () => {
          this.editor.insertContent(`{{${this.domainDataKey}.${item.propertyName}}}`);
        },
      };
    });

    if (this.editorDomainPropertyMenu) {
      this.editorDomainPropertyMenu.control.state.set('menu', items);
    }
  }

  ngAfterViewInit() {
    this.initializeTinyMCE();

    if (this.value) {
      tinymce.activeEditor.setContent(this.value);
    }
  }

  ngOnDestroy() {
    tinymce.remove(this.editor);
  }

  /**
   * Takes a new value from the form model and writes it into the view, and initializes rich text editor with same value.
   *
   * @param value
   */
  writeValue(value: string) {
    if (value) {
      this.value = value;
      /*GDES72: to solve the bug that assign the "value" of the control (n+1) to the control (n) if more the on controls are present in the page (form)
      if (tinymce.activeEditor)
      {
        tinymce.activeEditor.setContent(this.value);
      }
      */

      if (tinymce.activeEditor && this.elementId === tinymce.activeEditor.id) {
        tinymce.activeEditor.setContent(this.value);
      }
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(_fn: any) {}

  setDisabledState?(_isDisabled: boolean): void {}

  private initializeTinyMCE() {
    this.applyPlugins();
    this.setLanguage(this.translateService.currentLang);

    // GDES-72 (to not show menu bar in "view" mode)
    if (this.elementId.includes('View')) {
      tinymce.init({
        readonly: 1,
        menubar: false,
        statusbar: false,
        toolbar: false,

        selector: '#' + this.elementId,
        height: this.height,
        plugins: [
          'link',
          'paste',
          'table',
          'code',
          'preview',
          'advlist',
          'noneditable',
          'textcolor',
          'colorpicker',
          'autoresize',
          'PropertyFormattingPlugin',
          'ResourcePlugin',
        ],
        menu: {
          file: { title: 'File', items: 'newdocument' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
          insert: { title: 'Insert', items: 'link resource' },
          view: { title: 'View', items: 'visualaid' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },
          table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
          tools: { title: 'Tools', items: 'spellchecker code' },
        },
        autoresize_bottom_margin: 20,
        toolbar1: `formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor ` + 
        `| link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent advlist ` +
        `| removeformat | code | preview | domainPropertySelector | propertyFormatting`,
        extended_valid_elements: 'resource[class|height|width|name|style|id]',
        custom_elements: '~resource',
        skin_url: 'assets/skins/lightgray',
        setup: editor => {
          this.editor = editor;
          editor.on('keyup', () => {
            const content = editor.getContent();
            this.onEditorKeyup.emit(content);
            this.value = content;
          });
          editor.addButton('domainPropertySelector', {
            text: tinymce.i18n.translate('DomainPropertySelector.Title'),
            type: 'menubutton',
            icon: false,
            menu: [
              {
                text: tinymce.i18n.translate('DomainPropertySelector.Menu.Text'),
                tooltip: tinymce.i18n.translate('DomainPropertySelector.Menu.Tooltip'),
              },
            ],
            onPostRender: plugin => {
              this.editorDomainPropertyMenu = plugin;
            },
          });
        },
        branding: false,
        fontsize_formats: '8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt',
      });
    } else {
      tinymce.init({
        selector: '#' + this.elementId,
        height: this.height,

        plugins: [
          'link',
          'paste',
          'table',
          'code',
          'preview',
          'advlist',
          'noneditable',
          'textcolor',
          'colorpicker',
          'autoresize',
          'PropertyFormattingPlugin',
          'ResourcePlugin',
        ],
        autoresize_bottom_margin: 20,
        menu: {
          file: { title: 'File', items: 'newdocument' },
          edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall' },
          insert: { title: 'Insert', items: 'link resource' },
          view: { title: 'View', items: 'visualaid' },
          format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript | formats | removeformat' },
          table: { title: 'Table', items: 'inserttable tableprops deletetable | cell row column' },
          tools: { title: 'Tools', items: 'spellchecker code' },
        },
        toolbar: `formatselect | fontselect | fontsizeselect | bold italic strikethrough forecolor backcolor ` + 
        `| link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent advlist ` + 
        `| removeformat | code | preview | domainPropertySelector | propertyFormatting`,
        extended_valid_elements: 'resource[class|height|width|name|style|id]',
        custom_elements: '~resource',
        skin_url: 'assets/skins/lightgray',
        setup: editor => {
          this.editor = editor;
          editor.on('keyup', () => {
            const content = editor.getContent();
            this.onEditorKeyup.emit(content);
            this.value = content;
          });
          editor.addButton('domainPropertySelector', {
            text: tinymce.i18n.translate('DomainPropertySelector.Title'),
            type: 'menubutton',
            icon: false,
            menu: [
              {
                text: tinymce.i18n.translate('DomainPropertySelector.Menu.Text'),
                tooltip: tinymce.i18n.translate('DomainPropertySelector.Menu.Tooltip'),
              },
            ],
            onPostRender: plugin => {
              this.editorDomainPropertyMenu = plugin;
            },
          });
        },
        branding: false,
        fontsize_formats: '8pt 9pt 10pt 12pt 14pt 18pt 24pt 36pt',
      });
    }
  }

  private setLanguage(language: string): void {
    tinymce.addI18n(language, UserDefinedLanguages[language]);
    tinymce.i18n.setCode(language);
  }

  private applyPlugins(): void {
    tinymce.PluginManager.add('PropertyFormattingPlugin', PropertyFormattingPlugin);
    tinymce.PluginManager.add('ResourcePlugin', ResourcePlugin);
  }
}
