export const ResourcePlugin = function (editor: any, _url: string) {
  editor.addMenuItem('resource', {
    text: translate('Title'),
    icon: 'template',
    onclick: () => {
      editor.windowManager.open({
        title: translate('InsertForm_Title'),
        body: [
          {
            type: 'textbox',
            name: 'height',
            label: translate('InsertForm_Height_Label'),
            placeholder: translate('InsertForm_Height_Placeholder'),
          },
          {
            type: 'textbox',
            name: 'width',
            label: translate('InsertForm_Width_Label'),
            placeholder: translate('InsertForm_Width_Placeholder'),
          },
          {
            type: 'textbox',
            name: 'name',
            label: translate('InsertForm_Name_Label'),
            placeholder: translate('InsertForm_Name_Placeholder'),
          },
        ],
        onsubmit: event => {
          if (isInputValid(event.data)) {
            editor.insertContent(getResourceHtml(event.data));
          } else {
            editor.windowManager.alert(translate('InsertForm_ValidationError'));
          }
        },
      });
    },
  });
};

const isInputValid = ({ width, height, name }) => {
  return Number.isInteger(+width) && width > 0 && Number.isInteger(+height) && height > 0 && name;
};

const getResourceHtml = ({ width, height, name }) => {
  return `<resource
            class="mceNonEditable"
            height="${height}"
            width="${width}"
            name="${name}"
            id="${name}"
            style="background-color: #d0d4db;
              margin: 0 2px;
              width: ${width}px;
              height: ${height}px;
              display: inline-block;
              text-align: center;
              line-height: ${height}px;">${name}
          </resource>`;
};

const translate = (key: string) => tinymce.i18n.translate(`ResourcePlugin_${key}`);
