import { filter as lodashFilter } from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dataFilter',
})
export class DataFilterPipe implements PipeTransform {
  transform(array: any[], query: string, specificProperties: string): any {
    // Check if we have a query
    if (query) {
      return lodashFilter(array, function (item) {
        // Loop trough all properties in the item
        for (const property in item) {
          if (item.hasOwnProperty(property) && (specificProperties == null || specificProperties.includes(property))) {
            const currentValue = item[property];
            // Compare on normal properties
            if (currentValue && currentValue.toString().toLowerCase().includes(query.toString().toLowerCase())) {
              return true;
            }
            // Check if it's a Address
            if (currentValue && property.toString().toLowerCase().includes('address')) {
              // Loop trough address property
              for (const addressProperty in currentValue) {
                if (currentValue.hasOwnProperty(addressProperty)) {
                  if (
                    currentValue[addressProperty] &&
                    currentValue[addressProperty].toString().toLowerCase().includes(query.toString().toLowerCase())
                  ) {
                    return true;
                  }
                }
              }
            }
          }
        }

        return false;
      });
    }
    return array;
  }
}
