import { map, filter } from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';

@Component({
  templateUrl: './conversion-progress-modal.component.html',
  styleUrls: ['./conversion-progress-modal.component.scss'],
})
@DynamicModal()
export class ConversionProgressModalComponent implements OnInit, AfterViewInit {
  @ViewChild('convertDossier') private childModal: ModalDirective;
  public newDossierId$: BehaviorSubject<number>;
  public error$: BehaviorSubject<string>;
  public hasError$: Observable<boolean>;
  public warnings$: BehaviorSubject<{ Key: string; Value: string }[]>;
  public hasWarnings$: Observable<boolean>;
  public showWarnings$: Observable<boolean>;
  public dossierCancelled = true;
  public dossierConverted = false;
  public dossierReady = false;

  public get showSpinner(): boolean {
    return !this.dossierReady && this.error$.value === undefined;
  }

  constructor(private readonly router: Router) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.newDossierId$.pipe(filter(v => v !== undefined)).subscribe(_ => {
      this.dossierConverted = true;
      setTimeout(() => {
        this.dossierReady = true;
      }, 750);
    });

    this.hasError$ = this.error$.pipe(map(v => !!v));
    this.hasWarnings$ = this.warnings$.pipe(map(v => !!v && v.length > 0));
    this.showWarnings$ = combineLatest(this.hasWarnings$, this.hasError$).pipe(map(([hasWarning, hasError]) => hasWarning && !hasError));

    this.showModal();
  }

  private showModal() {
    this.childModal.config.ignoreBackdropClick = true;
    this.childModal.config.backdrop = true;
    this.childModal.show();
  }

  public navigateToDossier() {
    this.close();
    // Is this a bug? Have to navigate twice, otherwise the address in the addressbar changes,
    // but no actual navigation.
    this.router.navigate(['dossiers/edit/' + this.newDossierId$.value]);
    setTimeout(() => {
      this.router.navigate(['dossiers/edit/' + this.newDossierId$.value]);
    }, 500);
  }

  public close() {
    this.childModal.hide();
  }
}
