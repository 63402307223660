import { from as observableFrom, Observable } from 'rxjs';

import { map, catchError } from 'rxjs/operators';
import { Fittingstation } from '@hrz/core/models/fittingstation';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { FittingStationService } from '@hrz/core/services/fitting-station.service';
import { AvailableTimeRange } from '@hrz/core/models/scheduler/available-time-range';
import * as moment from 'moment';
import { AvailableSlot, DayAvailabilityDto } from '@hrz/core/models/available-slot';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { AppointmentCapacity, AppointmentCapacityException } from '@hrz/core/models/appointment-capacity';
import { Router } from '@angular/router';
import { FittingStationGroupService } from '@hrz/core/services/fitting-station-group.service';

@Injectable({
  providedIn: 'root',
})
export class ScheduleAvailabilityService {
  private baseUrl = ConfigurationManager.AppSettings.scheduleManagementApi;
  private scheduleAvailability = this.baseUrl + '/availability-calendar';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private router: Router,
    private fittingStationService: FittingStationService,
    private fittingStationGroupService: FittingStationGroupService,
    private serviceActionHandler: ServiceActionHandler
  ) { }

  public getAvailableSlots(fittingStationId: number, eventTypeId: number, utcDay: string): Observable<AvailableSlot[]> {
    return this.http
      .get<AvailableSlot[]>(`${this.scheduleAvailability}/${fittingStationId}/event-type/${eventTypeId}/day/${utcDay}/per-event-duration`, {
        headers: this.headers,
      })
      .pipe(
        catchError(error => this.serviceActionHandler.handleDefaultObservableError(error))
      );
  }

  public getDayAvailability(fittingStationId: number, eventTypeName: string, utcDay: string): Observable<DayAvailabilityDto[]> {
    return this.http
      .get<DayAvailabilityDto[]>(
        `${this.scheduleAvailability}/${fittingStationId}/event-type/${eventTypeName}?fromDate=${utcDay}&toDate=${utcDay}`,
        { headers: this.headers })
      .pipe(
        catchError(error => this.serviceActionHandler.handleDefaultObservableError(error))
      );
  }

  public getOpeningHours(fittingStationId: number, utcDay: string): Observable<AvailableTimeRange[]> {
    return this.http
      .get<any[]>(`${this.scheduleAvailability}/${fittingStationId}/openingHours?date=${utcDay}`, { headers: this.headers })
      .pipe(
        map(x =>
          x
            ? x.map(h => ({
              timeFrom: moment.utc(h.timeFrom, 'HH:mm').local().format('HH:mm'),
              timeTo: moment.utc(h.timeTo, 'HH:mm').local().format('HH:mm'),
              dayOfWeek: h.dayOfWeek,
            }))
            : []
        )
      );
  }

  public goToDossierID(dossierID) {
    this.router.navigate(['dossiers/edit/', dossierID]);
  }

  public getFittingStationDetails(fittingStationId: number): Observable<Fittingstation> {
    return observableFrom(this.fittingStationService.get(fittingStationId));
  }

  public getFittingstationsOfGroup(fittingStationGroupId: number): Promise<Fittingstation[]> {
    return this.fittingStationGroupService.get(fittingStationGroupId).then(fittingStationGroup => fittingStationGroup.Fittingstations);
  }

  public getFittingStationsOfGroups(fittingStationGroupIds: number[]): Promise<Fittingstation[]> {
    return this.fittingStationGroupService.getMultiple(fittingStationGroupIds).then(fittingStationGroups => {
      const fittingStations: Fittingstation[] = [];
      fittingStationGroups.forEach(group => fittingStations.push(...group.Fittingstations));
      return fittingStations;
    });
  }

  getAppointmentCapacities(fittingStationId: number): Promise<AppointmentCapacity[]> {
    return this.fittingStationService.getAppointmentCapacities(fittingStationId);
  }

  getAppointmentCapacityExceptions(fittingStationId: number): Promise<AppointmentCapacityException[]> {
    return this.fittingStationService.getAppointmentCapacityExceptions(fittingStationId);
  }

  public getAllFittingStations(): Promise<Fittingstation[]> {
    return this.fittingStationService.loadAll();
  }
}
