import { DefaultModal } from './default/default.modal';
import { LogoutModal } from './logout/logout.modal';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DeleteConfirmationModal } from './delete-confirmation.modal';
import { CancelConfirmationModal } from './cancel-confirmation.modal';
import { ImportConfirmationModal } from './import-confirmation.modal';
import { CancelWithReasonConfirmationModal } from './cancel-with-reason-confirmation.modal';
import { ZoomImageModal } from './zoom-image.modal';
import { HybrisProductsModal } from './hybris-products.modal';
import { DisplayPdfModal } from './display-pdf.modal';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DocumentsSliderModal } from './document-slider/documents-slider.modal';
import { ExportTranslationsModal } from './export-translations.modal';
import { CustomConfirmationModal } from './custom-confirmation.modal';
import { ExportDropdownsModal } from './export-dropdowns.modal';
import { DocumentModal } from './document/document.modal';
import { CreateQuoteModel } from './create-quote.modal';
import { DiscountApprovalRequestModal } from './discount-approval-request/discount-approval-request.modal';
import 'rxjs/Rx';
import { SharedComponentModule } from '../sharedComponent.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap';

// module definition
@NgModule({
  imports: [CommonModule, ModalModule, SharedComponentModule, PdfViewerModule, TranslateModule.forChild(), ReactiveFormsModule],
  declarations: [
    CancelConfirmationModal,
    CancelWithReasonConfirmationModal,
    CustomConfirmationModal,
    DefaultModal,
    DeleteConfirmationModal,
    DiscountApprovalRequestModal,
    DisplayPdfModal,
    DocumentModal,
    DocumentsSliderModal,
    ExportDropdownsModal,
    ExportTranslationsModal,
    HybrisProductsModal,
    ImportConfirmationModal,
    LogoutModal,
    ZoomImageModal,
    CreateQuoteModel,
  ],
  exports: [],
  providers: [],
})
export class ConfirmationsModule {}
