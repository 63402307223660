import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[tmDisableControl]',
})
export class DisableControlDirective {
  constructor(private elementRef: ElementRef) {}

  @Input() set tmDisableControl(condition: boolean) {
    this.elementRef.nativeElement.disabled = condition;
  }
}
