import {
  Component,
  NgModule,
  ViewChild,
  OnInit,
  ViewContainerRef,
  Compiler,
  Injectable,
  Injector,
  ComponentRef,
  Optional,
  SkipSelf,
} from '@angular/core';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';
import { Observable, ReplaySubject } from 'rxjs';

// the modalservice
@Injectable({
  providedIn : 'root'
})
export class DynamicModalService {
  private vcRef: ViewContainerRef;
  private injector: Injector;
  private _activeInstances = 0;

  constructor(private compiler: Compiler,appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: DynamicModalService) {
      if (parent) {
        appInsightsService.logException(new Error('DynamicModalService is a Singleton and should only be loaded in AppModule.'));
      }
  }

  public registerViewContainerRef(vcRef: ViewContainerRef): void {
    this.vcRef = vcRef;
  }

  public registerInjector(injector: Injector): void {
    this.injector = injector;
  }

  public create<T>(declaredInModule: any, declaredComponent: any, parameters?: Object): Observable<ComponentRef<T>> {
    const componentRef$ = new ReplaySubject();
    this.compiler.compileModuleAndAllComponentsAsync(declaredInModule).then(factory => {
      const componentFactory = factory.componentFactories.filter(item => item.componentType === declaredComponent)[0];
      const childInjector = Injector.create({providers: [], parent: this.injector});
      const componentRef = this.vcRef.createComponent(componentFactory, 0, childInjector);
      Object.assign(componentRef.instance, parameters); // pass the @Input parameters to the instance
      this._activeInstances++;
      componentRef.instance['componentIndex'] = this._activeInstances;
      componentRef.instance['destroy'] = () => {
        this._activeInstances--;
        componentRef.destroy();
      };
      componentRef$.next(componentRef);
      componentRef$.complete();
    });
    return <Observable<ComponentRef<T>>>componentRef$.asObservable();
  }
}

// this is the modal-placeholder, it will container the created modals
@Component({
  selector: 'modal-placeholder',
  template: `<div #modalplaceholder></div>`,
})
export class ModalPlaceholderComponent implements OnInit {
  @ViewChild('modalplaceholder', { read: ViewContainerRef }) viewContainerRef;

  constructor(
    private modalService: DynamicModalService,
    private injector: Injector
  ) {
    console.log('ModalPlaceholderComponent.constructor()');
  }
  ngOnInit(): void {
    console.log('ModalPlaceholderComponent.ngOnInit()');
    this.modalService.registerViewContainerRef(this.viewContainerRef);
    this.modalService.registerInjector(this.injector);
  }
}

// These 2 items will make sure that you can annotate
// a modalcomponent with @DynamicModal()
class DynamicModalContainer {
  destroy: Function;
  componentIndex: number;
  closeModal(): void {
    console.log('DynamicModalContainer.closeModal()');
    this.destroy();
  }
}
export function DynamicModal() {
  return target => {
    Object.assign(target.prototype, DynamicModalContainer.prototype);
  };
}

// module definition
@NgModule({
  imports: [],
  declarations: [ModalPlaceholderComponent],
  exports: [ModalPlaceholderComponent],
})
export class DynamicModalModule {}
