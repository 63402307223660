import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

import { DossierAccount } from '@hrz/core/models/dossier-account';
import { DossierService } from '@hrz/core/services/dossier.service';
import { ProtocolFlowEnum } from '@hrz/core/enums/protocol-flow.enum';
import { VatDivertCheckService } from '@hrz/core/services/vatdivert-check.service';

@Directive({
  selector: '[vatDivert]',
})
export class RequiresVatDivertDirective {
  @Input('vatDivert') dossierAccount: DossierAccount;
  @Input('vatDivertDossierId') dossierId: number;

  constructor(
    private control: NgControl,
    dossierService: DossierService,
    vatDivertCheckService: VatDivertCheckService
  ) {
    this.control.valueChanges.subscribe(_ => {
      if (this.control.dirty && this.dossierAccount && this.dossierAccount.OwnershipType) {
        if (
          (this.dossierAccount.OwnershipType === 'Freelance' && this.dossierAccount.Ownership === 'Private') ||
          (this.dossierAccount.OwnershipType === 'General' && this.dossierAccount.Ownership === 'Business')
        ) {
          dossierService
            .LoadAllProtocolsByDossierAndFlow(this.dossierId, ProtocolFlowEnum.VatDivert)
            .then(results => {
              if (results.length > 0) {
                vatDivertCheckService.setVatDiverted(true);
              }
            })
            .catch(_ => {});
        } else {
          vatDivertCheckService.setVatDiverted(false);
        }
      }
    });
  }
}
