import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CancelWithReason } from '@hrz/core/models/cancel-with-reason';
import { Enum } from '@hrz/core/models/enum';
import { DynamicModal } from '../dynamic-modal.module';
import { TranslateService } from '@ngx-translate/core';
import { Account } from '@hrz/core/models/account';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { DossierCancellationReasonKeys } from '@hrz/core/models/enums/domain-cancellation-reason-keys';

@Component({
  selector: 'cancel-with-reason-confirmation-modal',
  templateUrl: './cancel-with-reason-confirmation.modal.html',
})
@DynamicModal()
export class CancelWithReasonConfirmationModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public cancelReasons: Enum[];
  public alertUser: boolean;
  public context: CancelWithReason;
  public insuranceCompanyId: number;
  public onOk: Function;
  public onClose: Function;
  public insuranceCompanies: Account[];
  public isRejectDossier: boolean;
  public get showInsuranceCompanySelect(): boolean {
    return this.context.Reason === DossierCancellationReasonKeys.DamageIsCoveredContinueWithIcDossier;
  }
  public get cancelButtonIsDisabled(): boolean {
    if (!this.context.Reason) { return true; }
    if (this.context.Reason === 'Other' && !this.context.Note) { return true; }
    if (
      this.context.Reason === DossierCancellationReasonKeys.DamageIsCoveredContinueWithIcDossier &&
      !(this.context.InsuranceCompanyId > 0)
    ) {
      return true;
    }
    return false;
  }

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  constructor(
    private toasterService: ToasterService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    console.log('CancelWithReasonConfirmationModal.ngOnInit', this);
  }

  ngAfterViewInit(): void {
    console.log('CancelWithReasonConfirmationModal.ngAfterViewInit');
    if (!this.context) {
      this.context = new CancelWithReason();
    }

    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
    this.childModal.show();
  }

  ngOnDestroy(): void {
    console.log('CancelWithReasonConfirmationModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    if (!this.context || !this.context.Reason) {
      this.toasterService.showError(this.translateService.instant('SHARED.MODALS.REASON_REQUIRED'));
      return;
    }

    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
