import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { saveAs } from 'file-saver';

@Component({
  selector: 'display-pdf-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-customsize" style="width: 70%;" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="pull-left btn btn-sm btn-primary" (click)="clickDownload()" aria-label="Close">
              <i class="fa fa-arrow-circle-down" aria-hidden="true"></i> {{ 'SHARED.MODALS.DOWNLOAD_INVOICE' | translate }}
            </button>
            <button type="button" class="close" (click)="clickClose()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height:90%;">
            <pdf-viewer [src]="displayContent" [render-text]="true" [show-all]="true" style="display: block;"></pdf-viewer>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class DisplayPdfModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public pdfFile: FileDownload;
  public displayContent: any;

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;
  ngOnInit(): void {
    console.log('DisplayPdfModal.ngOnInit');
  }

  ngAfterViewInit(): void {
    console.log('DisplayPdfModal.ngAfterViewInit');
    window.addEventListener(
      'message',
      e => {
        if (e.data === 'close') {
          console.log('close message received');
          this.clickClose();
        }
      },
      false
    );
    this.childModal.show();
    const scoped = this;
    const fileReader = new FileReader();
    fileReader.onload = function () {
      scoped.displayContent = this.result;
    };
    fileReader.readAsArrayBuffer(this.pdfFile.blob);
  }
  ngOnDestroy(): void {
    console.log('DisplayPdfModal.ngOnDestroy');
  }

  clickClose(): void {
    console.log('DisplayPdfModal.clickClose');
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickDownload(): void {
    console.log('DisplayPdfModal.clickDownload');
    saveAs(this.pdfFile.blob, this.pdfFile.name);
  }

  clickOk(): void {
    console.log('DisplayPdfModal.clickOk');
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
