import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as moment from 'moment';
import { Protocol } from '@hrz/core/models/protocol';
import { Dossier } from '@hrz/core/models/dossier';
import { NewCustomer } from '@hrz/core/models/dossier/new-customer';
import { Country } from '@hrz/core/models/country';
import { Fittingstation } from '@hrz/core/models/fittingstation';
import { Account } from '@hrz/core/models/account';
import { CreateWizardCommand } from '@hrz/core/models/dossier/wizard/create-wizard-command';
import { CancelWithReason } from '@hrz/core/models/cancel-with-reason';
import { CheckInOutState } from '@hrz/core/models/dossier/check-in-out-state';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { AuthService } from '@hrz/core/services/auth.service';
import { DossierListItem } from '@hrz/core/models/DossierListItem';
import { SendToAccountListItem } from '@hrz/core/models/send-to-account-list-item';
import { ListDataRequest } from '@hrz/core/models/list-data-request';
import { ListRequestService } from '@hrz/core/services/list-request.service';
import { ListDataResponse } from '@hrz/core/models/list-data-response';
import { DossierLockStatus } from '@hrz/core/models/dossier/dossier-lock-status';
import { DossierStatusActionActivity } from '@hrz/core/models/dossier/dossier-status-action-activity';
import { DossierStatusActivity } from '@hrz/core/models/dossier/dossier-status-activity';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { DossierTaxInvoice } from '@hrz/core/models/dossier/dossier-taxinvoice';
import { DossierAccount } from '@hrz/core/models/dossier-account';
import { DossierVehicle } from '@hrz/core/models/dossier-vehicle';
import { Address } from '@hrz/core/models/address';
import { BatchFinancialValidationResponse } from '@hrz/core/models/dossier/batch-financial-validation-response';
import { ExportDossiersCommand } from '@hrz/core/models/dossier/export-dossiers-command';
import { blobAsString } from '@hrz/core/utils/blob-to-string';
import { ProtocolFlowEnum } from '@hrz/core/enums/protocol-flow.enum';
import { ValidationProtocol } from '@hrz/core/models/validation-protocol';
import { CodeTranslationListItemDto } from '@hrz/core/models/code-translation-list-item-dto';
import { LegalEntity } from '@hrz/core/models/legal-entity';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class DossierService {
  private baseUrl = ConfigurationManager.AppSettings.dossierApi;
  private searchBaseUrl = ConfigurationManager.AppSettings.dossierSearchApi;
  private dossierUrl = this.baseUrl + '/dossier';
  private dossierSearchUrl = this.searchBaseUrl + '/dossierSearch';
  private syncDataUrl = this.baseUrl + '/synceddata';
  private metricUrl = this.baseUrl + '/metric';
  private historyUrl = this.baseUrl + '/history';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private deepLinkUrl = ConfigurationManager.AppSettings.envDeepLinkUrl;
  private dossierDeepLink = this.deepLinkUrl + '/dossier';
  // In-Memory cache attributes
  private dataCountriesMemoryCache: Country[];
  private dataFittingStationsMemoryCache: Fittingstation[];
  private dataFittingStationsForUserMemoryCache: Fittingstation[];
  private dataAccountsMemoryCache: Account[];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private listRequestService: ListRequestService,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: DossierService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('DossierService is a Singleton and should only be loaded in AppModule.'));
    }
    // Load local memory cache
    this.loadAllCountries();
    this.loadAllFittingStations();
    this.loadFittingStationsForUser();
    this.loadAllAccounts();
  }

  public loadAll(dataRequest: ListDataRequest): Promise<ListDataResponse<DossierListItem>> {
    const params = this.listRequestService.getSearchParams(dataRequest);
    if (!dataRequest.intensiveSearch) {
      return this.http
        .get<ListDataResponse<DossierListItem>>(this.dossierUrl + '/filter', { params: params })
        .toPromise()
        .catch(this.serviceActionHandler.handleDefaultError);
    } else {
      return this.http
        .post<ListDataResponse<DossierListItem>>(
          this.dossierSearchUrl +
          '/v2/filter?page=' +
          dataRequest.page +
          '&noOfRecords=' +
          dataRequest.rowsPerPage +
          '&sortBy=' +
          dataRequest.sortBy +
          '&sortOrder=' +
          dataRequest.sortOrder +
          '&intensiveSearch=' +
          dataRequest.intensiveSearch,
          JSON.stringify(dataRequest.filters),
          { headers: this.headers }
        )
        .toPromise()
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public loadAllBatchFinValidation(dataRequest: ListDataRequest): Promise<ListDataResponse<DossierListItem>> {
    return this.http
      .post<ListDataResponse<DossierListItem>>(
        this.dossierSearchUrl +
        '/v2/batchFinVal?page=' +
        dataRequest.page +
        '&noOfRecords=' +
        dataRequest.rowsPerPage +
        '&sortBy=' +
        dataRequest.sortBy +
        '&sortOrder=' +
        dataRequest.sortOrder +
        '&intensiveSearch=' +
        dataRequest.intensiveSearch,
        JSON.stringify(dataRequest.filters),
        { headers: this.headers }
      )
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public loadAllCountries(): Promise<Country[]> {
    if (this.dataCountriesMemoryCache != null && this.dataCountriesMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Country[]>((resolve, _) => {
        resolve(this.dataCountriesMemoryCache);
      });
    } else {
      // Retrieve from API
      return this.http
        .get<Country[]>(this.syncDataUrl + '/countries')
        .toPromise()
        .then(response => {
          this.dataCountriesMemoryCache = response;
          return this.dataCountriesMemoryCache;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public loadAllFittingStations(): Promise<Fittingstation[]> {
    if (this.dataFittingStationsMemoryCache != null && this.dataFittingStationsMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Fittingstation[]>((resolve, _) => {
        resolve(this.dataFittingStationsMemoryCache);
      });
    } else {
      // Retrieve from API
      return this.authService
        .getTenantId()
        .pipe(mergeMap(tenantId => this.http.get<Fittingstation[]>(this.syncDataUrl + '/fittingStations/' + tenantId)))
        .toPromise()
        .then(response => {
          this.dataFittingStationsMemoryCache = response;
          return this.dataFittingStationsMemoryCache;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public loadFittingStationsForUser(mobileOnly: Boolean = false): Promise<Fittingstation[]> {
    if (this.dataFittingStationsForUserMemoryCache != null && this.dataFittingStationsForUserMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Fittingstation[]>((resolve, _) => {
        resolve(this.dataFittingStationsForUserMemoryCache);
      });
    } else {
      // Retrieve from API
      return this.authService
        .getTenantId()
        .pipe(
          mergeMap(tenantId => this.http.get<Fittingstation[]>(`${this.syncDataUrl}/fittingStations/${tenantId}/for-user/${mobileOnly}`))
        ).toPromise()
        .then(response => {
          this.dataFittingStationsForUserMemoryCache = response;
          return this.dataFittingStationsForUserMemoryCache;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public getFittingStationById(id: number): Promise<Fittingstation> {
    if (this.dataFittingStationsMemoryCache != null && this.dataFittingStationsMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Fittingstation>((resolve, _) => {
        resolve(this.dataFittingStationsMemoryCache.find(item => item.Id === id));
      });
    } else {
      // Retrieve from API
      return this.http
        .get<Fittingstation>(`${this.syncDataUrl}/fittingStations/GetById/${id}`)
        .toPromise()
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public loadAllAccounts(): Promise<Account[]> {
    if (this.dataAccountsMemoryCache != null && this.dataAccountsMemoryCache.length > 0) {
      // Leverage local cache
      return new Promise<Account[]>((resolve, _) => {
        resolve(this.dataAccountsMemoryCache);
      });
    } else {
      // Retrieve from API
      return this.http
        .get<Account[]>(this.syncDataUrl + '/accounts')
        .toPromise()
        .then(response => {
          this.dataAccountsMemoryCache = response;
          return this.dataAccountsMemoryCache;
        })
        .catch(this.serviceActionHandler.handleDefaultError);
    }
  }

  public get(id: number): Promise<Dossier> {
    return this.http
      .get<Dossier>(this.dossierUrl + '/' + id)
      .toPromise()
      .then(response => {
        const dossier = response;
        dossier.Documents.forEach(d => (d.Dossier = Object.assign(new Dossier(), { Id: dossier.Id })));
        return dossier;
      })
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getValidationProtocol(id: number, accountType: string, ownership: string): Promise<ValidationProtocol[]> {
    console.log(accountType + ' + ' + ownership);
    return this.http
      .get<ValidationProtocol[]>(`${this.dossierUrl}/${id}/refresh-validation-protocol?accountType=${accountType}&ownership=${ownership}`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public create(dossier: Dossier): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}`;
    const createCommand = {
      DossierStatusId: 2, // Setting it to New,
      FittingStationId: dossier.FittingStationId,
      DossierSource: 'FittingStation',
      CreationTime: new Date(),
      DriverName: dossier.DossierVehicle.Driver.Name,
      DriverEmail: dossier.DossierVehicle.Driver.Email,
      DriverPhone: dossier.DossierVehicle.Driver.Phone,
      DriverMobilePhone: dossier.DossierVehicle.Driver.Mobile,
      LicensePlate: dossier.DossierVehicle.LicensePlate,
    };

    if (!createCommand.FittingStationId) {
      createCommand.FittingStationId = this.authService.getFittingStationId();
    }

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createByCustomer(newcustomer: NewCustomer): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/v2`;
    newcustomer.address.City = newcustomer.city;
    newcustomer.address.CountryId = newcustomer.country.Id;
    const createCommand = {
      FittingStationId: newcustomer.FittingStationId,
      InsuranceCompanyAccountId: newcustomer.InsuranceCompanyAccountId,
      DriverMobilePhone: newcustomer.mobilePhoneNumber,
      LicensePlate: newcustomer.licensePlateNumber,
      DriverCountryId: newcustomer.country.Id,
      DriverFirstName: newcustomer.firstName,
      DriverLastName: newcustomer.lastName,
      AccountId: newcustomer.accountId,

      Vat: newcustomer.vat,
      BsnId: newcustomer.bsnId,
      Address: newcustomer.address,
      Email: newcustomer.email,
      Phone: newcustomer.phone,
      Fax: newcustomer.fax,

      Ownership: newcustomer.ownership ? newcustomer.ownership.Key : null,
      AccountType: newcustomer.accountType ? newcustomer.accountType.Key : null,
      CompanyName: newcustomer.companyName,

      CarBrand: newcustomer.carBrand,
      CarModel: newcustomer.carModel,
      Year: newcustomer.year,
      ChassisNumber: newcustomer.chassisNumber,
      Color: newcustomer.color,
      VehicleType: newcustomer.vehicleType,
      NumberOfDoors: newcustomer.numberOfDoors,

      ShowApp: newcustomer.showapp,
    };

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateShowApp(dossier: Dossier, valueToSet: boolean): Promise<boolean> {
    const url = `${this.dossierUrl}/${dossier.Id}/setshowapp?showApp=${valueToSet}`;
    console.log('POST - JSON Command: setShowApp');
    return this.http
      .patch(url, { headers: this.headers })
      .toPromise()
      .then(_ => valueToSet)
      .catch(_ => false);
  }

  public startCheckin(dossier: Dossier): void {
    const deepLink = `${this.dossierDeepLink}?target=open_dossier&dossier_id=${dossier.Id}`;
    console.log('GET - Opening App and start checking');

    const ua = navigator.userAgent.toLowerCase();
    const isAndroid = ua.indexOf('android') > -1; // android check
    const isIphone = ua.indexOf('iphone') > -1; // ios check
    if (isIphone === true) {
      console.log('iOS - deeplink: ' + deepLink);

      const app = {
        launchApp: function () {
          setTimeout(function () {
            window.location.href = deepLink;
          }, 25);
          window.location.href = deepLink; // which page to open(now from mobile, check its authorization)
        },
        openWebApp: function () {
          window.location.href = deepLink;
        },
      };
      app.launchApp();
    } else if (isAndroid === true) {
      console.log('Android - deeplink: ' + deepLink);

      const app = {
        launchApp: function () {
          window.location.replace(deepLink); // which page to open(now from mobile, check its authorization)
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.location.href = deepLink;
        },
      };
      app.launchApp();
    } else {
      console.log('PC - sending push notification: ');
      // send push notification
      const user = this.authService.getUserName();
      const userCleaned = user.replace('@', '');
      this.sendPushNotification(dossier.Id, userCleaned, 'open_dossier', 'DOSSIER ' + dossier.Id);
    }
  }

  public sendPushNotification(dossierId: number, userid: string, target: string, message: string): Promise<ApiActionResult> {
    let url = `${this.baseUrl}/v1/app/dossier/${dossierId}/sendnotificationtouser?userid=${userid}&target=${target}`;

    if (message && message.trim()) {
      url += `&message=${message}`;
    }

    console.log('POST - JSON Command: sendPushNotification using URL: ' + url);
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public update(dossier: Dossier): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${dossier.Id}`;
    const updateCommand = {
      Id: dossier.Id,
    };
    console.log('PUT - JSON Command: ', JSON.stringify(updateCommand));
    return this.http
      .put(url, JSON.stringify(updateCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public delete(dossierId: number): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${dossierId}`;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateStatus(instance: Dossier, status: string): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${instance.Id}/updatestatus?statusKey=${status}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateStatusSendToAccount(dossierId: number, statusKey: string, protocolId: number): Promise<ApiActionResult> {
    console.log('DossierService.updateStatusSendToAccount()');
    const url = `${this.dossierUrl}/${dossierId}/updatestatus?statusKey=${statusKey}&protocolId=${protocolId}`;
    console.log('POST - URL: ', url);
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public processAction(instance: Dossier, actionKey: string): Promise<ApiActionResult> {
    const actionRemarkt = '-';
    const actionRemarkType = '-';

    const url =
      `${this.baseUrl}/dossier/${instance.Id}/processaction?actionKey=${actionKey}` +
      `&actionRemark=${actionRemarkt}&actionRemarkType=${actionRemarkType}`;
    console.log('Calling post: ', url);
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public validateActionKey(instance: Dossier, actionKey: string): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${instance.Id}/validateprocessaction?actionKey=${actionKey}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public processActionRemark(
    instance: Dossier,
    actionKey: string,
    actionRemarkt: string,
    actionRemarkType: string,
    metaData: object = null
  ): Promise<ApiActionResult> {
    if (!actionRemarkt) {
      actionRemarkt = '';
    }
    if (!actionRemarkType) {
      actionRemarkType = '';
    }
    const url =
      `${this.baseUrl}/dossier/${instance.Id}/processaction?actionKey=${actionKey}` +
      `&actionRemark=${actionRemarkt}&actionRemarkType=${actionRemarkType}`;
    return this.http
      .post(url, metaData, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public loadAllSendToAccount(): Promise<SendToAccountListItem[]> {
    return this.http
      .get<SendToAccountListItem[]>(this.dossierUrl + '/sendtoaccount')
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public loadAllSendToAccountWithFilter(dataRequest: ListDataRequest): Promise<ListDataResponse<SendToAccountListItem>> {
    const params = this.listRequestService.getSearchParams(dataRequest);
    return this.http
      .get<ListDataResponse<SendToAccountListItem>>(this.dossierUrl + '/sendtoaccountfilter', { params: params })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public createWizard(model: CreateWizardCommand): Observable<ApiActionResult<Dossier>> {
    return this.http.post(`${this.baseUrl}/dossier/wizard`, model, { headers: this.headers }).pipe(
      map(response => this.serviceActionHandler.mapActionSuccess(response)),
      catchError(error => this.serviceActionHandler.handleObservableActionError(error))
    );
  }

  public validateWizard(model: CreateWizardCommand): Observable<ApiActionResult<Dossier>> {
    return this.http
      .post(`${this.baseUrl}/dossier/wizardvalidation`, model, { headers: this.headers })
      .pipe(
        map(
          response => this.serviceActionHandler.mapActionSuccess(response)
        ),
        catchError(error => this.serviceActionHandler.handleObservableActionError(error)
        )
      );
  }

  public LoadWebServiceAuthorizationProtocol(dossierId: number, accountId: number): Promise<ApiActionResult> {
    return this.http
      .get(`${this.baseUrl}/protocol/getprotocolforwebserviceauthorization/${accountId}/${dossierId}`)
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public LoadAccountProtocolsForSending(accountId: number): Promise<Protocol[]> {
    return this.http
      .get<Protocol[]>(`${this.baseUrl}/protocol/GetProtocolsForSending/${accountId}`)
      .toPromise()
      .catch(error => {
        this.serviceActionHandler.handleActionError(error);
        throw error;
      });
  }
  public getProtocolByFlowAndDossier(flow: string, dossierId: number): Promise<Protocol> {
    return this.http
      .get<Protocol>(this.baseUrl + `/protocol/flow/${flow}/dossier/${dossierId}/`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public executePreProtocolWorkflow(protocolId: number, dossierIds: number[]): Promise<ApiActionResult> {
    console.log('DossierService.executePreProtocolWorkflow()', dossierIds);
    return this.http
      .post(`${this.baseUrl}/protocol/ExecutePreProtocolWorkflow/${protocolId}/`, dossierIds, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public lock(dossierId: number): Promise<ApiActionResult> {
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/lock`, null, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public unlock(dossierId: number): Promise<ApiActionResult> {
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/unlock`, null, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public lockJob(dossierId: number): Promise<ApiActionResult> {
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/job/lock`, null, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public unlockJob(dossierId: number): Promise<ApiActionResult> {
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/job/unlock`, null, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getLockStatus(dossierId: number): Promise<DossierLockStatus> {
    return this.http
      .get<DossierLockStatus>(`${this.dossierUrl}/${dossierId}/lock-status`, { headers: this.headers })
      .toPromise();
  }

  public getRejectReasons(dossierId: number): Promise<DossierStatusActionActivity[]> {
    console.log('DossierService.getRejectReasons()');
    return this.http
      .get<DossierStatusActionActivity[]>(`${this.dossierUrl}/${dossierId}/action/reject`, { headers: this.headers })
      .toPromise();
  }

  public getDossierStatusActivitiesForActionKeys(dossierId: number, actionKeys: string[]): Promise<DossierStatusActionActivity[]> {
    console.log('DossierService.getDossierStatusActivitiesForActionKeys()', actionKeys);
    return this.http
      .post<DossierStatusActionActivity[]>(`${this.dossierUrl}/${dossierId}/dossierstatusactivities`, actionKeys, { headers: this.headers })
      .toPromise();
  }

  public getDossierStatusActivities(dossierId: number): Promise<DossierStatusActivity[]> {
    console.log('DossierService.getDossierStatusActivities()');
    return this.http
      .post<DossierStatusActivity[]>(`${this.dossierUrl}/${dossierId}/statusactivities`, null, { headers: this.headers })
      .toPromise();
  }

  public updateDossierValidation(context: any): Promise<ApiActionResult> {
    console.log('DossierService.updateDossierValidation()', context);

    const command = {
      DossierId: context.DossierId,
      LicensePlate: context.LicensePlate,
      IcClaimNumber: context.IcClaimNumber,
      PolicyNumber: context.PolicyNumber,
      DamageDate: moment(context.DamageDate).utc().toDate(),
      FsJobInvoiceId: context.JobInvoiceId,
      FsJobInvoiceNumber: context.InvoiceNumber,
      FsJobInvoiceDate: moment(context.InvoiceDate).utc().toDate(),
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));

    return this.http
      .post(`${this.dossierUrl}/${command.DossierId}/updateDossierValidation`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createMetrics(dossierId: number, context: any): Promise<ApiActionResult> {
    const body = JSON.stringify(context);
    console.log('POST - JSON Command: ', body);
    return this.http
      .post(`${this.metricUrl}/multiple?dossierId=${dossierId}`, body, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createMetricPayload(dossierId: number, time: number, action: string, step: string) {
    const command = {
      DossierId: dossierId,
      Time: time,
      Action: action,
      Step: step,
    };
    return command;
  }

  public cancelDossier(cancelWithReason: CancelWithReason): Promise<ApiActionResult> {
    console.log('DossierService.cancelDossier()', cancelWithReason);

    const command = {
      DossierId: cancelWithReason.DossierId,
      Reason: cancelWithReason.Reason,
      AdditionalReason: cancelWithReason.Note,
      InsuranceCompanyId: cancelWithReason.InsuranceCompanyId,
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http
      .post(`${this.dossierUrl}/${command.DossierId}/cancelDossier`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public doCheckIn(dossierId: number, state: CheckInOutState): Promise<ApiActionResult> {
    console.log('DossierService.doCheckIn()');
    const command = {
      DossierId: dossierId,
      DamageIndicationImage: state.checkInModalData.damageIndicateData.highlightedDamageImage.resultBase64,
      SignatureClient: state.checkInModalData.clientSignature,
      SignatureFitter: state.checkInModalData.fitterSignature,
      DamageIndicationData: {
        LightSensor: state.checkInModalData.damageIndicateData.lightSensor,
        RainSensor: state.checkInModalData.damageIndicateData.rainSensor,
        Radio: state.checkInModalData.damageIndicateData.radio,
        Dashboard: state.checkInModalData.damageIndicateData.dashboard,
        CentralLocking: state.checkInModalData.damageIndicateData.centralLocking,
        Gps: state.checkInModalData.damageIndicateData.gps,
        MobilePhone: state.checkInModalData.damageIndicateData.mobilePhone,
        Wipers: state.checkInModalData.damageIndicateData.wipers,
        WindowRunner: state.checkInModalData.damageIndicateData.windowRunner,
        Adas: state.checkInModalData.damageIndicateData.adas,
        ViaVerde: state.checkInModalData.damageIndicateData.viaVerde,
        Other: state.checkInModalData.damageIndicateData.other,
        OtherInfo: state.checkInModalData.damageIndicateData.otherInfo,
      },
      Question1: state.checkInModalData.LegalNoteQuestion1Allowed,
      Question2: state.checkInModalData.LegalNoteQuestion2Allowed,
      Question3: state.checkInModalData.LegalNoteQuestion3Allowed,
    };

    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/doCheckIn`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public doCheckOut(dossierId: number, state: CheckInOutState): Promise<ApiActionResult> {
    console.log('DossierService.doCheckOut()');
    const command = {
      DossierId: dossierId,
      SignatureClient: state.checkOutModalData.clientSignature,
      SignatureFitter: state.checkOutModalData.fitterSignature,
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/doCheckOut`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public doChangeFitter(
    dossierId: number,
    fittingstationId: number,
    currentFittingstationId: number,
    isMobileJob: Boolean,
    fittingAddress: Address
  ): Promise<ApiActionResult> {
    console.log('DossierService.doChangeFitter()');
    const command = {
      DossierId: dossierId,
      FittingstationId: fittingstationId,
      IsMobileJob: isMobileJob,
      AddressStreet: isMobileJob && fittingAddress ? fittingAddress.Street : null,
      AddressPostalcode: isMobileJob && fittingAddress ? fittingAddress.ZipCode : null,
      AddressCity: isMobileJob && fittingAddress ? fittingAddress.City : null,
    };
    console.log('POST - JSON Command: ', JSON.stringify(command));
    return this.http
      .post(`${this.dossierUrl}/${dossierId}/fittingstation/${currentFittingstationId}`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public DoCognitiveValidation(id: number): Promise<any> {
    return this.http
      .get<any>(this.dossierUrl + '/' + id + '/cognitivevalidation')
      .toPromise()
      .then(response => {
        return response.Entity;
      })
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getDossierTaxInvoices(dossierId: number): Promise<DossierTaxInvoice[]> {
    console.log('DossierService.getDossierTaxInvoices()');
    return this.http
      .get<DossierTaxInvoice[]>(`${this.dossierUrl}/${dossierId}/taxinvoice`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getByErpIdentifier(erpIdentifiers: number[]): Promise<number[]> {
    console.log('POST - JSON Command: ', JSON.stringify(erpIdentifiers));
    return this.http
      .post<number[]>(`${this.dossierUrl}/erpidentifier`, JSON.stringify(erpIdentifiers), { headers: this.headers })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getOwnerHistory(name: string, surname: string, company: string, licensePlate: string): Promise<DossierAccount[]> {
    return this.http
      .get<DossierAccount[]>(
        this.historyUrl +
        '/owner?queries.name=' +
        name +
        '&queries.surname=' +
        surname +
        '&queries.company=' +
        company +
        '&queries.licensePlate=' +
        licensePlate
      )
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getVehicleHistory(name: string, surname: string, company: string, licensePlate: string): Promise<DossierVehicle[]> {
    return this.http
      .get<DossierVehicle[]>(
        this.historyUrl +
        '/vehicle?queries.name=' +
        name +
        '&queries.surname=' +
        surname +
        '&queries.company=' +
        company +
        '&queries.licensePlate=' +
        licensePlate
      )
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public batchFinancialValidation(dossierIds: Array<number>): Promise<ApiActionResult<BatchFinancialValidationResponse>> {
    return this.http
      .post(this.dossierUrl + '/batchfinancialvalidation', dossierIds)
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public export(command: ExportDossiersCommand): Promise<ApiActionResult<any> | Blob> {
    return this.http
      .post(this.dossierUrl + '/export', command, { responseType: 'blob' })
      .toPromise()
      .catch(async error => {
        const blobString = await blobAsString(error.blob());
        const obj = JSON.parse(blobString);
        return this.serviceActionHandler.handleActionError(obj);
      });
  }

  public LoadAllProtocolsByDossierAndFlow(dossierId: number, protocolFlow: ProtocolFlowEnum): Promise<Protocol[]> {
    const flow: string = ProtocolFlowEnum[protocolFlow];
    console.log(`DossierService.LoadAllProtocols(${dossierId}, ${flow})`);
    return this.http
      .get<Protocol[]>(`${this.baseUrl}/protocol/GetProtocolsForFlowAndDossier/${flow}/${dossierId}`)
      .toPromise()
      .catch(error => {
        this.serviceActionHandler.handleActionError(error);
        throw error;
      });
  }
  public codeTranslation(tenantId: number): Promise<string> {
    const url = `${this.baseUrl}/codetranslation/type/${tenantId.toString()}/group/Dossier/key/enableBankAccount`;
    return this.http
      .get<any>(url)
      .toPromise()
      .then(response => response.CodeValue)
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getCodeTranslationListByGroupAndType(groupCode: string, typeCode: string): Promise<CodeTranslationListItemDto[]> {
    const url = `${this.baseUrl}/codetranslation/type/${typeCode}/group/${groupCode}`;
    return this.http
      .get<CodeTranslationListItemDto>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public toggleChargeFeeStatus(dossierId: number, chargeFeeStatus: number): Promise<ApiActionResult> {
    console.log('DossierService.toggleChargeFeeStatus()');
    const url = `${this.dossierUrl}/${dossierId}/toggleChargeFeeStatus/${chargeFeeStatus}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(result => this.serviceActionHandler.handleActionSuccess(result))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public changeFittingStationPayer(dossierId: number, fittingStationId: number): Promise<ApiActionResult> {
    console.log('DossierService.changeFittingStationPayer()');
    const url = `${this.dossierUrl}/${dossierId}/changeFittingStationPayer/${fittingStationId}`;
    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(result => this.serviceActionHandler.handleActionSuccess(result))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateCreditInformation(dossier: Dossier, convertedDate: Date): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${dossier.Id}/updateCreditInformation`;
    const command = {
      FittingStationToCreditId: dossier.FittingStationToCredit.Id,
      IsFittingStationBeginCredited: dossier.IsFittingStationBeingCredited,
      ToBeCreditedDate: convertedDate,
    };

    console.log('UpdateCreditInformation() called');
    console.log(command);
    return this.http
      .post(url, command, { headers: this.headers })
      .toPromise()
      .then(result => this.serviceActionHandler.handleActionSuccess(result))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public updateAveragePriceStatistics(dossier: Dossier): Promise<ApiActionResult> {
    const command = {
      AveragePriceRemovalStatisticsIncluded: dossier.AveragePriceRemovalStatisticsIncluded,
      HasBeenRemovedFromAverage: dossier.HasBeenRemovedFromAverage,
      ReasonForCheck: dossier.ReasonForCheck,
    };
    return this.http
      .post(`${this.dossierUrl}/${dossier.Id}/setaveragepricestatistics`, command, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public regenerateDocuments(dossierId: number): Promise<ApiActionResult> {
    const url = `${this.dossierUrl}/${dossierId}/RegenerateDocuments`;

    return this.http
      .post(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public getLegalEntityById(id: number): Promise<LegalEntity> {
    return this.http
      .get<LegalEntity>(`${this.baseUrl}/legalEntity/${id}`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }
}
