import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { Structure } from '@hrz/core/models/document/structure';
import { DocumentStructureService } from '../../services/document-structure.service';
import { FileMove } from '@hrz/core/models/document/file-move';
import { FileService } from '@hrz/core/services/file.service';
import { BaseActionModal } from '../base/base-action.modal';

@Component({
  templateUrl: './file-move.modal.html'
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class FileMoveModal extends BaseActionModal implements OnInit {
  public fileMoveModel: FileMove;
  public onFileMove: Function;
  public onFailure: Function;
  public structureLoading: boolean;
  public structure: Structure[];
  @ViewChild('fileMoveModal') childModal: ModalDirective;

  constructor(
    private fileService: FileService,
    private documentStructureService: DocumentStructureService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadWriteable();
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    this.moveFile();
  }

  moveFile() {
    this.loading = true;
    this.fileService
      .move(this.fileMoveModel)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        (_file: any) => {
          this.onFileMove();
        },
        error => {
          this.onFailure(error);
        }
      );
  }

  substructureSelect(structure: Structure) {
    this.fileMoveModel.parentId = structure.id;
  }

  loadWriteable() {
    this.structureLoading = true;
    this.documentStructureService
      .getWriteable()
      .pipe(
        finalize(() => {
          this.structureLoading = false;
        })
      )
      .subscribe((structure: Structure[]) => {
        this.structure = structure;
      });
  }

  isFileMoveModelValid() {
    return this.fileMoveModel.ids != null && this.fileMoveModel.ids.length > 0 && this.fileMoveModel.parentId != null;
  }
}
