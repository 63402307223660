import { zip as observableZip, of as observableOf, Observable } from 'rxjs';

import { map, finalize, tap, mergeMap } from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { SignatureComponent } from '@hrz/shared/components/canvas-helper/signature/signature.component';
import { CheckOutModalData } from '@hrz/core/models/dossier/check-out-modal-data';
import { CheckOutModalStepType } from '@hrz/core/models/dossier/check-out-modal-step-type';
import { ModalStep } from '@hrz/core/models/dossier/modal-step';
import { File as FileResult } from '@hrz/core/models/document/file';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { DossierService } from '@hrz/core/services/dossier.service';
import { SpinnerService } from '@hrz/core/services/spinner.service';

@Component({
  templateUrl: './dossier-instruction-check-out.modal.html',
  styleUrls: ['./dossier-instruction-check-out.modal.scss']
})
@DynamicModal()
export class DossierInstructionCheckOutModal implements AfterViewInit, OnInit {
  @ViewChild('dossierInstructionCheckOut') childModal: ModalDirective;
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('fitterSignature') fitterSignature: SignatureComponent;
  @ViewChild('signWaiver') signWaiver: SignatureComponent;

  model: CheckOutModalData;
  onInit: Observable<FileDownload>;
  onFinish: (documents: FileResult[]) => FileResult;
  onSave: (CheckOutModalData: CheckOutModalData) => CheckOutModalData;
  onCancel: () => void;
  downloadDocument: (document: FileResult) => void;
  generateCheckOutDocument: (model: CheckOutModalData) => Observable<string>;
  saveDocument: (file: string, filename: string) => Observable<FileResult>;
  prefillDocument: (model: CheckOutModalData) => Observable<string>;
  getSignWaiverDocument: (model: CheckOutModalData) => Observable<Blob>;
  saveBlob: (blob: Blob, fileName: string) => Observable<FileResult>;
  generateSignWaiver: (model: CheckOutModalData) => Observable<string>;
  saveCheckOutDocument: (file: FileResult) => any;
  saveUnsignedCheckOutDocument: (file: FileResult) => any;
  saveWaiverDeclarationtDocument: (file: FileResult) => any;

  ModalStepType = CheckOutModalStepType;
  private closeModal: Function;

  constructor(
    private spinnerService: SpinnerService,
    private dossierService: DossierService
  ) {}

  ngOnInit() {
    const stepType = this.getStepTypeByStep(this.model.step);
    this.model.modalStep = this.getDefaultModalStep(stepType);
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;

    // start Metrics;
    const start = new Date().getTime();
    this.onInit
      .pipe(
        mergeMap(file => {
          this.model.checkInDocumentFile = file;
          return this.prefillDocument(this.model);
        }),
        mergeMap((content: string) => {
          this.model.checkInDocumentContent = content;
          return this.saveDocument(content, 'Check-out_form_Glassdrive_unsigned.pdf');
        }),
        tap((file: FileResult) => this.saveUnsignedCheckOutDocument(file)),
        finalize(() => (this.model.navigationInProgress = false))
      )
      .subscribe(x => {
        this.model.checkInDocument = x;
        this.spinnerService.hide('chekInSpinner');
        // Stop metrics
        this.model.metricClientSignature = this.getMetrics(start);
      });
  }

  ngAfterViewInit(): void {
    this.spinnerService.show('chekInSpinner');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };
  }

  ngOnDestroy() {
    const jsonPayload = [];
    // TODO: create 2D array an loop over it instead of checking the model
    if (!this.model.metricClientSignature || this.model.metricClientSignature != 0) {
      jsonPayload[0] = this.dossierService.createMetricPayload(
        this.model.dossierId,
        this.model.metricClientSignature,
        'checkout',
        'clientsignature'
      );
      this.model.metricClientSignature = 0;
    }

    if (!this.model.metricFitterSignature || this.model.metricFitterSignature != 0) {
      jsonPayload[1] = this.dossierService.createMetricPayload(
        this.model.dossierId,
        this.model.metricFitterSignature,
        'checkout',
        'fittersignature'
      );
      this.model.metricFitterSignature = 0;
    }

    if (!this.model.metricFinish || this.model.metricFinish != 0) {
      jsonPayload[2] = this.dossierService.createMetricPayload(this.model.dossierId, this.model.metricFinish, 'checkout', 'finish');
      this.model.metricFinish = 0;
    }

    this.dossierService.createMetrics(this.model.dossierId, jsonPayload).then(() => this.close());
  }

  private getDefaultModalStep = (step: number): ModalStep => {
    return [
      {
        step: CheckOutModalStepType.clientSignature,
        nextStepText: 'DOSSIER.MODALS.STEP_2_3_OUT',
        previousStepText: 'COMMON.CANCEL',
        nextStepSelect: this.navigateToStep(CheckOutModalStepType.fitterSignature),
        previousStepSelect: this.cancel.bind(this),
        canGoNextStep: () => this.model.clientSignature != null && this.model.clientSignature.length > 0,
        anyChanges: false,
      },
      {
        step: CheckOutModalStepType.fitterSignature,
        nextStepText: 'DOSSIER.MODALS.STEP_3_3_OUT',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.navigateToStep(CheckOutModalStepType.signWaiver),
        previousStepSelect: this.navigateToStep(CheckOutModalStepType.clientSignature),
        canGoNextStep: () => this.model.fitterSignature != null && this.model.fitterSignature.length > 0,
        anyChanges: false,
      },
      {
        step: CheckOutModalStepType.signWaiver,
        nextStepText: 'DOSSIER.MODALS.FINISH_CHECK_OUT',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.finish.bind(this),
        previousStepSelect: this.navigateToStep(CheckOutModalStepType.fitterSignature),
        canGoNextStep: () => this.model.signWaiver != null && this.model.signWaiver.length > 0,
        anyChanges: false,
      },
    ].find((modalStep: ModalStep) => modalStep.step === this.getStepTypeByStep(step));
  }

  navigateToStep = (stepType: CheckOutModalStepType) => () => {
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;
    this.beforeNavigationAction()
      .pipe(
        finalize(() => {
          this.model.navigationInProgress = false;
          this.spinnerService.hide('chekInSpinner');
        })
      )
      .subscribe(() => {
        this.model.step = +stepType;
        this.model.modalStep = this.getDefaultModalStep(stepType);
      });
  }

  cancel(): void {
    this.closeModal();
  }

  close(): void {
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onSave(model);
      });
  }

  updateModel() {
    this.model.clientSignature = this.clientSignature.getImageAsString();
    this.model.fitterSignature = this.fitterSignature.getImageAsString();
    this.model.signWaiver = this.signWaiver.getImageAsString();
    this.model.signWaiverBlob = this.signWaiver.getImage();
    return observableOf(this.model);
  }

  finish() {
    // Metrics
    const start = new Date().getTime();
    // Here should be generated:
    // PDF for Checkout (signed with client and fitter)
    // PDF for sign waiver (signed)
    // only PDFs should be passed
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;
    // this.updateModel()
    //     .flatMap(this.generateCheckOutDocument)
    //     .map((template: string) => {
    //         return template;
    //     })
    //     .flatMap((x: string) => this.saveDocument(x, 'Check-out_form_Glassdrive.pdf'))
    //     .map((result: FileResult) => {
    //         this.model.checkOutDocumentId = result.id;
    //         return this.model;
    //     })

    this.updateModel()
      .pipe(
        mergeMap(this.getSignWaiverDocument),
        mergeMap((blob: Blob) => {
          this.model.signWaiverDocumentContent = blob;
          return this.saveBlob(blob, 'Sign_waiver.pdf');
        }),
        tap((file: FileResult) => this.saveWaiverDeclarationtDocument(file)),
        map(file => {
          this.model.signWaiverDocumentId = file.id;
          this.model.metricFinish = this.getMetrics(start);
          return this.model;
        }),

        // this.updateModel()
        //     .flatMap(this.generateSignWaiver)
        //     .map((template: string) => {
        //         return template;
        //     })
        //     .flatMap((x: string) => this.saveDocument(x, 'Sign_waiver.pdf'))
        //     .map((result: FileResult) => {
        //         this.model.signWaiverDocumentId = result.id;
        //          return this.model;
        //      })
        finalize(() => {
          this.closeModal();
        }),
        tap((model: CheckOutModalData) => {
          this.onSave(model);
        }),
        mergeMap((model: CheckOutModalData) =>
          observableOf([
            { id: model.checkInDocument.id, filename: model.checkInDocument.filename, size: model.checkInDocument.size },
            { id: model.checkOutDocumentId, filename: 'Check-out_form_Glassdrive.pdf', size: 0 },
            { id: model.signWaiverDocument.id, filename: model.signWaiverDocument.filename, size: model.signWaiverDocument.size },
            { id: model.signWaiverDocumentId, filename: 'Sign_waiver.pdf', size: 0 },
          ] as FileResult[])
        )
      )
      .subscribe((documents: FileResult[]) => {
        this.onFinish(documents);
        // this.closeModal();
      });
  }

  clientSignatureChange(clientSignature: string) {
    this.model.clientSignature = clientSignature;
  }

  fitterSignatureChange(fitterSignature: string) {
    this.model.fitterSignature = fitterSignature;
  }

  signWaiverChange(signWaiver: string) {
    this.model.signWaiver = signWaiver;
  }

  private getStepTypeByStep(step: number): CheckOutModalStepType {
    this.spinnerService.hide('chekInSpinner');
    return CheckOutModalStepType[CheckOutModalStepType[step]];
  }

  childComponentDataChange() {
    this.model.modalStep.anyChanges = true;
  }

  beforeNavigationAction(): Observable<{}> {
    switch (this.model.modalStep.step) {
      case CheckOutModalStepType.fitterSignature: {
        const start = new Date().getTime();
        const signWaiverDoc = this.getSignWaiverDocument(this.model).pipe(
          mergeMap((blob: Blob) => {
            this.model.signWaiverDocumentContent = blob;
            return this.saveBlob(blob, 'Sign_waiver_unsigned.pdf');
          }),
          mergeMap(file => {
            this.model.signWaiverDocument = file;
            return observableOf({});
          })
        );

        const signedCheckOutDoc = this.updateModel().pipe(
          mergeMap(this.generateCheckOutDocument),
          map((template: string) => {
            return template;
          }),
          mergeMap((x: string) => this.saveDocument(x, 'Check-out_form_Glassdrive.pdf')),
          tap((result: FileResult) => this.saveCheckOutDocument(result)),
          map((result: FileResult) => {
            this.model.checkOutDocumentId = result.id;
            return observableOf({});
          })
        );

        return observableZip(signWaiverDoc, signedCheckOutDoc).pipe(
          mergeMap(_ => {
            this.model.metricFitterSignature = this.getMetrics(start);
            return observableOf({});
          })
        );
      }
      default:
        return observableOf({});
    }
  }

  getMetrics(start: number) {
    return (new Date().getTime() - start) / 1000;
  }
}
