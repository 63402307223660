import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'tm-form-file-uploader',
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FormFileUploaderComponent), multi: true }],
  template: `
    <input
      class="form-control"
      type="file"
      (change)="fileChange($event.target.files[0])"
      [disabled]="isDisabled"
      [ngClass]="[
        control.valid ? 'ng-valid' : 'ng-invalid',
        control.pristine ? 'ng-pristine' : 'ng-dirty',
        control.touched ? 'ng-touched' : 'ng-untouched'
      ]" />
  `,
})
export class FormFileUploaderComponent implements ControlValueAccessor {
  @Input() control: FormControl;
  isDisabled: boolean;
  onChange = (_: File) => {};
  onTouched = () => {};

  writeValue(_value) {}
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  fileChange(file: File) {
    if (file == null) {
      return this.onChange(null);
    }
    this.onChange(file);
  }
}
