import { Component, OnInit, Input, ElementRef, Output, EventEmitter, AfterViewInit, AfterContentInit, OnDestroy } from '@angular/core';
import * as SignaturePadNative from 'signature_pad';

interface Point {
  x: number;
  y: number;
  time: number;
}

type PointGroup = Array<Point>;

@Component({
  selector: 'hrz-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css'],
})
export class SignatureComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
  @Input() public options: Object;
  @Input() height: number;
  @Input() width: number;
  @Input() showBorder: boolean;
  @Output() onDrawComplete: EventEmitter<string>;
  @Output() public onBeginEvent: EventEmitter<boolean>;
  @Output() public onEndEvent: EventEmitter<boolean>;

  private signaturePad: any;
  private elementRef: ElementRef;
  private parentElement: HTMLElement;

  constructor(elementRef: ElementRef) {
    this.elementRef = elementRef;
    this.options = this.options || {};
    this.onBeginEvent = new EventEmitter();
    this.onEndEvent = new EventEmitter();
    this.onDrawComplete = new EventEmitter<string>();
    this.parentElement = (this.elementRef.nativeElement as HTMLElement).parentElement;
  }

  ngOnInit() {}

  public ngAfterViewInit() {
    this.set('minWidth', 3);
    this.set('canvasHeight', this.height || this.parentElement.clientHeight);
    this.set('canvasWidth', this.width || this.parentElement.clientWidth);
    this.clear();
    this.setBorder();
    if (this.showBorder) {
      this.setBorder();
    }
  }

  public ngAfterContentInit(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');

    if ((this.options as any).canvasHeight) {
      canvas.height = (this.options as any).canvasHeight;
    }

    if ((this.options as any).canvasWidth) {
      canvas.width = (this.options as any).canvasWidth;
    }

    this.signaturePad = new SignaturePadNative.default(canvas, this.options);
    this.signaturePad.onBegin = this.onBegin.bind(this);
    this.signaturePad.onEnd = this.onEnd.bind(this);
  }

  public ngOnDestroy(): void {
    const canvas: any = this.elementRef.nativeElement.querySelector('canvas');
    canvas.width = 0;
    canvas.height = 0;
  }

  public clear() {
    this.signaturePad.clear();
    this.onDrawComplete.emit(null);
  }

  public resizeCanvas(): void {
    // When zoomed out to less than 100%, for some very strange reason,
    // some browsers report devicePixelRatio as less than 1
    // and only part of the canvas is cleared then.
    const ratio: number = Math.max(window.devicePixelRatio || 1, 1);
    const canvas: any = this.signaturePad.canvas;
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    this.signaturePad.clear(); // otherwise isEmpty() might return incorrect value
  }

  // Returns signature image as an array of point groups
  public toData(): Array<PointGroup> {
    if (this.signaturePad) {
      return this.signaturePad.toData();
    } else {
      return [];
    }
  }

  public resize() {
    this.set('canvasWidth', this.width || this.parentElement.clientWidth);
  }

  // Draws signature image from an array of point groups
  public fromData(points: Array<PointGroup>): void {
    this.signaturePad.fromData(points);
  }

  // Returns signature image as data URL (see https://mdn.io/todataurl for the list of possible paramters)
  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  // Draws signature image from data URL
  public fromDataURL(dataURL: string, options: any = {}): void {
    // set default height and width on read data from URL
    if (!options.hasOwnProperty('height') && (this.options as any).canvasHeight) {
      options.height = (this.options as any).canvasHeight;
    }
    if (!options.hasOwnProperty('width') && (this.options as any).canvasWidth) {
      options.width = (this.options as any).canvasWidth;
    }
    this.signaturePad.fromDataURL(dataURL, options);
  }

  public fromBase64ImageString(value: string) {
    return this.fromDataURL('data:image/png;base64,' + value, {});
  }

  public getImage(): Blob {
    const data = this.toDataURL().split(',');
    const mimeString = data[0].split(':')[1].split(';')[0];
    const array: number[] = atob(data[1])
      .split('')
      .map(x => x.charCodeAt(0));
    return new Blob([new Uint8Array(array)], { type: mimeString });
  }

  public getImageAsString(): string {
    return this.toDataURL().replace(/^data:image\/(png|jpg);base64,/, '');
  }

  public setBorder() {
    const canvas = (this.elementRef.nativeElement as HTMLElement).getElementsByTagName('canvas')[0];
    canvas.style.border = '1px solid black';
  }

  // Returns true if canvas is empty, otherwise returns false
  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  // Unbinds all event handlers
  public off(): void {
    this.signaturePad.off();
  }

  // Rebinds all event handlers
  public on(): void {
    this.signaturePad.on();
  }

  // set an option on the signaturePad - e.g. set('minWidth', 50);
  public set(option: string, value: any): void {

    switch (option) {
      case 'canvasHeight':
        this.signaturePad._canvas.height = value;
        break;
      case 'canvasWidth':
        this.signaturePad._canvas.width = value;
        break;
      default:
        this.signaturePad[option] = value;
    }
  }

  // notify subscribers on signature begin
  public onBegin(): void {
    this.onBeginEvent.emit(true);
  }

  // notify subscribers on signature end
  public onEnd(): void {
    this.onEndEvent.emit(true);
    this.onDrawComplete.emit(this.getImageAsString());
  }

  public queryPad(): any {
    return this.signaturePad;
  }

}
