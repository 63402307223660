import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';

@Component({
  selector: 'edit-dossier-frame-modal',
  providers: [TranslateService],
  templateUrl: './edit-dossier-frame.modal.html',
})
@DynamicModal()
export class EditDossierFrameModal implements OnInit, AfterViewInit, OnDestroy {
  public onClose: Function;
  public onOk: Function;
  public storeLocatorUrl: string;
  public storeLocatorSaveUrl: SafeUrl;
  public dossierId: number;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;
  constructor(
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    console.log('EditDossierFrameModal.ngOnInit');

    this.storeLocatorUrl = ConfigurationManager.AppSettings.applicationUrl + '/#/dossiers/edit/' + this.dossierId;
    this.storeLocatorSaveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.storeLocatorUrl);
  }

  handleMessage = event => {
    if (event.data.event === 'dossier_edited') {
      this.dossierUpdated(event.data.dossierId);
    }
  }

  ngAfterViewInit(): void {
    console.log('EditDossierFrameModal.ngAfterViewInit');

    if (window.addEventListener) {
      window.addEventListener('message', this.handleMessage, false);
    }

    this.childModal.show();
  }

  ngOnDestroy(): void {
    console.log('EditDossierFrameModal.ngOnDestroy');
    window.removeEventListener('message', this.handleMessage, false);
  }

  dossierUpdated(dossierId: number): void {
    if (this.onOk) { this.onOk(dossierId); }
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }
}
