import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AlertMessageComponent } from './components/alert-message/alert-message.component';
import { BreadcrumbsComponent } from './components/breadcrumb/breadcrumb.component';
import { FullLayoutComponent } from './components/layouts/full-layout.component';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { SwitchVersionComponent } from './components/switch-version/switch-version.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { ToastComponent } from './components/toaster/toast/toast.component';
import { UserProfileDropdownComponent } from './components/user-profile-dropdown/user-profile-dropdown.component';
import { AlertMessagePipe } from './components/alert-message/AlertMessage.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertModule, BsDropdownModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { PipesModule } from '@hrz/shared/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { SidebarToggleDirective, MobileSidebarToggleDirective } from './directives/sidebar.directive';
import { SlimLoadingBarComponent } from './components/slim-loading-bar/slim-loading-bar.component';


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AlertModule.forRoot(),
    RouterModule,
    TranslateModule,
    PipesModule,
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    AlertMessageComponent,
    AlertMessagePipe,
    BreadcrumbsComponent,
    FullLayoutComponent,
    ScrollToTopComponent,
    SwitchVersionComponent,
    ToasterComponent,
    ToastComponent,
    UserProfileDropdownComponent,
    SidebarToggleDirective,
    MobileSidebarToggleDirective,
    SlimLoadingBarComponent
  ],
  exports: [
    AlertMessageComponent,
    BreadcrumbsComponent,
    FullLayoutComponent,
    ScrollToTopComponent,
    SwitchVersionComponent,
    UserProfileDropdownComponent,
    SlimLoadingBarComponent
  ],
  providers: [],
})
export class CoreModule {}
