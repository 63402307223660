// tslint:disable:max-line-length
export default {
  ResourcePlugin_Title: 'Zasób',
  ResourcePlugin_InsertForm_Title: 'Wstaw zasób',
  ResourcePlugin_InsertForm_Height_Label: 'Wysokość:',
  ResourcePlugin_InsertForm_Width_Label: 'Szerokość:',
  ResourcePlugin_InsertForm_Name_Label: 'Nazwa:',
  ResourcePlugin_InsertForm_Height_Placeholder: 'Wysokość w pikselach',
  ResourcePlugin_InsertForm_Width_Placeholder: 'Szerokość w pikselach',
  ResourcePlugin_InsertForm_Name_Placeholder: 'Nazwa',
  ResourcePlugin_InsertForm_ValidationError: 'Niepoprawne wartości',

  PropertyFormattingPlugin_Title: 'Formatowanie właściwości',
  PropertyFormattingPlugin_Menu_Numeric: 'Numeryczne',
  PropertyFormattingPlugin_Menu_Datetime: 'Data i czas',

  PropertyFormats_Numeric_FixedPoint_Name: 'Stałoprzecinkowy',
  PropertyFormats_Numeric_FixedPoint_Example: '12.123 -> 12.1 | obsługuje specyfikator precyzji jako liczbę cyfr dzisiętnych',
  PropertyFormats_Numeric_Currency_Name: 'Walutowy',
  PropertyFormats_Numeric_Currency_Example: '123.456 -> 123.46€ | obsługuje specyfikator precyzji jako liczbę cyfr dzisiętnych',
  PropertyFormats_Numeric_Percent_Name: 'Procentowy',
  PropertyFormats_Numeric_Percent_Example: '1 -> 100.00 % | obsługuje specyfikator precyzji jako liczbę miejsc po przecinku',
  PropertyFormats_Numeric_Number_Name: 'Numeryczny',
  PropertyFormats_Numeric_Number_Example: '1234.567 -> 1,234.57 | obsługuje specyfikator precyzji jako liczbę miejsc po przecinku',
  PropertyFormats_Datetime_ShortDateLongTime_Name: 'Krótka data + długi czas',
  PropertyFormats_Datetime_ShortDateLongTime_Example: '3/9/2008 10:05:07',
  PropertyFormats_Datetime_LongTime_Name: 'Długi czas',
  PropertyFormats_Datetime_LongTime_Example: '10:05:07',

  'DomainPropertySelector.Title': 'Właściwości danych domenowych',
  'DomainPropertySelector.Menu.Text': '-- Brak właściwości do wyboru --',
  'DomainPropertySelector.Menu.Tooltip': 'Wybierz typ obiektu domenowego w zakładce "Ogólnie informacje"',
};
