import {
  Component,
  ElementRef,
  AfterViewInit,
  OnInit,
  Input,
  DoCheck,
  OnChanges,
  IterableDiffers,
  IterableDiffer,
  EventEmitter,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { TranslateInitializeService } from '@hrz/core/services/translate-initialize.service';

import { AvailableTimeRange } from '@hrz/core/models/scheduler/available-time-range';
import { DateRange } from '@hrz/core/models/scheduler/date-range';
import { timeRangeToMoment } from '@hrz/core/utils/timerange-to-moment';
import { DetectDevice } from '@hrz/core/utils/device-detector';
import { AppointmentCapacity, AppointmentCapacityException } from '@hrz/core/models/appointment-capacity';
import { AppointmentSchedulerModel } from '@hrz/core/models/scheduler/appointment-scheduler-model';

declare var kendo: any;

@Component({
  selector: 'app-scheduler',
  template: ``,
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit, AfterViewInit, DoCheck, OnChanges {
  @Input() events: AppointmentSchedulerModel[];
  @Input() group: any;
  @Input() resources: any;
  @Input() selectable: boolean;
  @Input() removable: boolean;
  @Input() footer: string;
  @Input() availableTimeRanges: AvailableTimeRange[] = [];
  @Input() useSingleClickEvents = false;
  @Input() visible: boolean;
  @Input() startDate: Date;
  @Input() culture: string;
  @Input() appointmentCapacities: AppointmentCapacity[];
  @Input() appointmentCapacityExceptions: AppointmentCapacityException[];
  @Input() blockAddEvents: boolean;
  @Input() readOnly: boolean;

  @Output() onInit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() onEdit: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDragStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDragEnd: EventEmitter<any> = new EventEmitter<any>();
  @Output() onNavigate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDateChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  config: any;
  schedulerElement: any;
  scheduler: any;
  differ: IterableDiffer<{}>;

  weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  title1: any;

  constructor(
    element: ElementRef,
    iterableDiffers: IterableDiffers,
    private translateService: TranslateService,
    private translateInitializeService: TranslateInitializeService
  ) {
    this.schedulerElement = element.nativeElement;
    this.differ = iterableDiffers.find([]).create(null);
  }

  ngOnInit() {
    this.title1 = this.translateService.instant('SHARED.SCHEDULER.DAY');
    const device = DetectDevice();
    this.config = {
      selectable: !!this.selectable,
      change: this.change,
      add: this.add,
      dataBound: this.dataBound,
      moveStart: this.moveStart,
      moveEnd: this.moveEnd,
      resizeEnd: this.resizeEnd,
      edit: this.edit,
      editable: {
        resize: !this.readOnly,
        move: !this.readOnly,
        confirmation: false,
        destroy: !!this.removable,
      },
      group: this.group,
      remove: this.remove,
      resources: this.resources,
      eventTemplate: `
      #if (!appointment.isConfirmed) {#
        <div class="confirmation-sidebar"></div>
      #}#
      <div style="overflow: hidden" >
      <div class="day-view clearfix">
   <div class="col-l first-col-mgl">
      <p class="day-view appointment-time"><img height="11px" width="11px" src="/assets/img/time-icon.svg"> &nbsp; #: kendo.toString(start, "HH:mm") # - #: kendo.toString(end, "HH:mm") #</p>
   </div>
   <div class="col-r">
      <span class="damageLocation-text">
         #if (appointment.appointmentData.damageLocation) {#
         <div class="k-event-template"> #: appointment.appointmentData.damageLocation #</div>
         #}#
      </span>
   </div>
</div>
<div class="clearfix">
   <div class="col-l">
      <strong class="day-view-text">
         <div class="k-event-template vehicle-name">  #if (appointment.isMobileJob) {# <img height="10px" width="10px" src="/assets/img/mobilejob.svg"> #}# #if (appointment.appointmentData.modelBrandName) {# <span  > #: appointment.appointmentData.modelBrandName #</span> #}# #if (appointment.appointmentData.modelName) {# <span  > #: appointment.appointmentData.modelName #</span> #}#</div>
      </strong>
      <strong class="week-view-text">
         <div class="k-event-template vehicle-name #if (!appointment.isConfirmed) {# appointment-confirmed #}#"> #if(appointment.isMobileJob){# <img height="10px" width="10px" src="/assets/img/mobilejob.svg"> #}# #: title #</div>
      </strong>
   </div>
   <div class="col-r day-view">
      <div class="k-event-template"> #if (appointment.appointmentData.firstName) {# <span> #: appointment.appointmentData.firstName #</span> #}# #if (appointment.appointmentData.lastName) {# <span> #: appointment.appointmentData.lastName #</span> #}#  </div>
   </div>
</div>
<div class="day-view clearfix">
   <div class="col-l">
      <span class="licensePlate-text">
         #if (appointment.appointmentData.licensePlate) {#
         <div class="k-event-template"> #: appointment.appointmentData.licensePlate #</div>
         #}#
      </span>
   </div>
   <div class="col-r">
      <span class="phone-text day-view">
         #if (appointment.appointmentData.phoneNumber) {#
         <div class="k-event-template"> #: appointment.appointmentData.phoneNumber #</div>
         #}#
      </span>
   </div>
</div>
<div class="day-view clearfix address-col">
   <div >
      <div class="fittingAddressStreet-text">
         #if (appointment.appointmentData.fittingAddressStreet) {#
         <div class="k-event-template"> #: appointment.appointmentData.fittingAddressStreet #</div>
         #}#
      </div>
      <div class="k-event-template"> <span class="fittingAddressCity-text"> #if (appointment.appointmentData.fittingAddressCity) {# <span  > #: appointment.appointmentData.fittingAddressCity , #</span> #}# </span> <span class="fittingAddressCountryName-text"> #if (appointment.appointmentData.fittingAddressCountryName) {# <span > #: appointment.appointmentData.fittingAddressCountryName #</span> #}# </span> </div>
   </div>
   <div class="col-r"> </div>
   </div>
</div>`,
      dataSource: new kendo.data.SchedulerDataSource({
        data: this.events,
      }),
      navigate: this.navigate,
      views: device === 'phone' ? [{ type: 'day', selected: true }] : ['day', { type: 'week', selected: true }],
      footer: {
        command: this.footer ? this.footer : false,
      },
      workDayStart: moment().startOf('day').toDate(),
      workDayEnd: moment().endOf('day').toDate(),
      date: this.startDate ? this.startDate : moment().toDate(),
      workWeekStart: 0,
      workWeekEnd: 6,
      showWorkHours: true,
      allDaySlot: false,
      majorTimeHeaderTemplate: kendo.template('<strong>#=kendo.toString(date, "HH:mm")#</strong>'),
      dateHeaderTemplate: this.getDateHeaderTemplate(),
      messages: {
        today: this.translateService.instant('SHARED.SCHEDULER.TODAY'),
        views: {
          day: this.translateService.instant('SHARED.SCHEDULER.DAY'),
          week: this.translateService.instant('SHARED.SCHEDULER.WEEK'),
        },
      },
      mobile: device === 'phone' || device === 'tablet',
    };
  }

  ngAfterViewInit() {
    this.translateInitializeService.getLanguageObservable().subscribe((lang: string) => {
      kendo.culture(lang);

      this.scheduler = kendo.jQuery(this.schedulerElement).kendoScheduler(this.config).data('kendoScheduler');

      this.onInit.emit();
      this.updateDataSource();
    });
  }

  ngDoCheck() {
    const changes = this.differ.diff(this.events);
    if (!this.scheduler || changes == null) {
      return;
    }
    this.updateDataSource();
  }

  ngOnChanges(changes: any) {
    if (!this.scheduler) {
      return;
    }
    if (
      (changes['visible'] && this.visible) ||
      (changes['availableTimeRanges'] && this.availableTimeRanges) ||
      (changes['events'] && this.events)
    ) {
      this.updateDataSource();
      this.setBusinessHours();
    }
    if (changes['startDate'] && this.startDate) {
      this.updateStartDate();
    }
    if (changes['culture'] && this.culture) {
      this.updateCulture();
    }
  }

  add = event => {
    event.preventDefault();
    const weekDayNumber: number = event.event.start.getDay();

    if (this.readOnly || this.blockAddEvents || !this.isSlotOpen(event.event.start, event.event.end, weekDayNumber)) {
      return;
    }
    this.onAdd.emit(event.event);
  }

  change = event => {
    this.onChange.emit(event.event);
  }

  remove = event => {
    event.preventDefault();

    if (this.readOnly) {
      return;
    }

    this.onRemove.emit(event.event);
  }

  edit = event => {
    event.preventDefault();
    if (this.readOnly) {
      return false;
    }
    this.onEdit.emit(event.event);
  }

  moveEnd = event => {
    event.preventDefault();
    const weekDayNumber: number = event.start.getDay();
    if (this.readOnly || !this.isSlotOpen(event.start, event.end, weekDayNumber)) {
      return false;
    }
    this.onDragEnd.emit(event);
  }

  moveStart = event => {
    this.onDragStart.emit(event);
  }

  resizeEnd = event => {
    event.preventDefault();
    const weekDayNumber: number = event.start.getDay();
    if (this.readOnly || !this.isSlotOpen(event.start, event.end, weekDayNumber)) {
      return false;
    }
    this.onDragEnd.emit(event);
  }

  navigate = event => {
    this.onNavigate.emit(event.event);
  }

  dataBound = event => {
    const view = event.sender.view();

    this.applyColoring(view);

    this.onDateChange.emit({
      startDate: moment(view.startDate()).utc().format(),
      endDate: moment(view.endDate()).endOf('day').utc().format(),
    } as DateRange);
  }

  updateDataSource() {
    const dataSource = new kendo.data.SchedulerDataSource({
      data: this.events,
    });

    this.scheduler.setDataSource(dataSource);
  }

  updateStartDate() {
    this.scheduler.date(this.startDate);
  }

  updateCulture() {
    this.scheduler.options.dateHeaderTemplate = this.getDateHeaderTemplate();
  }

  getDateHeaderTemplate(): string {
    return kendo.template(
      `<span class="k-link k-nav-day"><strong>#=kendo.toString(date, 'ddd ${this.getDateFormatForCulture(
        this.culture
      )}')#</strong></span>`
    );
  }

  private getDateFormatForCulture(culture: string): string {
    console.log('culture ' + culture);
    switch (culture) {
      case 'en-US':
        return 'MM/dd';
      case 'pt-PT':
        return 'dd/MM';
      default:
        return 'dd/MM';
    }
  }

  private applyColoring(view: any) {
    if (!this.visible || !this.scheduler) {
      return;
    }

    const container = view.element;
    const cells: any[] = container.find('td[role=gridcell]');

    for (let i = 0; i < cells.length; i++) {
      const cell = kendo.jQuery(cells[i]);
      const slot = this.scheduler.slotByElement(cell);
      if (slot) {
        const weekDayNumber: number = slot.startDate.getDay();
        if (!this.isSlotOpenByOpeningHours(slot.startDate, slot.endDate, weekDayNumber)) {
          cell.addClass('unavailableHours');
          cell.removeClass('availableHours');
        } else if (!this.isSlotOpenByCapacity(slot.startDate, slot.endDate, weekDayNumber)) {
          // different styling can be applied for unavailable slots based on capacity
          cell.addClass('unavailableHoursByCapacity');
          cell.removeClass('availableHours');
          cell.removeClass('unavailableHours');
        } else {
          cell.addClass('availableHours');
          cell.removeClass('unavailableHours');
          if (!this.blockAddEvents) { kendo.jQuery(cell.attr('title', this.translateService.instant('SHARED.DOUBLE_CLICK'))); }
        }

        this.events.forEach((evt: AppointmentSchedulerModel) => {
          if (evt.appointment.blockedMinutesBefore > 0) {
            const startBlockTime = moment.utc(evt.appointment.start).add(-evt.appointment.blockedMinutesBefore, 'minutes');
            const startAppointmentTime = moment.utc(evt.appointment.start);
            const slotStart = moment(slot.startDate);
            const slotEnd = moment(slot.endDate);

            if (startBlockTime <= slotStart && slotEnd <= startAppointmentTime) {
              cell.addClass('blockedHours');
              cell.removeClass('availableHours');
              cell.removeClass('unavailableHours');
            }
          }

          if (evt.appointment.blockedMinutesAfter > 0) {
            const endBlockTime = moment.utc(evt.appointment.end).add(evt.appointment.blockedMinutesAfter, 'minutes');
            const endAppointmentTime = moment.utc(evt.appointment.end);
            const slotStart = moment(slot.startDate);
            const slotEnd = moment(slot.endDate);

            if (endBlockTime >= slotEnd && slotStart >= endAppointmentTime) {
              cell.addClass('blockedHours');
              cell.removeClass('availableHours');
              cell.removeClass('unavailableHours');
            }
          }
        });
      }
    }
  }

  private isSlotOpen(startDate: Date, endDate: Date, weekDayNumber: number) {
    return (
      this.isSlotOpenByOpeningHours(startDate, endDate, weekDayNumber) && this.isSlotOpenByOpeningHours(startDate, endDate, weekDayNumber)
    );
  }

  private isSlotOpenByOpeningHours(startDate: Date, endDate: Date, weekDayNumber: number) {
    // validate based on current date - user cannot add event to past date
    const currentDate = moment(new Date());
    if (moment(startDate).isBefore(currentDate)) {
      return false;
    }

    // validate based on opening hours
    const dayNameEnglish = this.weekdays[weekDayNumber];
    const availableTimeRangePerGivenDay = this.availableTimeRanges.filter(e => e.dayOfWeek === dayNameEnglish);

    for (let j = 0; j < availableTimeRangePerGivenDay.length; j++) {
      const availableRange = timeRangeToMoment(
        availableTimeRangePerGivenDay[j].timeFrom,
        availableTimeRangePerGivenDay[j].timeTo,
        startDate
      );

      if (moment(startDate).isSameOrAfter(availableRange.from) && moment(endDate).isSameOrBefore(availableRange.to)) {
        // slot is open based on OpeningHours, next check based on capacity exceptions
        return true;
      }
    }
    return false;
  }

  private isSlotOpenByCapacity(startDate: Date, endDate: Date, weekDayNumber: number) {
    // validate based on current date - user cannot add event to past date
    const currentDate = moment(new Date());
    if (moment(startDate).isBefore(currentDate)) {
      return false;
    }

    const dayNameEnglish = this.weekdays[weekDayNumber];
    const availableTimeRangePerGivenDay = this.availableTimeRanges.filter(e => e.dayOfWeek === dayNameEnglish);

    for (let j = 0; j < availableTimeRangePerGivenDay.length; j++) {
      const availableRange = timeRangeToMoment(
        availableTimeRangePerGivenDay[j].timeFrom,
        availableTimeRangePerGivenDay[j].timeTo,
        startDate
      );

      // get exceptions for the current day
      const exceptionsForDay = this.appointmentCapacityExceptions.filter(ae => {
        return ae.Year == availableRange.from.year() && ae.Week == availableRange.from.week() && ae.DayOfWeek == dayNameEnglish;
      });

      if (exceptionsForDay && exceptionsForDay.length > 0) {
        const noon = moment(availableRange.from).set({ h: 12, m: 0, s: 0 });

        // Check morning
        const totalCapacityMorning = exceptionsForDay
          .map(e => e.Morning)
          .reduce((accumulator, current) => {
            return accumulator + current;
          });

        if (totalCapacityMorning == 0) {
          if (moment(startDate).isSameOrAfter(availableRange.from) && moment(endDate).isSameOrBefore(noon)) {
            return false;
          }
        }

        // Check afternoon
        const totalCapacityAfternoon = exceptionsForDay
          .map(e => e.Afternoon)
          .reduce((accumulator, current) => {
            return accumulator + current;
          });

        if (totalCapacityAfternoon == 0) {
          if (moment(startDate).isSameOrAfter(noon) && moment(endDate).isSameOrBefore(availableRange.to)) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private setBusinessHours() {
    if (!this.availableTimeRanges || this.availableTimeRanges.length === 0) {
      this.scheduler.options.workDayStart = null;
      this.scheduler.options.workDayEnd = null;
      return;
    }

    const startDate = new Date(),
      intialVal = timeRangeToMoment(this.availableTimeRanges[0].timeFrom, this.availableTimeRanges[0].timeTo, startDate);
    let start = intialVal.from,
      end = intialVal.to;

    for (let j = 0; j < this.availableTimeRanges.length; j++) {
      const availableRange = timeRangeToMoment(this.availableTimeRanges[j].timeFrom, this.availableTimeRanges[j].timeTo, startDate);

      start = availableRange.from.isBefore(start) ? availableRange.from : start;
      end = availableRange.to.isAfter(end) ? availableRange.to : end;
    }
    this.scheduler.options.workDayStart = start.toDate();
    this.scheduler.options.workDayEnd = end.toDate();

    // update the view by re-selecting it
    if (this.scheduler.view()) { this.scheduler.view(this.scheduler.view().name); }
  }
}
