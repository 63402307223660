import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@hrz/shared/shared.module';
import { StructureCreateModal } from './modals/structure-create-modal/structure-create.modal';
import { StructureCreateComponent } from './modals/structure-create/structure-create.component';
import { StructureMoveModal } from './modals/structure-move-modal/structure-move.modal';
import { StructureManagePermissionsModal } from './modals/structure-manage-permissions-modal/structure-manage-permissions.modal';
import { FileMoveModal } from './modals/file-move-modal/file-move.modal';
import { FileUploadModal } from './modals/file-upload-modal/file-upload.modal';
import { FileUploadComponent } from './modals/file-upload/file-upload.component';
import { FileUploadModule } from 'primeng/primeng';
import { FileShareModal } from './modals/file-share-modal/file-share.modal';
import { StructureManagePermissionsComponent } from './modals/structure-manage-permissions/structure-manage-permissions.component';
import { FileDownloadModal } from './modals/file-download/file-download.modal';

@NgModule({
  imports: [TranslateModule.forChild(), SharedModule, FileUploadModule],
  declarations: [
    StructureCreateModal,
    StructureCreateComponent,
    StructureMoveModal,
    StructureManagePermissionsModal,
    FileMoveModal,
    FileUploadModal,
    FileUploadComponent,
    FileShareModal,
    StructureManagePermissionsComponent,
    FileDownloadModal,
  ],
})
export class DocumentManagementModalModule {}
