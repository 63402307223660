export const DetectDevice = (): string => {
  const userAgent = navigator.userAgent.toLowerCase();
  const tabletRegex = new RegExp(
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
  );
  const mobileRegex = new RegExp(
    // tslint:disable-next-line:max-line-length
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/
  );

  if (tabletRegex.test(userAgent)) {
    return 'tablet';
  } else if (mobileRegex.test(userAgent)) {
    return 'phone';
  }
  return 'desktop';
};
