import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { SharedModule } from '../../shared/shared.module';
import { EditDossierAppointmentModal } from './modals/edit-dossier-appointment-modal/edit-dossier-appointment.modal';
import { EditCreateWizardAppointmentModal } from './modals/edit-create-wizard-appointment-modal/edit-create-wizard-appointment.modal';
import { AddDossierAppointmentModal } from './modals/add-dossier-appointment-modal/add-dossier-appointment.modal';
import { DossierInstructionCheckInModal } from './modals/dossier-instruction-check-in-modal/dossier-instruction-check-in.modal';
import { FasterDossierInstructionCheckInModal } from './modals/dossier-instruction-check-in-modal/faster-dossier-instruction-check-in.modal';
import { DossierInstructionCheckOutModal } from './modals/dossier-instruction-check-out-modal/dossier-instruction-check-out.modal';
import { FasterDossierInstructionCheckOutModal } from './modals/dossier-instruction-check-out-modal/faster-dossier-instruction-check-out.modal';
import { IndicateDamageComponent } from './modals/indicate-damage/indicate-damage.component';
import { CanvasHelperModule } from '@hrz/shared/components/canvas-helper/canvas-helper.module';
import { CreditNoteReasonModal } from './components/invoicing/credit-note-reason.modal';
import { StoreLocatorModal } from './modals/store-locator/store-locator.modal';
import { SendNewMessageModal } from './modals/send-new-message-modal/send-new-message.modal';
import { ChangeFittingStationModal } from './modals/change-fitting-station-modal/change-fitting-station.modal';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { RequestCreditModal } from './components/invoicing/request-credit-modal/request-credit-modal.component';
import { RejectRequestCreditModal } from './components/invoicing/reject-request-credit-modal/reject-request-credit-modal.component';
import { ConversionProgressModalComponent } from './modals/conversion-progress-modal/conversion-progress-modal.component';
import { CreateNewNoteModal } from './modals/create-new-note-modal/create-new-note.modal';
import { PipesModule } from '@hrz/shared/pipes/pipes.module';
import { EditDossierFrameModal } from './modals/edit-dossier-frame/edit-dossier-frame.modal';
import { AuthorizationRejectedWarningModal } from './modals/authorization-rejected-warning-modal/authorization-rejected-warning-modal';
import { AddPromotionFrameModal } from './modals/add-promotion-frame/add-promotion-frame.modal';
import { ReverseTaxInvoiceModal } from './modals/reverse-tax-invoice-modal/reverse-tax-invoice.modal';
import { ModifyJobLineModal } from './modals/modify-job-line-modal/modify-job-line.modal';
import { CustomConfirmationModalComponentV2 } from '../../shared/modals/custom-confirmation-v2.modal';
import { MultiDocumentViewerModal } from '../../shared/modals/multi-document-viewer.modal';
import { IcAuthRequestModal } from './modals/action-button-modal/ic-auth-request.modal';
import { IcAuthApproveModal } from './modals/action-button-modal/ic-auth-approve.modal';
import { WarningDeprecatedVersionModal } from '@hrz/shared/modals/warning-deprecated-version/warning-deprecated-version-modal';

@NgModule({
  imports: [
    SharedModule,
    PipesModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    TranslateModule.forChild(),
    CanvasHelperModule,
    SelectDropDownModule,
  ],
  declarations: [
    AddDossierAppointmentModal,
    IcAuthRequestModal,
    IcAuthApproveModal,
    ChangeFittingStationModal,
    ConversionProgressModalComponent,
    CreditNoteReasonModal,
    DossierInstructionCheckInModal,
    DossierInstructionCheckOutModal,
    DossierInstructionCheckOutModal,
    EditDossierAppointmentModal,
    FasterDossierInstructionCheckInModal,
    FasterDossierInstructionCheckOutModal,
    IndicateDamageComponent,
    ModifyJobLineModal,
    CustomConfirmationModalComponentV2,
    RejectRequestCreditModal,
    RequestCreditModal,
    SendNewMessageModal,
    CreateNewNoteModal,
    StoreLocatorModal,
    EditDossierFrameModal,
    EditCreateWizardAppointmentModal,
    AuthorizationRejectedWarningModal,
    AddPromotionFrameModal,
    ReverseTaxInvoiceModal,
    MultiDocumentViewerModal,
    WarningDeprecatedVersionModal
  ],
  exports: []
})
export class DossierModalsModule {}
