import { Component, OnInit, ViewChild, ElementRef, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Point } from '@hrz/core/models/point';
import { HighlightImage } from '@hrz/core/models/highlight-image';

@Component({
  selector: 'app-highlight-image',
  templateUrl: './highlight-image.component.html',
  styleUrls: ['./highlight-image.component.css'],
})
export class HighlightImageComponent implements OnInit, AfterViewInit {
  @ViewChild('imageObject') imageElement: ElementRef;
  @ViewChild('points') pointsElement: ElementRef;
  @ViewChild('resultImage') resultElement: ElementRef;
  @Input() public image: string;
  @Input() public fillColor: string;
  @Input() public height: number;
  @Input() public width: number;
  @Input() public metadata: HighlightImage;
  @Output() public onHighlightedImageChanged: EventEmitter<number> = new EventEmitter<number>();
  @Input() isReadOnly: boolean;

  private points: Point[] = [];
  private imageCanvas: HTMLCanvasElement;
  private pointsCanvas: HTMLCanvasElement;
  private resultCanvas: HTMLCanvasElement;
  private readonly pointSize: number = 4;

  constructor(private parentElement: ElementRef) {
    this.isReadOnly = false;
  }

  ngAfterViewInit(): void {
    this.imageCanvas = this.imageElement.nativeElement as HTMLCanvasElement;
    this.pointsCanvas = this.pointsElement.nativeElement as HTMLCanvasElement;
    this.resultCanvas = this.resultElement.nativeElement as HTMLCanvasElement;

    const pointsContext: CanvasRenderingContext2D = this.pointsCanvas.getContext('2d');
    this.loadData();

    this.pointsCanvas.onclick = (event: MouseEvent) => {
      if (!this.isReadOnly) {
        const clientRect: ClientRect = this.imageCanvas.getBoundingClientRect();
        const x = event.clientX - clientRect.left;
        const y = event.clientY - clientRect.top;
        const point = new Point();
        point.x = x;
        point.y = y;
        this.points = this.reorganizePoints(point, this.pointSize);
        this.putDotsOnCanvas(pointsContext, this.points, this.pointSize);
        this.onHighlightedImageChanged.emit(this.points.length);
      }
    };
  }
  ngOnInit(): void {}

  public clearDots(): void {
    const context = this.pointsCanvas.getContext('2d');
    this.points = [];
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  }

  public async getImage(): Promise<Blob> {
    return new Promise<Blob>((resolve, _) => {
      const context = this.resultCanvas.getContext('2d');
      this.putDotsOnCanvas(context, this.points, this.pointSize, false);
      this.resultCanvas.toBlob(b => {
        resolve(b);
      });
    });
  }

  convertToBase64: any = imageBlob => {
    const tempFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      tempFileReader.onerror = () => {
        tempFileReader.abort();
        reject('Problem reading the Damage Indication Image!');
      };

      tempFileReader.onload = () => {
        resolve((tempFileReader.result as string).split(',').pop() + '');
      };
      tempFileReader.readAsDataURL(imageBlob);
    });
  }

  public async getMetadata(): Promise<HighlightImage> {
    const result = {
      id: this.metadata && this.metadata.id,
      baseImage: this.image,
      points: this.points,
      resultHeight: this.imageCanvas.height,
      resultWidth: this.imageCanvas.width,
      result: null, // await this.getImage(),
      resultBase64: '',
    };
    return this.getImage().then(async imageBlob => {
      result.result = imageBlob;
      result.resultBase64 = await this.convertToBase64(imageBlob);
      return Promise.resolve(result);
    });
  }

  public getPoints(): Point[] {
    return this.points;
  }

  public loadData() {
    const imageContext = this.imageCanvas.getContext('2d');
    const pointContext = this.pointsCanvas.getContext('2d');
    const resultContext = this.resultCanvas.getContext('2d');
    const imageSrc: string = this.metadata ? this.metadata.baseImage : null;

    this.loadImage(
      imageContext,
      (width: number, height: number) => {
        width = this.metadata ? this.metadata.resultWidth : width;
        height = this.metadata ? this.metadata.resultHeight : height;
        this.imageCanvas.width = this.pointsCanvas.width = this.width || width;
        this.imageCanvas.height = this.pointsCanvas.height = this.height || height;
        this.parentElement.nativeElement.width = this.width || width;
        this.parentElement.nativeElement.height = this.height || height;
      },
      imageSrc
    ).then(() => {
      this.points = this.metadata ? this.metadata.points : this.points;
      this.putDotsOnCanvas(pointContext, this.points, this.pointSize);
    });

    this.loadImage(resultContext, (width: number, height: number) => {
      width = this.metadata ? this.metadata.resultWidth : width;
      height = this.metadata ? this.metadata.resultHeight : height;
      this.resultCanvas.width = this.width || width;
      this.resultCanvas.height = this.height || height;
    }).then(() => {});
  }

  public getBoxStyle(): { [name: string]: string } {
    return {
      height: `${this.height}px`,
      width: `${this.width}px`,
    };
  }

  private loadImage(
    context: CanvasRenderingContext2D,
    resize: (width: number, height: number) => void,
    imageSrc: string = null
  ): Promise<void> {
    return new Promise<void>((resolve, _) => {
      const image = new Image();
      image.onload = (_event: Event) => {
        resize(image.width, image.height);
        context.drawImage(image, 0, 0, this.width || image.width, this.height || image.height);
        resolve();
      };
      image.src = imageSrc || this.image;
    });
  }

  private reorganizePoints(point: Point, pointSize: number): Point[] {
    const indexOfPointToRemove = this.points.findIndex(
      x => point.x - pointSize < x.x && x.x < point.x + pointSize && point.y - pointSize < x.y && x.y < point.y + pointSize
    );
    return indexOfPointToRemove >= 0 ? this.points.filter((_value, index) => index !== indexOfPointToRemove) : this.points.concat(point);
  }

  private putDotsOnCanvas(context: CanvasRenderingContext2D, points: Point[], pointSize: number, clear: boolean = true): void {
    if (clear) {
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    }
    context.fillStyle = this.fillColor || '#ff0000';
    points.forEach(point => {
      context.beginPath();
      context.arc(point.x, point.y, pointSize, 0, Math.PI * 2, true);
      context.fill();
    });
  }
}
