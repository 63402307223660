import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { Dossier } from '@hrz/core/models/dossier';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { DynamicProperty } from '@hrz/core/models/dynamic-property';

export class Document {
  Id: number;
  DocumentType: string;
  FileMetaData: FileMetaData;
  Number: number;
  UploadDate: Date;
  IsVisable: boolean;
  Dossier: Dossier;
  DossierVehicleId: number;
  InsuranceInfoId: number;
  DamageId: number;
  IsApproved: boolean;
  documentPath: string;
  IsSigned: boolean;

  // UI Related Properties...
  // Contains the Base64 Encoding, wich can be used with: 'data:' + file.type + ';base64,' + encodingvalue...
  FileContent: any;
  File: any; // this contains the Javascript File object....
  FileByteArray: any; // Contains the FileByteArray... (Mostly used for PDF's...)
  FinishedLoading: Boolean; // Indication whether the Contents has been loaded...
  FileDownload: FileDownload;
  FileDataUrl: string;
  FileBlob: Blob;
  CanvasDataUrl: any;
  thumbnails: any;
  DocumentDate: Date;
  Reference: string;
  IsSignedOnUpload: boolean;
  FileNameTranslated: string;
  DocumentMetaData: DynamicProperty[];
}

export class DocFilter {
  type: string;
  latest: boolean;
}
