import { HighlightImage } from '@hrz/core/models/highlight-image';

export class DamageIndicateData {
  id: number;
  lightSensor = false;
  rainSensor = false;
  radio = false;
  dashboard = false;
  centralLocking = false;
  gps = false;
  mobilePhone = false;
  wipers = false;
  electricMirrors = false;
  windowRunner = false;
  adas = false;
  viaVerde = false;
  other = false;
  otherInfo = '';
  highlightedDamageImage: HighlightImage;
}
