import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'zoom-image-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ imageTitle }}</h4>
            <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            <app-image-magnifier [imageSrc]="message" [lensZommLevel]="3" [lensCustomWidth]="150" [lensCustomHeight]="150">
            </app-image-magnifier>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class ZoomImageModal implements OnInit, AfterViewInit, OnDestroy {
  public message: SafeResourceUrl;
  public imageTitle: string;
  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  ngOnInit(): void {
    console.log('ZoomImageModal.ngOnInit');
  }
  ngAfterViewInit(): void {
    console.log('ZoomImageModal.ngAfterViewInit');
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('ZoomImageModal.ngOnDestroy');
  }
  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }
}
