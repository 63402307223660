import { Component, ElementRef, EventEmitter, Input, OnInit, Output, AfterContentChecked } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser/src/security/dom_sanitization_service';

import { tap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { Document } from '@hrz/core/models/document';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { FileMetaData } from '@hrz/core/models/file-meta-data';
import { FileService } from '@hrz/core/services/file.service';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { DynamicModalService } from '@hrz/shared/dynamic-modal.module';
import { ByteHelpers } from '@hrz/core/utils/helpers';
import { ConfirmationsModule } from '@hrz/shared/modals/confirmations.module';
import { DeleteConfirmationModal } from '@hrz/shared/modals/delete-confirmation.modal';
import { DocumentModal } from '@hrz/shared/modals/document/document.modal';
import { AuthService } from '@hrz/core/services/auth.service';
import { CountryService } from '@hrz/core/services/country.service';
import { Country } from '@hrz/core/models/country';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { Guid } from '@hrz/core/utils/guid-generator';
import { ImageService } from '@hrz/core/services/image.service';

@Component({
  selector: 'hrz-manage-document',
  templateUrl: 'manage-document.component.html',
  styleUrls: ['manage-document.component.scss'],
})
export class ManageDocumentComponent implements OnInit, AfterContentChecked {
  spinnerName = 'spinner_' + Guid.newGuid();
  private maxFileSizeInBytes = 5242880;
  public get maxFileSizeInMb(): number {
    return this.maxFileSizeInBytes / 1048576;
  }
  private country: Country;
  private get documentLocation(): string {
    return `${this.country.IsoCode}\\${this.domain}\\${this.domainId}_${this.documentType}`;
  }

  @Input() set document(value: Document) {
    if (!!value && !!this._document && this._document.Id === value.Id) { return; }

    this._document = value;
    if (value) {
      this._documentLoaded = false; // Lets reset this..
    }

    if (value && this.IsVisible && !this._documentLoaded) {
      console.log(
        'ManageDocumentComponent.context() - ' + value.DocumentType + ' --> IsVisible + DocumentNotLoaded, Load Document when Available!'
      );
      this.loadDocument(value, this.showErrorLoadingDocumentUponInit);
    }
  }
  get document(): Document {
    return this._document;
  }

  @Input() domain: string;
  @Input() domainId: number;
  @Input() documentType: string;
  @Input() title: string;
  @Input() isSignedOnUpload: boolean;
  @Input() allowedFileTypes: string[];
  @Input() removeWithConfirmation: boolean;
  @Input() isReadOnly: boolean;
  @Input() viewModeEnabled: boolean;
  @Input() showErrorLoadingDocumentUponInit = false;
  @Input() beforeRemoval: (document: Document) => Promise<boolean>;
  @Input() postProcessing: (document: Document) => Promise<boolean>;
  @Input() emailAddress: string;
  @Input() emailAddressIsReadOnly = false;
  @Input() overrideSendEmail: (email: string) => Promise<boolean>;
  @Input() subTitle: string;
  @Input() subTitleColor: string;
  @Input() stripedBackground = false;
  @Input() subject: string;
  @Output() onFileRemoved: EventEmitter<Document> = new EventEmitter<Document>();

  randomcolor: string;
  buttonText = this.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOAD_FILE');

  protected message: string = this.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOAD_FILE');
  protected imgUrl: SafeUrl = '';
  private _document: Document;
  private _documentLoaded: boolean;
  private _isVisible: boolean;
  private _spinnerOperations = [];

  public IsVisible(value: boolean) {
    this._isVisible = value;
    if (!this._documentLoaded && value) {
      console.log('ManageDocumentComponent.IsVisible() - ' + this.documentType + ' --> DocumentNotLoaded, Load Document when Available!');
      this.loadDocument(this.document, this.showErrorLoadingDocumentUponInit);
    }
  }

  get canUpload(): boolean {
    return !this.document && !this.isReadOnly;
  }

  constructor(
    private fileService: FileService,
    private modalService: DynamicModalService,
    private translateService: TranslateService,
    private sanitizer: DomSanitizer,
    private toasterService: ToasterService,
    private elementRef: ElementRef,
    private imageService: ImageService,
    private authService: AuthService,
    private countryService: CountryService,
    private spinnerService: SpinnerService
  ) {
    this.removeWithConfirmation = true;
  }

  showSpinner(operation: string, spinnerMessage: string): void {
    this._spinnerOperations.push(operation);
    this.message = this.translateService.instant(spinnerMessage);
    this.spinnerService.show(this.spinnerName);
    console.log(`ManageDocumentComponent.showSpinner(${operation})`, this._spinnerOperations);
  }

  hideSpinner(operation: string): void {
    const index = this._spinnerOperations.indexOf(operation, 0);
    if (index > -1) {
      this._spinnerOperations.splice(index, 1);
    }

    if (this._spinnerOperations.length <= 0) {
      this.spinnerService.hide(this.spinnerName);
    }
    console.log(`ManageDocumentComponent.hideSpinner(${operation})`, this._spinnerOperations);
  }

  ngOnInit(): void {
    this.validateArguments();

    console.log('ManageDocumentComponent.ngOnInit() - ' + this.documentType);
    this.showSpinner('getCountry', 'MOBILE.MESSAGE.LOADING');
    this.authService.getTenantId().subscribe(id => {
      this.countryService.getCountry(id).then(country => {
        this.country = country;
        this.hideSpinner('getCountry');
      });
    });
  }

  validateArguments() {
    if (!this.isReadOnly) {
      if (!this.postProcessing) {
        throw new Error('ManageDocumentComponent: postProcessing input argument required.');
      }
      if (!this.beforeRemoval) {
        throw new Error('ManageDocumentComponent: beforeRemoval input argument required.');
      }
    }
  }

  ngAfterContentChecked(): void {
    if (!this._isVisible && this.elementRef.nativeElement.offsetParent != null) {
      console.log('ManageDocumentComponent -> Visibility Changed to Visible - ' + this.documentType);
      this.IsVisible(true);
    } else if (this._isVisible && this.elementRef.nativeElement.offsetParent == null) {
      console.log('ManageDocumentComponent -> Visibility Changed to Hidden - ' + this.documentType);
      this.IsVisible(false);
    }
  }

  private loadDocument(document: Document, showError: boolean = true) {
    if (this._documentLoaded) { return; }

    if (document) {
      this._documentLoaded = true;
      if (document.FileMetaData && document.FileMetaData.Reference && document.FileMetaData.Reference !== '') {
        // Load image from fileservice
        console.log('ManageDocumentComponent - ' + this.documentType + ' --> Load Document Url with Size: Small');
        this.showSpinner('downloadSingle', 'DOCUMENT_MANAGEMENT.FILE_DOWNLOAD.INFO');
        this.fileService.downloadDossierDocument(document, 'Small').subscribe(
          (fileDownload: FileDownload) => {
            if (fileDownload) {
              this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(fileDownload.blob));
              console.log('ManageDocumentComponent - ' + this.documentType + ' --> DocumentUrl as Blob Loaded!');
              this.document.FileBlob = new Blob([fileDownload.blob], { type: document.FileMetaData.FileType });
            } else {
              this._documentLoaded = false;
              this.downloadFailed(undefined, showError);
            }
            this.hideSpinner('downloadSingle');
          },
          error => {
            this._documentLoaded = false;
            this.downloadFailed(error, showError);
          }
        );
      } else if (
        document.FileContent !== '' &&
        document.FileMetaData &&
        document.FileMetaData.FileType &&
        document.FileMetaData.FileType !== ''
      ) {
        // Load image from blob (when uploading for example)
        const dataUrl = `data:${document.FileMetaData.FileType};base64,${document.FileContent}`;
        this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
        console.log('ManageDocumentComponent - ' + this.documentType + ' --> DocumentUrl as Base64 Loaded!');
      }
    }
  }

  private isFileValidFile(fileType: string): boolean {
    console.log(`ManageDocumentComponent.isFileValidFile() - file type: ${fileType}`);
    const validImageType = this.allowedFileTypes.indexOf(fileType) >= 0;

    return validImageType;
  }

  private isFileSizeValid(file): boolean {
    console.log(file.size + 'Uploaded File Size');
    const isValidSize = file.size <= this.maxFileSizeInBytes;
    return isValidSize;
  }

  private formatFileType(fileType: string): string {
    const re = /(?:\.([^.]+))?$/;
    return re.exec(fileType)[1];
  }

  private userFriendlyFileTypes(): string[] {
    const returnValue = [];
    this.allowedFileTypes.forEach(fileType => {
      returnValue.push(fileType.substring(fileType.indexOf('/') + 1));
    });
    return returnValue;
  }

  onChangeFile($event: any): void {
    console.log('ManageDocumentComponent.onChangeFile() - documentType: ' + this.documentType);
    console.log('ManageDocumentComponent.onChangeFile() - files: ', $event.target.files);
    this.message = this.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    const scope: ManageDocumentComponent = this;
    let file = $event.target.files[0];

    if (this.allowedFileTypes && this.allowedFileTypes.length > 0) {
      const fileType = file['type'];
      const isValidFile = this.isFileValidFile(fileType);
      const isSizeValid = this.isFileSizeValid(file);
      if (isSizeValid === false) {
        this.toasterService.showError(`${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.EXCEED_SIZE_LIMIT')}`);
        return;
      }

      if (isValidFile === false && isSizeValid === true) {
        console.log(`ManageDocumentComponent.onChangeFile() - file valid: ${fileType}`);
        this.toasterService.showError(
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.WRONGTYPE')} ${this.formatFileType(file.name)}. ` +
          `${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.ALLOWED')} ${this.userFriendlyFileTypes().join(', ')}`
        );
        return;
      }
    }

    if (file.size >= 2750000 && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      console.log(
        'ManageDocumentComponent.onChangeFile() - File is bigger than 2.75mb, its exactly: ' +
          file.size / 1000 / 1000 +
          'mb, thus needs compression...'
      );
      const originalFile = file;
      const fileSizeInMb = 0.5; // ((file.size / 1000) / 1000) / 2;
      console.log('ManageDocumentComponent.onChangeFile() - Will compress it to ' + fileSizeInMb + 'Mb...');
      scope.imageService
        .compressImage(file, fileSizeInMb)
        .pipe(
          map(result => {
            file = new File([result], result.name);
            console.log('ManageDocumentComponent.onChangeFile() - Now reading the compressed file...');
            this.readFileAsBuffer(file, originalFile);
          })
        )
        .toPromise()
        .catch(error => {
          console.log('ManageDocumentComponent.onChangeFile() - Compress failed with file: ', error);
        });
      return;
    } else {
      this.readFileAsBuffer(file, file);
    }
  }

  private readFileAsBuffer(file: File, originalFile: File): void {
    const reader = new FileReader();
    reader.onprogress = () => {
      this.message = this.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    };

    reader.onload = () => {
      try {
        this.showSpinner('read.onload', 'SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
        if (!this.document) {
          this.document = new Document();
        }

        if (!this.document.FileMetaData) {
          this.document.FileMetaData = new FileMetaData();
        }
        this.document.DocumentType = this.documentType;

        const arrayBuffer = reader.result;
        this.document.FileContent = ByteHelpers.arrayBufferToBase64(arrayBuffer);
        this.document.FileMetaData.FileName = `${this.domainId}_${this.documentType.toLowerCase()}.${file.name.split('.').pop()}`;
        this.document.FileMetaData.FileSize = file.size;
        this.document.FileMetaData.FileType = originalFile.type; // file.type;
        this.document.File = file;
        // This is required so we can change the filename that will be uploaded to DocumentManagement...
        this.document.File.newfilename = this.document.FileMetaData.FileName;
        this.document.IsSignedOnUpload = this.isSignedOnUpload;
        this.persistDocument(this.document);
        this.hideSpinner('read.onload');
      } catch (ex) {
        this.uploadErrorOccurred('ManageDocumentComponent.readFileAsBuffer', ex);
      }
    };
    reader.readAsArrayBuffer(file);
  }

  persistDocument(document: Document) {
    const location = this.documentLocation;
    console.log('ManageDocumentComponent.persistDocument', document, location);
    this.showSpinner('persistDocument', 'SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOADING');
    this.fileService
      .uploadDocument(document, location)
      .toPromise()
      .then(file => {
        console.log('ManageDocumentComponent: fileService.uploadDocument', file);
        this.document.FileMetaData.Reference = file.id.toString();
        this.postProcessing(document)
          .then(success => {
            if (success) {
              console.log('ManageDocumentComponent.postProcessing done.', document);
              this.loadDocument(this.document);
              const uploadSuccessMessage = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.SUCCESS');
              this.toasterService.showSuccess(uploadSuccessMessage);
            } else {
              const uploadErrorMessage = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_UPLOAD.FAILURE');
              this.toasterService.showError(uploadErrorMessage);
            }
            this.hideSpinner('persistDocument');
          })
          .catch(reason => {
            // Delete the file since post processing failed
            this.fileService.delete({ ids: [file.id] }).toPromise();
            this.uploadErrorOccurred('ManageDocumentComponent.postProcessing - error', reason);
          });
      })
      .catch(reason => {
        this.uploadErrorOccurred('ManageDocumentComponent: fileService.uploadDocument - error', reason);
      });
  }

  uploadErrorOccurred(message: string, reason: any): void {
    this.document = null;
    console.error(message, reason);
    this.spinnerService.hide(this.spinnerName);
    this._spinnerOperations = [];
    this.buttonText = this.translateService.instant('SHARED.SINGLE_DOCUMENT_UPLOADER.UPLOAD_FAILED');
    const alertText = this.getTextFromReason(reason);
    if (!!alertText) {
      this.toasterService.showError(alertText);
    }
  }

  getTextFromReason(reason: any): string {
    let text = '';
    if (!!reason._body) {
      const validationErrors = JSON.parse(reason._body);
      if (Array.isArray(validationErrors)) {
        validationErrors.forEach(validationError => {
          if (!!validationError['Value']) {
            text = text + this.translateService.instant(validationError['Value']) + '\n';
          }
        });
      }
    }
    return text;
  }

  canRemove(): boolean {
    return !!(this.document && this.document.FileMetaData && this.document.FileMetaData.Reference);
  }

  remove(): void {
    console.log('ManageDocumentComponent.remove() - ' + this.documentType);
    this.showSpinner('remove', 'COMMON.PLEASE_WAIT');
    this.beforeRemoval(this.document)
      .then(continueWithDeletion => {
        if (!continueWithDeletion) {
          this.hideSpinner('remove');
          const deleteErrorMessage = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DELETE.FAILURE');
          this.toasterService.showError(deleteErrorMessage);
          return;
        }
        this.fileService
          .delete({ ids: [Number(this.document.FileMetaData.Reference)] })
          .toPromise()
          .then(() => {
            this.document = null;
            this.imgUrl = '';
            this.hideSpinner('remove');
            const deleteSuccessMessage = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DELETE.SUCCESS');
            this.toasterService.showSuccess(deleteSuccessMessage);
            this.onFileRemoved.emit(this.document);
          })
          .catch(reason => {
            this.hideSpinner('remove');
            throw new Error(reason);
          });
      })
      .catch(reason => {
        this.toasterService.showError(`${this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DELETE.FAILURE')}`);
        this.hideSpinner('remove');
        throw new Error(reason);
      });
  }

  removeDocument(): void {
    if (this.spinnerService.isShowing(this.spinnerName)) { return; }

    if (this.removeWithConfirmation) {
      this.modalService.create(ConfirmationsModule, DeleteConfirmationModal, {
        message: this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DELETE.MESSAGE'),
        onOk: () => {
          this.remove();
        },
        onClose: () => {
          console.log('ManageDocumentComponent.DeleteConfirmationModal.onClose');
        },
      });
    } else {
      this.remove();
    }
  }

  downloadDocument(): void {
    console.log('ManageDocumentComponent.downloadDocument()');
    if (this.document && this.document.FileBlob) {
      saveAs(this.document.FileBlob, this.document.FileNameTranslated);
    } else {
      this.showSpinner('downloadDocument', 'DOCUMENT_MANAGEMENT.FILE_DOWNLOAD.INFO');

      this.fileService.downloadDossierDocument(this.document, 'Small').subscribe(
        (fileDownload: FileDownload) => {
          if (fileDownload) {
            this.document.FileBlob = new Blob([fileDownload.blob], { type: this.document.FileMetaData.FileType });
            this.document.FileNameTranslated = fileDownload.name;
            saveAs(this.document.FileBlob, fileDownload.name);
          } else {
            this.downloadFailed();
          }
          this.hideSpinner('downloadDocument');
        },
        error => {
          this.hideSpinner('downloadDocument');
          this.downloadFailed(error);
        }
      );
    }
  }

  downloadFailed(reason?: any, showError: boolean = true): void {
    const errorMessage = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DOWNLOAD.FAILURE');
    console.error(errorMessage);
    if (showError) {
      this.toasterService.showError(errorMessage);
    }
    if (!!reason) {
      console.error(reason);
    }
  }

  OpenDocumentPopup() {
    if (!this.document) {
      this.showOpenDocumentFailure();
      return;
    }

    this.showSpinner('opening_document', 'COMMON.PLEASE_WAIT');
    this.fileService
      .downloadDossierDocument(this.document)
      .pipe(
        tap((downloadedFile: FileDownload) => {
          console.log(
            'ManageDocument.OpenDocumentPopup.LoadFileContents() => Loaded File for Doc: ' +
              this.document.DocumentType +
              ', DocumentId:' +
              this.document.Id +
              '. Now reading into ByteArray...'
          );
          this.document.FileBlob = new Blob([downloadedFile.blob], { type: this.document.FileMetaData.FileType });
          this.document.FileNameTranslated = downloadedFile.name;
          const fileReader = new FileReader();
          fileReader.onload = () => {
            this.document.FileByteArray = fileReader.result;
            this.document.FileContent = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(downloadedFile.blob));
            this.document.FinishedLoading = true;
          };
          fileReader.readAsArrayBuffer(downloadedFile.blob);
        })
      )
      .subscribe(
        () => {
          this.hideSpinner('opening_document');
          this.modalService.create(ConfirmationsModule, DocumentModal, {
            document: this.document,
            subject: this.subject,
            emailAddress: this.emailAddress,
            overrideSendEmail: this.overrideSendEmail,
            emailAddressIsReadOnly: this.emailAddressIsReadOnly,
            onClose: () => {},
          });
        },
        error => {
          this.showOpenDocumentFailure(error);
        }
      );
  }

  showOpenDocumentFailure(reason?: any) {
    this.hideSpinner('opening_document');
    if (!!reason) {
      console.error('Failed to open document', reason);
    }
    const message = this.translateService.instant('DOCUMENT_MANAGEMENT.FILE_DOWNLOAD.FAILURE');
    this.toasterService.showError(message);
  }
}
