import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'cancel-confirmation-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <ng-container *ngIf="customTitle">
              <h4 class="modal-title">{{ customTitle }}</h4>
            </ng-container>
            <ng-container *ngIf="!customTitle">
              <h4 class="modal-title">{{ 'SHARED.MODALS.CANCEL_CONFIRMATION' | translate }}</h4>
            </ng-container>
            <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ng-container *ngIf="message">
              <p>{{ message }}</p>
            </ng-container>
            <ng-container *ngIf="!message">
              <p>{{ 'SHARED.MODALS.ARE_YOU_SURE_YOU_WANT_TO_REVERT_YOUR_CHANGES' | translate }}</p>
            </ng-container>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="clickCancel()">{{ 'COMMON.NO' | translate }}</button>
            <button type="button" class="btn btn-danger" *ngIf="alertUser" (click)="clickOk()">
              {{ 'COMMON.YES_I_AM_SURE' | translate }}
            </button>
            <button type="button" class="btn btn-primary" *ngIf="!alertUser" (click)="clickOk()">
              {{ 'COMMON.YES_I_AM_SURE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class CancelConfirmationModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public alertUser: boolean;

  public onOk: Function;
  public onClose: Function;
  public customTitle: string = undefined;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  ngOnInit(): void {
    console.log('CancelConfirmationModal.ngOnInit');
  }
  ngAfterViewInit(): void {
    console.log('CancelConfirmationModal.ngAfterViewInit');
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('CancelConfirmationModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
