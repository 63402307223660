export class DossierFilter {
  dateRange: string;
  account: string[] = [];
  fittingstation: number[] = [];
  state: string;
  subState: string[] = [];
  appointmentState: string[] = [];
  searchQuery: string;
  intensiveSearch: boolean;
  expertsAssessment: boolean;
  aDAS: boolean;
  oE: boolean;
  fittingStationGroupAverageCost: string = null; // directly bound to dropdown hence null to show placeholder with html select
  jobType: string[] = [];
  dossierType: string;
  constructor() {
    this.dateRange = 'LastThreeMonths';
    this.expertsAssessment = null;
    this.aDAS = null;
    this.oE = null;
    this.dossierType = null;
  }
}
