import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DynamicModal } from '../../dynamic-modal.module';

@Component({
  selector: 'app-default-modal',
  templateUrl: './default.modal.html',
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class DefaultModal implements OnInit, AfterViewInit {
  public message: string;
  public title: string;

  public onOk: Function;
  public onClose: Function;

  private closeModal: Function;
  @ViewChild('defaultModal') private childModal: ModalDirective;

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.childModal.show();
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) {
      this.onClose();
    }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) {
      this.onOk();
    }
  }
}
