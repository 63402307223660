import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';

@Component({
  templateUrl: './file-download.modal.html',
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class FileDownloadModal implements AfterViewInit {
  private closeModal: Function;
  @ViewChild('fileDownloadModal') childModal: ModalDirective;

  constructor() {}

  ngAfterViewInit(): void {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: 'static',
      keyboard: false,
      show: true,
    };
  }

  cancel(): void {
    this.closeModal();
  }
}
