import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DynamicModal } from '../../../../../shared/dynamic-modal.module';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { SpinnerHelper } from '@hrz/core/utils/spinner-helper';
import { JobInvoice } from '@hrz/core/models/job-invoice';
import { JobInvoiceService } from '../../../services/job-invoice.service';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { ActionTypeKeys } from '@hrz/core/models/action-type-keys';
import { DossierService } from '@hrz/core/services/dossier.service';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Dossier } from '@hrz/core/models/dossier';
import { EnumService } from '@hrz/core/services/enum.service';
import { Enum } from '@hrz/core/models/enum';

@Component({
  selector: 'reject-request-credit-modal',
  templateUrl: './reject-request-credit-modal.component.html'
})
@DynamicModal()
export class RejectRequestCreditModal implements OnInit, AfterViewInit {
  public closeModal: Function;
  public dossier: Dossier;
  public jobInvoice: JobInvoice;
  public onSuccess: (data: { reason: string; additionalNotes: string }) => void;
  public rejectCreditForm: FormGroup;
  public additionalNotes = '';
  public rejectionReasons: Enum[] = [];
  public rejectionReason: string;

  private spinnerHelper: SpinnerHelper;
  @ViewChild('myModal')
  private childModal: ModalDirective;

  constructor(
    private readonly toasterService: ToasterService,
    private readonly dossierService: DossierService,
    private readonly enumService: EnumService,
    private readonly formBuilder: FormBuilder,
    private readonly jobInvoiceService: JobInvoiceService,
    spinnerService: SpinnerService,
    private readonly translateService: TranslateService
  ) {
    this.spinnerHelper = new SpinnerHelper(spinnerService, () => 'rejectCreditNoteNgcSpinner');
  }

  ngOnInit() {
    if (!this.jobInvoice) {
      throw new Error('RejectRequestCreditModalComponent: No jobInvoice is set.');
    }

    this.buildForm();
  }

  async ngAfterViewInit(): Promise<void> {
    console.log('requestCreditModal.ngAfterViewInit');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: false,
      ignoreBackdropClick: true,
    };
    await this.spinnerHelper.withSpinnerAsync(async () => {
      this.rejectionReasons = await this.enumService.getRejectCreditRequestReasons();
    });
  }

  public close() {
    this.closeModal();
  }

  public isValid(): boolean {
    return this.rejectCreditForm.valid && !!this.rejectionReason && this.rejectionReason !== 'null';
  }

  public async rejectCredit(): Promise<void> {
    this.rejectionReason = this.rejectCreditForm.value.rejectionReason;
    this.additionalNotes = this.rejectCreditForm.value.additionalNotes;

    console.log('RejectRequestCreditModalComponent.rejectCredit', this.jobInvoice);
    await this.spinnerHelper.withSpinnerAsync(async () => {
      const jobId = this.jobInvoice.Job.Id;
      const jobInvoiceId = this.jobInvoice.Id;
      let apiActionResult = await this.jobInvoiceService.patch(this.dossier.Id, jobId, jobInvoiceId, {
        RejectCreditAdditionalNotes: this.additionalNotes,
        RejectCreditReason: this.rejectionReason,
      });
      if (apiActionResult.commandSucceeded) {
        apiActionResult = await this.dossierService.processAction(this.dossier, ActionTypeKeys.reject_credit);
      }
      this.handleApiActionResult(apiActionResult);
    });
  }

  private buildForm() {
    this.rejectCreditForm = this.formBuilder.group({
      additionalNotes: [null, null],
      rejectionReason: [null, Validators.required],
    });
  }

  private handleApiActionResult(apiActionResult: ApiActionResult<any>) {
    if (apiActionResult.commandSucceeded) {
      const successMessage = this.translateService.instant('DOSSIER.INVOICE_FS.CREDIT_REQUEST_REJECTED');
      this.toasterService.showSuccess(successMessage);
      if (!!this.onSuccess) {
        this.onSuccess({ reason: this.rejectionReason, additionalNotes: this.additionalNotes });
      }
      this.close();
    } else {
      console.error('RejectRequestCreditModalComponent.rejectCredit failed', apiActionResult);
      const errorMessage = this.translateService.instant('DOSSIER.INVOICE_FS.CREDIT_REQUEST_REJECTION_FAILED');
      this.toasterService.showError(errorMessage);
    }
  }
}
