import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CodeTranslation } from '@hrz/core/models/code-translation';

import { DynamicModal } from '../dynamic-modal.module';
import { SmartDropdown } from '@hrz/core/models/smart-dropdown';

@Component({
  selector: 'import-confirmation-modal',
  templateUrl: './import-confirmation.modal.html',
})
@DynamicModal()
export class ImportConfirmationModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public alertUser: boolean;
  public codeTranslations: CodeTranslation[];
  public dropdowns: SmartDropdown[];
  public detectedLanguageCodes: string[];

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  ngOnInit(): void {
    console.log('ImportConfirmationModal.ngOnInit');
    if (this.codeTranslations) {
      this.detectedLanguageCodes = [];
      const firstItem = this.codeTranslations[0];

      for (let i = 0; i <= firstItem.translations.length - 1; i++) {
        this.detectedLanguageCodes.push(firstItem.translations[i].languageCode);
      }
    } else if (this.dropdowns) {
      this.detectedLanguageCodes = [];
      const firstDropdown = this.dropdowns[0];

      for (let i = 0; i <= firstDropdown.items[0].translations.length - 1; i++) {
        this.detectedLanguageCodes.push(firstDropdown.items[0].translations[i].languageCode);
      }
    }
  }
  ngAfterViewInit(): void {
    console.log('ImportConfirmationModal.ngAfterViewInit');
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('ImportConfirmationModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
