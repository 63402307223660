import { Component, ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core/src/metadata/lifecycle_hooks';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'app-custom-confirmation-modal-v2',
  templateUrl: './custom-confirmation-v2.modal.html',
})
@DynamicModal()
export class CustomConfirmationModalComponentV2 implements AfterViewInit {
  @ViewChild('myModal') private _childModal: ModalDirective;

  title: string;
  message: string;
  bulletList: string[];
  alertUser: boolean;

  cancelButtonText = 'COMMON.CANCEL';
  okButtonText = 'COMMON.OK';

  onOk: Function;
  onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;

  ngAfterViewInit(): void {
    this._childModal.show();
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
