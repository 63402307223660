import {Component, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { DynamicModal } from '@hrz/shared/dynamic-modal.module';
import { environment } from '@hrz/env';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';


@Component({
  templateUrl: 'warning-deprecated-version-modal.html',
  styleUrls: ['warning-deprecated-version-modal.scss'],
  providers: []
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class WarningDeprecatedVersionModal implements AfterViewInit, OnDestroy {

  @ViewChild('warningDeprecatedVersion') private childModal: ModalDirective;

  public countdownValue = 4;

  private timerId;
  private timerInterval = 1000;
  private newHorizonsUri = environment.newHorizonsModal.redirectUri;
  private closeModal: Function;

  constructor(private appInsightsService: AppInsightsService) {
    this.appInsightsService.logEvent("WDVMDisplay");
  }

  ngAfterViewInit(): void {
    this.childModal.config = {...this.childModal.config};
    this.childModal.show();
    this.timerId = setInterval(() => this.processCountDown(), this.timerInterval);
  }

  ngOnDestroy(): void {
    clearTimeout(this.timerId);
  }

  clickUseOld(): void {
    this.appInsightsService.logEvent("WDVMClickUseOld");
    clearTimeout(this.timerId);
    this.closeModal();
  }

  clickUseNew(): void {
    this.appInsightsService.logEvent("WDVMClickUseNew");
    this.processRedirect();
  }

  private processCountDown(): void {
    this.countdownValue = this.countdownValue - 1;
    if (this.countdownValue === 0) {
      this.appInsightsService.logEvent("WDVMTimeout");
      this.processRedirect();
    }
  }

  private processRedirect(): void {
    this.appInsightsService.logEvent("WDVMRedirect");
    clearTimeout(this.timerId);
    window.location.href = this.newHorizonsUri;
  }
}
