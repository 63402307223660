export async function blobAsString(blob: Blob): Promise<string> {
  let bas: string;
  const fileReader = new FileReader();
  const promise = new Promise(resolve => {
    fileReader.onloadend = function (e) {
      bas = (<any>e.target).result as string;
      resolve();
    };
  });
  fileReader.readAsText(blob);
  await promise;
  return bas;
}
