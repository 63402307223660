import { Directive, HostListener } from '@angular/core';

/**
 * Allows the sidebar to be toggled via click.
 */
@Directive({
  // tslint:disable-next-line
  selector: '.sidebar-toggler',
})
export class SidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-compact');
    window.dispatchEvent(new Event('resize'));
    console.log('clicked Menu');
  }
}

@Directive({
  // tslint:disable-next-line
  selector: '.mobile-sidebar-toggler',
})
export class MobileSidebarToggleDirective {
  constructor() {}

  @HostListener('click', ['$event'])
  toggleOpen($event: any) {
    $event.preventDefault();
    document.querySelector('body').classList.toggle('sidebar-mobile-show');
  }
}
