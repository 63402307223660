import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from '@hrz/env'

if (environment.production) {
  console.log('Production Environment has been enabled...');
  enableProdMode();
} else {
  console.log('Development mode has been enabled...');
}

platformBrowserDynamic().bootstrapModule(AppModule);
