import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { BaseActionModal } from '../base/base-action.modal';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { FileService } from '@hrz/core/services/file.service';
import { StorageService } from '../../services/storage.service';
import { Observable } from 'rxjs';
import { Storage } from '@hrz/core/models/document/storage';
import { File } from '@hrz/core/models/document/file';
import { StructureInfo } from '@hrz/core/models/document/structure-info';

@Component({
  templateUrl: './file-upload.modal.html'
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class FileUploadModal extends BaseActionModal implements OnInit {
  parent: StructureInfo;
  storages: Observable<Storage[]>;
  onFileUpload: Function;
  onFailure: Function;
  @ViewChild('fileUploadModal') childModal: ModalDirective;
  @ViewChild(FileUploadComponent) fileUploadForm: FileUploadComponent;

  constructor(
    private fileService: FileService,
    private storageService: StorageService
  ) {
    super();
  }

  ngOnInit() {
    this.storages = this.storageService.list();
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    this.uploadFile();
  }

  uploadFile() {
    const fileUploadModel = this.fileUploadForm.getModel();
    this.loading = true;
    this.fileService
      .upload(fileUploadModel)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        (_file: File) => {
          this.onFileUpload();
        },
        error => {
          this.onFailure(error);
        }
      );
  }
}
