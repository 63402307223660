import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule,
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';
import { SlimLoadingBarService } from './core/services/slim-loading-bar.service';
import { AuthGuard } from '@hrz/core/guards/auth-guard.service';
import { PermissionGuard } from '@hrz/core/guards/permission-guard.service';
import { Permission as Perm } from '@hrz/core/models/enums/permission.enum';

// Layouts
import { FullLayoutComponent } from '@hrz/core/components/layouts/full-layout.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.HOME',
      requiredPermission: Perm.mi_db,
      fallback: '/dossiers',
    }, // We must define the layouts we want to display...
    children: [
      {
        path: 'dashboard',
        data: { title: 'BREADCRUMBS.DASHBOARD' },
        loadChildren: './features/dashboard/dashboard.module#DashboardModule',
      },
    ],
  },
  {
    path: 'dossiers',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DOSSIERS',
      requiredPermission: Perm.r_ds,
    },
    children: [
      {
        path: '',
        loadChildren: './features/dossiers/dossier.module#DossierModule',
      },
    ],
  },
  {
    path: 'notifications',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.NOTIFICATIONS',
      requiredPermission: Perm.r_ds,
    },
    children: [
      {
        path: '',
        loadChildren: './features/notifications/notifications.module#NotificationsModule',
      },
    ],
  },
  {
    path: 'webauthority',
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.WEB_AUTHORITY',
    },
    children: [
      {
        path: '',
        loadChildren: './features/webauthority/webauthority.module#WebauthorityModule',
      },
    ],
  },
  {
    path: 'countries',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.COUNTRIES',
      requiredPermission: Perm.r_cy,
    },
    children: [
      {
        path: '',
        loadChildren: './features/countries/country.module#CountryModule',
      },
    ],
  },
  {
    path: 'organizations',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ORGANIZATIONS',
      requiredPermission: Perm.r_or,
    },
    children: [
      {
        path: '',
        loadChildren: './features/organizations/organization.module#OrganizationModule',
      },
    ],
  },
  {
    path: 'central-departments',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CENTRAL_DEPARTMENTS',
      requiredPermission: Perm.r_cd,
    },
    children: [
      {
        path: '',
        loadChildren: './features/centraldepartments/central-department.module#CentralDepartmentModule',
      },
    ],
  },
  {
    path: 'fitting-station-groups',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.FITTING_STATIONS_GROUP',
      requiredPermission: Perm.r_fsg,
    },
    children: [
      {
        path: '',
        loadChildren: './features/fittingstationgroups/fitting-station-group.module#FittingStationGroupModule',
      },
    ],
  },
  {
    path: 'discount-approval-config',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DISCOUNT_APPROVAL_CONFIG.DISCOUNT_APPROVAL_CONFIG',
      requiredPermission: Perm.r_dac,
    },
    children: [
      {
        path: '',
        loadChildren: './features/discountapprovalconfig/discount-approval-config.module#DiscountApprovalConfigModule',
      },
    ],
  },
  {
    path: 'accounts',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ACCOUNTS',
      requiredPermission: Perm.r_ac,
    },
    children: [
      {
        path: '',
        loadChildren: './features/accounts/account.module#AccountModule',
      },
    ],
  },
  {
    path: 'origin',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.ORIGINS',
      requiredPermission: Perm.cs_cc_o_r,
    },
    children: [
      {
        path: '',
        loadChildren: './features/origin/origin.module#OriginModule',
      },
    ],
  },
  // GDES72
  {
    path: 'procedures',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.PROCEDURES',
      requiredPermission: Perm.ac_r,
    },
    children: [
      {
        path: '',
        loadChildren: './features/procedures/procedure.module#ProcedureModule',
      },
    ],
  },
  {
    path: 'countersales',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.COUNTERSALES',
      requiredPermission: Perm.r_cs,
    },
    children: [
      {
        path: '',
        loadChildren: './features/countersales/countersale.module#CountersaleModule',
      },
    ],
  },
  {
    path: 'stock',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.STOCK',
      requiredPermission: Perm.mi_sic,
    },
    children: [
      {
        path: '',
        loadChildren: './features/stock/stock.module#StockModule',
      },
    ],
  },
  {
    path: 'tax',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.TAX',
      requiredPermission: Perm.r_tax,
    },
    children: [
      {
        path: '',
        loadChildren: './features/tax/tax.module#TaxModule',
      },
    ],
  },
  {
    path: 'availability-calendar',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.AVAILABILITY',
      requiredPermission: Perm.r_cav,
    },
    children: [
      {
        path: '',
        loadChildren: './features/availabilitycalendar/availability-calendar.module#AvailabilityCalendarModule',
      },
    ],
  },
  {
    path: 'directsales',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.DIRECT_SALES',
      requiredPermission: Perm.r_dsc,
    },
    children: [
      {
        path: '',
        loadChildren: './features/directsales/directsales.module#DirectSalesModule',
      },
    ],
  },
  {
    path: 'quotation',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.QUOTATIONS',
      requiredPermission: Perm.r_dsc,
    },
    children: [
      {
        path: '',
        loadChildren: './features/quotation/quotation.module#QuotationModule',
      },
    ],
  },
  {
    path: 'logistics',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.LOGISTICS.ROUTE',
      requiredPermissions: [Perm.r_reqiv, Perm.r_erpiv],
    },
    children: [
      {
        path: '',
        loadChildren: './features/logistics/logistics.module#LogisticsModule',
      },
    ],
  },
  {
    path: 'sendtoaccount',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.SEND_TO_ACCOUNT',
      requiredPermission: Perm.r_snd,
    },
    children: [
      {
        path: '',
        loadChildren: './features/sendtoaccount/sendtoaccount.module#SendToAccountModule',
      },
    ],
  },
  {
    path: 'batchfinancialvalidation',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.BATCH_FINANCIAL_VALIDATION',
      requiredPermission: Perm.u_bfv,
    },
    children: [
      {
        path: '',
        loadChildren: './features/batchfinancialvalidation/batchfinancialvalidation.module#BatchFinancialValidationModule',
      },
    ],
  },
  {
    path: 'senddocuments',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.SEND_DOCUMENTS',
      requiredPermission: Perm.r_snd,
    },
    children: [
      {
        path: '',
        loadChildren: './features/senddocuments/senddocuments.module#SendDocumentsModule',
      },
    ],
  },
  {
    path: 'message-center',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.MESSAGE_CENTER',
      requiredPermission: Perm.r_msg,
    },
    children: [
      {
        path: '',
        loadChildren: './features/messagecenter/message-center.module#MessageCenterModule',
      },
    ],
  },
  {
    path: 'templates',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.TEMPLATE_MANAGEMENT',
      requiredPermission: Perm.r_tp,
    },
    children: [
      {
        path: '',
        loadChildren: './features/template/template.module#TemplateModule',
      },
    ],
  },
  {
    path: 'multilanguage',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: { title: 'BREADCRUMBS.TRANSLATION_MANAGEMENT', requiredPermission: Perm.r_lc },
    children: [
      {
        path: '',
        loadChildren: './features/multilanguage/multilanguage.module#MultilanguageModule',
      },
    ],
  },
  {
    path: 'codification',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CODIFICATIONS',
      requiredPermission: Perm.r_lc, // TODO permissions
    },
    children: [
      {
        path: '',
        loadChildren: './features/codification/codification.module#CodificationModule',
      },
    ],
  },
  {
    path: 'documentmanagement',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: { title: 'BREADCRUMBS.DOCUMENT_MANAGEMENT', requiredPermission: Perm.r_fl },
    children: [
      {
        path: '',
        loadChildren: './features/documentmanagement/documentmanagement.module#DocumentManagementModule',
      },
    ],
  },
  {
    path: 'protocols',
    canActivate: [AuthGuard],
    component: FullLayoutComponent,
    data: { title: 'BREADCRUMBS.PROTOCOLS' },
    children: [
      {
        path: '',
        loadChildren: './features/protocols/protocols.module#ProtocolsModule',
      },
    ],
  },
  {
    path: 'users',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.USER_MANAGEMENT',
      requiredPermission: Perm.um_um,
    },
    children: [
      {
        path: '',
        loadChildren: './features/usermanagement/usermanagement.module#UserManagementModule',
      },
    ],
  },
  {
    path: 'cases',
    canActivate: [PermissionGuard],
    component: FullLayoutComponent,
    data: {
      title: 'BREADCRUMBS.CASES',
      requiredPermission: Perm.r_ds,
    },
    children: [
      {
        path: '',
        loadChildren: './features/cases/cases.module#CasesModule',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  declarations: [],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor(
    router: Router,
    private slimLoadingBarService: SlimLoadingBarService
  ) {
    router.events.subscribe((event: RouterEvent): void => {
      this.navigationInterceptor(event);
    });
  }

  navigationInterceptor(event: RouterEvent): void {
    let PageName, navUrl, navUrlArray;
    if (event instanceof NavigationStart) {
      console.info('Started the navigation!' + event.url);
      navUrl = event.url;
      navUrlArray = navUrl.split('/');
      PageName = 'page-' + navUrlArray[navUrlArray.length - 1];

      this.slimLoadingBarService.start();
      if (document.querySelector('body').classList.contains('btn-compact-pressed')) {
        document.querySelector('body').classList.add('sidebar-compact');
      } else {
        document.querySelector('body').classList.remove('sidebar-compact');
      }
      document.querySelector('body').classList.add(PageName);
    }
    if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
      console.info('Ended the navigation!');
      // this.loading = false;
      navUrl = event.url;
      navUrlArray = navUrl.split('/');
      PageName = 'page-' + navUrlArray[navUrlArray.length - 1];
      this.slimLoadingBarService.complete();
      document.querySelector('body').classList.remove(PageName);
    }
  }
}
