import { pipe } from '@hrz/core/utils/pipe';
import { PropertyFormat } from '@hrz/core/models/template/property-format';
import { PropertyFormats } from './property-formats';

export const PropertyFormattingPlugin = function (editor: any, _url: string) {
  editor.addButton('propertyFormatting', {
    text: translate('Title'),
    type: 'menubutton',
    icon: false,
    menu: [
      {
        text: translate('Menu_Numeric'),
        menu: getMenuItems(PropertyFormats.numeric),
      },
      {
        text: translate('Menu_Datetime'),
        menu: getMenuItems(PropertyFormats.datetime),
      },
    ],
  });
};

const getMenuItems = (propertyFormats: PropertyFormat[]) => {
  return propertyFormats.map((propertyFormat: PropertyFormat) => ({
    text: tinymce.i18n.translate(propertyFormat.name),
    onclick: function () {
      setFormat(propertyFormat.format);
    },
    tooltip: tinymce.i18n.translate(propertyFormat.example),
  }));
};

const setFormat = (format: string): void => {
  const selection = tinymce.activeEditor.selection;
  pipe(selection.getContent.bind(selection), addFormat(format).bind(this), selection.setContent.bind(selection))();
};

const addFormat = (format: string) => (content: string) =>
  findElements(content).reduce((previous, current) => previous.replace(current, applyFormat(current, format)), content);

const findElements = (content: string) => content.match(/{{(.*?)}}/g) || [];

const applyFormat = (value: string, format: string) => {
  const clearValue = value.replace(/{|}/g, '');
  return `{{${clearValue} | '${format}'}}`;
};

const translate = (key: string) => tinymce.i18n.translate(`PropertyFormattingPlugin_${key}`);
