import { from as observableFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SafeUrl } from '@angular/platform-browser/src/security/dom_sanitization_service';

import { File as FileResult } from '@hrz/core/models/document/file';
import { FileService } from '@hrz/core/services/file.service';
import { FileDownload } from '@hrz/core/models/document/file-download';

@Component({
  selector: 'app-photo-item',
  templateUrl: 'photo-item.component.html',
  styleUrls: ['./photo-item.component.scss'],
})
export class PhotoItemComponent implements OnInit {
  @Input() photo: FileResult;
  @Output() onDownload: EventEmitter<FileResult> = new EventEmitter<FileResult>();
  imgUrl: SafeUrl = '';
  isImageLoading = true;

  constructor(
    private fileService: FileService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.getImgUrl();
  }

  download() {
    this.onDownload.emit(this.photo);
  }

  private getImgUrl() {
    observableFrom(this.fileService.downloadSingle(this.photo.id))
      .pipe(map((fileDownload: FileDownload) => URL.createObjectURL(fileDownload.blob)))
      .subscribe(x => {
        this.imgUrl = this.sanitizer.bypassSecurityTrustResourceUrl(x);
        this.isImageLoading = false;
      });
  }
}
