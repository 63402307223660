import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'hybris-products-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-customsize" style="width: 90%; height:90%;" role="document">
        <div class="modal-content" style="height:100%;">
          <div class="modal-header">
            <button type="button" class="pull-left btn btn-sm btn-primary" (click)="clickClose()" aria-label="Close">
              <i class="fa fa-arrow-circle-left" aria-hidden="true"></i> {{ 'SHARED.MODALS.BACK_TO_DOSSIER' | translate }}
            </button>
            <button type="button" class="close" (click)="clickClose()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height:90%;">
            <iframe width="100%" height="100%" [src]="searchUrl"></iframe>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class HybrisProductsModal implements OnInit, AfterViewInit, OnDestroy {
  public message: string;
  public searchUrl: string;
  public initUrl: string;

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  public closeModal: Function;
  @ViewChild('myModal') public childModal: ModalDirective;

  ngOnInit(): void {
    console.log('HybrisProductsModal.ngOnInit');
  }
  ngAfterViewInit(): void {
    console.log('HybrisProductsModal.ngAfterViewInit');
    window.addEventListener(
      'message',
      e => {
        if (e.data === 'close') {
          console.log('close message received');
          this.clickClose();
        }
      },
      false
    );
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('HybrisProductsModal.ngOnDestroy');
  }

  clickClose(): void {
    this.childModal.hide();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.childModal.hide();
    if (this.onOk) { this.onOk(); }
  }
}
