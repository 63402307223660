import { of as observableOf, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { SignatureComponent } from '@hrz/shared/components/canvas-helper/signature/signature.component';
import { CheckOutModalData } from '@hrz/core/models/dossier/check-out-modal-data';
import { CheckOutModalStepType } from '@hrz/core/models/dossier/check-out-modal-step-type';
import { ModalStep } from '@hrz/core/models/dossier/modal-step';
import { SpinnerService } from '@hrz/core/services/spinner.service';

@Component({
  templateUrl: './faster-dossier-instruction-check-out.modal.html',
  styleUrls: ['./faster-dossier-instruction-check-out.modal.scss']
})
@DynamicModal()
export class FasterDossierInstructionCheckOutModal implements AfterViewInit, OnInit {
  @ViewChild('dossierInstructionCheckOut') childModal: ModalDirective;
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('fitterSignature') fitterSignature: SignatureComponent;

  model: CheckOutModalData;
  onModelUpdated: (checkOutModalData: CheckOutModalData) => CheckOutModalData;
  onFinish: (checkOutModalData: CheckOutModalData) => CheckOutModalData;
  onCancel: () => void;

  ModalStepType = CheckOutModalStepType;
  private closeModal: Function;

  constructor(
    private spinnerService: SpinnerService
  ) {}

  ngOnInit() {
    console.log('FasterDossierInstructionCheckOutModal.ngOnInit()');
    const stepType = this.getStepTypeByStep(this.model.step);
    this.model.modalStep = this.getDefaultModalStep(stepType);
  }

  ngAfterViewInit(): void {
    console.log('FasterDossierInstructionCheckOutModal.ngAfterViewInit()');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };

    if (this.model.clientSignature != null && this.model.clientSignature.length > 0) {
      this.clientSignature.fromBase64ImageString(this.model.clientSignature);
    }
    if (this.model.fitterSignature != null && this.model.fitterSignature.length > 0) {
      this.fitterSignature.fromBase64ImageString(this.model.fitterSignature);
    }
  }

  ngOnDestroy() {
    console.log('FasterDossierInstructionCheckOutModal.ngOnDestroy()');
  }

  private getDefaultModalStep = (step: number): ModalStep => {
    return [
      {
        step: CheckOutModalStepType.clientSignature,
        nextStepText: 'DOSSIER.MODALS.STEP_1_2_OUT',
        previousStepText: 'COMMON.CANCEL',
        nextStepSelect: this.navigateToStep(CheckOutModalStepType.fitterSignature),
        previousStepSelect: this.cancel.bind(this),
        canGoNextStep: () => this.model.clientSignature != null && this.model.clientSignature.length > 0,
        anyChanges: false,
      },
      {
        step: CheckOutModalStepType.fitterSignature,
        nextStepText: 'DOSSIER.MODALS.FINISH_CHECK_OUT',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.finish.bind(this),
        previousStepSelect: this.navigateToStep(CheckOutModalStepType.clientSignature),
        canGoNextStep: () => this.model.fitterSignature != null && this.model.fitterSignature.length > 0,
        anyChanges: false,
      },
    ].find((modalStep: ModalStep) => modalStep.step === this.getStepTypeByStep(step));
  }

  navigateToStep = (stepType: CheckOutModalStepType) => () => {
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;
    this.beforeNavigationAction()
      .pipe(
        finalize(() => {
          this.model.navigationInProgress = false;
          this.spinnerService.hide('chekInSpinner');
        })
      )
      .subscribe(() => {
        this.model.step = +stepType;
        this.model.modalStep = this.getDefaultModalStep(stepType);
      });
  }

  cancel(): void {
    console.log('FasterDossierInstructionCheckOutModal.cancel()');
    this.closeModal();
  }

  close(): void {
    console.log('FasterDossierInstructionCheckOutModal.close()');
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onModelUpdated(model);
      });
  }

  exit(): void {
    console.log('FasterDossierInstructionCheckOutModal.exit()');
    const modalStep = this.model.step;
    if (modalStep === 1) {
      this.closeModal();
      return;
    } else {
      this.close(); // After step 1 we can update the modal.
    }
  }
  finish(): void {
    console.log('FasterDossierInstructionCheckOutModal.finish()');
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onFinish(model);
      });
  }

  updateModel() {
    console.log('FasterDossierInstructionCheckOutModal.updateModel()');
    this.model.clientSignature = this.clientSignature.getImageAsString();
    this.model.fitterSignature = this.fitterSignature.getImageAsString();
    return observableOf(this.model);
  }

  clientSignatureChange(clientSignature: string) {
    this.model.clientSignature = clientSignature;
  }

  fitterSignatureChange(fitterSignature: string) {
    this.model.fitterSignature = fitterSignature;
  }
  private getStepTypeByStep(step: number): CheckOutModalStepType {
    this.spinnerService.hide('chekInSpinner');
    return CheckOutModalStepType[CheckOutModalStepType[step]];
  }

  childComponentDataChange() {
    this.model.modalStep.anyChanges = true;
  }

  beforeNavigationAction(): Observable<{}> {
    switch (this.model.modalStep.step) {
      case CheckOutModalStepType.fitterSignature:
      default:
        return observableOf({});
    }
  }
}
