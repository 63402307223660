import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-money-input',
  template: `
    <div *ngIf="showCurrencyPrefix" class="input-group">
      <div class="input-group-prepend">
        <div class="input-group-text">€</div>
      </div>
      <input
        [type]="type"
        [class]="delegateclass"
        [value]="formattedData"
        [style]="delegateStyle"
        (change)="onChange($event)"
        (keyup)="onChange($event)"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [readonly]="readonly"
        [pattern]="pattern" />
    </div>
    <input
      *ngIf="!showCurrencyPrefix"
      [type]="type"
      [class]="delegateclass"
      [value]="formattedData"
      [style]="delegateStyle"
      (change)="onChange($event)"
      (keyup)="onChange($event)"
      [placeholder]="placeholder"
      [disabled]="disabled"
      [readonly]="readonly"
      [pattern]="pattern" />
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoneyInput),
      multi: true,
    },
  ],
})
export class MoneyInput implements ControlValueAccessor {
  formattedData: string;
  private inputData: number;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() delegateclass: string;
  @Input() readonly = false;
  @Input() delegateStyle: string;
  @Input() disabled = false;
  @Input() pattern: string;
  @Input() showCurrencyPrefix = false;

  writeValue(obj: any) {
    this.inputData = obj ? obj : 0;
    this.formattedData = this.inputData.toString().replace('.', ',');
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched() {}

  onChange(event) {
    const newValue = event.target.value;

    try {
      if (this.pattern) {
        this.inputData = Number(newValue.replace(',', '.'));
      } else {
        this.inputData = parseFloat(newValue.replace(',', '.'));
      }
      this.formattedData = newValue.replace('.', ',');
    } catch (ex) {}

    this.propagateChange(this.inputData);
  }

  private propagateChange = (_: any) => {};
}
