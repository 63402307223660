import { of as observableOf, Observable } from 'rxjs';

import { tap, finalize, mergeMap, map, switchMap } from 'rxjs/operators';
import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { CheckInModalData } from '@hrz/core/models/dossier/check-in-modal-data';
import { ModalStep } from '@hrz/core/models/dossier/modal-step';
import { ToasterService } from '@hrz/core/services/toaster.service';
import { IndicateDamageComponent } from '../indicate-damage/indicate-damage.component';
import { DamageIndicateData } from '@hrz/core/models/dossier/damage-indicate-data';
import { CheckInModalStepType } from '@hrz/core/models/dossier/check-in-modal-step-type';
import { SignatureComponent } from '@hrz/shared/components/canvas-helper/signature/signature.component';
import { FileDownload } from '@hrz/core/models/document/file-download';
import { File as FileResult } from '@hrz/core/models/document/file';
import { SpinnerService } from '@hrz/core/services/spinner.service';
import { FormGroup, FormControl } from '@angular/forms';
import { DossierService } from '@hrz/core/services/dossier.service';

@Component({
  templateUrl: './dossier-instruction-check-in.modal.html',
  styleUrls: ['./dossier-instruction-check-in.modal.scss']
})
@DynamicModal()
export class DossierInstructionCheckInModal implements AfterViewInit, OnInit {
  @ViewChild('dossierInstructionCheckIn') childModal: ModalDirective;
  @ViewChild(IndicateDamageComponent) indicateDamageComponent: IndicateDamageComponent;
  @ViewChild('clientSignature') clientSignature: SignatureComponent;
  @ViewChild('fitterSignature') fitterSignature: SignatureComponent;

  model: CheckInModalData;
  onFinish: (documents: FileResult[]) => FileResult;
  onSave: (checkInModalData: CheckInModalData) => CheckInModalData;
  saveUnsignedCheckInDocument: (file: FileResult) => any;
  saveCheckInDocument: (file: FileResult) => any;
  onCancel: () => void;
  onFailure: () => void;
  onInit: Observable<FileDownload>;
  generateTemplate: (model: CheckInModalData) => Observable<string>;
  saveDocument: (file: string, filename: string) => Observable<FileResult>;
  downloadDocument: (fileId: number) => void;
  ModalStepType = CheckInModalStepType;
  private closeModal: Function;

  question1FormGroup: FormGroup;
  question2FormGroup: FormGroup;
  question3FormGroup: FormGroup;

  private canContinueWithLegalNote = false;
  private useLegalNoteVersion: string;

  constructor(
    private translateService: TranslateService,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private sanitizer: DomSanitizer,
    private dossierService: DossierService
  ) { }

  ngOnInit() {
    const stepType = this.getStepTypeByStep(this.model.step);
    this.model.modalStep = this.getDefaultModalStep(stepType);
    this.model.navigationInProgress = true;
    this.model.LegalEntityCountryIsoCode = 'PRT';
    this.useLegalNoteVersion = this.translateService.instant('LEGAL_NOTE.' + this.model.LegalEntityCountryIsoCode + '.USE_VERSION');

    this.model.LegalNoteInitialContent = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.INITIAL')
    );
    this.model.LegalNoteQuestion1Content = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_1')
    );
    this.model.LegalNoteQuestion2Content = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_2')
    );
    this.model.LegalNoteQuestion3Content = this.sanitizer.bypassSecurityTrustHtml(
      this.translateService.instant('LEGAL_NOTE.VERSIONS.' + this.useLegalNoteVersion + '.QUESTION_3')
    );
    this.model.LegalNoteVersion = this.useLegalNoteVersion;

    this.spinnerService.show('chekInSpinner');
    this.onInit.subscribe((file: FileDownload) => {
      if (file && file.blob) {
        this.model.checkInDocument = file;
      } else {
        this.onFailure();
        this.closeModal();
      }
      this.model.navigationInProgress = false;
      this.spinnerService.hide('chekInSpinner');
    });
    this.question1FormGroup = this.initializeQuestion1FormGroup(this.model);
    this.question2FormGroup = this.initializeQuestion2FormGroup(this.model);
    this.question3FormGroup = this.initializeQuestion3FormGroup(this.model);
    this.canContinueWithLegalNote = true; // this.model.LegalNoteQuestion3Allowed;
  }

  ngOnDestroy() {
    const jsonPayload = [];
    // TODO: create 2D array an loop over it instead of checking the model
    if (!this.model.metrictDamageIndicate || this.model.metrictDamageIndicate != 0) {
      jsonPayload[0] = this.dossierService.createMetricPayload(this.model.dossierId, this.model.metrictDamageIndicate, 'checkin', 'damage');
      this.model.metrictDamageIndicate = 0;
    }

    if (!this.model.metricFinish || this.model.metricFinish != 0) {
      jsonPayload[1] = this.dossierService.createMetricPayload(this.model.dossierId, this.model.metricFinish, 'checkin', 'finish');
      this.model.metricFinish = 0;
    }

    this.dossierService.createMetrics(this.model.dossierId, jsonPayload).then(() => this.close());
  }

  ngAfterViewInit(): void {
    this.spinnerService.show('chekInSpinner');
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: true,
      ignoreBackdropClick: true,
    };

    if (this.useLegalNoteVersion.indexOf('LEGAL_NOTE.') !== -1) {
      this.toasterService.showError(
        'Please configure Translation: "LEGAL_NOTE.' +
        this.model.LegalEntityCountryIsoCode +
        '.USE_VERSION" correctly. Or contact the system administrator.'
      );
    }
  }

  close(): void {
    if (this.model.step === CheckInModalStepType.fitterSignature) {
      this.model.step = CheckInModalStepType.clientSignature;
    }
    this.updateModel()
      .pipe(
        finalize(() => {
          this.closeModal();
        })
      )
      .subscribe(model => {
        this.onSave(model);
      });
  }

  finish() {
    // Metrics
    const start = new Date().getTime();

    this.model.navigationInProgress = true;
    this.spinnerService.show('chekInSpinner');
    this.updateModel()
      .pipe(
        mergeMap(this.generateTemplate),
        map((template: string) => {
          this.model.signedDocumentContent = template;
          this.model.signedDocumentName = 'Check-in_form_Glassdrive_signed.pdf';
          return template;
        }),
        mergeMap((template: string) => this.saveDocument(template, 'Check-in_form_Glassdrive_signed.pdf')),
        tap((result: FileResult) => this.saveCheckInDocument(result)),
        map((result: FileResult) => {
          this.model.signedDocumentId = result.id;
          return this.model;
        }),
        finalize(() => {
          this.closeModal();
        }),
        tap((model: CheckInModalData) => this.generateTemplate(model)),
        tap((model: CheckInModalData) => {
          this.onSave(model);
        }),
        switchMap((model: CheckInModalData) =>
          observableOf([
            { id: model.unsignedDocumentId, filename: model.unsignedDocumentName, size: 0 },
            { id: model.signedDocumentId, filename: model.signedDocumentName, size: 0 },
          ] as FileResult[])
        )
      )
      .subscribe((documents: FileResult[]) => {
        this.onFinish(documents);
        this.model.metricFinish = this.getMetrics(start);
      });
  }

  getMetrics(start: number) {
    return (new Date().getTime() - start) / 1000;
  }

  cancel(): void {
    this.closeModal();
  }

  childComponentDataChange() {
    this.model.modalStep.anyChanges = true;
  }

  navigateToStep = (stepType: CheckInModalStepType) => () => {
    this.spinnerService.show('chekInSpinner');
    this.model.navigationInProgress = true;
    this.beforeNavigationAction()
      .pipe(
        finalize(() => {
          this.model.navigationInProgress = false;
        })
      )
      .subscribe(() => {
        this.model.step = +stepType;
        this.model.modalStep = this.getDefaultModalStep(stepType);
      });
  }

  beforeNavigationAction(): Observable<{}> {
    if (!this.model.modalStep.anyChanges) {
      return observableOf({});
    }

    switch (this.model.modalStep.step) {
      // Capture start end.
      case CheckInModalStepType.indicateDamage:
        this.clientSignature.clear();
        this.fitterSignature.clear();
        const start = new Date().getTime();
        return this.updateModel().pipe(
          mergeMap((model: CheckInModalData) => this.generateTemplate(model)),
          map((template: string) => {
            this.model.unsignedDocumentContent = template;
            this.model.unsignedDocumentName = 'Check-in_form_Glassdrive_unsigned.pdf';
            return template;
          }),
          mergeMap((file: string) => this.saveDocument(file, 'Check-in_form_Glassdrive_unsigned.pdf')),
          tap((file: FileResult) => this.saveUnsignedCheckInDocument(file)),
          tap(() => {
            this.model.clientSignature = null;
            this.model.fitterSignature = null;
          }),
          map((result: FileResult) => {
            this.model.unsignedDocumentId = result.id;
            this.model.metrictDamageIndicate = this.getMetrics(start);
            return {};
          })
        );
      case CheckInModalStepType.legalNote:
        this.model.LegalEntityCountryIsoCode = 'PRT';
        this.model = this.UpdateModelWithLegalNoteForms(this.model);
        return observableOf({});
      default:
        return observableOf({});
    }
  }

  isCheckInValid(): boolean {
    return this.indicateDamageComponent.isValid();
    // there should be added other components
  }

  updateModel(): Observable<CheckInModalData> {
    this.spinnerService.show('chekInSpinner');
    return this.indicateDamageComponent.getModel().pipe(
      tap((damageIndicateData: DamageIndicateData) => {
        this.model.damageIndicateData = damageIndicateData;
        this.model.clientSignature = this.clientSignature.getImageAsString();
        this.model.fitterSignature = this.fitterSignature.getImageAsString();
      }),
      tap(() => {
        this.model = this.UpdateModelWithLegalNoteForms(this.model);
      }),
      switchMap(() => observableOf(this.model))
    );
  }

  clientSignatureChange(clientSignature: string) {
    this.model.clientSignature = clientSignature;
  }

  fitterSignatureChange(fitterSignature: string) {
    this.model.fitterSignature = fitterSignature;
  }

  private getDefaultModalStep = (step: number): ModalStep => {
    return [
      {
        step: CheckInModalStepType.indicateDamage,
        nextStepText: 'DOSSIER.MODALS.STEP_1_3_IN',
        previousStepText: 'COMMON.CANCEL',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.legalNote),
        previousStepSelect: this.cancel.bind(this),
        canGoNextStep: () => this.indicateDamageComponent.isValid() && !this.model.navigationInProgress,
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.legalNote,
        nextStepText: 'DOSSIER.MODALS.STEP_2_3_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.clientSignature),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.indicateDamage),
        canGoNextStep: () => this.canContinueWithLegalNote,
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.clientSignature,
        nextStepText: 'DOSSIER.MODALS.STEP_3_3_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.navigateToStep(CheckInModalStepType.fitterSignature),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.legalNote),
        canGoNextStep: () => this.model.clientSignature != null && this.model.clientSignature.length > 0,
        anyChanges: false,
      },
      {
        step: CheckInModalStepType.fitterSignature,
        nextStepText: 'DOSSIER.MODALS.FINISH_CHECK_IN',
        previousStepText: 'DOSSIER.MODALS.BACK',
        nextStepSelect: this.finish.bind(this),
        previousStepSelect: this.navigateToStep(CheckInModalStepType.clientSignature),
        canGoNextStep: () => this.model.fitterSignature != null && this.model.fitterSignature.length > 0,
        anyChanges: false,
      },
    ].find((modalStep: ModalStep) => modalStep.step === this.getStepTypeByStep(step));
  }

  private getStepTypeByStep(step: number): CheckInModalStepType {
    this.spinnerService.hide('chekInSpinner');
    return CheckInModalStepType[CheckInModalStepType[step]];
  }

  private initializeQuestion1FormGroup(model: { LegalNoteQuestion1Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question1: new FormControl(model.LegalNoteQuestion1Allowed != null ? model.LegalNoteQuestion1Allowed : true),
    });
  }

  private initializeQuestion2FormGroup(model: { LegalNoteQuestion2Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question2: new FormControl(model.LegalNoteQuestion2Allowed != null ? model.LegalNoteQuestion2Allowed : true),
    });
  }

  private initializeQuestion3FormGroup(model: { LegalNoteQuestion3Allowed?: boolean }): FormGroup {
    return new FormGroup({
      question3: new FormControl(model.LegalNoteQuestion3Allowed != null ? model.LegalNoteQuestion3Allowed : true),
    });
  }

  private UpdateModelWithLegalNoteForms(model: CheckInModalData): CheckInModalData {
    const question1formValue = (fieldName: string) => this.question1FormGroup.get(fieldName).value;
    const question2formValue = (fieldName: string) => this.question2FormGroup.get(fieldName).value;
    const question3formValue = (fieldName: string) => this.question3FormGroup.get(fieldName).value;

    const allowQ1 = question1formValue('question1');
    const allowQ2 = question2formValue('question2');
    const allowQ3 = question3formValue('question3');

    return {
      ...model,
      LegalNoteQuestion1Allowed: allowQ1,
      LegalNoteQuestion2Allowed: allowQ2,
      LegalNoteQuestion3Allowed: allowQ3,
      LegalNoteInitialAllowed: true,
    };
  }
}
