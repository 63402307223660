import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { DocumentStructureService } from '../../services/document-structure.service';
import { StructureDetail } from '@hrz/core/models/document/structure-detail';
import { Structure } from '@hrz/core/models/document/structure';
import { StructureEdit } from '@hrz/core/models/document/structure-edit';
import { BaseActionModal } from '../base/base-action.modal';

@Component({
  templateUrl: './structure-move.modal.html',
})
@DynamicModal()
export class StructureMoveModal extends BaseActionModal implements OnInit {
  public structureMoveModel: StructureEdit;
  public onStructureMove: Function;
  public onFailure: Function;
  public structureLoading: boolean;
  public structure: Structure[];
  @ViewChild('structureMoveModal') childModal: ModalDirective;

  constructor(private documentStructureService: DocumentStructureService) {
    super();
  }

  ngOnInit(): void {
    this.loadStructure();
  }

  cancel(): void {
    this.closeModal();
  }

  apply(): void {
    this.moveStructure();
  }

  moveStructure() {
    this.loading = true;
    this.documentStructureService
      .update(this.structureMoveModel)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
        })
      )
      .subscribe(
        (_structure: StructureDetail) => {
          this.onStructureMove();
        },
        error => {
          this.onFailure(error);
        }
      );
  }

  substructureSelect(structure: Structure) {
    this.structureMoveModel.parentId = structure.id;
  }

  loadStructure() {
    this.structureLoading = true;
    this.documentStructureService
      .getMoveable(this.structureMoveModel.id)
      .pipe(
        finalize(() => {
          this.structureLoading = false;
        })
      )
      .subscribe((structure: Structure[]) => {
        this.structure = structure;
      });
  }

  isStructureMoveModelValid() {
    return this.structureMoveModel.id != null && this.structureMoveModel.name != null && this.structureMoveModel.parentId != null;
  }
}
