import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { AuthService } from '@hrz/core/services/auth.service';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';

@Component({
  selector: 'store-locator-modal',
  providers: [TranslateService],
  templateUrl: './store-locator.modal.html',
})
@DynamicModal()
export class StoreLocatorModal implements OnInit, AfterViewInit, OnDestroy {
  public onClose: Function;
  public formData: any;
  public storeLocatorUrl: string;
  public storeLocatorSaveUrl: SafeUrl;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;
  constructor(
    private authService: AuthService,
    private sanitizer: DomSanitizer
  ) {}
  ngOnInit(): void {
    console.log('StoreLocatorModal.ngOnInit');

    this.authService.getTenantId().subscribe(tenantId => {
      this.storeLocatorUrl = ConfigurationManager.AppSettings.storeLocatorUrlList.find(obj => obj.key == tenantId).value;
      if (this.formData.job.model && this.formData.job.model.isMobileJob && this.formData.job.model.isMobileJob.Value === 'Yes') {
        const address =
          this.formData.job.model.AddressStreet +
          ' ' +
          this.formData.job.model.AddressPostalcode +
          ' ' +
          this.formData.job.model.AddressCity +
          ' ' +
          (this.formData.job.model.AddressCountry ? this.formData.job.model.AddressCountry.Name : '');
        this.storeLocatorUrl = this.storeLocatorUrl + '?address=' + address + '&mobile=1';
      }
      this.storeLocatorSaveUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.storeLocatorUrl);
    });
  }

  ngAfterViewInit(): void {
    console.log('StoreLocatorModal.ngAfterViewInit');

    const parentScope = this;
    if (window.addEventListener) {
      window.addEventListener('message', function (event: any) {
        parentScope.handleMessage(event, parentScope);
      });
    } else {
      (<any>window).attachEvent('onmessage', function (event: any) {
        parentScope.handleMessage(event, parentScope);
      });
    }

    this.childModal.show();
  }

  handleMessage(event: any, scope: any) {
    if (event.data.event_id === 'fitting_station_message') {
      if (event.data.data.fitting_station == null) {
        return;
      }
      scope.clickCancel();
    }
  }

  ngOnDestroy(): void {
    console.log('StoreLocatorModal.ngOnDestroy');
  }
  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }
}
