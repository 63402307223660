import { finalize } from 'rxjs/operators';
import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { SpinnerService } from '@hrz/core/services/spinner.service';
import { DynamicModal } from '../../../../shared/dynamic-modal.module';
import { EditDossierAppointmentComponent } from '../edit-dossier-appointment/edit-dossier-appointment.component';
import { Dossier } from '@hrz/core/models/dossier';
import { AvailableTimeRange } from '@hrz/core/models/scheduler/available-time-range';
import { ScheduleAvailabilityService } from '@hrz/core/services/schedule-availability.service';
import { UnavailablePeriod } from '@hrz/core/models/unavailable-period';
import { Appointment } from '@hrz/core/models/appointment';
import { AppointmentUpdate } from '@hrz/core/models/appointment-update';
import { AppointmentSchedulerModel } from '@hrz/core/models/scheduler/appointment-scheduler-model';
import { AvailableHours } from '@hrz/core/models/available-hours';
import { AvailableSlot } from '@hrz/core/models/available-slot';
import { AppointmentData } from '@hrz/core/models/appointment-data';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { noOnlyWhitespace } from '@hrz/core/utils/validators';
import { EnumService } from '@hrz/core/services/enum.service';
import { Enum } from '@hrz/core/models/enum';
import { AuthService } from '@hrz/core/services/auth.service';
import { Permission } from '@hrz/core/models/enums/permission.enum';
import { DateRange } from '@hrz/core/models/scheduler/date-range';

@Component({
  templateUrl: 'edit-dossier-appointment.modal.html',
  styleUrls: ['edit-dossier-appointment.modal.scss']
})
@DynamicModal()
// tslint:disable-next-line:component-class-suffix
export class EditDossierAppointmentModal implements OnInit, AfterViewInit, OnDestroy {
  public onSave: (
    appointmentUpdate: AppointmentUpdate,
    preferredNotificationType: string,
    email: string
  ) => Observable<ApiActionResult<Appointment>>;
  onAfterSave: (appointment: Appointment) => void;
  onDelete: (appointmentId: number) => Observable<ApiActionResult<void>>;
  onAfterDelete: (appointmentId: number) => void;
  onFailure: () => void;
  appointmentData: AppointmentData;
  dossier: Dossier;
  availableTimeRange: AvailableTimeRange[] = [];
  item: AppointmentSchedulerModel;
  editMobileAddressForm: FormGroup;
  appointmentDurationInHours: number;
  formattedAppointmentDate: string;
  availableHours: AvailableHours[] = [];
  availablePeriodSubscription: Subscription = Subscription.EMPTY;
  timeDiffBetweenAvailableHours = 60; // in minutes
  _loading = false;
  editMode: boolean;
  shouldNotifyMode: boolean;
  fittingStationHasMobileService: boolean;
  fittingStationIsMobileOnly: boolean;
  isMobileJob: boolean;
  editFittingAddressAllowed: boolean;
  isSaveEnable = false;
  isMobileJobDirty = false;
  mytime: string;
  preferredNotificationMode = false;
  preferredNotificationTypeOptions: Enum[] = [];
  hasConfirmationRights = false;
  ownerHasEmail: boolean;
  damageLocationEnum: Enum[];
  translatedJobType: string;
  translatedDamageLocation: string;

  private preferredNotificationForm: FormGroup;
  private preferredNotificationType: string = null;
  private email: string = null;
  private editedDateTimeModel: DateRange = null;
  private shouldNotify = false;

  get loading(): boolean {
    return this._loading;
  }
  set loading(value: boolean) {
    this.shouldSetClickOutsideDisabled(value);
    this._loading = value;
  }
  isAdressChangeEnable = false;
  private closeModal: Function;
  @ViewChild('editDossierAppointment') private childModal: ModalDirective;
  @ViewChild(EditDossierAppointmentComponent) editDossierAppointmentFormComp: EditDossierAppointmentComponent;

  constructor(
    private scheduleAvailabilityService: ScheduleAvailabilityService,
    private spinnerService: SpinnerService,
    private formBuilder: FormBuilder,
    private enumService: EnumService,
    private translateService: TranslateService,
    private authService: AuthService
  ) {}

  private initialIsMobile: boolean;
  ngOnInit(): void {
    this.formattedAppointmentDate = moment.utc(this.item.start).local().format('YYYY-MM-DD');
    this.appointmentDurationInHours = moment.duration(moment(this.item.end).diff(moment(this.item.start))).asHours();
    this.setAvailableHours(this.item.start);
    this.appointmentData = { ...this.item.appointment.appointmentData };
    this.isMobileJob = this.item.appointment.isMobileJob || this.fittingStationIsMobileOnly;
    this.initialIsMobile = this.isMobileJob;
    this.authService.getTenantId().subscribe(_tenantId => {
      this.enumService.getPreferredNotificationTypes().then(items => (this.preferredNotificationTypeOptions = items));
    });
    this.hasConfirmationRights = this.authService.userHasScope(Permission.u_a_cnf);

    if (this.appointmentData.jobType === 'Repair') {
      this.translatedJobType = this.translateService.instant('DOSSIER.MODIFY.REPAIR');
    } else if (this.appointmentData.jobType === 'Replace') {
      this.translatedJobType = this.translateService.instant('DOSSIER.MODIFY.REPLACE');
    } else {
      this.translatedJobType = this.appointmentData.jobType;
    }

    this.enumService.getDamageLocation().then(results => {
      this.damageLocationEnum = results;
      this.translatedDamageLocation =
        this.damageLocationEnum.find(x => x.Key === this.appointmentData.damageLocation).Value || this.appointmentData.damageLocation;
    });

    this.editMobileAddressForm = new FormGroup({
      fittingAddressStreet: new FormControl(null, Validators.required),
      fittingAddressZipCode: new FormControl(null, Validators.required),
      fittingAddressCity: new FormControl(null, Validators.required),
    });

    this.preferredNotificationForm = this.formBuilder.group({
      preferredNotificationType: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.email, noOnlyWhitespace]),
    });

    this.subscribeToFormChanges();

    this.editMobileAddressForm.get('fittingAddressStreet').setValue(this.appointmentData.fittingAddressStreet);
    this.editMobileAddressForm.get('fittingAddressZipCode').setValue(this.appointmentData.fittingAddressZipCode);
    this.editMobileAddressForm.get('fittingAddressCity').setValue(this.appointmentData.fittingAddressCity);
  }

  ngAfterViewInit(): void {
    this.childModal.show();
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: false,
      ignoreBackdropClick: true,
    };
    if (this.isMobileJob) {
      this.setEnableAddressChange();
    }
  }

  subscribeToFormChanges() {
    this.editMobileAddressForm.valueChanges.subscribe(() => {
      if (this.editMobileAddressForm.valid && this.editMobileAddressForm.dirty) {
        this.isSaveEnable = true;
      }
    });

    this.editMobileAddressForm.get('fittingAddressStreet').valueChanges.subscribe(newValue => {
      this.appointmentData.fittingAddressStreet = newValue;
    });

    this.editMobileAddressForm.get('fittingAddressZipCode').valueChanges.subscribe(newValue => {
      this.appointmentData.fittingAddressZipCode = newValue;
    });

    this.editMobileAddressForm.get('fittingAddressCity').valueChanges.subscribe(newValue => {
      this.appointmentData.fittingAddressCity = newValue;
    });
  }

  ngOnDestroy(): void {}

  clickCancel(): void {
    this.closeModal();
  }

  clickDelete(): void {
    this.spinnerService.show('appointmentSpinner');
    this.loading = true;
    this.onDelete(this.item.id)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.closeModal();
          this.spinnerService.hide('appointmentSpinner');
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterDelete) {
            this.onAfterDelete(this.item.id);
          }
        },
        () => {
          this.onFailure();
          this.spinnerService.hide('appointmentSpinner');
        }
      );
  }

  isValid(): boolean {
    return this.editMobileAddressForm.valid;
  }

  isDirty(): boolean {
    return this.editMobileAddressForm.dirty;
  }

  setEnableAddressChange() {
    this.isAdressChangeEnable = !this.isAdressChangeEnable;
    return;
  }

  enableAddressChange() {
    if (this.initialIsMobile != this.isMobileJob) {
      this.isSaveEnable = true;
      return;
    }

    this.isAdressChangeEnable = !this.isAdressChangeEnable;
    if (this.isMobileJob && !this.editFittingAddressAllowed) {
      this.isSaveEnable = true;
    }
    if (this.editFittingAddressAllowed && this.isMobileJob && this.editMobileAddressForm.valid) {
      this.isSaveEnable = true;
    }
    if (!this.isMobileJob) {
      this.isSaveEnable = true;
    }
    return;
  }

  dateChange(date: Date) {
    this.getAvailableSlots(date);
  }

  clickFinalSave(): void {
    if (this.preferredNotificationMode === true) {
      this.shouldNotifyMode = true;
    } else {
      this.clickSave();
    }
  }

  clickSave(): void {
    this.spinnerService.show('appointmentSpinner');

    if (this.editMode) {
      this.editedDateTimeModel = this.editDossierAppointmentFormComp.getModel();
      this.editMode = false;
      this.preferredNotificationMode = false;
      this.shouldNotifyMode = true;
    } else {
      this.updateDossierAppointment();
    }
  }

  clickDontNotify(): void {
    this.shouldNotify = false;
    this.clickSave();
  }

  clickNotify(): void {
    this.shouldNotify = true;
    this.clickSave();
  }

  clickEdit(): void {
    this.editMode = true;
  }

  clickReschedule(): void {
    this.editMode = true;
  }

  clickConfirm(): void {
    this.item.appointment.isConfirmed = true;
    if (this.dossier.DossierVehicle.Owner.PreferredNotificationType !== null) {
      this.shouldNotifyMode = true;
    } else {
      this.preferredNotificationMode = true;
    }
  }

  setAvailableHours(date: Date) {
    this.getAvailableSlots(date);
  }

  updateDossierAppointment() {
    this.loading = true;

    if (this.preferredNotificationMode) {
      this.preferredNotificationType = this.preferredNotificationForm.get('preferredNotificationType').value;
      if (!this.ownerHasEmail) {
        this.email = this.preferredNotificationForm.get('email').value;
      }
    } else if (this.authService.userHasScope(Permission.u_a_cnf)) {
      this.item.appointment.isConfirmed = true;
    }

    this.onSave(this.getUpdatedAppointmentModel(), this.preferredNotificationType, this.email)
      .pipe(
        finalize(() => {
          this.loading = false;
          this.spinnerService.hide('appointmentSpinner');
          this.closeModal();
        })
      )
      .subscribe(
        response => {
          if (response.commandSucceeded && this.onAfterSave) {
            this.onAfterSave(response.entity);
          }
        },
        () => {
          this.onFailure();
          this.spinnerService.hide('appointmentSpinner');
        }
      );
  }

  mapPeriods(periods: UnavailablePeriod[]): UnavailablePeriod[] {
    return periods.map(p => {
      p.start = moment.utc(p.start).local().toDate();
      p.end = moment.utc(p.end).local().toDate();
      return p;
    });
  }

  getAvailableSlots(date: Date): void {
    this.availablePeriodSubscription.unsubscribe();
    this.availablePeriodSubscription = this.scheduleAvailabilityService
      .getAvailableSlots(
        this.item.fittingStationId,
        this.item.appointment.appointmentEventType.id,
        moment(date).utc().startOf('day').format('YYYY-MM-DD')
      )
      .subscribe((slots: AvailableSlot[]) => {
        this.availableHours = this.mapAvailableHours(slots);
      });
  }

  mapAvailableHours(slots: AvailableSlot[]): AvailableHours[] {
    if (this.editDossierAppointmentFormComp) {
      this.editDossierAppointmentFormComp.editDossierAppointmentForm.valueChanges.subscribe(() => {
        if (
          this.editDossierAppointmentFormComp.editDossierAppointmentForm.valid &&
          this.editDossierAppointmentFormComp.editDossierAppointmentForm.dirty
        ) {
          this.isSaveEnable = true;
        }
      });
    }

    if (!slots) {
      return [];
    }
    const timeFormat = 'HH:mm:ss';
    return slots.map(s => ({
      start: moment.utc(s.start, timeFormat).local(),
      end: moment.utc(s.end, timeFormat).local(),
      displayHours: this.formatHours({ start: moment.utc(s.start, timeFormat).local(), end: moment.utc(s.end, timeFormat).local() }),
      displayStartHours: this.formatStartHours({ start: moment.utc(s.start, timeFormat).local() }),
      displayEndHours: this.formatEndHours({ end: moment.utc(s.end, timeFormat).local() }),
    }));
  }

  formatHours(hours: { start: moment.Moment; end: moment.Moment }): string {
    return `${hours.start.format('HH:mm')} - ${hours.end.format('HH:mm')}`;
  }

  formatStartHours(hours: { start: moment.Moment }): string {
    return `${hours.start.format('HH:mm')}`;
  }

  formatEndHours(hours: { end: moment.Moment }): string {
    return ` ${hours.end.format('HH:mm')}`;
  }

  shouldSetClickOutsideDisabled = (disabled: boolean) => {
    this.childModal.config = {
      ...this.childModal.config,
      backdrop: disabled ? 'static' : true,
      keyboard: !disabled,
    };
  }

  getUpdatedAppointmentModel(): AppointmentUpdate {
    const appointmentUpdate: AppointmentUpdate = {
      id: this.item.id,
      dossierId: this.item.dossierId,
      fittingStationId: this.item.fittingStationId,
      appointmentEventTypeId: this.item.appointment.appointmentEventType.id,
      start: moment(this.item.start).utc().format(),
      end: moment(this.item.end).utc().format(),
      appointmentData: this.appointmentData,
      isMobileJob: this.isMobileJob,
      blockedMinutesBefore: this.item.appointment.blockedMinutesBefore,
      blockedMinutesAfter: this.item.appointment.blockedMinutesAfter,
      assignedByCallCenter: this.item.appointment.assignedByCallCenter,
      isConfirmed: this.item.appointment.isConfirmed,
      shouldNotify: this.shouldNotify,
    };

    if (this.editedDateTimeModel !== null) {
      (appointmentUpdate.start = this.editedDateTimeModel.startDate),
        (appointmentUpdate.end = this.editedDateTimeModel.endDate),
        (appointmentUpdate.blockedMinutesBefore = this.editedDateTimeModel.blockedMinutesBefore),
        (appointmentUpdate.blockedMinutesAfter = this.editedDateTimeModel.blockedMinutesAfter);
    }
    return appointmentUpdate;
  }

  isFinalSaveValid(): boolean {
    if (this.ownerHasEmail || this.preferredNotificationForm.get('preferredNotificationType').value !== 'Email') {
      return this.preferredNotificationForm.get('preferredNotificationType').valid;
    }
    return this.preferredNotificationForm.valid;
  }
}
