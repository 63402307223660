import { Injectable, Optional, SecurityContext, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ListDataResponse } from '@hrz/core/models/list-data-response';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { CollaborationMessage } from '@hrz/core/models/dossier/collaboration-message';
import { DomSanitizer } from '@angular/platform-browser';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class DossierCollaborationMessageService {
  private dossierUrl = ConfigurationManager.AppSettings.dossierApi + '/dossier';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private domSanitizer: DomSanitizer,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: DossierCollaborationMessageService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('DossierCollaborationMessageService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getAllByDossierId(dossierId: number, page: number, noOfRecords: number): Promise<ListDataResponse<CollaborationMessage>> {
    return this.http
      .get<ListDataResponse<CollaborationMessage>>(`${this.dossierUrl}/${dossierId}/collaborationMessage?page=${page}&noOfRecords=${noOfRecords}`)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public getDestinationMails(dossierId: number, recipient: string, channelType: string): Promise<Array<string>> {
    const url = `${this.dossierUrl}/${dossierId}/collaborationMessage/DestinationMails?recipient=${recipient}&channelType=${channelType}`;

    return this.http
      .get<Array<string>>(url)
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public create(message: CollaborationMessage): Promise<ApiActionResult> {
    console.log('POST - JSON Command: ', JSON.stringify(message));

    const createCommand = {
      From: message.From,
      Recipient: message.Recipient,
      To: message.To,
      ChannelType: 'Email',
      // ChannelType: message.Recipient === 'InsuranceCompany' ? 'WebApi' : 'Email',
      Subject: message.Subject,
      Content: message.Content,
      DossierId: message.DossierId,
      // Attachments: message.Attachments.map(a => a.Reference),
      Attachments: message.Attachments,
      PermissionScope: message.PermissionScope,
    };
    const sanitizedSubject = createCommand.Subject;
    const sanitizedContent = this.domSanitizer.sanitize(SecurityContext.HTML, createCommand.Content);
    createCommand.Subject = sanitizedSubject;
    createCommand.Content = sanitizedContent;
    return this.http
      .post(`${this.dossierUrl}/${message.DossierId}/collaborationMessage`, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
