import { Injectable, Optional, SkipSelf } from '@angular/core';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { JobInvoice } from '@hrz/core/models/job-invoice';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { Document } from '@hrz/core/models/document';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { DossierTaxInvoice } from '@hrz/core/models/dossier/dossier-taxinvoice';
import { JobInvoicePatchCommand } from '@hrz/core/models/dossier/job-invoice-patch-command';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class JobInvoiceService {
  private baseUrl = ConfigurationManager.AppSettings.dossierApi;
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: JobInvoiceService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('JobInvoiceService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  public getAll(dossierId: number, jobId: number): Promise<JobInvoice[]> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobId}/jobinvoice`;
    const data = this.http.get<JobInvoice[]>(url);
    return data
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public get(dossierId: number, jobId: number, jobInvoice: JobInvoice): Promise<JobInvoice[]> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobId}/jobinvoice/${jobInvoice.Id}`;
    const data = this.http.get<JobInvoice>(url);
    return data
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public delete(dossierId: number, jobId: number, jobInvoice: JobInvoice): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobId}/jobinvoice/${jobInvoice.Id}`;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public create(document: Document, jobId: number, jobInvoice: JobInvoice): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${document.Dossier.Id}/job/${jobId}/jobinvoice`;
    const createCommand = {
      JobId: jobId,
      InvoiceNumber: jobInvoice.InvoiceNumber,
      InvoiceDate: moment(jobInvoice.InvoiceDate).utc().format(),
      InvoiceType: jobInvoice.InvoiceType,
      DossierId: document.Dossier.Id,
      DossierVehicleId: document.DossierVehicleId,
      DocumentType: document.DocumentType,
      MetaDataFileName: document.FileMetaData.FileName,
      MetaDataFileType: document.FileMetaData.FileType,
      MetaDataFileSize: document.FileMetaData.FileSize,
      MetaDataReference: document.FileMetaData.Reference,
      Number: document.Number,
      UploadDate: moment(new Date()).utc().toDate(),
    };

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createWithoutValidations(document: Document, jobId: number, jobInvoice: JobInvoice): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${document.Dossier.Id}/job/${jobId}/jobinvoice/createwithoutvalidations`;
    const createCommand = {
      JobId: jobId,
      InvoiceNumber: jobInvoice.InvoiceNumber,
      InvoiceDate: moment(jobInvoice.InvoiceDate).utc().format(),
      InvoiceType: jobInvoice.InvoiceType,
      DossierId: document.Dossier.Id,
      DossierVehicleId: document.DossierVehicleId,
      DocumentType: document.DocumentType,
      ExistingDocumentId: document.Id,
      MetaDataFileName: document.FileMetaData.FileName,
      MetaDataFileType: document.FileMetaData.FileType,
      MetaDataFileSize: document.FileMetaData.FileSize,
      MetaDataReference: document.FileMetaData.Reference,
      Number: document.Number,
      UploadDate: moment(new Date()).utc().toDate(),
    };

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public update(jobInvoice: JobInvoice, dossierId: number): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobInvoice.Job.Id}/jobinvoice/${jobInvoice.Id}`;
    const updateCommand = {
      Id: jobInvoice.Id,
      JobId: jobInvoice.Job.Id,
      InvoiceNumber: jobInvoice.InvoiceNumber,
      InvoiceDate: moment(jobInvoice.InvoiceDate).utc().format(),
      InvoiceType: jobInvoice.InvoiceType,
      InvoiceDocumentId: jobInvoice.InvoiceDocument.Id,
    };

    console.log('PUT - JSON Command: ', JSON.stringify(updateCommand));
    return this.http
      .put(url, JSON.stringify(updateCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public patch(dossierId: number, jobId: number, jobInvoiceId: number, patchCommand: JobInvoicePatchCommand): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobId}/jobinvoice/${jobInvoiceId}`;

    const cmdJson = JSON.stringify(patchCommand);
    console.log('PATCH - JSON Command: ', cmdJson);
    return this.http
      .patch(url, cmdJson, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public createCreditNote(dossierId: number, jobInvoice: JobInvoice): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${dossierId}/job/${jobInvoice.Job.Id}/jobinvoice/requestcreditnote`;
    const createCommand = {
      JobInvoiceId: jobInvoice.Id,
      Reason: jobInvoice.RequestCreditReason,
    };

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public copyTax(taxInvoice: DossierTaxInvoice): Promise<ApiActionResult> {
    const url = `${this.baseUrl}/dossier/${taxInvoice.DossierId}/job/0/jobinvoice/copytax`;
    const createCommand = {
      InvoiceNumber: taxInvoice.Number,
      InvoiceDate: moment(taxInvoice.Date).utc().format(),
      InvoiceType: 'FitterInvoice',
      CreatedOn: moment(taxInvoice.CreatedOn).utc().format(),
      DossierId: taxInvoice.DossierId,
      Attachments: taxInvoice.Attachments,
      UploadDate: moment(taxInvoice.CreatedOn).utc().toDate(),
      Totals: {
        NetValue: taxInvoice.Totals.Net,
        Vat: taxInvoice.Totals.Vat,
        Total: taxInvoice.Totals.Total,
      },
    };

    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
