import { Injectable, Optional, SkipSelf } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import * as exifr from 'exifr';
import picaLib from 'pica';

import { AppInsightsService } from '@hrz/core/services/app-insights.service';

/* Service to manipulate image : compress, resize, ...
   Forked from https://github.com/bergben/ng2-img-max and internalize to avoid external dependency
   Relies on pica and exifr as external dependencies
*/

const pica = (picaLib as any)();
const globalWindow: any = window;

interface ResizeCanvasOptions {
    quality?: number;
    alpha?: boolean;
    unsharpAmount?: number;
    unsharpRadius?: number;
    unsharpThreshold?: number;
}

interface ResizeBufferOptions {
    src: Uint8Array;
    width: number;
    height: number;
    toWidth: number;
    toHeight: number;
    quality?: number;
    alpha?: boolean;
    unsharpAmount?: number;
    unsharpRadius?: number;
    unsharpThreshold?: number;
}

@Injectable({
    providedIn: 'root'
})
class ImageExifService {

    constructor(
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: ImageExifService) {
        if (parent) {
            appInsightsService.logException(new Error('ImageExifService is a Singleton and should only be loaded in AppModule.'));
        }
    }

    public getOrientedImage(image: HTMLImageElement): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>(resolve => {
            let img: any;
            exifr.orientation(image).catch(_ => undefined).then(orientation => {
                if (orientation !== 1) {
                    const canvas: HTMLCanvasElement = document.createElement('canvas');
                    const ctx: CanvasRenderingContext2D = <CanvasRenderingContext2D>canvas.getContext('2d');
                    let cw: number = image.width;
                    let ch: number = image.height;
                    let cx = 0;
                    let cy = 0;
                    let deg = 0;
                    switch (orientation) {
                        case 3:
                        case 4:
                            cx = -image.width;
                            cy = -image.height;
                            deg = 180;
                            break;
                        case 5:
                        case 6:
                            cw = image.height;
                            ch = image.width;
                            cy = -image.height;
                            deg = 90;
                            break;
                        case 7:
                        case 8:
                            cw = image.height;
                            ch = image.width;
                            cx = -image.width;
                            deg = 270;
                            break;
                        default:
                            break;
                    }

                    canvas.width = cw;
                    canvas.height = ch;
                    if (orientation && [2, 4, 5, 7].indexOf(orientation) > -1) {
                        // flip image
                        ctx.translate(cw, 0);
                        ctx.scale(-1, 1);
                    }
                    ctx.rotate(deg * Math.PI / 180);
                    ctx.drawImage(image, cx, cy);
                    img = document.createElement('img');
                    img.width = cw;
                    img.height = ch;
                    img.addEventListener('load', function () {
                        resolve(img);
                    });
                    img.src = canvas.toDataURL('image/png');
                } else {
                    resolve(image);
                }
            });
        });
    }
}

@Injectable({
    providedIn: 'root'
})
class ImageMaxSizeService {
    
    MAX_STEPS = 15;
    initialFile: File | undefined;

    constructor(
        private imageExifService: ImageExifService,
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: ImageMaxSizeService) {
        if (parent) {
            appInsightsService.logException(new Error('ImageMaxSizeService is a Singleton and should only be loaded in AppModule.'));
        }
    }

    public compressImage(file: File, maxSizeInMB: number, ignoreAlpha: boolean = false): Observable<any> {
        const compressedFileSubject: Subject<any> = new Subject<any>();
        this.initialFile = file;
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            // END OF COMPRESSION
            setTimeout(() => {
                compressedFileSubject.error({
                    compressedFile: file,
                    reason: 'File provided is neither of type jpg nor of type png.',
                    error: 'INVALID_EXTENSION'
                });
            }, 0);
            return compressedFileSubject.asObservable();
        }

        const oldFileSize = file.size / 1024 / 1024;
        if (oldFileSize < maxSizeInMB) {
            // END OF COMPRESSION
            // FILE SIZE ALREADY BELOW MAX_SIZE -> no compression needed
            setTimeout(() => {
                compressedFileSubject.next(file);
            }, 0);
            return compressedFileSubject.asObservable();
        }

        const cvs = document.createElement('canvas');
        let ctx = cvs.getContext('2d');
        const img = new Image();
        const self = this;
        img.onload = () => {
            this.imageExifService.getOrientedImage(img).then(orientedImg => {
                window.URL.revokeObjectURL(img.src);
                cvs.width = orientedImg.width;
                cvs.height = orientedImg.height;
                ctx.drawImage(orientedImg, 0, 0);
                const imageData = ctx.getImageData(0, 0, orientedImg.width, orientedImg.height);
                if (file.type === 'image/png' && this.isImgUsingAlpha(imageData) && !ignoreAlpha) {
                    // png image with alpha
                    compressedFileSubject.error({
                        compressedFile: file,
                        reason: 'File provided is a png image which uses the alpha channel. No compression possible.',
                        error: 'PNG_WITH_ALPHA'
                    });
                }
                ctx = cvs.getContext('2d', { 'alpha': false });
                ctx.drawImage(orientedImg, 0, 0);
                self.getCompressedFile(cvs, 50, maxSizeInMB, 1).then((compressedFile) => {
                    compressedFileSubject.next(compressedFile);
                }).catch((error) => {
                    compressedFileSubject.error(error);
                });
            });
        };
        img.src = window.URL.createObjectURL(file);
        return compressedFileSubject.asObservable();
    }

    private getCompressedFile(cvs: HTMLCanvasElement, quality: number, maxSizeInMB: number, currentStep: number): Promise<File> {
        const result: Promise<File> = new Promise((resolve, reject) => {
            cvs.toBlob((blob) => {

                if (!blob) {
                    return reject({
                        compressedFile: null,
                        reason: 'Blob error',
                        error: 'BAD_BLOB'
                    });
                }

                if (currentStep + 1 > this.MAX_STEPS) {
                    // COMPRESSION END
                    // maximal steps reached
                    reject({
                        compressedFile: this.getResultFile(blob),
                        reason: 'Could not find the correct compression quality in ' + this.MAX_STEPS + ' steps.',
                        error: 'MAX_STEPS_EXCEEDED'
                    });
                } else {
                    const newQuality = this.getCalculatedQuality(blob, quality, maxSizeInMB, currentStep);
                    this.checkCompressionStatus(cvs, blob, quality, maxSizeInMB, currentStep, newQuality)
                        .then(result1 => {
                            resolve(result1);
                        })
                        .catch(result2 => {
                            reject(result2);
                        });
                }
            }, 'image/jpeg', quality / 100);
        });
        return result;
    }

    private getResultFile(blob: Blob): File | null {

        if (!this.initialFile) {
            return null;
        }

        return this.generateResultFile(blob, this.initialFile.name, this.initialFile.type, new Date().getTime());
    }

    private generateResultFile(blob: Blob, name: string, type: string, lastModified: number): File {
        const resultFile = new Blob([blob], { type: type });
        return this.blobToFile(resultFile, name, lastModified);
    }

    private blobToFile(blob: Blob, name: string, lastModified: number): File {
        const file: any = blob;
        file.name = name;
        file.lastModified = lastModified;

        // Cast to a File() type
        return <File>file;
    }

    private getCalculatedQuality(blob: Blob, quality: number, maxSizeInMB: number, currentStep: number): number {

        if (!this.initialFile) {
            return 0;
        }

        // CALCULATE NEW QUALITY
        const currentSize = blob.size / 1024 / 1024;
        let ratioMaxSizeToCurrentSize = maxSizeInMB / currentSize;
        if (ratioMaxSizeToCurrentSize > 5) {
            // max ratio to avoid extreme quality values
            ratioMaxSizeToCurrentSize = 5;
        }
        let ratioMaxSizeToInitialSize = currentSize / (this.initialFile.size / 1024 / 1024);
        if (ratioMaxSizeToInitialSize < 0.05) {
            // min ratio to avoid extreme quality values
            ratioMaxSizeToInitialSize = 0.05;
        }
        let newQuality = 0;
        let multiplicator = Math.abs(ratioMaxSizeToInitialSize - 1) * 10 / (currentStep * 1.7) / ratioMaxSizeToCurrentSize;
        if (multiplicator < 1) {
            multiplicator = 1;
        }
        if (ratioMaxSizeToCurrentSize >= 1) {
            newQuality = quality + (ratioMaxSizeToCurrentSize - 1) * 10 * multiplicator;
        } else {
            newQuality = quality - (1 - ratioMaxSizeToCurrentSize) * 10 * multiplicator;
        }

        if (newQuality > 100) {
            // max quality = 100, so let's set the new quality to the value in between the old quality and 100 in case of > 100
            newQuality = quality + (100 - quality) / 2;
        }

        if (newQuality < 0) {
            // min quality = 0, so let's set the new quality to the value in between the old quality and 0 in case of < 0
            newQuality = quality - quality / 2;
        }
        return newQuality;
    }

    private checkCompressionStatus(cvs: HTMLCanvasElement, blob: Blob, quality: number, maxSizeInMB: number,
        currentStep: number, newQuality: number): Promise<any> {
        const result: Promise<any> = new Promise((resolve, reject) => {
            if (quality === 100 && newQuality >= 100) {
                // COMPRESSION END
                // Seems like quality 100 is max but file still too small, case that shouldn't exist as the compression
                // shouldn't even have started in the first place
                reject({
                    compressedFile: this.initialFile,
                    reason: 'Unfortunately there was an error while compressing the file.',
                    error: 'FILE_BIGGER_THAN_INITIAL_FILE'
                });
            } else if ((quality < 1) && (newQuality < quality)) {
                // COMPRESSION END
                // File size still too big but can't compress further than quality=0
                reject({
                    compressedFile: this.getResultFile(blob),
                    reason: 'Could not compress image enough to fit the maximal file size limit.',
                    error: 'UNABLE_TO_COMPRESS_ENOUGH'
                });
            } else if ((newQuality > quality) && (Math.round(quality) === Math.round(newQuality))) {
                // COMPRESSION END
                // next steps quality would be the same quality but newQuality is slightly bigger than old one,
                // means we most likely found the nearest quality to compress to maximal size
                resolve(this.getResultFile(blob));
            } else if (currentStep > 5 && (newQuality > quality) && (newQuality < quality + 2)) {
                // COMPRESSION END
                // for some rare occasions the algorithm might be stuck around e.g. 98.5 and 97.4
                // because of the maxQuality of 100, the current quality is the nearest possible quality in that case
                resolve(this.getResultFile(blob));
            } else if ((newQuality > quality) && Number.isInteger(quality) && (Math.floor(newQuality) === quality)) {
                // COMPRESSION END
                /*
                    in the previous step if ((quality > newQuality) && (Math.round(quality) == Math.round(newQuality))) applied, so
                    newQuality = Math.round(newQuality) - 1; this was done to reduce the quality at least a full integer down to
                    not waste a step with the same compression rate quality as before. Now, the newQuality is still only in between
                    the old quality (e.g. 93) and the newQuality (e.g. 94) which most likely means that the value for the newQuality
                    (the bigger one) would make the filesize too big so we should just stick with the current, lower quality and
                    return that file.
                */
                resolve(this.getResultFile(blob));
            } else {
                // CONTINUE COMPRESSION
                if ((quality > newQuality) && (Math.round(quality) === Math.round(newQuality))) {
                    // quality can only be an integer -> make sure difference between old quality and new one is at least
                    // a whole integer number - it would be nonsense to compress again with the same quality
                    newQuality = Math.round(newQuality) - 1;
                }
                // recursively call function again
                resolve(this.getCompressedFile(cvs, newQuality, maxSizeInMB, currentStep + 1));
            }
        });
        return result;
    }

    private isImgUsingAlpha(imageData: any): boolean {
        for (let i = 0; i < imageData.data.length; i += 4) {
            if (imageData.data[i + 3] !== 255) {
                return true;
            }
        }
        return false;
    }
}

@Injectable({
    providedIn: 'root'
})
class PicaService {
    constructor(
        private imageExifService: ImageExifService,
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: PicaService) {
        if (parent) {
            appInsightsService.logException(new Error('PicaService is a Singleton and should only be loaded in AppModule.'));
        }
    }

    public resize(files: File[], width: number, height: number, keepAspectRatio: boolean = false): Observable<any> {
        const resizedFile: Subject<File> = new Subject<File>();
        for (let i = 0; i < files.length; i++) {
            this.resizeFile(files[i], width, height, keepAspectRatio).then((returnedFile) => {
                resizedFile.next(returnedFile);
            }).catch((error) => {
                resizedFile.error(error);
            });
        }
        return resizedFile.asObservable();
    }

    public resizeCanvas(from: HTMLCanvasElement, to: HTMLCanvasElement, options: ResizeCanvasOptions): Promise<HTMLCanvasElement> {
        const result: Promise<HTMLCanvasElement> = new Promise((resolve, reject) => {
            let curPica = pica;
            if (!curPica || !curPica.resize) {
                curPica = new globalWindow.pica();
            }
            curPica.resize(from, to, options)
                .then((response: any) => {
                    resolve(response);
                },
                    (error: any) => {
                        reject(error);
                    });
        });
        return result;
    }

    public resizeBuffer(options: ResizeBufferOptions): Promise<Uint8Array> {
        const result: Promise<Uint8Array> = new Promise((resolve, reject) => {
            let curPica = pica;
            if (!curPica || !curPica.resizeBuffer) {
                curPica = new globalWindow.pica();
            }
            curPica.resizeBuffer(options)
                .then((response: any) => {
                    resolve(response);
                },
                    (error: any) => {
                        reject(error);
                    });
        });
        return result;
    }

    private resizeFile(file: File, width: number, height: number, keepAspectRatio: boolean = false): Promise<File> {
        const result: Promise<File> = new Promise((resolve, reject) => {
            const fromCanvas: HTMLCanvasElement = document.createElement('canvas');
            let ctx = fromCanvas.getContext('2d');
            const img = new Image();
            img.onload = () => {
                this.imageExifService.getOrientedImage(img).then(orientedImg => {
                    globalWindow.URL.revokeObjectURL(img.src);
                    fromCanvas.width = orientedImg.width;
                    fromCanvas.height = orientedImg.height;
                    ctx.drawImage(orientedImg, 0, 0);
                    const imageData = ctx.getImageData(0, 0, orientedImg.width, orientedImg.height);
                    if (keepAspectRatio && imageData) {
                        const ratio = Math.min(width / imageData.width, height / imageData.height);
                        width = Math.round(imageData.width * ratio);
                        height = Math.round(imageData.height * ratio);
                    }
                    let useAlpha = true;
                    if (file.type === 'image/jpeg' || (file.type === 'image/png' && !this.isImgUsingAlpha(imageData))) {
                        // image without alpha
                        useAlpha = false;
                        ctx = fromCanvas.getContext('2d', { 'alpha': false });
                        ctx.drawImage(orientedImg, 0, 0);
                    }
                    const toCanvas: HTMLCanvasElement = document.createElement('canvas');
                    toCanvas.width = width;
                    toCanvas.height = height;
                    this.resizeCanvas(fromCanvas, toCanvas, { 'alpha': useAlpha })
                        .then((resizedCanvas: HTMLCanvasElement) => {
                            resizedCanvas.toBlob((blob) => {
                                if (!blob) {
                                    return reject('error blob');
                                }
                                const newFile: File = this.generateResultFile(blob, file.name, file.type, new Date().getTime());
                                resolve(newFile);
                            }, file.type);
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
            };
            img.src = globalWindow.URL.createObjectURL(file);
        });
        return result;
    }

    private isImgUsingAlpha(imageData: any): boolean {
        for (let i = 0; i < imageData.data.length; i += 4) {
            if (imageData.data[i + 3] !== 255) {
                return true;
            }
        }
        return false;
    }

    private generateResultFile(blob: Blob, name: string, type: string, lastModified: number): File {
        const resultFile = new Blob([blob], { type: type });
        return this.blobToFile(resultFile, name, lastModified);
    }

    private blobToFile(blob: Blob, name: string, lastModified: number): File {
        const file: any = blob;
        file.name = name;
        file.lastModified = lastModified;

        // Cast to a File() type
        return <File>file;
    }
}

@Injectable({
    providedIn: 'root'
})
class ImageMaxPXSizeService {

    constructor(
        private picaService: PicaService,
        private imageExifService: ImageExifService,
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: ImageMaxPXSizeService) {
        if (parent) {
            appInsightsService.logException(new Error('ImageMaxPXSizeService is a Singleton and should only be loaded in AppModule.'));
        }
    }

    public resizeImage(file: File, maxWidth: number, maxHeight: number, keepAspectRatio: boolean): Observable<any> {
        const resizedFileSubject: Subject<any> = new Subject<any>();
        if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
            // END OF RESIZE
            setTimeout(() => {
                resizedFileSubject.error({
                    resizedFile: file,
                    reason: 'The provided File is neither of type jpg nor of type png.',
                    error: 'INVALID_EXTENSION'
                });
            }, 0);
            return resizedFileSubject.asObservable();
        }
        const img = new Image();
        const self = this;
        img.onload = () => {
            this.imageExifService.getOrientedImage(img).then(orientedImg => {
                window.URL.revokeObjectURL(img.src);
                const currentWidth = orientedImg.width;
                let currentHeight = orientedImg.height;
                let newWidth = currentWidth;
                let newHeight = currentHeight;
                if (newWidth > maxWidth) {
                    newWidth = maxWidth;
                    // resize height proportionally
                    const ratio = maxWidth / currentWidth; // is gonna be <1
                    newHeight = newHeight * ratio;
                }
                currentHeight = newHeight;
                if (newHeight > maxHeight) {
                    newHeight = maxHeight;
                    // resize width proportionally
                    const ratio = maxHeight / currentHeight; // is gonna be <1
                    newWidth = newWidth * ratio;
                }
                if (newHeight === orientedImg.height && newWidth === orientedImg.width) {
                    // no resizing necessary
                    resizedFileSubject.next(file);
                } else {
                    self.picaService.resize([file], newWidth, newHeight, keepAspectRatio).subscribe((result) => {
                        // all good, result is a file
                        resizedFileSubject.next(result);
                    }, error => {
                        // something went wrong
                        resizedFileSubject.error({ resizedFile: file, reason: error, error: 'PICA_ERROR' });
                    });
                }
            });
        };
        img.src = window.URL.createObjectURL(file);

        return resizedFileSubject.asObservable();
    }
}

@Injectable({
    providedIn: 'root'
})
export class ImageService {

    constructor(
        private imageMaxSizeService: ImageMaxSizeService,
        private imageMaxPXSizeService: ImageMaxPXSizeService,
        appInsightsService: AppInsightsService,
        @Optional() @SkipSelf() parent?: ImageService) {
        if (parent) {
            appInsightsService.logException(new Error('ImageService is a Singleton and should only be loaded in AppModule.'));
        }
    }

    public compressImage(file: File, maxSizeInMB: number, ignoreAlpha: boolean = false): Observable<any> {
        return this.imageMaxSizeService.compressImage(file, maxSizeInMB, ignoreAlpha);
    }

    public resizeImage(file: File, maxWidth: number, maxHeight: number, keepAspectRatio: boolean): Observable<any> {
        return this.imageMaxPXSizeService.resizeImage(file, maxWidth, maxHeight, keepAspectRatio);
    }
}
