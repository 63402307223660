import { environment } from '@hrz/env';

export class AppSetting {
  get organizationApi() {
    return environment.organizationApi;
  }
  get fittingApi() {
    return environment.fittingApi;
  }
  get applicationUrl() {
    if (!window.location.origin) {
      console.log(
        'APPURL: ' + window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      );
      return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
    }
    console.log('APPURL: ' + window.location.origin);
    return window.location.origin;
  }
  get debug(): boolean {
    return environment.debug;
  }
  get accountApi() {
    return environment.accountApi;
  }
  get logisticApi() {
    return environment.logisticApi;
  }
  get saplogisticsApi() {
    return environment.sapLogisticApi;
  }
  get dossierApi() {
    return environment.dossierApi;
  }
  get scheduleManagementApi() {
    return environment.scheduleManagementApi;
  }
  get multilangualManagementApi() {
    return environment.multilangualManagementApi;
  }
  get templateManagementApi() {
    return environment.templateManagementApi;
  }
  get documentManagementApi() {
    return environment.documentManagementApi;
  }
  get messagingApi() {
    return environment.messagingApi;
  }
  get sendToAccountApi() {
    return environment.sendToAccountApi;
  }
  get auth0Domain() {
    return environment.auth0Domain;
  }
  get auth0Identifier() {
    return environment.auth0Identifier;
  }
  get auth0ClientId() {
    return environment.auth0ClientId;
  }
  get notificationsApi() {
    return environment.notificationsApi;
  }
  get googleMapsApi() {
    return environment.googleMapsApi;
  }
  get googleMapsApiKey() {
    return environment.googleMapsApiKey;
  }
  get codificationApi() {
    return environment.codificationApi;
  }
  get mstrUrl() {
    return environment.mstrUrl;
  }
  get userApi() {
    return environment.userApi;
  }
  get storeLocatorUrlList() {
    return environment.storeLocatorUrlList;
  }
  get taxApi() {
    return environment.taxApi;
  }
  get webauthorityApi() {
    return environment.webauthorityApi;
  }
  get mobileJobsEnabledTenants() {
    return environment.mobileJobEnabledTenants;
  }
  get autoverOrderUrl() {
    return environment.autoverOrderUrl;
  }
  get casesApi() {
    return environment.casesApi;
  }
  get utilProxyApi() {
    return environment.utilProxyApi;
  }
  // 3507
  get envDeepLinkUrl() {
    return environment.envDeepLinkUrl;
  }
  get dossierSearchApi() {
    return environment.dossierSearchApi;
  }
}
