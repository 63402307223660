import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { FittingStationGroup } from '@hrz/core/models/fitting-station-group';
import { ConfigurationManager } from '@hrz/core/models/configuration.manager';
import { ApiActionResult } from '@hrz/core/models/api-action-result';
import { ServiceActionHandler } from '@hrz/core/services/service-action-handler';
import { UrlUtils } from 'src/app/core/utils/url.utils';
import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class FittingStationGroupService {
  private httpUrl = ConfigurationManager.AppSettings.fittingApi + '/fittingstationgroup';
  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

  constructor(
    private http: HttpClient,
    private serviceActionHandler: ServiceActionHandler,
    appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: FittingStationGroupService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('FittingStationGroupService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  loadAll(): Promise<FittingStationGroup[]> {
    return this.http
      .get<FittingStationGroup[]>(this.httpUrl, { headers: this.headers })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }

  public get(id: number): Promise<FittingStationGroup> {
    return this.http
      .get<FittingStationGroup>(this.httpUrl + '/' + id, { headers: this.headers })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }
  public getMultiple(ids: number[]): Promise<FittingStationGroup[]> {
    const url = `${this.httpUrl}/GetMultiple?${UrlUtils.getQueryStringForIds(ids)}`;

    return this.http
      .get<FittingStationGroup[]>(url, { headers: this.headers })
      .toPromise()
      .catch(this.serviceActionHandler.handleDefaultError);
  }
  public create(fittingStationGroup: FittingStationGroup): Promise<ApiActionResult> {
    const url = `${this.httpUrl}`;
    const createCommand = {
      Name: fittingStationGroup.Name,
      Vat: fittingStationGroup.Vat,
      LegalName: fittingStationGroup.LegalName,
      GroupType: fittingStationGroup.GroupType,
      // ChamberOfCommerce: fittingStationGroup.Coc,
      // Iban: fittingStationGroup.Iban,
      Status: fittingStationGroup.Status,
      LegalEntityId: fittingStationGroup.LegalEntityId,
      LegalEntityName: fittingStationGroup.LegalEntityName,
      InvoiceAddressStreet: fittingStationGroup.InvoiceAddress.Street,
      InvoiceAddressZipCode: fittingStationGroup.InvoiceAddress.ZipCode,
      InvoiceAddressExtra: fittingStationGroup.InvoiceAddress.Extra,
      InvoiceAddressCity: fittingStationGroup.InvoiceAddress.City,
      InvoiceAddressRegion: fittingStationGroup.InvoiceAddress.Region,
      InvoiceAddressCountryId: fittingStationGroup.InvoiceAddress.CountryId,
      ErpIdentifier: fittingStationGroup.ErpIdentifier,
      VendorIdentifier: fittingStationGroup.VendorIdentifier,
      AverageCost: fittingStationGroup.AverageCost,
    };
    console.log('POST - JSON Command: ', JSON.stringify(createCommand));
    return this.http
      .post(url, JSON.stringify(createCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public update(fittingStationGroup: FittingStationGroup): Promise<ApiActionResult> {
    const url = `${this.httpUrl}/${fittingStationGroup.Id}`;
    const updateCommand = {
      Id: fittingStationGroup.Id,
      Name: fittingStationGroup.Name,
      LegalName: fittingStationGroup.LegalName,
      GroupType: fittingStationGroup.GroupType,
      Vat: fittingStationGroup.Vat,
      // ChamberOfCommerce: fittingStationGroup.Coc,
      // Iban: fittingStationGroup.Iban,
      Status: fittingStationGroup.Status,
      LegalEntityId: fittingStationGroup.LegalEntityId,
      LegalEntityName: fittingStationGroup.LegalEntityName,
      InvoiceAddressStreet: fittingStationGroup.InvoiceAddress.Street,
      InvoiceAddressZipCode: fittingStationGroup.InvoiceAddress.ZipCode,
      InvoiceAddressExtra: fittingStationGroup.InvoiceAddress.Extra,
      InvoiceAddressCity: fittingStationGroup.InvoiceAddress.City,
      InvoiceAddressRegion: fittingStationGroup.InvoiceAddress.Region,
      InvoiceAddressCountryId: fittingStationGroup.InvoiceAddress.CountryId,
      ErpIdentifier: fittingStationGroup.ErpIdentifier,
      VendorIdentifier: fittingStationGroup.VendorIdentifier,
      AverageCost: fittingStationGroup.AverageCost,
    };
    console.log('PUT - JSON Command: ', JSON.stringify(updateCommand));
    return this.http
      .put(url, JSON.stringify(updateCommand), { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }

  public delete(fittingStationGroupId: number): Promise<ApiActionResult> {
    const url = `${this.httpUrl}/${fittingStationGroupId}`;
    return this.http
      .delete(url, { headers: this.headers })
      .toPromise()
      .then(response => this.serviceActionHandler.handleActionSuccess(response))
      .catch(error => this.serviceActionHandler.handleActionError(error));
  }
}
