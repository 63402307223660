import { AfterViewInit, Component, ViewChild, Input } from '@angular/core';
import { saveAs } from 'file-saver';
import { PDFDocumentProxy, PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Document } from '@hrz/core/models/document';
import { DynamicModal } from '../../dynamic-modal.module';

@Component({
  selector: 'document-modal',
  providers: [PdfViewerModule],
  templateUrl: 'document.modal.html',
  styleUrls: ['document.modal.scss'],
})
@DynamicModal()
export class DocumentModal implements AfterViewInit {
  public document: Document;
  public emailAddress: string;
  public subject: string;
  public onCancel: Function;
  private closeModal: Function;

  @Input() canDownload = true;
  @Input() overrideSendEmail: () => Promise<boolean>;
  @Input() emailAddressIsReadOnly = false;

  @ViewChild('myModal') private childModal: ModalDirective;

  pdfPage = 1;
  pageCount = 0;
  pages: number[];

  callBackFn(pdf: PDFDocumentProxy) {
    this.pages = [];
    this.pageCount = pdf.numPages;
    for (let i = 1; i <= this.pageCount; i++) { this.pages.push(i); }
  }

  ngAfterViewInit(): void {
    console.log('DocumentModal.ngAfterViewInit');
    this.childModal.show();
  }

  clickClose(): void {
    console.log('DocumentModal.clickClose()');
    this.closeModal();
    if (this.onCancel) { this.onCancel(); }
  }

  btnDownloadFile(): void {
    console.log('DocumentModal.btnDownloadFile()');
    if (this.document) { saveAs(this.document.FileBlob, this.document.FileNameTranslated); }
  }

  readSubject(): string {
    if (this.subject) {
      return this.subject;
    }
    if (this.document) {
      return this.document.DocumentType;
    }
    return '';
  }
}
