import * as _ from 'lodash';
import { Pipe, PipeTransform } from '@angular/core';
// import { DatePipe } from "@angular/common";
import { WebauthorityListItem } from '@hrz/core/models/webauthority-list-item';
import * as moment from 'moment';

@Pipe({
  name: 'webauthorityFilter',
})
export class webauthorityFilterPipe implements PipeTransform {
  transform(
    array: WebauthorityListItem[],
    filterString: string,
    _filterJobState: string,
    filterAccountName: string,
    filterDate: string,
    _filterJobSubState: string
  ): any {
    let returnArr = array;

    // Filter SearchBar
    if (filterString) {
      filterString = filterString.toLowerCase();
      const self = this;
      returnArr = _.filter(returnArr, function (item: WebauthorityListItem) {
        return (
          // self.filterString(item.AmountOfJobs, filterString) ||
          // self.filterString(item.DossierAccountName, filterString) ||
          // self.filterString(item.DossierStatusCategoryName, filterString) ||
          // self.filterString(item.DossierStatusName, filterString) ||
          // self.filterString(item.DossierVehicleLicensePlate, filterString) ||
          self.filterString(item.FittingStationName, filterString) || self.filterString(item.DossierId, filterString)
        );
      });
    }

    // Filter by JobState dropdown
    // if (filterJobState) {
    //     filterJobState = filterJobState.toLowerCase();
    //     returnArr = _.filter(returnArr, function(item: WebauthorityListItem) {
    //         return (
    //             item.DossierStatusCategoryName &&
    //             item.DossierStatusCategoryName.toLowerCase() == filterJobState.toLowerCase()
    //         );
    //     });
    // }

    // Filter by JobState dropdown
    // if (filterJobSubState) {
    //     filterJobSubState = filterJobSubState.toLowerCase();
    //     returnArr = _.filter(returnArr, function(item: WebauthorityListItem) {
    //         return (
    //             item.DossierStatusName &&
    //             item.DossierStatusName.toLowerCase() == filterJobSubState.toLowerCase()
    //         );
    //     });
    // }

    // Filter by AccountName dropdown
    if (filterAccountName) {
      filterAccountName = filterAccountName.toLowerCase();
      returnArr = _.filter(returnArr, function (item: any) {
        return (
          (item.DossierAccountName && item.DossierAccountName.toLowerCase().trim() == filterAccountName) ||
          (item.AccountName && item.AccountName.toLowerCase().trim() == filterAccountName)
        );
      });
    }

    // Apply date filters
    if (filterDate) {
      let startDate: moment.Moment;
      let endDate: moment.Moment;
      switch (filterDate) {
        case 'ThisWeek':
          startDate = moment().startOf('isoWeek');
          endDate = moment().endOf('isoWeek');
          break;
        case 'LastWeek':
          startDate = moment().subtract(1, 'week').startOf('isoWeek');
          endDate = moment().subtract(1, 'week').endOf('isoWeek');
          break;
        case 'ThisMonth':
          startDate = moment().date(1);
          endDate = moment().add(1, 'month').date(1).subtract(1, 'day');
          break;
        case 'LastMonth':
          startDate = moment().subtract(1, 'month').date(1);
          endDate = moment().date(1).subtract(1, 'day');
          break;
      }

      returnArr = _.filter(returnArr, function (item: WebauthorityListItem) {
        if (item.Created) {
          const creationDate = moment(item.Created);
          return creationDate.isSameOrAfter(startDate, 'day') && creationDate.isBefore(endDate, 'day');
        }
        return false;
      });
    }

    return returnArr;
  }

  filterString(value: any, query: string): boolean {
    if (value) {
      // Check on lower case string and return result
      return value.toString().toLowerCase().includes(query);
    }
    // No value to check, skip
    return false;
  }
}
