import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureComponent } from './signature/signature.component';
import { HighlightImageComponent } from './highlight-image/highlight-image.component';

@NgModule({
  imports: [CommonModule],
  declarations: [SignatureComponent, HighlightImageComponent],
  exports: [SignatureComponent, HighlightImageComponent],
})
export class CanvasHelperModule {}
