import { Injectable, Optional, SkipSelf } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppInsightsService } from '@hrz/core/services/app-insights.service';

@Injectable({
  providedIn: 'root',
})
export class VatDivertCheckService {
  private isVatDivertRequiredField = new BehaviorSubject(false);

  isVatDivertRequired = this.isVatDivertRequiredField.asObservable();

  constructor(appInsightsService: AppInsightsService,
    @Optional() @SkipSelf() parent?: VatDivertCheckService
  ) {
    // Enforces this service to be loaded as a singleton
    if (parent) {
      appInsightsService.logException(new Error('VatDivertCheckService is a Singleton and should only be loaded in AppModule.'));
    }
  }

  setVatDiverted(nextStatus: boolean) {
    this.isVatDivertRequiredField.next(nextStatus);
  }
}
