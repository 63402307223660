import { from as observableFrom, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, ViewChild, OnInit, Input, OnDestroy, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { HighlightImageComponent } from '@hrz/shared/components/canvas-helper/highlight-image/highlight-image.component';
import { DamageIndicateData } from '@hrz/core/models/dossier/damage-indicate-data';
import { HighlightImage } from '@hrz/core/models/highlight-image';

@Component({
  selector: 'app-indicate-damage',
  templateUrl: './indicate-damage.component.html',
  styleUrls: ['./indicate-damage.component.scss'],
})
export class IndicateDamageComponent implements OnInit, OnChanges, OnDestroy {
  private otherFormFieldChangesSubscription: Subscription;
  private anyFormDataChangeSubscription: Subscription;
  private pointsCountOnHighlightImageComponent: number;
  @ViewChild(HighlightImageComponent) highlightImageComponent: HighlightImageComponent;
  @Input() damageIndicateData: DamageIndicateData;
  @Input() disabled = false;
  @Output() onChange: EventEmitter<void> = new EventEmitter<void>();

  damageIndicateForm: FormGroup;

  ngOnInit(): void {
    this.damageIndicateForm = this.getInitializedForm(this.damageIndicateData);
    this.otherFormFieldChangesSubscription = this.damageIndicateForm.get('other').valueChanges.subscribe((value: boolean) => {
      this.otherFormFieldChanged(value);
    });
    this.anyFormDataChangeSubscription = this.damageIndicateForm.valueChanges.subscribe(() => {
      this.componentDataChange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.damageIndicateForm && changes['disabled'] != null) {
      this.disabled ? this.damageIndicateForm.disable() : this.damageIndicateForm.enable();
    }
  }

  ngOnDestroy(): void {
    this.otherFormFieldChangesSubscription.unsubscribe();
    this.anyFormDataChangeSubscription.unsubscribe();
  }

  otherFormFieldChanged(newValue: boolean): void {
    const control = this.damageIndicateForm.get('otherInfo');
    newValue ? control.enable() : control.disable();
  }

  isValid(): boolean {
    return (
      this.pointsCountOnHighlightImageComponent > 0 ||
      Object.keys(this.damageIndicateForm.value).some(
        (key: string) => this.damageIndicateForm.value[key] !== false && this.damageIndicateForm.value[key] !== ''
      )
    );
  }

  validationChecked = false;
  _isValid = false;
  checkForValid(): boolean {
    if (this.validationChecked) { return this._isValid; }
    console.log('IndicateDamageComponent.checkForValid()');
    const countedPointsInImage = this.highlightImageComponent.getPoints().length;
    const countedPoints = this.pointsCountOnHighlightImageComponent;
    const checkboxesSelected = Object.keys(this.damageIndicateForm.value).some(
      (key: string) => this.damageIndicateForm.value[key] !== false && this.damageIndicateForm.value[key] !== ''
    );
    this._isValid = countedPointsInImage > 0 || countedPoints > 0 || checkboxesSelected;
    this.validationChecked = true;
    return this._isValid;
  }

  getModel(): Observable<DamageIndicateData> {
    const formValue = (fieldName: string) => this.damageIndicateForm.get(fieldName).value;
    return observableFrom(this.highlightImageComponent.getMetadata()).pipe(
      map((metadata: HighlightImage) => ({
        id: this.damageIndicateData ? this.damageIndicateData.id : 0,
        lightSensor: formValue('lightSensor'),
        rainSensor: formValue('rainSensor'),
        radio: formValue('radio'),
        dashboard: formValue('dashboard'),
        centralLocking: formValue('centralLocking'),
        gps: formValue('gps'),
        mobilePhone: formValue('mobilePhone'),
        wipers: formValue('wipers'),
        electricMirrors: formValue('electricMirrors'),
        windowRunner: formValue('windowRunner'),
        adas: formValue('adas'),
        viaVerde: formValue('viaVerde'),
        other: formValue('other'),
        otherInfo: formValue('otherInfo'),
        highlightedDamageImage: metadata,
      }))
    );
  }

  componentDataChange() {
    console.log('IndicateDamageComponent.componentDataChange');
    this.onChange.emit();
    this.validationChecked = false;
  }

  imageChange(pointsCount: number) {
    console.log('IndicateDamageComponent.imageChange');
    this.onChange.emit();
    this.validationChecked = false;
    this.pointsCountOnHighlightImageComponent = pointsCount;
  }

  private getInitializedForm(data: DamageIndicateData = new DamageIndicateData()): FormGroup {
    return new FormGroup({
      lightSensor: new FormControl(data.lightSensor),
      rainSensor: new FormControl(data.rainSensor),
      radio: new FormControl(data.radio),
      dashboard: new FormControl(data.dashboard),
      centralLocking: new FormControl(data.centralLocking),
      gps: new FormControl(data.gps),
      mobilePhone: new FormControl(data.mobilePhone),
      wipers: new FormControl(data.wipers),
      electricMirrors: new FormControl(data.electricMirrors),
      windowRunner: new FormControl(data.windowRunner),
      adas: new FormControl(data.adas),
      viaVerde: new FormControl(data.viaVerde),
      other: new FormControl(data.other),
      otherInfo: new FormControl({ value: data.otherInfo, disabled: !data.other }),
    });
  }
}
