import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

import { DynamicModal } from '../dynamic-modal.module';

@Component({
  selector: 'custom-confirmation-modal',
  template: `
    <div bsModal #myModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered glassdrive-form   glassdrive-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <ng-container>
              <h4 class="modal-title">{{ title | translate }}</h4>
            </ng-container>
            <button type="button" class="close" (click)="clickCancel()" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ng-container>
              <p>{{ message | translate }}</p>
            </ng-container>
          </div>
          <div class="modal-footer clearfix">
            <button type="button" class="btn btn-secondary-light btn-md pull-left" (click)="clickCancel()">
              {{ cancelButtonText | translate }}
            </button>
            <button type="button" class="btn btn-danger btn-md pull-right" *ngIf="alertUser" (click)="clickOk()">
              {{ okButtonText | translate }}
            </button>
            <button type="button" class="btn btn-primary btn-md pull-right" *ngIf="!alertUser" (click)="clickOk()">
              {{ okButtonText | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  `,
})
@DynamicModal()
export class CustomConfirmationModal implements OnInit, AfterViewInit, OnDestroy {
  public title: string;
  public message: string;
  public alertUser: boolean;

  public cancelButtonText: string;
  public okButtonText: string;

  public onOk: Function;
  public onClose: Function;

  // This will be called when the modal is being closed / destroyed.
  private closeModal: Function;
  @ViewChild('myModal') private childModal: ModalDirective;

  ngOnInit(): void {
    console.log('CancelConfirmationModal.ngOnInit');
  }
  ngAfterViewInit(): void {
    console.log('CancelConfirmationModal.ngAfterViewInit');
    this.childModal.show();
  }
  ngOnDestroy(): void {
    console.log('CancelConfirmationModal.ngOnDestroy');
  }

  clickCancel(): void {
    this.closeModal();
    if (this.onClose) { this.onClose(); }
  }

  clickOk(): void {
    this.closeModal();
    if (this.onOk) { this.onOk(); }
  }
}
